import { CommonModule } from '@angular/common';
import { Input, Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-icon-button',
  standalone: true,
  imports: [ MatIconModule, CommonModule ],
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent implements OnInit {
  fontWeight?: 'bold' | 'normal' | 'lighter';
  @Input() text: any = "";
  @Input() size: any = "";
  @Input() fontSize: any = "";
  @Input() widthIcon: any = "25px";
  @Input() heightIcon: any = "25px";
  @Input() icon: String = "";
  @Input() gap: String = "1";
  @Input() color: String = "";
  @Input() textSize: String = "";
  @Input() textColor: String = "";
  @Input() firstIcon: string = 'false';
  @Input() colorIcon: string = 'false';
  @Input() matIconColor: string = '';
  @Input() sizeIcon: string = '';
  @Input() disabled: string = '';
  @Input() filter: string = 'grayscale(1);';
  @Input() vip?: boolean = false;
  @Input() isDisabled?: boolean = false;
  // @Input() vip: string = 'filter: grayscale(1);';
  @Input() class: string = '';
  @Input() type: string = '';
  @Input() styles?: { [key: string]: string };
  @Input() circle?: boolean = false;

  public reverse = "";
  ngOnInit(): void {
    if(this.isDisabled){
      this.disabled = 'disabled';
    }else {
      this.disabled = '';
    }

    if (this.firstIcon === 'true') {
      this.reverse = "flex-row-reverse";
    } else {
      this.reverse = "flex-row";
    }

    if(this.vip){
      this.filter = 'grayscale(1)';
    }else {
      this.filter = 'grayscale(0)';
    }
  }
}

