<div style="width: 100%; height: auto; position: relative;">
    @if(isPdf(fileUrl)){
        <div style="width: 100%; height: 100%;">
            <iframe 
                [src]="sanitizer.bypassSecurityTrustResourceUrl(fileUrl)"
                (loadeddata)="onIframeLoad()"
                width="100%" 
                height="100%"
                frameborder="0">
            </iframe>
            <div class="actions">
                <button (click)="openFile(fileUrl)">Abrir</button>
                <!-- <button (click)="downloadFile(fileUrl)">Guardar como...</button> -->
              </div>
        </div>
    }@else if(isVideo(fileUrl)){
        <div style="width: 100%">
            <video
                [src]="fileUrl" 
                controls 
                width="100%" 
                height="100%" 
                (loadeddata)="onIframeLoad()"
            ></video>
        </div>
    }@else{
        <div style="width: 100%;">
            <img
            [src]="fileUrl"
            alt="Vista previa"
            style="width: 100%; height: auto; display: block;" />
        </div>
    }
</div>
