<div class="modal fade" id="modal-folloups-history" tabindex="-1" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl custom-modal-width">
    <div class="modal-content">
      <div class="modal-header text-center align-items-center">
        <label class="modal-subtitle">{{'process_started_on' | translate}} <b>11-10-2023</b></label>
        <h1 class="modal-title fs-5 ms-auto  d-flex align-items-center gap-1" id="exampleModalLabel">
          <so-icon svg="reload-alt" [size]="0.8"></so-icon>
          {{'follow_up_history' | translate}}
        </h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="d-flex justify-content-center gap-4 mt-3">
          <div class="d-flex gap-2">
            <div class="bd-circle-success"></div>
            <label class="bd-title">{{'followup_to_be_completed_today' | translate}}</label>
          </div>
          <div class="d-flex gap-2">
            <div class="bd-circle-warning"></div>
            <label class="bd-title"> <app-so-replace-text-color
                text="day_has_passed_since_the_established_fulfillment_date"
                [replacement]="'1'"></app-so-replace-text-color></label>
          </div>
          <div class="d-flex gap-2">
            <div class="bd-circle-danger"></div>
            <label class="bd-title"> <app-so-replace-text-color
                text="day_has_passed_since_the_established_fulfillment_date" color="fff"
                [replacement]="'2'"></app-so-replace-text-color></label>
          </div>

        </div>

        <div class="table-filters d-flex align-items-center justify-content-between mt-4">
          <div class="d-flex  justify-content-center align-items-center gap-2">
            <label class="table_view">{{'show' | translate}}</label>
            <select class="form-select form-select-sm">
              <option *ngFor="let item of table_list" [value]="item">{{item}}</option>
            </select>
            <label class="so-text-4">{{'records' | translate}}</label>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <input type="text" class="form-control form-control-sm inputSearch"
              placeholder="{{'search' | translate}}">
            <so-icon class="btnSeach" icon="search" />
          </div>

        </div>

        <div class="table-responsive mt-1">
          <table class="table table-sm table-hover">
            <thead>
              <tr>
                <th scope="col" style="white-space:nowrap">

                </th>
                <th scope="col" style="white-space:nowrap" class="text-center"
                  *ngFor="let table_header of table_header ">
                  <div class="so-text-3-bold d-flex align-items-center justify-content-center">
                    {{table_header | translate}} <so-icon icon="unfold_more" [size]="0.7" /></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class=" align-items-center" *ngFor="let data of table_data"
                [style.background]="'var(--so-' + data.bg + '-subtle-opacity-50)'">

                <td class="text-center " style="background: none;">
                  <div class="d-flex justify-content-end align-items-center">
                    <so-icon [svg]="dataIcons" *ngFor="let dataIcons of data.icons" [size]="0.8" />
                  </div>
                </td>
                <td class="text-center " style="background: none;">
                  <label class="so-text-3">{{data.cumplida}}</label>
                </td>
                <td class="text-center " style="background: none;">
                  <label class="so-text-3">{{data.exitosa}}</label>
                </td>
                <td class="text-center " style="background: none;">
                  <label class="so-text-3">{{data.intentos}}</label>
                </td>
                <td class="text-center " style="background: none;">
                  <label class="so-text-3">{{data.fecha}}</label>
                </td>
                <td class="text-center " style="background: none;">
                  <label class="so-text-3">{{data.detSeg}}</label>
                </td>
                <td class="text-center " style="background: none;">
                  <label class="so-text-3">{{data.status}}</label>
                </td>
                <td class="text-center " style="background: none;">
                  <label class="so-text-3">{{data.autoInt}}</label>
                </td>
                <td class="text-center " style="background: none;">
                  <label class="so-text-3">{{data.obs}}</label>
                </td>
                <td class="text-center " style="background: none;">
                  <label class="so-text-3">{{data.fechaCump}}</label>
                </td>
                <td class="text-center " style="background: none;">
                  <label class="so-text-3">{{data.obsCump}}</label>
                </td>
                <td class="text-center " style="background: none;">
                  <label class="so-text-3">{{data.ejecutivo}}</label>
                </td>
                <td class="text-center " style="background: none;">
                  <label class="so-text-3">{{data.rol}}</label>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-pagination d-flex align-items-center justify-content-between">
          <label for="">
            <app-so-replace [text]="'showing_records_of_table' | translate" replacement="{{ getRegister() }}"
              replacement="{{ getCountRegisters() }}" replacement="{{ table_data.length }}"></app-so-replace>
          </label>

          <div class="d-flex justify-content-between gap-2">
            <button type="button" class="btn btn-sm d-flex align-items-center btn-filtersTable"
              [class.disabled]="currentPage === 1" (click)="prevPage()">
              <so-icon svg="play-reverse-success" [size]="0.5"></so-icon>
              {{'previous' | translate}}
            </button>

            <button type="button" class="btn btn-sm d-flex align-items-center btn-filtersTable filtersTable-selected"
              *ngFor="let page of pages" (click)="setPage(page)">
              {{ page }}
            </button>

            <button type="button" class="btn btn-sm d-flex align-items-center btn-filtersTable"
              [class.disabled]="currentPage === totalPages" (click)="nextPage()">
              {{'next' | translate}}
              <so-icon svg="mdi-play-success" [size]="0.5"></so-icon>
            </button>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>
