import { Component, Input, OnInit, Output } from '@angular/core';
import { MarcasService } from '@core/services/catalogos/autos-de-interes/nuevos/marcas.service';
import { GlobalSessionService } from '@core/services/global-session.service';
import { Request } from '@core/models/requests.model';
import { AutosService } from '@core/services/catalogos/autos-de-interes/nuevos/autos.service';
import { AniosService } from '@core/services/catalogos/autos-de-interes/nuevos/anios.service';
import { VersionesService } from '@core/services/catalogos/autos-de-interes/nuevos/versiones.service';
import { ColoresService } from '@core/services/catalogos/autos-de-interes/nuevos/colores.service.';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'so-car-interest-new',
  templateUrl: './car-interest-new.component.html',
  styleUrl: './car-interest-new.component.scss'
})
export class CarInterestNewComponent implements OnInit{

  @Input() form!: FormGroup;
  @Input() brandControl: string = '';
  @Input() carsControl: string = '';
  @Input() yearsControl: string = '';
  @Input() versionsControl: string = '';
  @Input() colorsControl: string = '';
  @Input() directionItems: 'row' | 'column' = 'row';

  private userInfo = this.globalSessionService.userInfo().data;
  
  public isMultiBrand: boolean = false;

  public loadingBrand: boolean = false;
  public loadingCars: boolean = false;
  public loadingYears: boolean = false;
  public loadingVersions: boolean = false;  
  public loadingColors: boolean = false;  

  public listBrands: any[] = [];
  public listCars: any[] = [];
  public listYears: any[] = [];
  public listVersions: any[] = [];
  public listColors: any[] = [];

  private selectedModelCar: string = '';
  private selectedYearCar: string = '';
  
  constructor(
    private globalSessionService: GlobalSessionService,
    private request: Request,
    private marcasService: MarcasService,
    private autosService: AutosService,
    private aniosService: AniosService,
    private versionesService: VersionesService,
    private coloresService: ColoresService,
  ){
    this.setIsMultiBrand();
  }

  ngOnInit(): void {

    this.loadControlsForm();
   
    if(this.isMultiBrand){
      this.loadBrands();
    }else{
      this.loadCars('153');
    }
  }

  loadControlsForm(){
    if (!this.form.get(this.carsControl)) {
      this.form.addControl(this.carsControl, new FormControl());
    }
    if (!this.form.get(this.yearsControl)) {
      this.form.addControl(this.yearsControl, new FormControl());
    }
    if (!this.form.get(this.versionsControl)) {
      this.form.addControl(this.versionsControl, new FormControl());
    }
    if (!this.form.get(this.colorsControl)) {
      this.form.addControl(this.colorsControl, new FormControl());
    }
  }

  loadBrands(){
    this.loadingBrand = true;
    const paramsBrand = {
      configuracion: this.request.getConfigUser(),
      idEmpresa: this.userInfo.empleado.empresa.idEmpresa,
      idTipoDeNegocio: this.userInfo.tiposDeNegocio[0].idTipoDeNegocio,
    }

    this.marcasService.getMarcas(paramsBrand).subscribe({
      next: (res) => {
          const response = res.response;
          this.listBrands = this.marcasService.formatValues(response, 'idmarca', 'alias');
          this.loadingBrand  = false;
      },
      error: (err) => {
        console.log(err);
        this.loadingBrand  = false;
      }
    })
  }

  
  onChangeBrand(value: any){
    this.listCars = [];
    this.listYears = [];
    this.listVersions = [];
    this.listColors = [];
    this.loadCars(value);
  }

  loadCars(value: string){
    this.loadingCars = true;
    const paramsCars = {
      configuracion: this.request.getConfigUser(),
      idMarca: value,
      idTipoDeNegocio: this.userInfo.tiposDeNegocio[0].idTipoDeNegocio,
    }

    this.autosService.getAllAutos(paramsCars).subscribe({
      next: (res) => {
        const response = res.response;
        this.listCars = this.autosService.formatValues(response, 'idauto', 'nombre');
        this.loadingCars = false;
      },
      error:(err) => {
        console.log(err);
        this.loadingCars = false;
      }
    })
  }

  onChangeCars(value: any){
    this.listYears = [];
    this.listVersions = [];
    this.listColors = [];
    this.selectedModelCar = value;
    this.loadCarYears(value);
  }

  loadCarYears(value: any){
    this.loadingYears = true;
    const paramsCarYears = {
      configuracion: this.request.getConfigUser(),
      idAuto: value,
      idMarca: '153',
      idTipoDeNegocio: 'TN00000001',
    }

    this.aniosService.getAnios(paramsCarYears).subscribe({
      next: (res) => {
        const response = res.response;
        this.listYears = this.aniosService.formatValues(response, 'anio', 'anio');
        this.loadingYears = false;
      },
      error: (err) => {
        console.log(err);
        this.loadingYears = false;
      }
    })
  }

  onChangeCarYears(value: any){
    this.listVersions = [];
    this.listColors = [];
    this.selectedYearCar = value;
    this.loadCarVersions(value);
  }

  loadCarVersions(value: any){
    this.loadingVersions = true;
    const paramsVersionsCar = {
      configuracion: this.request.getConfigUser(),
      idAuto: this.selectedModelCar,
      anio: this.selectedYearCar,
      idMarca: '153',
      idTipoDeNegocio: 'TN00000001',
    }

    this.versionesService.getVersiones(paramsVersionsCar).subscribe({
      next: (res) => {
        const response = res.response;
        this.listVersions = this.versionesService.formatValues(response, 'idProducto', 'nombre');
        this.loadingVersions = false;
      },
      error: (err) => {
        console.log(err);
        this.loadingVersions = false;
      } 
    })
  }

  onChangeVersionCar(value: any){
    this.listColors = [];
    this.loadColorsCar(value);
  }

  loadColorsCar(value: any){
    this.loadingColors = true;

    const paramsColorsCar = {
      configuracion: this.request.getConfigUser(),
    }

    this.coloresService.getAllColores(paramsColorsCar).subscribe({
      next: (res) => {
        const response = res.response;
        this.listColors = this.coloresService.formatValues(response, 'idColor', 'nombre', 'color');
        this.loadingColors = false;
      },
      error: (err) => {
        console.log(err);
        this.loadingColors = false;
      }
    })
  }


  setIsMultiBrand(){
    this.isMultiBrand = this.userInfo.empleado.empresa.habilitarMultimarca;
  }


}
