import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-folloups-history',
  templateUrl: './modal-folloups-history.component.html',
  styleUrl: './modal-folloups-history.component.scss'
})
export class ModalFolloupsHistoryComponent {


  table_header = [
    'fulfilled',
    'successful',
    'attempts',
    'date',
    'det_fu',
    'status',
    'car_interest',
    'obs',
    'date_ful',
    'obs_ful',
    'executive',
    'role',
  ]
  table_data = [
    {
      icons: ['mdi-check-circle-outline', 'mdi-alarm-success', "mdi-visibility-success", "delete-forever-outline-danger"],
      cumplida: 'No',
      exitosa: 'No',
      intentos: 3,
      fecha: '2023-02-13 12:00 horas',
      detSeg: 'Llamada de Seguimiento',
      status: 'Cita',
      autoInt: 'Aveo 2022',
      obs: 'Ok',
      fechaCump: '2023-02-13 12:00 horas',
      obsCump: 'Iniciar compra de contacto',
      ejecutivo: 'Danie Angel',
      rol: 'Ejecutivo',
      bg: 'success'
    },
    {
      icons: ['mdi-check-circle-outline', "mdi-visibility-success", "delete-forever-outline-danger"],
      cumplida: 'No',
      exitosa: 'No',
      intentos: 3,
      fecha: '2023-02-13 12:00 horas',
      detSeg: 'Llamada de Seguimiento',
      status: 'Cita',
      autoInt: 'Aveo 2022',
      obs: 'Ok',
      fechaCump: '2023-02-13 12:00 horas',
      obsCump: 'Iniciar compra de contacto',
      ejecutivo: 'Danie Angel',
      rol: 'Ejecutivo',
      bg: 'danger'
    },
    {
      icons: ['mdi-check-circle-outline', 'mdi-alarm-success', "mdi-visibility-success", "delete-forever-outline-danger"],
      cumplida: 'No',
      exitosa: 'No',
      intentos: 3,
      fecha: '2023-02-13 12:00 horas',
      detSeg: 'Llamada de Seguimiento',
      status: 'Cita',
      autoInt: 'Aveo 2022',
      obs: 'Ok',
      fechaCump: '2023-02-13 12:00 horas',
      obsCump: 'Iniciar compra de contacto',
      ejecutivo: 'Danie Angel',
      rol: 'Ejecutivo',
      bg: 'warning'
    },
    {
      icons: ['mdi-check-circle-outline', 'mdi-alarm-success', "mdi-visibility-success", "delete-forever-outline-danger"],
      cumplida: 'No',
      exitosa: 'No',
      intentos: 3,
      fecha: '2023-02-13 12:00 horas',
      detSeg: 'Llamada de Seguimiento',
      status: 'Cita',
      autoInt: 'Aveo 2022',
      obs: 'Ok',
      fechaCump: '2023-02-13 12:00 horas',
      obsCump: 'Iniciar compra de contacto',
      ejecutivo: 'Danie Angel',
      rol: 'Ejecutivo',
      bg: 'danger'
    },
    {
      icons: [],
      cumplida: 'No',
      exitosa: 'No',
      intentos: 3,
      fecha: '2023-02-13 12:00 horas',
      detSeg: 'Llamada de Seguimiento',
      status: 'Cita',
      autoInt: 'Aveo 2022',
      obs: 'Ok',
      fechaCump: '2023-02-13 12:00 horas',
      obsCump: 'Iniciar compra de contacto',
      ejecutivo: 'Danie Angel',
      rol: 'Ejecutivo',
      bg: ''
    },
    {
      icons: [],
      cumplida: 'No',
      exitosa: 'No',
      intentos: 3,
      fecha: '2023-02-13 12:00 horas',
      detSeg: 'Llamada de Seguimiento',
      status: 'Cita',
      autoInt: 'Aveo 2022',
      obs: 'Ok',
      fechaCump: '2023-02-13 12:00 horas',
      obsCump: 'Iniciar compra de contacto',
      ejecutivo: 'Danie Angel',
      rol: 'Ejecutivo',
      bg: ''
    },

  ];


  currentPage = 1;
  itemsPerPage = 10;
  get totalPages(): number {
    return Math.ceil(this.table_data.length / this.itemsPerPage);
  }

  get pages(): number[] {
    const pages = [];
    for (let i = 1; i <= this.totalPages; i++) {
      pages.push(i);
    }
    return pages;
  }

  setPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }

  prevPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  getCountRegisters() {
    return '' + Math.min(this.currentPage * this.itemsPerPage, this.table_data.length)
  }

  getRegister() {
    return '' + (this.currentPage - 1) * this.itemsPerPage + 1;
  }

  table_list = [
    5,
    10,
    20,
    50
  ]

}
