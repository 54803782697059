import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-default-image',
  templateUrl: './modal-default-image.component.html',
  styleUrls: ['./modal-default-image.component.scss']
})
export class ModalDefaultImageComponent{
  selectedImage: string | null = null; // Inicialmente, no hay ninguna imagen seleccionada

  // Función para manejar el clic en una imagen
  selectImage(imagePath: string): void {
    this.selectedImage = imagePath;
  }
}
