<div class="data-Prospect ">
  <div class="" style="position: absolute; right: 5%; top: 6%;">
    <mat-icon class="mat-Icon so-secondary">record_voice_over</mat-icon>
  </div>
  <h5 class="name-Prospect">{{name_Prospect}}</h5>
  <h6 class="subtitle">{{status_Prospect}}</h6>
  <div class="div-icon mt-2 row">
    <!-- <div class="mt-3 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-xxl-2" *ngFor=" let icons of iconsProspects"
      [ngClass]="icons.visible ? '' : 'icon-visible'">
      <div class="icon-outline-white" style="cursor: pointer;" data-bs-toggle="modal"
      [attr.data-bs-target]="'#'+ icons.event">
      <so-icon svg="{{icons.icon}}" [size]="0.6" [ngStyle]="{'transform' : icons.icon == 'reload-alt-white' ? ' rotate(95deg)' : ''}" />
    </div>
  </div> -->
    <div class="mt-3 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-xxl-2" *ngFor=" let icons of iconsProspects"
      [ngClass]="icons.visible ? '' : 'icon-visible'">
      <app-icon-button icon="{{path}}{{icons.icon}}.svg" [circle]="true" [widthIcon]="'20px'" [heightIcon]="'20px'"
        data-bs-toggle="modal" [attr.data-bs-target]="'#'+ icons.event">
      </app-icon-button>
    </div>
  </div>
</div>

<div class="accordion-container">
  <app-so-accordion [styleAccordion]="styleAccordion">
    <app-so-accordion-panel panelId="prospect_data" panelTitle="prospect_data" svg="mdi-contact-info">
    @if (visible && notLoading) {
      @defer (on viewport) {
      <app-accordion-prospect-data></app-accordion-prospect-data>
      }
      @placeholder {
      <so-spinner />
      }
    }
    @else {
      <so-spinner />
    }
    </app-so-accordion-panel>
    <app-so-accordion-panel panelId="communication_channels" panelTitle="communication_data" svg="mdi-handshake-outline">
    @if (visible && notLoading) {
      @defer (on viewport) {
      <app-accordion-communication-channels></app-accordion-communication-channels>
      }
      @placeholder {
      <so-spinner />
      }
    }
    @else {
      <so-spinner />
    }
    </app-so-accordion-panel>
    <app-so-accordion-panel panelId="sales_opportunity" panelTitle="sales_opportunity" svg="mdi-cash-register">
    @if (visible && notLoading) {
      @defer (on viewport) {
      <app-accordion-sales-opportunity></app-accordion-sales-opportunity>
      }
      @placeholder {
      <so-spinner />
      }
    }
    @else {
      <so-spinner />
    }
    </app-so-accordion-panel>
    <app-so-accordion-panel panelId="privacy_log" panelTitle="privacy_log" svg="mdi-account-lock-open-outline">
    @if (visible && notLoading) {
      @defer (on viewport) {
      <app-accordion-privacy-log></app-accordion-privacy-log>
      }
      @placeholder {
      <so-spinner />
      }
    }
    @else {
      <so-spinner />
    }
    </app-so-accordion-panel>
    <app-so-accordion-panel panelId="follow_up_history" panelTitle="follow_up_history" svg="reload-alt" rotateIcon="95">
    @if (visible && notLoading) {
      @defer (on viewport) {
      <app-accordion-follow-up-history></app-accordion-follow-up-history>
      }
      @placeholder {
      <so-spinner />
      }
    }
    @else {
      <so-spinner />
    }
    </app-so-accordion-panel>
    <app-so-accordion-panel panelId="sms_history" panelTitle="sms_history" svg="mdimessage-comment">
    @if (visible && notLoading) {
    }
    @else {
      <so-spinner />
    }
    </app-so-accordion-panel>
    <app-so-accordion-panel panelId="sells_history" panelTitle="follow_up_sells" svg="hitorial-ventas">
    @if (visible && notLoading) {
      @defer (on viewport) {
      <app-so-sells-component></app-so-sells-component>
      }
      @placeholder {
      <so-spinner />
      }
    }
    @else {
      <so-spinner />
    }
    </app-so-accordion-panel>
    <app-so-accordion-panel panelId="valuations_history" panelTitle="follow_up_valuations" svg="historial-valuaciones">
    @if (visible && notLoading) {
      @defer (on viewport) {
      <app-so-valuations-component></app-so-valuations-component>
      }
      @placeholder {
      <so-spinner />
      }
    }
    @else {
      <so-spinner />
    }
    </app-so-accordion-panel>
    <app-so-accordion-panel panelId="demos_history" panelTitle="follow_up_demos" svg="historial-demos">
    @if (visible && notLoading) {
      @defer (on viewport) {
      <app-so-history-component></app-so-history-component>
      }
      @placeholder {
      <so-spinner />
      }
    }
    @else {
      <so-spinner />
    }
    </app-so-accordion-panel>
    <app-so-accordion-panel panelId="attachment_history" panelTitle="attachment_history" svg="mdi-file-document-outline" [isLastItem]="true">
    @if (visible && notLoading) {
      @defer (on viewport) {
      <app-so-attachment-history [idProspecto]="currentProspect.idProspecto"></app-so-attachment-history>
      }
      @placeholder {
      <so-spinner />
      }
    }
    @else {
      <so-spinner />
    }
    </app-so-accordion-panel>
  </app-so-accordion>
</div>

<ng-content></ng-content>

<!-- MODALS -->
<!-- <app-modal-reject-prospect />
<app-modal-sale-order />
<app-modal-valuation-request />
<app-modal-add-file />
<app-modal-folloups-history></app-modal-folloups-history>
<app-so-modal-editar-prospecto />
<app-so-modal-asignar-venta />
<app-so-new-follow-up /> -->
