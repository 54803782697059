import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-so-replace',
  template: `{{ replacedText }}`,
})
export class SoReplaceComponent {
  @Input() text: string = '';
  @Input() replacement: string = '';

  get replacedText(): string {
    return this.replaceLettersAfterPercent(this.text);
  }

  replaceLettersAfterPercent(text: string): string {
    const regex = /%[a-zA-Z]/g;
    return text.replace(regex, this.replacement);
  }
}
