import { Component, inject, Input, OnInit } from '@angular/core';
import { GlobalSessionService } from '@core/services/global-session.service';
import { MARKETING_LINKS } from './links/marketing';
import { LEAD_MANAGEMENT_LINKS } from './links/lead-management';
import { ItemsLateralMenu } from '@shared/interfaces/items-lateral-menu.interface';

@Component({
  selector: 'app-shared-layout',
  templateUrl: './shared-layout.component.html',
  styleUrls: ['./shared-layout.component.scss'],
})
export class SharedLayoutComponent implements OnInit {
  public readonly globalSession = inject(GlobalSessionService);
  @Input() itemsMenu: ItemsLateralMenu = { items: [], actionsItems: [] };

  ngOnInit(): void {
    let module = localStorage.getItem('module');

    if (module === 'marketing')
      this.itemsMenu = MARKETING_LINKS;
    else
      this.itemsMenu = LEAD_MANAGEMENT_LINKS;
  }

  showClickItemEvent(event: any) {
    // console.log(event);
  }
}
