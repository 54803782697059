<app-so-modal modalId="modal-confirm-create" verticalPosition="center" size="modal-lg">
  <div class="modal__header" [style]="{'display':'flex','width':'100%','margin-top':'30px'}">
  </div>
  <div class="modal__body" [style]="{'padding':'0px'}">
    <div class="d-flex justify-content-center mt-2">
      <img src="./assets/img/mdi-report-outline.svg" alt="" style="width: 34px; height: 30px;">
      <p style="font: normal normal bold 22px/28px Montserrat; padding-left: 8px; padding-top: 2px;">
        {{'are_you_sure'|translate}}</p>
    </div>
    <div class="d-flex justify-content-center mt-2" style="width: 100%;">
      <p style="font: normal normal normal 22px/28px Montserrat; padding-left: 8px; padding-top: 2px;">
        {{'you_are_going_to_create_and_assign_the_following_products_to'|translate}}
        <span class="negrita">{{prospecto}}:</span>
      </p>
    </div>
    <div style="margin-top: 20px; margin-bottom: 20px;">
      <div class="d-flex justify-content-center">
        <label class="azul">{{'new'|translate}}:</label>
        <label class="descripcion">NP 300 Frontier | 2022 | Platinum LT | Rojo</label>
      </div>
      <div class="d-flex justify-content-center">
        <label class="azul">{{'pre_owned'|translate}}:</label>
        <label class="descripcion">NP 100 Frontier | 2008 | Silver BT | Azul</label>
      </div>
      <div class="d-flex justify-content-center">
        <label class="azul">{{'existing_fleet'|translate}}:</label>
        <label style="font: italic normal bold 16px/30px Montserrat;
        color: #272D3B;margin-left: 10px;">7x</label>
        <label class="descripcion">Hyundai Santa Fe | 2021 | Klass | Blanco</label>
      </div>
      <div class="d-flex justify-content-center">
        <label class="azul">{{'insurances'|translate}}:</label>
        <label class="descripcion">NP 300 Frontier | Seekop Seguros | Universal | Todo Riesgo </label>
      </div>
      <div class="d-flex justify-content-center">
        <label class="azul">{{'financial'|translate}}:</label>
        <label class="descripcion">NP 300 Frontier | Enganche | Anual | 5% com. | 5% Tas. | Universal </label>
      </div>
      <div class="d-flex justify-content-center">
        <label class="azul">{{'after_sale'|translate}}:</label>
        <label class="descripcion">NP 300 Frontier | 2022 | Platinum LT | Tipo de seguro | Cobertura</label>
      </div>
    </div>

    <div class="d-flex justify-content-center gap-2">
      <button class="d-flex mt-1">
        <label
          style="color: #F44336; font: normal normal normal 14px/14px Montserrat; margin-top: 8px; margin-right: 3px;">
          {{'i_am_not_sure'|translate}}
        </label>
        <mat-icon [ngStyle]="{'font-size':'32px','width': '30px','height': '30px', 'color':'#F44336'}">
          highlight_off</mat-icon>
      </button>
      <button class="d-flex mt-1">
        <label style="font: normal normal normal 14px/14px Montserrat;margin-top: 8px;margin-right: 3px;
        margin-left: 10px;">
          {{'i_am_sure'|translate}}
        </label>
        <img class="" src="assets/img/mdi-check-circle-outline.svg"
          style="display: inline;margin-left: 12px;margin-bottom: 3px; width: 28px; height: 28px;">
      </button>
    </div>
  </div>
</app-so-modal>
