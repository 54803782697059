<div class="select__form" [ngClass]="{'p-1': !isWhiteBox}">
    @if( selectLabel ) {
        <label class="fw-medium p-1 custom-reactive-select-label" [style]="labelStyle"> 
            {{ selectLabel }} <app-so-required-mark [show]="requiredMark" [isValid]="touched && valid" />
        </label>
    }
    <div class="select-container" [ngClass]="{'white-box': isWhiteBox}">
        <select #selectElement (change)="updateValue($event)" [value]="value" [disabled]="isDisabled" [name]="name"
                [class.custom-reactive-select-arrow]="!isLoading"
                class="custom-reactive-select custom-reactive-select-arrow form-select form-select-sm app-so-input-border w-100"
                [style]="customStyle"
                [matTooltip]="(tooltipDisabledText && isDisabled)  ? tooltipDisabledText : ''"
                matTooltipPosition="above">
            @if (!isWhiteBox) {
                <option value="" hidden> {{ 'selected' | translated : 'Seleccionar' }} </option>
            }
            @for (groupKey of getKeys(_items); track $index) {
                <optgroup [label]="groupKey | translate">
                    @for (item of _items[groupKey]; track trackByIndex) {
                        <option [value]="item.key" [disabled]="shouldBeDisabled(item.key)" [selected]="item.key == selectedItemId">{{item.value}}</option>
                    }
                </optgroup>
            }
            <!-- @for (item of items; track $index) {
                <option [value]="item.key" [disabled]="shouldBeDisabled(item.key)" [selected]="item.key == selectedItemId">{{ item.value | translate }}</option>
            } -->
        </select>
        @if(isLoading){
            <div class="spinner"></div>
        }
    </div>
</div>

