
<div class="newNavbar fixed-top px-1">
  <div class=" d-flex justify-content-between contentNavBar">
    <app-navbar-multicanal  *ngIf="isRouteActive('/multicanal')" ></app-navbar-multicanal>
    <app-so-input-group-dropdown *ngIf="!isRouteActive('/multicanal') && typeModule != 'marketing'" (mostrarCompleto)="onValueChanged($event)" [receivedValue]="receivedValue" ></app-so-input-group-dropdown>
    <app-navbar-line-business *ngIf="!isRouteActive('/multicanal') && typeModule != 'marketing' && ( !receivedValue ) " ></app-navbar-line-business>

    <app-navbar-marketing *ngIf="typeModule === 'marketing'" ></app-navbar-marketing>
    <app-navbar-user *ngIf=" ( !receivedValue ) "></app-navbar-user>

  </div>
  
</div>
