<!-- sin formulario -->
<div class="input__form {{ paddingClasses }}" [id]="id" *ngIf="!formGroup" [style]="'height: fit-content; width: ' + width + '; max-width: ' + maxWidth + ';'"
  [attr.data-field]="type">
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'email'">
      <label class="pt-1 pb-1 fw-500" *ngIf="label !==''" [style]="'color:'+textColor+''">{{label}}<app-so-required-mark [show]="requiredMark" /></label>
      <div class="h-fit flex">
        <input #firstInput type="text" dataInput="1" [name]="name" [id]="name"
          class=" start w-50"
          style="border-top-left-radius: 10px; border-bottom-left-radius: 10px;" (input)="onInputChange($event)"
          (keyup)="onKeyPress(firstInput.value, true)" value="{{value}}">
        <span class="" style="width: 44px; height: 28px; padding: 3px; color: var(--so-primary);
          font-weight: bold; background-color: var(--so-neuter); text-align: center;line-height: 20px;">{{ '@'
          }}</span>
        <input type="text" #secondInput dataInput="2" [name]="name" [id]="name"
          class=" end w-50"
          style="border-bottom-right-radius: 10px; border-top-right-radius: 10px;" (input)="onInputChange($event)"
          (keyup)="onKeyPress(secondInput.value, false)">
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'tel'">
      <div *ngIf="label !==''">
        <label class="fw-medium p-1" [style]="'color:'+textColor+''">{{label}}</label>
      </div>
      <div class="d-flex align-items-center">
        <app-icon-button class="" *ngIf="showVip" firstIcon="true" size="16px" widthIcon="31px" heightIcon="31px"
          icon="./assets/img/mdi-vip.svg" (click)="activeVip($event)" [vip]="vip">
        </app-icon-button>
        <div class="d-flex w-100">
          <input [type]="type" dataInput="2" [name]="name" [placeholder]="placeholder"
            class=" w-100" style="width: 30px; padding: 3px;"
            (input)="onInputChange($event)" autocomplete="off" onpaste="return false;" value="{{value}}">
          <span class="phone">
            <img src="./assets/img/mdi-cellphone.svg" alt="">
          </span>
          <app-icon-button class="" *ngIf="showTrash" firstIcon="true" size="16px" widthIcon="25px" heightIcon="25px"
            icon="./assets/img/delete-forever-outline.svg" [vip]="vip">
          </app-icon-button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'date'">
      <div *ngIf="label !==''">
        <label class="fw-medium p-1" [style]="'color:'+textColor+''">{{label}}<app-so-required-mark [show]="requiredMark" /></label>
      </div>
      <div class="content__input-date">
        <input [type]="'date'" dataInput="2" [name]="name" [placeholder]="placeholder"
          class=" w-100" style="width: 30px; padding: 3px;border-radius: 10px;"
          (change)="onInputChange($event)" (input)="$event.preventDefault()" data-field="date" [id]="name"
          required="{{required}}" onkeydown="return false">
        <p [style]="'background-color:'+bgColor+'!important;'">{{fechaFormateada}}</p>
      </div>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <div *ngIf="label !==''">
        <label class="fw-medium {{ paddingClasses }}" [style]="'color:'+textColor+''">{{label}}<app-so-required-mark [show]="requiredMark" /></label>
      </div>
      <input [type]="type" dataInput="2" [name]="name" [placeholder]="placeholder"
        class=" w-100" style="width: 30px; padding: 3px;border-radius: 10px;"
        (input)="onInputChange($event)" value="{{value}}">
    </ng-container>
  </ng-container>
</div>

<!-- sin formulario -->


<!-- Con formulario -->
<div class="p-1 input__form content" [ngClass]=" (formGroup.get(name)?.invalid && formGroup.get(name)?.touched ? 'invalid':'valid' )" [id]="id" *ngIf="formGroup" [formGroup]="formGroup"
  [style]="'height: fit-content; width: ' + width + '; max-width: ' + maxWidth + ';'" [class.valid]="formGroup.get(name)?.dirty && isValid "
  [class.invalid]="formGroup.get(name)?.dirty && !isValid" [attr.data-field]="type">
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'email'">
      <label class="pt-1 pb-1 fw-500" *ngIf="label !==''" [style]="'color:'+textColor+''">{{label}}<app-so-required-mark [show]="requiredMark" /></label>
      <div class="h-fit flex input__form-email">
        <input type="email_name" dataInput="1" [name]="name" [id]="name"
          class=" start w-50"
          style="border-top-left-radius: 10px; border-bottom-left-radius: 10px;" [formControlName]="name"
          (input)="onInputChange($event)" maxlength="{{maxlength}}" minlength="{{minlength}}" data-type="email_name"
          required="{{required}}" value="{{value}}"/>
        <span class="" style="width: 44px; height: 28px; padding: 3px; color: var(--so-primary);
          font-weight: bold; background-color: var(--so-neuter); text-align: center;line-height: 20px;">{{ '@'
          }}</span>
        <input type="email_domain" dataInput="2" [formControlName]="name+'_2'"
          class=" end w-50"
          style="border-bottom-right-radius: 10px; border-top-right-radius: 10px;" data-type="email_domain"
          (input)="onInputChange($event)" [id]="name+'_2'" required="{{required}}" autocomplete="off" />
        <ul *ngIf="filteredSuggestions.length > 0">
          <li *ngFor="let suggestion of filteredSuggestions" (click)="selectSuggestion(suggestion)">
            {{ suggestion }}
          </li>
        </ul>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'tel'">
      <div *ngIf="label !==''">
        <label class="fw-medium p-1" [style]="'color:'+textColor+''">{{label}}<app-so-required-mark [show]="requiredMark" /></label>
      </div>
      <div class="d-flex align-items-center">
        <app-icon-button class="" *ngIf="showVip" firstIcon="true" size="16px" widthIcon="31px" heightIcon="31px"
          icon="./assets/img/mdi-vip.svg" (click)="activeVip($event)" [vip]="vip">
        </app-icon-button>
        <div class="d-flex w-100">
          <input [type]="type" dataInput="2" [name]="name" [placeholder]="placeholder" [formControlName]="name"
            class=" w-100" style="width: 30px; padding: 3px;"
            (input)="onInputChange($event)" maxlength="{{maxlength}}" minlength="{{minlength}} [id]='name'"
            required="{{required}}" autocomplete="off" onpaste="return false;" value="{{value}}">
          <span class="phone">
            <img src="./assets/img/mdi-cellphone.svg" alt="">
          </span>
          <app-icon-button class="" *ngIf="showTrash" firstIcon="true" size="16px" widthIcon="25px" heightIcon="25px"
            icon="./assets/img/delete-forever-outline.svg" [vip]="vip">
          </app-icon-button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'date'">
      <div *ngIf="label !==''">
        <label class="fw-medium p-1" [style]="'color:'+textColor+''">{{label}}<app-so-required-mark [show]="requiredMark" /></label>
      </div>
      <div class="content__input-date">
        <input [type]="'date'" dataInput="2" [name]="name" [placeholder]="placeholder" [formControlName]="name"
          class=" w-100" style="width: 30px; padding: 3px;border-radius: 10px;"
          (input)="onInputChange($event)" data-field="date" [id]="name" required="{{required}}" mask="00-00-0000"
          placeholder="dd-mm-yyyy" value="{{value}}">
        <p [style]="'background-color:'+bgColor+'!important;'">{{fechaFormateada}}</p>
      </div>
    </ng-container>


    <ng-container *ngSwitchDefault>
      <div *ngIf="label !==''">
        <label class="fw-medium p-1" [style]="'color:'+textColor+''">{{label}}<app-so-required-mark [show]="requiredMark" /></label>
      </div>
      <input [type]="type" dataInput="2" [name]="name" [placeholder]="placeholder" [formControlName]="name"
        class=" w-100" style="width: 30px; padding: 3px;border-radius: 10px;"
        (input)="onInputChange($event)" maxlength="{{maxlength}}" minlength="{{minlength}}" [id]="name"
        required="{{required}}" value="{{value}}">
    </ng-container>
  </ng-container>
  <span class="status">{{statusField}} </span>
</div>
