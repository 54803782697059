<div class="so-timeline-indicator" [style]="'border-left: 8px solid var(' + color + ');'">
    <div class="p-2 text d-flex justify-content-between" [style]="(shadowText ? 'box-shadow: var(--box-shadow-intern-cards);' : '') + 'background-color: var(' + background + ');'">
        <div class="d-flex align-items-center justify-content-between w-100">
            <div>
                <p class="m-0 ps-2 so-text-2" [ngClass]="{'fw-semibold': textBold, 'text-nowrap overflow-hidden': !allowTextWrap}">{{text}}</p>
                @if(subtext != null) {
                    <p class="m-0 ps-2 pt-2 so-text-4 text-start" [ngClass]="{'text-nowrap overflow-hidden': !allowTextWrap}">{{subtext}}</p>
                }
                @if(subtext2 != null) {
                    <p class="m-0 ps-2 so-text-4 text-start" [ngClass]="{'pt-1': subtext != null, 'pt-2': subtext == null, 'text-nowrap overflow-hidden': !allowTextWrap}">{{subtext2}}</p>
                }
            </div>
            @if (icon) {
                <div class="pe-2">
                    <app-so-svg-icon src="assets/img/{{icon}}.svg" [color]="'--so-primary'" [size]="1.5"></app-so-svg-icon>
                </div>
            }
        </div>
        @if (deleteButton) {
            <div>
                <app-button
                    [svg]="!isTemp ? 'mdi-delete-forever-outline-danger' : 'mdi-minus-circle-outline-danger'"
                    [type]="'button'"
                    [tooltip]="'erase' | translated"
                    [injection]="{ button: 'pt-0', icon: 'mt-0' }"
                    [scale]="0.8"
                ></app-button>
            </div>
        }
    </div>
    @if(number != null) {
        <div class="circle so-primary so-text-4-bold" [style]="subtext != null ? 'width: 60px; height: 60px;' : 'width: 30px; height: 30px;'">
            <span>{{number}}</span>
        </div>
    }
</div>