import { ItemsLateralMenu } from "@shared/interfaces/items-lateral-menu.interface";

export const LEAD_MANAGEMENT_LINKS: ItemsLateralMenu = {
  items: [
    {
      type: 'toggle',
      show: false,
      svg: 'mdi-megamenu.svg',
      config: {
        colors: {
          background: '',
          icon: '',
          label: '',
        },
        size: {
          width: '',
          height: '',
        },
      },
      contentLinks: [
        {
          type: 'link',
          url: '/lead-management/inventory',
          icon: 'add_shopping_cart',
          label: 'inventory',
        },
        {
          type: 'link',
          url: '/lead-management/call',
          icon: 'call',
          label: 'caller_id',
        },
        {
          type: 'link',
          url: '/lead-management/followups',
          params: { open: 'indicesRop' },
          icon: 'bar_chart',
          label: 'rop_productivity_indices',
        },
        {
          type: 'link',
          url: '/',
          icon: 'local_car_wash',
          label: 'driving_test_report',
        },
      ],
    },

    {
      type: 'link',
      url: '/lead-management/home',
      icon: 'home',
    },
    {
      type: 'link',
      url: '/lead-management/followups',
      icon: 'filter_2',
    },
    {
      type: 'link',
      url: '/settings',
      icon: 'settings',
    },
    {
      type: 'link',
      url: '/lead-management/directory',
      icon: 'perm_contact_calendar',
    },
    {
      type: 'link',
      url: '/lead-management/multiagenda',
      icon: 'calendar-text-outline',
    },
    {
      type: 'link',
      url: '/lead-management/statistics',
      icon: 'donut_large',
    },
    {
      type: 'toggle',
      hoverable: true,
      show: false,
      svg: 'file-chart-outline.svg',
      config: {
        colors: {
          background: '',
          icon: '',
          label: '',
        },
        size: {
          width: '',
          height: '',
        },
      },
      contentLinks: [
        {
          type: 'link',
          url: 'daily-analysis-2-0',
          svg: 'chart-areaspline.svg',
          label: 'bdc_analysis_2_0',
        },
      ],
    },

  ],
  actionsItems: [
    {
      icon: 'add_circle_outline',
      event: 'open-plus-menu',
    },
  ],
};
