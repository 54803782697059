import { Input, Component, ComponentFactoryResolver, ViewContainerRef, ViewChild, OnInit, inject, AfterViewInit } from '@angular/core';
import { ProspectEditData, ProspectEditDataResponse } from '@modules/lead-management/submodules/directory/interfaces/prospect-edit-data.interface';
import { SharedStateService } from '@modules/lead-management/submodules/directory/services/shared-state.service';
import { SummaryProspectService } from '@modules/lead-management/submodules/directory/services/summary-prospect.service';
import { SoInputComponent } from '@shared/components/atomics/so-input-form/so-input-form.component';

@Component({
  selector: 'app-so-modal-editar-prospecto',
  templateUrl: './so-modal-editar-prospecto.component.html',
  styleUrls: ['./so-modal-editar-prospecto.component.scss']
})
export class SoModalEditarProspectoComponent implements OnInit, AfterViewInit {
  private readonly sharedStateService = inject(SharedStateService);
  private readonly summaryProspectService = inject(SummaryProspectService);
  @ViewChild('phones', { read: ViewContainerRef }) phonesContainer?: ViewContainerRef;
  @Input() vip?: string = "grayscale(1)";
  @Input() isVip?: boolean = true;
  @Input() showVip?: boolean = false;
  inputsMaxWidth: number = 265;
  editData: ProspectEditData = {
    idProspectos: '',
    nombre: '',
    segundoNombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    cp: '',
    fuente: '',
    subcampanas: undefined,
    rangoEdad: undefined,
    genero: '',
    empresa: '',
    rfc: '',
    curp: '',
    datosPrivados: false,
    estadoCivil: '',
    sector: '',
    cumpleanios: '',
    observacion: ''
  };

  // TODO
  fuentes: any[] = [];
  estadosCiviles: any[] = [];
  subcampanas: any[] = [];
  sectores: any[] = [];
  rangosEdadedes: any[] = [];

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}
  ngOnInit(): void {
    console.log('Editar el prospecto');
    console.log("phones container: ");
    //console.log(this.phonesContainer);
  }

  // Observar si el modal tiene la clase 'show'
  ngAfterViewInit(): void {
    const thisModal: HTMLElement | null = document.getElementById('modalEditarProspecto');
    if (!thisModal) return
    new MutationObserver(() => {
      if(thisModal.classList.contains('show')) this.loadProspectData();
    }).observe(thisModal, { attributeFilter: ['class'] });
  }

  activeVip($event: any) {
    this.isVip = !this.isVip;
    this.vip = this.validateVip();
    $event.target.style.filter = this.vip;
  }

  validateVip() {
    if (this.isVip) {
      return 'grayscale(0)';
    } else {
      return 'grayscale(1)';
    }
  }

  addComponent() {
    // Crear una instancia del componente que deseas agregar (por ejemplo, MyCustomComponent).
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SoInputComponent);

    // Limpiar cualquier componente previamente agregado.
    // this.phonesContainer.clear();

    // Agregar el nuevo componente dinámicamente dentro del contenedor "phonesContainer".
    if(!!this.phonesContainer){
      const componentRef:any = componentFactory.create(this.phonesContainer.injector);
      this.phonesContainer.insert(componentRef);
    }
  }

  // Cargar datos del prospecto
  loadProspectData() {
    const res = this.summaryProspectService.getProspectEditData(this.sharedStateService.idProspect());
    res.subscribe((data: ProspectEditDataResponse) => {
      this.editData = data.response.datosPrincipal;

      // TODO
      this.fuentes = [{
        key: this.editData.fuente,
        value: this.editData.fuente,
      }];
      this.estadosCiviles = [{
        key: this.editData.estadoCivil,
        value: this.editData.estadoCivil,
      }];  
      this.subcampanas = [{
        key: this.editData.subcampanas,
        value: this.editData.subcampanas,
      }];
      this.sectores = [{
        key: this.editData.sector,
        value: this.editData.sector,
      }];
      this.rangosEdadedes = [];
    });
  }
}
