import { Injectable } from '@angular/core';
import { Marcas } from '@core/interfaces/catalogos.intefaces';
import { Request } from '@core/models/requests.model';

@Injectable({
  providedIn: 'root'
})
export class MarcasFlotillasService {
  private url = `ventas/catalogos/flotillas/marcas/search`;

  constructor(private request: Request) { }

  getMarcasFlotillas(body: any) {
    const options = { headers: this.request.headers };
    return this.request.Post<Marcas>(this.url, body, options);
  }

  formatValues(data: any[], keyProp: string, valueProp: string): { key: any; value: any }[] {
    return data.map(item => ({ key: item[keyProp], value: item[valueProp] }));
  }
}
