import { Component, Input, OnInit, Output, EventEmitter, ElementRef, OnDestroy, HostListener, SimpleChanges, OnChanges } from '@angular/core';
import { DynamicComponentService,AlertType } from '../body-loading/dynamic-component-service.service';
@Component({
  selector: 'app-so-modal',
  templateUrl: './so-modal.component.html',
  styleUrls: ['./so-modal.component.scss'],
})
export class SoModalComponent implements OnInit, OnChanges{
  @Output() close = new EventEmitter<any>();
  typeSizing?:
    | 'modal-xxs'
    | 'modal-xs'
    | 'modal-xl'
    | 'modal-lg'
    | 'modal-sm'
    | 'modal-full-page'
    | 'modal-full-screen';
  @Input() modalId?: string;
  @Input() modalTitle?: string;
  @Input() bgColorHeader?: string;
  @Input() width?: string;
  @Input() height?: string;
  @Input() data: any;
  @Input() hideButtonClose?: boolean = false;
  @Input() activeFooter?: boolean = true;
  @Input() verticalPosition?: string;
  @Input() styles?: { [key: string]: string };
  @Input() style?: string;
  @Input() classes?: any;
  @Input() preventClose?: boolean = false;
  @Input() size?: this['typeSizing'];
  @Input() containerStyles?: { [key: string]: string };
  @Output() cerrarModalEvent = new EventEmitter<void>();
  verticalPositionValidated: string = '';
  sizeValidated: string = '';
  staticBackdrop: any;

  private screenWidth?: number;
  private reSizeModal: boolean = false;

  constructor(private dynamicComponentService: DynamicComponentService,
    ){}

  ngOnInit(): void {
    this.validPosition();
    this.validSize();
    this.getScreenSize();
    // console.log(this.style);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['size']) {
      this.reSizeModal = true;
    }
  }

  validPosition() {
    if (this.verticalPosition === 'center') {
      this.verticalPositionValidated = 'modal-dialog-centered';
    }
    // console.log(this.verticalPositionValidated);
  }

  validSize() {
    if (this.size === 'modal-full-screen') {
      this.sizeValidated = 'modal-fullscreen';
    }
  }

  public cerrarModal(modalId:string) {
    var modal = document.getElementById(modalId);
    var modalBackdrop = document.querySelector('.modal-backdrop');
    if (modal) {
      modal.style.display = 'none';
      if (modalBackdrop) {
        modalBackdrop.remove();
      }
    }
  }

  onClose(event: any) {
    this.close.emit(event);
    this.reSizeModal = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.getScreenSize();
  }

  private getScreenSize(): void {
    this.screenWidth = window.innerWidth;
    if( this.reSizeModal){
      if (this.screenWidth < 1550) {
        this.size = 'modal-lg'
      } else {
        this.size = 'modal-xl'
      }
    }

  }

}
