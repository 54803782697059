import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[SanitizeInput]',
})
export class SanitizeInputDirective {

  @Input('SanitizeInput') isActive: boolean = true;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('input') onInput(): void {
    if (this.isActive) {
      const inputElement = this.el.nativeElement as HTMLTextAreaElement;
      const value = inputElement.value;
      const sanitizedValue = this.sanitize(value);
      this.renderer.setProperty(inputElement, 'value', sanitizedValue);
    }
  }

  private sanitize(value: string): string {
    // Reemplazar caracteres sospechosos o aplicar cualquier regla de sanitización
    return value.replace(/['";\-]/g, ''); // Ejemplo básico
  }

}
