<mat-dialog-content>
  <div class="container-modal">
    <section class="container-modal__header">
      <div>
        <app-title icon="Grupo-4620-blue" text="{{ 'inventory' | translate }}" weight="bold" size="base"
          [iconScale]="0.8" [injection]="{
            label: 'font-weight-bold mb-2',
            icon: 'so-danger m-auto'
          }" />
      </div>

      <img src="./assets/img/mdi-close.svg" alt="" (click)="onNoClick()">
    </section>

    <section>
      <h2>{{ 'automatic_filters' | translate }}</h2>

      <div>
        <div class="row">
          <div class="col">
            <app-so-select [loading]="loadingYearly()" label="{{'year_of_car_to_evaluate'|translate}}"
              name="year_of_car_to_evaluate" [typeInfoStructure]="true" [arregloJson]="dataYearList()"
              (event)="getBrandList($event)" />
          </div>
          <div class="col">
            <app-so-select label="{{'vehicle_brand'|translate}}" name="vehicle_brand" [typeInfoStructure]="true"
              [loading]="loadingBrand()" [arregloJson]="dataBrandList()" (event)="getCarList($event)" />
          </div>
          <div class="col">
            <app-so-select label="{{'car_of_interest'|translate}}" name="car_of_interest" [typeInfoStructure]="true"
              [loading]="loadingCarInterest()" [arregloJson]="dataCarInterestlist()" (event)="getVersionList($event)" />
          </div>
        </div>

        <div class="row">
          <div class="col">
            <app-so-select label="{{'car_version'|translate}}" name="car_version" [typeInfoStructure]="true"
              [loading]="loadingVersion()" [arregloJson]="dataVersionList()" (event)="getColorList($event)" />
          </div>
          <div class="col">
            <app-so-select label="Color" name="color" [typeInfoStructure]="true" [loading]="loadingColor()"
              [arregloJson]="dataColorList()" />
          </div>
          <div class="col">
          </div>
        </div>
      </div>
    </section>

    <section style="margin-bottom: 90px;">
      <h2>{{ 'inventory_car_list' | translate }}</h2>

      <div class="table-container p-2" style="height: 150px;">
        <div class="overflow-x-auto w-100">
          <table class="custom-table text-sm text-left text-gray-500 dark:text-gray-400 text-left w-100">
            <thead class="table-header text-xs text-gray-700 uppercase dark:text-gray-400 p-[10px]">
              <tr class="header-row h-10" style="height: 20px;">
                <th class="table-header-cell fw-bold" style="color:var(--so-black)" scope="col"
                  *ngFor="let header of headers()">
                  <div class="header-content d-flex justify-content-start align-items-center"
                    style="font-size: var(--so-text-4);">
                    <p style="margin: 0;">
                      {{header | translate | uppercase}}
                    </p>
                    <ng-container *ngIf="header.length > 0">
                      <mat-icon>unfold_more</mat-icon>
                    </ng-container>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              @for (row of rows; track $index) {
              <tr class="data-row" style="border-top-width: 1px; border-color:var(--so-neuter)">
                <td class="data-cell"
                  style="height: 4.5rem; font-size: var(--so-text-4); text-align: center; width: 160px;">
                  <p style="font-size: 12px; font: var(--so-text-4); margin-right: 40px;">{{ row.noSerie }}</p>
                </td>
                <td class="data-cell" style="height: 2.5rem; font-size: 14px;">
                  <div style="display: flex; justify-content: flex-start; gap: 5px; margin-top: 5px;">
                    <img [src]="row.car" alt="" style="width: 50px;">
                    <p style="font: normal normal 600 14px/20px Montserrat;
                      letter-spacing: 0px;
                      color: #272D3B;
                      opacity: 1; margin: auto 0;">NISSAN</p>
                  </div>
                </td>
                <td class="data-cell">
                  <div style="display: flex; justify-content: flex-start; gap: 5px; margin-top: 5px;">
                    <img [src]="row.car" alt="" style="width: 50px;">
                    <p style="font: normal normal 600 14px/20px Montserrat;
                      letter-spacing: 0px;
                      color: #272D3B;
                      opacity: 1; margin: auto 0;">NP300 FRONTIER</p>
                  </div>
                </td>
                <td class="data-cell" style="height: 2.5rem; min-width: 3rem;">
                  <p style="font-size: 12px; font: var(--so-text-4);">{{ row.model }}</p>
                </td>
                <td class="data-cell">
                  <p style="font-size: 12px; font: var(--so-text-4);">{{ row.version }}</p>
                </td>
              </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </section>

    <section style="display: flex; justify-content: flex-end;">
      <app-button svg="mdi-check-circle-outline" iconAlignment="right" [scale]=".8" text="{{ 'assign_car' | translate }}"
        [injection]="{ label: 'pt-2' }" (click)="onNoClick()" />
    </section>
  </div>
</mat-dialog-content>
