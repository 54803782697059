export interface User {
  email: string;
  id: string;
  username: string;
  timestamp?: string;
}

export interface UserInfo {
  redirectTypeUser: string;
  data: UserData;
  config: ConfigInterface;
}

export interface Marcas {
  idmarca: string;
  nombre: string;
  alias: string;
}

export interface ConfigInterface {
  basesDeDatos: {
    dbEmpresa: string;
  };
  idioma: {
    idIdioma: string;
    idPais: string;
  };
}

export interface ConfigWithTimeZone extends ConfigInterface {
  "husoHorario": {
    idHuso: string;
    nombre: string;
  }
}

export interface UserData {
  idUsuario: string;
  email: string;
  idNivelDeAcceso: number;
  idBasDeDatos: string;
  tema: string,
  empleado: {
    idEmpleado: string;
    nombre: string;
    segundo: string;
    paterno: string;
    materno: string;
    foto: string;
    genero: string;
    celular: string;
    email: string;
    idGrupoDeTrabajo: string;
    activo: boolean;
    empresa: {
      idEmpresa: string;
      nombre: string;
      idDistribuidor: string;
      ambiente: number;
      lada: string;
      longitud: string;
      latitud: string;
      idioma: {
        idIdioma: string;
        nombre: string;
        codigo: string;
        activo: boolean;
      };
      pais: {
        idPais: string;
        nombre: string;
        activo: boolean;
      };
      husoHorario: {
        idHuso: string;
        nombre: string;
        activo: boolean;
      };
      marcas: Marcas[];
      habilitarMultimarca: boolean;
    };
  };
  tiposDeNegocio: TipoDeNegocio[];
  perfiles: {
    idPerfil: string;
    titulo: string;
  }[];
}

interface TipoDeNegocio {
  idTipoDeNegocio: string;
  nombre: string;
  activo: boolean;
}

export interface AuthLoginApiResponse {
  success: boolean;
  message: string;
  response: {
    token: string;
    refreshToken: string;
    usuario: UserData;
    configuracion: ConfigInterface;
  };
}

export interface GenericResponse {
  success: boolean;
  message: string;
}

export const userInfoObject: UserInfo = {
  redirectTypeUser: '',
  data: {
    idUsuario: '',
    email: '',
    idNivelDeAcceso: 0,
    idBasDeDatos: '',
    tema:'',
    empleado: {
      idEmpleado: '',
      nombre: '',
      segundo: '',
      paterno: '',
      materno: '',
      foto: '',
      genero: '',
      celular: '',
      email: '',
      idGrupoDeTrabajo: '',
      activo: false,
      empresa: {
        idEmpresa: '',
        nombre: '',
        idDistribuidor: '',
        ambiente: 0,
        lada: '',
        longitud: '',
        latitud: '',
        idioma: {
          idIdioma: '',
          nombre: '',
          codigo: '',
          activo: false,
        },
        pais: {
          idPais: '',
          nombre: '',
          activo: false,
        },
        husoHorario: {
          idHuso: '',
          nombre: '',
          activo: false,
        },
        marcas: [],
        habilitarMultimarca: false,
      },
    },
    tiposDeNegocio: [],
    perfiles: [],
  },
  config: {
    basesDeDatos: {  dbEmpresa: '' },
    idioma: {
      idIdioma: '',
      idPais: ''
    }
  }
};
