<form [formGroup]="formSimple" id="simple-lead">
    <div [hidden]="!isAdmin || isStep2" class="mt-3" style="padding: 5px 20px 0px 20px;">
        <div class="d-flex  gap-2 mt-3">
          <img src="./assets/img/mdi-person-outline.svg" alt="" style="width: 27px; height: 24px;">
          <p style="font: normal normal bold 20px/29px Montserrat;letter-spacing: 0px;
           color: #272D3B;margin-top: 0px;">{{'executive_to_assign'|translate}}</p>
        </div>
        <div class="row">
          <div class="col-6">
            <app-so-executive-select label="{{'select_admin'|translate}} *" [showLoadingIcon]="true"
              [required]="true"></app-so-executive-select>
          </div>
          <div class="col-6">
            <app-so-select label="{{'sale_type'|translate}} *" [data]="typeOfSale" [required]="true"></app-so-select>
          </div>
        </div>
      </div>
      <div [hidden]="isDetailSteps || isStep2" style="padding: 0px 20px;">
        <div class="d-flex  gap-2 mt-3">
          <img src="./assets/img/mdi-clipboard-account-outline.svg" alt="" style="width: 24px">
          <p style="font: normal normal bold 20px/29px Montserrat;letter-spacing: 0px;
         color: #272D3B;margin-top: 20px;">{{'main_information'|translate}}</p>
        </div>
        <div class="row">
          <div class="col">
            <app-so-input-form label="{{'name'|translate}} *" type="name" name="nombre" [required]="true"
              (event)="onChange($event)" [formGroup]="formSimple"></app-so-input-form>
          </div>
          <div class="col">
            <app-so-input-form label="{{'second_name'|translate}}" type="name" name="segundoNombre"
              [formGroup]="formSimple" (event)="onChange($event)"></app-so-input-form>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <app-so-input-form label="{{'last_name'|translate}} *" type="name" name="paterno" [formGroup]="formSimple"
              (event)="onChange($event)" [required]="true"></app-so-input-form>
          </div>
          <div class="col">
            <app-so-input-form label="{{'mothers_last_name'|translate}}" type="name" name="materno"
              [formGroup]="formSimple" (event)="onChange($event)"></app-so-input-form>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <app-so-input-form label="{{'postal_code'|translate}}" name="cp" type="postalCode" [maxlength]="5"
              [formGroup]="formSimple"></app-so-input-form>
          </div>
          <div class="col">
            <app-so-input-form label="{{'company'|translate}}" type="text" name="company"
              [formGroup]="formSimple"></app-so-input-form>
          </div>
        </div>
        <!-- Multiple Emails -->
        @if(!showEmail){
          @for (emailControl of emailArray.controls; track $index) {
            <div class="row" formArrayName="emails">
              <div class="col-3">
                <app-so-select [showVip]="true" label="{{'type_follow_up'|translate}} *" [data]="emails" [required]="!showEmail"
                [name]="'type_email__'+$index" data-component="type-email" (clickVip)="isVipMail($event)"
                ></app-so-select>
              </div>
              <div class="col-6" style="position: relative; top: 2px;">
                <so-input-email-reactive-form
                  [label]="('email'|translate) + ' *'"
                  [formControlName]="$index"
                  [requiredField]="emailArray.controls[$index].hasError('required') && emailArray.controls[$index].touched"
                >
                </so-input-email-reactive-form>
              </div>
              <div class="col">
                <div class="d-flex justify-content-start" style="margin-top: 17px;">
                    <div class="col-2"> 
                      @if (emailArray.length > 1) {
                        <button (click)="removeEmail($index)">
                          <img src="./assets/img/mdi-minus-circle-outline-danger.svg" style="width: 25px; height: 54px;">
                        </button>
                      }
                    </div>
                  <div class="col-2">
                    @if( emailArray.length < 5 ) { 
                        @if ($index === emailArray.length - 1) {
                          <button (click)="addEmail()"> 
                            <mat-icon [ngClass]="'so-secondary'" [ngStyle]="{'font-weight':'500','font-size':'30px','width':'35px',
                            'height':'35px','margin-top': '12px','margin-right': '4px'}">add_circle_outline</mat-icon>
                          </button>
                        }
                      }
                  </div>
                </div>
              </div>
            </div>
          }
        }
        <!-- End Multiple Emails -->
        <div class="row gap-2">
          <div class="col-3 mt-2 d-flex gap-2">
            <app-so-checkbox [size]="20" (click)="activateImportantLead()"></app-so-checkbox>
            <label style="color: #C5C5C5;">
              {{'important_lead'|translate}}
            </label>
          </div>
          <div class="col-3 mt-2 d-flex gap-2" style="padding-left: 5px;">
            <app-so-checkbox [size]="20" (click)="showEmailFn($event)"></app-so-checkbox>
            <label style="color: #C5C5C5;">
              {{'does_not_provide_email'|translate}}
            </label>
          </div>
        </div>

        @for (phoneControl of phoneArray.controls; track $index) {
          <div class="row" formArrayName="phones">
            <div class="col-3">
              <app-so-select [showVip]="true" label="{{'type_follow_up'|translate}}" (clickVip)="isVipTel($event)"
                [data]="telefonos" [name]="'type_tel__'+$index" data-component="type-tel"></app-so-select>
            </div>
            <div class="col-6" style="position: relative; top: 2px;">
              <so-input-reactive-form
                [label]="'phone' | translate"
                [typeInput]="'tel'"
                [formControlName]="$index"
                [requiredField]="phoneArray.controls[$index].hasError('required') && phoneArray.controls[$index].touched"
              >
              </so-input-reactive-form>
            </div>
            <div class="col">
              <div class="d-flex justify-content-start" style="margin-top: 17px;">
                <div class="col-2">
                  @if (phoneArray.length > 1) {
                    <button (click)="removePhone($index)">
                      <img src="./assets/img/mdi-minus-circle-outline-danger.svg" style="width: 25px; height: 54px;">
                    </button>
                  }
                </div>
                <div class="col-2">
                  @if (phoneArray.length < 5) {
                    @if($index === phoneArray.length - 1){
                      <button (click)="addPhone()">
                        <mat-icon 
                          [ngClass]="'so-secondary'" 
                          [ngStyle]="{'font-weight':'500','font-size':'30px','width':'35px',
                          'height':'35px','margin-top': '12px','margin-right': '4px'}">
                          add_circle_outline
                        </mat-icon>
                      </button>
                    }
                  }
                </div>
              </div>
            </div>
          </div>
        }
        <div class="d-flex gap-2 mt-2">
          <img src="./assets/img/chart.svg" alt="" style="width: 27px; height: 27px;">
          <p style="font: normal normal bold 20px/29px Montserrat;letter-spacing: 0px;
            color: #272D3B;margin-top: 0px;">{{'segmentation'|translate}}</p>
        </div>
        <div class="row">
          <div class="col">
            <app-so-select label="{{'type_of_first_contact'|translate}} *" (event)="onSelect($event)"
              [data]="firstContactType" name="type_of_first_contact" [required]="true"
              [loading]="loadTypeFirstContact"></app-so-select>
            <app-so-select class="mt-1" label="{{'subcampaign'|translate}}" (event)="onSelect($event)" [data]="campaigns"
              name="subcampaign_simple_lead"></app-so-select>
          </div>
          <div class="col">
            <app-so-select [data]="fonts" label="{{'contact_source'|translate}} *" name="fuentes_simple_lead"
              (event)="onSelect($event)" [required]="true"></app-so-select>
            <app-so-select class="mt-1" label="{{'closure_type'|translate}} *" (event)="onSelect($event)"
              [data]="closuretypes" [required]="true"></app-so-select>
          </div>
          <div class="col">
            <app-so-text-area label="{{'observations'|translate}}" rows="5" (event)="onChange($event)" 
               name="observations" ></app-so-text-area>
          </div>
        </div>
      </div>
   
</form>

