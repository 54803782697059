@if (requestStatus === PromiseStatus.Pending) {
<so-spinner />
}
@else if (requestStatus === PromiseStatus.Rejected || prospectFiles.length === 0) {
<span class="so-text-2 d-block text-center">{{ message }}</span>
}
@else {
<div class="content">
  @for (item of prospectFiles; track $index) {
  <div class="list">
    <div class="d-flex justify-content-between">
      <label>{{item.nombre}}</label>
      <label>{{item.tamano | fileSize: true }}</label>
    </div>
    <div class="d-flex justify-content-between">
      <so-icon svg="mdi-cloud-download-outline-secondary"  [size]="0.8"/>
      <so-icon svg="mdi-delete-forever-outline-danger"  [size]="0.8"/>
    </div>
  </div>
  }
</div>
}
