<div class="p-1">
    @if( label !== ''){
        <label class="fw-medium p-1" [style.color]="textColor" >{{ label }}</label>
    }
    <div class="d-flex align-items-center">
        <div class="position-relative d-inline-flex w-100">
            <div class="input-icon-wrapper">
                @if (!updatedStyles) {
                    <so-icon
                        class="search-icon"
                        icon="search"
                        style="color: var(--so-gray-thick)!important;"
                    ></so-icon>
                }
                <input
                    #input
                    [(ngModel)]="inputValue"
                    class="border border-secondary-subtle w-100"
                    [ngClass]="{'text-ident' : !updatedStyles, 'custom-input-reactive' : !updatedStyles, 'custom-input-reactive-v10': updatedStyles}"
                    type="search" 
                    [placeholder]="placeholder" 
                    [maxLength]="maxlength"
                    [minLength]="minlength"
                    [style]="customStyle"
                    [disabled]="disabled"
                    (input)="onChangeValue(getValue($event))"
                />
                @if (!updatedStyles) {
                    @if(inputValue && !disabled){
                        <app-button
                            svg="mdi-delete-forever-outline-danger"
                            class="clear-icon"
                            colorIcon="--so-gray-thick"
                            [applyChanges]="true"
                            [scale]=".57"
                            (click)="clearInput()"
                            [injection]="{ icon:'margin-top: 0px !important; margin-bottom: 3px;' }"
                        ></app-button>
                    }
                }@else {
                    @if(inputValue && !disabled){
                        <so-icon
                            svg="close-circle-outline"
                            class="clear-icon-v10"
                            color="--so-white"
                            (click)="clearInput()"
                            [size]="0.7"
                            [applyChange]="true"
                            style="align-items: center; display: flex; justify-content: center;"
                        ></so-icon>
                    }@else {
                        <so-icon
                            class="search-icon-v10"
                            svg="mdi-search-secondary"
                            color="--so-white"
                            [size]="0.7"
                            style="align-items: center; display: flex; justify-content: center;"
                            [applyChange]="true"
                        ></so-icon>
                    }
                }
            </div>
        </div>
    </div>
</div>