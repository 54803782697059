import { Component, Input, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-so-svg-icon',
  standalone: true,
  imports: [],
  templateUrl: './so-svg-icon.component.html',
  styleUrl: './so-svg-icon.component.scss',
})
export class SoSvgIconComponent implements OnChanges {
  @Input({required: true }) src!: string;
  @Input() color: any = '--so-secondary';
  @Input() class: string = '';
  @Input() width: string = '28';
  @Input() height: string = '28';
  @Input() size: number = 0.95;
  @Input() applyChange: boolean = true;
  @Input() onlyPath: boolean = true;
  @Input() rotation: string = '0deg';

  svgContent?: SafeHtml;

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  ngOnChanges() {
    this.loadSvg();
  }

  loadSvg() {
    // console.log('color',this.color);
    const color = `var(${!this.color ? '--so-secondary' : this.color})`;

    this.http.get(this.src, { responseType: 'text' }).subscribe((data) => {
      if(!this.onlyPath){
        this.svgContent = this.sanitizer.bypassSecurityTrustHtml(
          this.cambiarColorSvg(
            data,
            `var(${!this.color ? '--so-secondary' : this.color})`
          )
        );
      }else{
        this.svgContent = this.sanitizer.bypassSecurityTrustHtml(
          this.changeColorPath(data, color)
        );
      }
    
    });
  }

  cambiarColorSvg(svgString: any, nuevoColor: string) {
    let svgWithNewColor = svgString;
    if (this.applyChange) {
      svgWithNewColor = svgString
        .replace(/(<path[^>]*?)fill="[^"]*"/gi, `$1fill="${nuevoColor}"`)
        .replace(/width="([^"]*)"/gi, '')
        .replace(/height="([^"]*)"/gi, '')
        .replace(/<svg /gi, '<svg style="max-width:100%;max-height:100%;"');

      // Verificar si el SVG contiene un elemento <g>
      if (svgWithNewColor.includes('<g')) {
        let strokeReplaced = false;
        svgWithNewColor = svgWithNewColor.replace(
          /(<g[^>]*>.*?<\/g>)/gis,
          (match: any) => {
            if (!strokeReplaced) {
              strokeReplaced = true;
              return match.replace(
                /(<[^>]*?)stroke="[^"]*"/i,
                `$1stroke="${nuevoColor}"`
              );
            }
            return match;
          }
        );
      }
    }
    return svgWithNewColor;
  }

  changeColorPath(svgString: string, nuevoColor: string): string {
    let svgWithNewColor = svgString;
    
    // Reemplazar el fill en los elementos <path>
    svgWithNewColor = svgWithNewColor.replace(/(<path[^>]*?)fill="[^"]*"/gi, `$1fill="${nuevoColor}"`);
    
    return svgWithNewColor;
  }

}
