import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-so-accordion-panel',
  templateUrl: './so-accordion-panel.component.html',
  styleUrls: ['./so-accordion-panel.component.scss']
})
export class SoAccordionPanelComponent {
   /*
   idAccordion_Referece: Es la referecnai para cuando se tenga mas de un "Grupo" Acordeon y que se cierre cuando se abra Uno.
   Si no se Declara o No se llaman igual que el Panel Siempre se mantendra abierto, si es mas de un "Grupo" de Acordeon.
   Se debe de declarar tanto en el "Padre" como en los "Hijos" (Panel)
   */
  @Input() idAccordion_Referece = '';
  @Input() panelId = '';
  @Input() panelTitle = '';
  @Input() isOpen = false;
  @Input() icon = '';
  @Input() svg = '';
  @Input() rotateIcon = '';
  @Input() small = false;
  @Input() isLastItem: boolean = false;

}
