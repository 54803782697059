import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@core/api/translate.service';


@Component({
  selector: 'so-pagination',
  templateUrl: './so-pagination.component.html',
  styleUrl: './so-pagination.component.scss'
})
export class SoPaginationComponent{

  @Input() totalItems: number = 0;
  @Input() itemsPerPage: number = 10;
  @Input() currentPage: number = 1;
  @Input() showText: boolean = true;

  @Output() pageChanged = new EventEmitter<number>();

  public textShowRecords: string = '';
  
  constructor(
    private translateService :TranslateService,
  ){}

  ngAfterContentChecked(): void {
    this.setTextShowRecords();
  }

  setTextShowRecords(){
    const firstIndex = this.getCurrentPageFirstIndex();
    const lastIndex = this.getCurrentPageLastIndex();
    const totalRecords = this.totalItems;

    const translatedText  = this.translateService.instant('showing_records_of_table', 'Mostrando registros del %s al %s de un total de %s registros.');
    this.textShowRecords = this.formatString(translatedText, firstIndex, lastIndex, totalRecords);

  }

  getTotalPages(): number {
    return Math.ceil(this.totalItems / this.itemsPerPage);
  }

  getCurrentPageFirstIndex(): number {
    return (this.currentPage - 1) * this.itemsPerPage + 1;
  }

  getCurrentPageLastIndex(): number {
    return Math.min(this.currentPage * this.itemsPerPage, this.totalItems);
  }

  goToPage(page: number): void {
    if (page >= 1 && page <= this.getTotalPages()) {
      this.currentPage = page;
      this.pageChanged.emit(this.currentPage);
    }
  }

  goToPreviousPage(): void {
    if (this.currentPage > 1) {
      this.goToPage(this.currentPage - 1);
    }
  }

  goToNextPage(): void {
    if (this.currentPage < this.getTotalPages()) {
      this.goToPage(this.currentPage + 1);
    }
  }

  getPaginationArray(): number[] {
    return Array(this.getTotalPages()).fill(0).map((x, i) => i + 1);
  }

  private formatString(text: string, ...args: any[]): string {
    return text.replace(/%s/g, () => args.shift());
  }

}
