import { Component, Input, OnChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SoSvgIconComponent } from '@shared/components/atomics/so-svg-icon/so-svg-icon.component';

@Component({
    selector: 'so-icon',
    standalone: true,
    templateUrl: './so-icon.component.html',
    styleUrls: ['./so-icon.component.scss'],
    imports: [MatIconModule, SoSvgIconComponent]
})
export class SoIconComponent implements OnChanges {
  @Input() name!: string;
  @Input() svg: string = '';
  @Input() icon: string = '';
  @Input() size: number = 1;
  @Input() style!: string ;
  @Input() color: string  = '--color-icon';
  @Input() onlyIcon: boolean = false;
  @Input() applyChange: boolean = false;
  @Input() onlyPath: boolean = false;
  @Input() rotation: string = '0deg';

  public iconF: boolean = false;
  public svgContent: string = '';
  public iconsFolderPath: string = 'assets/img/';

  ngOnChanges() {
    if(this.svg != '') {
      this.name = this.svg;
      this.iconF = false;
    }
    else if(this.icon != '') {
      this.name = this.icon;
      this.iconF = true;
    } else if(!this.customIconExists(this.name))
      this.iconF = true;
  }

  private customIconExists(iconName: string): boolean {
    // Verificar si el archivo del icono personalizado existe en la carpeta de SVG personalizados
    const customIconUrl = `${this.iconsFolderPath}${iconName}.svg`;

    // Realizar una solicitud de HEAD para verificar si el archivo existe
    const request = new XMLHttpRequest();
    request.open('HEAD', customIconUrl, false); // Síncrono para que espere la respuesta
    request.send();

    return request.status !== 404;
  }
}
