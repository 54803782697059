import { Component, Input, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-so-button-group-radio-filters',
  templateUrl: './so-button-group-radio-filters.component.html',
  styleUrls: ['./so-button-group-radio-filters.component.scss']
})
export class SoButtonGroupRadioFiltersComponent {
  private readonly translateService = inject(TranslateService);
  
  @Input() idGroup: string = "";

  filters: any[] = [
    {
      title: 'current_month',
      selected: true,
    },
    {
      title: 'accumulated',
      selected: false,
    },
    {
      title: 'by_rank',
      selected: false,
    },
    {
      title: 'by_period',
      selected: false,
    },
  ];

  filters_tipe: any[] = [
    {
      title: 'of_the_month',
      selected: true,
    },
    {
      title: '7 DÍAS',
      selected: false,
    },
    {
      title: '15 DÍAS',
      selected: false,
    },
    {
      title: '60 DÍAS',
      selected: false,
    },
    {
      title: '90 DÍAS',
      selected: false,
    },
    {
      title: '120 DÍAS',
      selected: false,
    },
  ];

  constructor() {
    const days = this.translateService.instant('days');
    this.filters_tipe[1].title = days.replace('%s', '7');
    this.filters_tipe[2].title = days.replace('%s', '15');
    this.filters_tipe[3].title = days.replace('%s', '60');
    this.filters_tipe[4].title = days.replace('%s', '90');
    this.filters_tipe[5].title = days.replace('%s', '120');
  }
}
