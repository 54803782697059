import {Component, Input, booleanAttribute} from '@angular/core';

@Component({
  selector: 'app-so-required-mark',
  template: `<span [ngClass]="{'d-none': !show, 'valid': isValid}"> *</span>`,
  styles: `
    span {
      color: var(--so-danger);
      &.valid {
        color: var(--so-success);
      }
    }
  `,
})
export class SoRequiredMarkComponent {
  @Input({ required: true, transform: booleanAttribute }) show!: boolean;
  @Input({ required: false }) isValid: boolean = false;
}
