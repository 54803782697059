import { Component, ElementRef, Input, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageChatInterface } from '@shared/interfaces/message-chat.interface';
import { CheckboxService } from '@shared/services/observables/checkbox.service';

@Component({
  selector: 'so-toggle-chat',
  templateUrl: './toggle-chat.component.html',
  styleUrls: ['./toggle-chat.component.scss']
})
export class SoToggleChatComponent {

  private readonly fb = inject(FormBuilder);
  private readonly checkboxService = inject(CheckboxService);

  @Input() messages: MessageChatInterface[] = [];

  @ViewChild('messageContainer') messageContainer: ElementRef | null = null;

  private variableTemp: boolean = true;
  public signInForm: FormGroup = this.fb.group({
    message: ['', [ Validators.required ]],
  });

  sendMessage() {
    this.variableTemp = !this.variableTemp; // BORRAR DESPUÉS

    if (this.signInForm.valid) {
      const newMessage: MessageChatInterface = {
        transmitter:  this.variableTemp ? 'me' : 'other',
        message: this.signInForm.controls['message'].value,
        type: 'message'
      }

      this.messages.push(newMessage);
      this.signInForm.controls['message'].setValue('');
    }

    this.scrollToBottom();
  }

  scrollToBottom() {
    if (this.messageContainer && this.messageContainer.nativeElement) {
      const messageContainer = this.messageContainer.nativeElement;
      setTimeout(() => {
        messageContainer.scrollTop = messageContainer.scrollHeight;
      }, 0);
    }
  }

  onFileSelected(event: any) {
    this.variableTemp = !this.variableTemp; // BORRAR DESPUÉS
    const file: File = event.target.files[0];

    const fileSize = file.size;
    const sizeInKB = fileSize / 1024;
    const sizeInMB = sizeInKB / 1024;
    const sizeInGB = sizeInMB / 1024;

    let sizeFormatted: string;
    if (sizeInGB >= 1) {
      sizeFormatted = sizeInGB.toFixed(2) + ' GB';
    } else if (sizeInMB >= 1) {
      sizeFormatted = sizeInMB.toFixed(2) + ' MB';
    } else if (sizeInKB >= 1) {
      sizeFormatted = sizeInKB.toFixed(2) + ' KB';
    } else {
      sizeFormatted = fileSize + ' Bytes';
    }

    const newFile: MessageChatInterface = {
      transmitter:  this.variableTemp ? 'me' : 'other',
      message: file.name,
      size: sizeFormatted,
      type: 'file'
    }

    this.messages.push(newFile);
  }

  onUploadClick() {
    // Cuando se hace clic en el <section>, activamos el input de tipo file
    const inputElement: HTMLElement = document.querySelector('.input-upload__file') as HTMLElement;
    inputElement.click();
  }

  closeChatFunction() {
    this.checkboxService.updateCloseChat(false);
  }
}
