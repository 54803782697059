<div>
  <app-so-modal modalId="modal-upload-from-pc" verticalPosition="center">
    <div class="modal__header">
    </div>
    <div class="modal__body" [style]="{'padding':'0px'}">
      <div class="d-flex justify-content-center">
        <img src="./assets/img/mdi-cloud-upload-outline.svg" alt="" style="width: 34px; height: 30px;">
        <p class="fw-bold" style="font-size: 18px; padding-left: 8px; padding-top: 2px;">
          {{'upload_photo_from_computer'|translate}}</p>
      </div>
      <section (click)="onUploadClick()">
        <div>
          <app-icon-button class="icono" text="" color="#a3bd36" icon="./assets/img/mdi-cloud-upload-outline.svg">
          </app-icon-button>
          <input type="file" accept="image/*" style="display: none" (change)="onFileSelected($event)" />
        </div>
        <span>{{ "upload_image" | translate }}</span>
      </section>
      <div class="text-center">
        <app-button svg="mdi-check-circle-outline" iconAlignment="right" [scale]=".8" text="{{ 'confirm' | translate }}"
          [injection]="{ label: 'pt-2' }" data-bs-toggle="modal" />
      </div>
    </div>
  </app-so-modal>
</div>
