import {
  AfterViewInit,
  Component,
  OnInit,
  inject,
  AfterViewChecked,
  AfterContentInit,
} from '@angular/core';
import { ThemeService } from '@core/theme.service';
import { TranslateService } from '@core/api/translate.service';
import { StorageService } from '@core/services/storages.service';

import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private readonly configTheme = inject(ThemeService);
  private readonly translateService = inject(TranslateService);

  ngOnInit(): void {
    this.translateService.applyLang();
    this.configTheme.validateTheme();
  }
}
