import { Injectable, inject } from '@angular/core';
import { StorageService } from '@core/services/storages.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private readonly storageService = inject(StorageService);

  validateTheme() {
    const { tema: theme } = this.storageService.getLocalStorage('userInfo')?.data ?? { tema: 0 };
    document.querySelector('html')?.setAttribute('data-theme', theme == 0 ? 'light' : 'dark');
  }
}
