import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Request } from '@core/models/requests.model';
import { AniosFlotillasService } from '@core/services/catalogos/autos-de-interes/flotillas/anios-flotillas.service';
import { AutosFlotillasService } from '@core/services/catalogos/autos-de-interes/flotillas/autos-flotillas.service';
import { ColoresFlotillasService } from '@core/services/catalogos/autos-de-interes/flotillas/colores-flotillas.service';
import { MarcasFlotillasService } from '@core/services/catalogos/autos-de-interes/flotillas/marcas-flotillas.service';
import { VersionesFlotillasService } from '@core/services/catalogos/autos-de-interes/flotillas/versiones-flotillas.service';
import { GlobalSessionService } from '@core/services/global-session.service';
import { ColoresService } from '@core/services/catalogos/autos-de-interes/nuevos/colores.service.';


@Component({
  selector: 'so-car-interest-fleet',
  templateUrl: './car-interest-fleet.component.html',
  styleUrl: './car-interest-fleet.component.scss'
})
export class CarInterestFleetComponent implements OnInit{

  @Input() form!: FormGroup;
  @Input() directionItems: 'row' | 'column' = 'row';
  @Input() interestBrandControl!: string;
  @Input() carInterestControl!: string;
  @Input() yearsControl!: string;
  @Input() versionsControl!: string;
  @Input() colorsControl!: string;
  @Input() quantityControl!: string;

  public loaddingCarInterestBrand: boolean = false;
  public loaddingCarInterest: boolean = false;
  public loaddingCarsYear: boolean = false;
  public loaddingCarsVersion: boolean =  false;
  public loaddingColors: boolean = false;
  public loaddingQuantity: boolean = false;

  public listCarInterestBrand: any[] = [];
  public listCarInterest: any[] = [];
  public listCarsYear: any[] = [];
  public listCarsVersion: any[] = [];
  public listColors: any[] = [];
  public listQuantity: any[] = [];

  private userInfo = this.globalSessionService.userInfo().data;

  private selectedModelCar: string = '';
  private selectedCar: string = '';

  constructor(
    private request: Request,
    private globalSessionService: GlobalSessionService,
    private marcasFlotillasSerivce: MarcasFlotillasService,
    private autosFlotillasService: AutosFlotillasService,
    private aniosFlotillasService: AniosFlotillasService,
    private versionesFlotillasService: VersionesFlotillasService,
    private coloresFlotillasService: ColoresFlotillasService,
    private ColoresService: ColoresService,
  ){}

  ngOnInit(): void {

    this.loadControlsForm();

    this.loadCarInterestBrand();
  }

  loadControlsForm(){
    if (!this.form.get(this.interestBrandControl)) {
      this.form.addControl(this.interestBrandControl, new FormControl());
    }
    if (!this.form.get(this.carInterestControl)) {
      this.form.addControl(this.carInterestControl, new FormControl());
    }
    if (!this.form.get(this.yearsControl)) {
      this.form.addControl(this.yearsControl, new FormControl());
    }
    if (!this.form.get(this.versionsControl)) {
      this.form.addControl(this.versionsControl, new FormControl());
    }
    if (!this.form.get(this.colorsControl)) {
      this.form.addControl(this.colorsControl, new FormControl());
    }
    if (!this.form.get(this.quantityControl)) {
      this.form.addControl(this.quantityControl, new FormControl());
    }
  }


  loadCarInterestBrand(){
    this.loaddingCarInterestBrand = true;

    const paramsCarInerestBrand = {
      configuracion: this.request.getConfigUser(),
      idEmpresa: this.userInfo.empleado.empresa.idEmpresa,
    }

    this.marcasFlotillasSerivce.getMarcasFlotillas(paramsCarInerestBrand).subscribe({
      next: (res) => {
        const response = res.response;
        this.listCarInterestBrand = this.marcasFlotillasSerivce.formatValues(response, 'idmarca', 'alias');
        this.loaddingCarInterestBrand = false;
      },
      error: (err) => {
        console.log(err);
        this.loaddingCarInterestBrand = false;
      }
    });

  }

  onChangeCarInterestBrand(value: any) {
    this.listCarInterest = [];
    this.listCarsYear = [];
    this.listCarsVersion = [];
    this.listColors = [];
    this.listQuantity = [];

    this.selectedModelCar = value;
    this.loadCarInterest(value);
  }

  loadCarInterest(value: any){
    this.loaddingCarInterest = true;

    const paramsCarInteres = {
      configuracion: this.request.getConfigUser(),
      idMarca: value,
    }

    this.autosFlotillasService.getAutos(paramsCarInteres).subscribe({
      next: (res) => {
        const response = res.response;
        this.listCarInterest = this.marcasFlotillasSerivce.formatValues(response, 'idauto', 'nombre');
        this.loaddingCarInterest = false;
      },
      error: (err) => {
        console.log(err);
        this.loaddingCarInterest = false;
      }
    });
  }

  onChangeCarInterest(value: any){
    this.listCarsYear = [];
    this.listCarsVersion = [];
    this.listColors = [];
    this.listQuantity = [];

    this.selectedCar = value;

    this.loadCarsYear(value);
  }

  loadCarsYear(value: any){
    this.loaddingCarsYear = true;

    const paramsCarYears = {
      configuracion: this.request.getConfigUser(),
      idAuto: value,
      idMarca: this.selectedModelCar,
    }

    this.aniosFlotillasService.getAniosFlotillas(paramsCarYears).subscribe({
      next: (res) => {
        const response = res.response;
        this.listCarsYear =  this.marcasFlotillasSerivce.formatValues(response, 'anio', 'anio');
        this.loaddingCarsYear = false;
      },
      error: (err) => {
        console.log(err);
        this.loaddingCarsYear = false;
      }
    });
  }

  onChangeCarYears(value: any){
    this.listCarsVersion = [];
    this.listColors = [];
    this.listQuantity = [];

    this.loadCarVersion(value);
  }

  loadCarVersion(value: any){
    this.loaddingCarsVersion = true;

    const paramsVersionsCar = {
      configuracion: this.request.getConfigUser(),
      idAuto: this.selectedCar,
      anio: value,
      idMarca: this.selectedModelCar,
    }

    this.versionesFlotillasService.getVersionesFlotillas(paramsVersionsCar).subscribe({
      next: (res) => {
        const response = res.response;
        this.listCarsVersion = this.versionesFlotillasService.formatValues(response, 'idProducto', 'nombre');
        this.loaddingCarsVersion = false;
      },
      error: (err) => {
        console.log(err);
        this.loaddingCarsVersion = false;
      }
    });
  }

  onChangeCarVersions(value: any){
    this.listColors = [];
    this.listQuantity = [];

    this.loadColorsCar(value)
  }

  loadColorsCar(value: any){
    this.loaddingColors = true;

    const paramsColorsCar = {
      configuracion: this.request.getConfigUser(),
    };

    this.coloresFlotillasService.getAllColoresFlotillas(paramsColorsCar).subscribe({
      next: (res) => {
        const response = res.response;
        this.listColors = this.ColoresService.formatValues(response,'idColor', 'nombre', 'color');
        this.loaddingColors = false;
      },
      error: (err) => {
        console.log(err);
        this.loaddingColors = false;
      }
    });
  }

  onChangeColor(value: any){
    this.listQuantity = [];

    this.loadQuantity(value);
  }

  loadQuantity(value: any){
    this.loaddingQuantity = true;

    this.listQuantity = [
      { value: '1', quantity: '1' },
      { value: '2', quantity: '2' },
      { value: '3', quantity: '3' },
      { value: '4', quantity: '4' },
      { value: '5', quantity: '5' },
      { value: '6', quantity: '6' },
      { value: '7', quantity: '7' },
      { value: '8', quantity: '8' },
      { value: '9', quantity: '9' },
      { value: '10', quantity: '10' },
    ];

    this.loaddingQuantity = false;
  }

}
