import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TiposDeFechas } from '@core/interfaces/catalogos.intefaces';
import { Request } from '@core/models/requests.model';

@Injectable({
  providedIn: 'root',
})
export class TiposDeFechasService {
  public token = localStorage.getItem('Token');
  private url = `ventas/catalogos/tipos-fechas/search`;
  private URL_ORDENAR = 'ventas/catalogos/tipos-orden/search'
  private URL_MOSTRAR = 'ventas/catalogos/tipos-mostrar/search'
  private URL_PERSONAS = 'global/catalogos/tipodepersona/all'
  constructor(private http: HttpClient, private request: Request) {}

  getAllTiposDeFechas() {
    const options = { headers: this.request.headers };
    const body = {
      configuracion: this.request.getConfigUser(),
    };
    return this.request.Post<TiposDeFechas>(`${this.url}`, body, options);
  }
  getTiposDeOrden() {
    const options = { headers: this.request.headers };
    const body = {
      configuracion: this.request.getConfigUser(),
    };
    return this.request.Post<any>(`${this.URL_ORDENAR}`, body, options);
  }
  getTiposDeMostrar() {
    const options = { headers: this.request.headers };
    const body = {
      configuracion: this.request.getConfigUser(),
    };
    return this.request.Post<any>(`${this.URL_MOSTRAR}`, body, options);
  }
  getTiposDePersona(){
    const options = { headers: this.request.headers };
    const body = {
      basesDeDatos: this.request.getConfigUser().basesDeDatos,
      idioma: this.request.getConfigUser().idioma,
      husoHorario: this.request.getConfigUser().husoHorario
    };
    return this.request.Post<any>(`${this.URL_PERSONAS}`, body, options);
  }
}
