import { Component, OnInit, inject } from '@angular/core';
import { Response } from '@modules/lead-management/submodules/directory/interfaces/sale-opportunity.interface';
import { SharedStateService } from '@modules/lead-management/submodules/directory/services/shared-state.service';
import { SummaryProspectService } from '@modules/lead-management/submodules/directory/services/summary-prospect.service';
import { PromiseStatus } from '@shared/enums';

@Component({
  selector: 'app-accordion-sales-opportunity',
  templateUrl: './accordion-sales-opportunity.component.html',
  styleUrls: ['./accordion-sales-opportunity.component.scss']
})
export class AccordionSalesOpportunityComponent implements OnInit {
  
  private sharedStateServices = inject(SharedStateService);
  private summaryProspectService = inject(SummaryProspectService);
  public productsInterest?: Response;

  PromiseStatus = PromiseStatus;
  requestStatus = PromiseStatus.Pending;
  errorMessage: string = '';

  ngOnInit(): void {
    this.loadSaleOpportunity();
  }

  loadSaleOpportunity() {
    let idProspect = this.sharedStateServices.idProspect();
    this.summaryProspectService
    .getSaleOpportunity(idProspect)
    .subscribe({
      next: (data) => {
        const response = data.response;
        this.productsInterest = response;
        this.requestStatus = PromiseStatus.Fulfilled;
      },
      error: ({ error }) => {
        this.errorMessage = error.message;
        this.requestStatus = PromiseStatus.Rejected;
      },
    });
  }
}
