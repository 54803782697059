import { Component, Input } from '@angular/core';
import { SkeletonType } from 'src/app/common/types/skeleton.type';

@Component({
  selector: 'app-skeleton',
  standalone: true,
  imports: [],
  template: `
    <div class="placeholder-{{ type }}">
      <span 
        style="border-radius: {{ borderRadius }}; height: {{ height }}; width: {{ width }}; min-height: {{ minHeight }};"
        class="placeholder col-12"
      ></span>
    </div>
  `,
  styles: `
    div, span {
      width: 100%;
      height: 100%;
    }
  `
})
export class SkeletonComponent {
  @Input() type: SkeletonType = 'wave';
  @Input() borderRadius: string = '';
  @Input() height: string = '';
  @Input() width: string = '';
  @Input() minHeight: string = '1em';
}
