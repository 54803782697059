import { Component, Input, ViewChild, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '@modules/configuration-ia/services/api/token.service';
import { SharedStateKbdService } from '@modules/configuration-ia/services/signals/kbd/shared-state-kbd.service';
import { Tab } from '@shared/interfaces/nav-bar-ia.interfaces';

@Component({
  selector: 'app-so-tabs',
  templateUrl: './so-tabs.component.html',
  styleUrls: ['./so-tabs.component.scss']
})
export class SoTabsComponent{
  @Input() tabs: Tab[] = [];
  @Input() tabsDisplay: 'horizontal' | 'vertical' | 'sections' | 'steps' = 'horizontal';
  @Input() activeTabIndex: number = 0;
  @Input() type: 'simple' | 'principal' = 'simple';
  @Input() tabContentClass: string = '';
  @Input() tooltipDisabledText: string = '';
  @Output() activeTabIndexChange = new EventEmitter<number>();
  // @contentCard true: wrap in card, false: wrap in empty container
  @Input() contentCard: boolean = true;

  public isUploading: boolean = true;
  public isUploadingTextFile: boolean = true;

  @ViewChild('tabContainer', { read: ViewContainerRef, static: false }) tabContainer!: ViewContainerRef;
  constructor(
    public route: Router,
    public tokenService: TokenService,
    private sharedStateKdbService: SharedStateKbdService,
  ){
    this.getStateUploading();
  }

  setActiveTab(index: number): void {
    // if (index === this.activeTabIndex) return;
    this.activeTabIndex = index;    
    this.activeTabIndexChange.emit(this.activeTabIndex);
  }

  private getStateUploading(){
    this.sharedStateKdbService.isCompleted$.subscribe({
      next: (value) => {
        this.isUploading = value;
      }
    });
    this.sharedStateKdbService.textFileIsCompleted$.subscribe({
      next: (value) => {
        this.isUploadingTextFile = value;
      }
    });
  }

}