import { NgModule, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SoLateralMenuComponent } from './components/cellular/so-lateral-menu/so-lateral-menu.component';
import { SoLinkComponent } from './components/atomics/so-link/so-link.component';
import { RouterModule } from '@angular/router';
import { SoBadgeComponent } from './components/atomics/so-badge/so-badge.component';
import { SoBubbleWarningComponent } from './components/atomics/so-bubble-warning/so-bubble-warning.component';
import { SoCardBgComponent } from './components/atomics/so-card-bg/so-card-bg.component';
import { SoReplaceComponent } from './components/atomics/so-replace/so-replace.component';
import { CardInfoComponent } from './components/atomics/card-info/so-card-info.component';
import { SoSelectComponent } from './components/atomics/so-select/so-select.component';
import { LiComponent } from './components/atomics/UnorderedList/li/so-li.component';
import { SoSessionHistoryComponent } from '../modules/settings/components/so-session-history/so-session-history.component';
import { SoInputComponent } from './components/atomics/so-input-form/so-input-form.component';
import { SoModalComponent } from './components/atomics/so-modal/so-modal.component';
import { SoFormConfigComponent } from './components/atomics/so-form-config/so-form-config.component';
import { SoColorReplaceComponent } from './components/atomics/so-color-replace/so-color-replace.component';
import { SoCardComponent } from './components/atomics/so-card/so-card.component';
import { SimpleLeadCaptureComponent } from '@modules/lead-management/submodules/home/components/simple-lead-capture/simple-lead-capture.component';
import { SoNavbarComponent } from './components/cellular/so-navbar/so-navbar.component';
import { SoAvatarComponent } from './components/atomics/so-avatar/so-avatar.component';
import { SoInputGroupDropdownComponent } from './components/atomics/so-input-group-dropdown/so-input-group-dropdown.component';
import { SoIconWithBadgeComponent } from './components/atomics/so-icon-with-badge/so-icon-with-badge.component';
import { SoChartDoughnutComponent } from './components/atomics/so-chart-doughnut/so-chart-doughnut.component';
import { SoChartDoughnutSimpleComponent } from './components/atomics/so-chart-doughnut-simple/so-chart-doughnut-simple.component';
import { SoButtonGroupRadioComponent } from './components/atomics/so-button-group-radio/so-button-group-radio.component';
import { SoButtonGroupRadioFiltersComponent } from './components/atomics/so-button-group-radio-filters/so-button-group-radio-filters.component';
import { SofloatingIconComponent } from './components/cellular/so-floating-icon/so-floating-icon.component';
import { SoDinamicTableComponent } from './components/atomics/so-dinamic-table/so-dinamic-table.component';
import { SoTextStateComponent } from './components/atomics/so-text-state/so-text-state.component';
import { SoChartBarLineComponent } from './components/atomics/so-chart-bar-line/so-chart-bar-line.component';
import { SoChartLineCurveComponent } from './components/atomics/so-chart-line-curve/so-chart-line-curve.component';
import { SoChartLineComponent } from './components/atomics/so-chart-line/so-chart-line.component';
import { SoCloseSessionComponent } from './components/cellular/so-close-session/close-session.component';
import { SoMessageComponent } from './components/atomics/so-message/so-message.component';
import { SoToggleChatComponent } from './components/organism/toggle-chat/toggle-chat.component';
import { SoMessageFileComponent } from './components/atomics/so-message-file/so-message-file.component';
import { SoFollowUpComponent } from './components/atomics/so-follow-up/so-follow-up.component';
import { SoTextAreaComponent } from './components/atomics/so-text-area/so-text-area.component';
import { SoRadioComponent } from './components/atomics/so-radio/so-radio.component';
import { SoSwitchComponent } from './components/atomics/so-switch/so-switch.component';
import { SoAccordionPanelComponent } from './components/atomics/so-accordion-panel/so-accordion-panel.component';
import { SoCardListComponent } from './components/atomics/so-card-list/so-card-list.component';
import { SoCheckComponent } from './components/atomics/so-check/so-check.component';
import { SoToggleButtonComponent } from './components/atomics/so-toggle-button/so-toggle-button.component';
import { SoTitleIconComponent } from './components/atomics/so-title-icon/so-title-icon.component';
import { ModalUploadFromPcComponent } from '@modules/lead-management/submodules/home/components/modal-upload-from-pc/modal-upload-from-pc.component';
import { ModalDefaultImageComponent } from '@modules/lead-management/submodules/home/components/modal-default-image/modal-default-image.component';
import { ModalPhotoFromCameraComponent } from '@modules/lead-management/submodules/home/components/modal-photo-from-camera/modal-photo-from-camera.component';
import { SoCheckboxComponent } from './components/atomics/so-checkbox/so-checkbox.component';
import { MatTabsModule } from '@angular/material/tabs';
import { SoCardBgMultiComponent } from './components/atomics/so-card-bg-multi/so-card-bg-multi.component';
import { ModalSureConfirmComponent } from '@modules/lead-management/submodules/home/components/modal-sure-confirm/modal-sure-confirm.component';
import { ModalConfirmCreateComponent } from '@modules/lead-management/submodules/home/components/modal-confirm-create/modal-confirm-create.component';
import { NavbarMarketingComponent } from './components/cellular/so-navbar/components/navbar-marketing/navbar-marketing.component';
import { NavbarMulticanalComponent } from './components/cellular/so-navbar/components/navbar-multicanal/navbar-multicanal.component';
import { NavbarLineBusinessComponent } from './components/cellular/so-navbar/components/navbar-line-business/navbar-line-business.component';
import { SharedLayoutComponent } from './layout/shared-layout/shared-layout.component';
import { BodyLoadingComponent } from './components/atomics/body-loading/body-loading.component';
import { textTransform } from '@core/pipes/text-transform.pipe';
import { SoMatIconComponent } from './components/atomics/so-mat-icon/so-mat-icon.component';
import { AppAlertComponent } from './components/atomics/so-dynamic-alert/so-dynamic-alert';
import { SoSummaryComponent } from './components/cellular/so-summary/so-summary.component';
import { AccordionCommunicationChannelsComponent } from './components/cellular/so-summary/components/accordion-communication-channels/accordion-communication-channels.component';
import { AccordionFollowUpHistoryComponent } from './components/cellular/so-summary/components/accordion-follow-up-history/accordion-follow-up-history.component';
import { AccordionPrivacyLogComponent } from './components/cellular/so-summary/components/accordion-privacy-log/accordion-privacy-log.component';
import { AccordionProspectDataComponent } from './components/cellular/so-summary/components/accordion-prospect-data/accordion-prospect-data.component';
import { AccordionSalesOpportunityComponent } from './components/cellular/so-summary/components/accordion-sales-opportunity/accordion-sales-opportunity.component';
import { ModalAddFileComponent } from './components/cellular/so-summary/components/modal-add-file/modal-add-file.component';
import { ModalRejectProspectComponent } from './components/cellular/so-summary/components/modal-reject-prospect/modal-reject-prospect.component';
import { ModalSaleOrderComponent } from './components/cellular/so-summary/components/modal-sale-order/modal-sale-order.component';
import { ModalValuationRequestComponent } from './components/cellular/so-summary/components/modal-valuation-request/modal-valuation-request.component';
import { SoModalEditarProspectoComponent } from '@modules/lead-management/submodules/follow-ups/components/so-modal-editar-prospecto/so-modal-editar-prospecto.component';
import { SoModalAsignarVentaComponent } from '@modules/lead-management/submodules/follow-ups/components/so-modal-asignar-venta/so-modal-asignar-venta.component';
import { SoNewFollowUpComponent } from '@modules/lead-management/submodules/follow-ups/components/so-new-follow-up/so-new-follow-up.component';
import { CoreModule } from '@core/core.module';
import { ModalFolloupsHistoryComponent } from './components/cellular/so-summary/components/modal-folloups-history/modal-folloups-history.component';
import { SoReplaceTextColorComponent } from './components/atomics/so-replace-text-color/so-replace-text-color.component';
import { SoRequiredMarkComponent } from './components/atomics/so-required-mark/so-required-mark.component';
import { SoSpinnerComponent } from './components/atomics/so-spinner/so-spinner.component';
import { NavbarUserComponent } from './components/cellular/so-navbar/components/navbar-user/navbar-user.component';
import { NavbarPanelComponent } from './components/cellular/so-navbar/components/navbar-panel/navbar-panel.component';
import { SoExecutiveSelectComponent } from './components/atomics/so-executive-select/so-executive-select.component';
import { SoIconComponent } from '@common/components/atomics/so-icon/so-icon.component';
import { CardComponent } from '@common/components/atomics/card/card.component';
import { TitleComponent } from '@common/components/atomics/title/title.component';
import { ButtonComponent } from '@common/components/atomics/button/button.component';
import { IconButtonComponent } from '@common/components/atomics/icon-button/icon-button.component';
import { InputComponent } from '@common/components/atomics/input/input.component';
import { SoAccordionComponent } from '../common/components/atomics/so-accordion/so-accordion.component';
import { OrganismModalAddCarInventoryComponent } from '@shared/components/organism/organism-modal-add-car-inventory/organism-modal-add-car-inventory.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SoUserIconComponent } from '@shared/components/atomics/so-user-icon/so-user-icon.component'
import { SoSearchFiltersComponent } from '@shared/components/cellular/so-search-filters/so-search-filters.component';
import { LeadCaptureStepsComponent } from '@modules/lead-management/submodules/home/components/simple-lead-capture/components/lead-capture-steps/lead-capture-steps.component';
import { SoAdvanceFiltersComponent } from './components/cellular/so-advance-filters/so-advance-filters.component';
import { ModalFilterAdvanceComponent } from './components/cellular/so-advance-filters/modal-filter-advance/modal-filter-advance.component';
import { SimpleCaptureComponent } from '@modules/lead-management/submodules/home/components/simple-lead-capture/components/simple-capture/simple-capture.component';
import { DetailCaptureComponent } from '@modules/lead-management/submodules/home/components/simple-lead-capture/components/detail-capture/detail-capture.component';
import { SoSelectCustomReactiveComponent } from './components/atomics/so-select-custom-reactive/so-select-custom-reactive.component';
import { SoSelectReactiveFormComponent } from './components/atomics/so-select-reactive-form/so-select-reactive-form.component';
import { SoSelectGroupedReactiveFormComponent } from './components/atomics/so-select-grouped-reactive-form/so-select-grouped-reactive-form.component';
import { SoGroupSelectReactiveForm } from './components/atomics/so-group-select-reactive-form/so-group-select-reactive-form.component';
import { SoInputReactiveFormComponent } from './components/atomics/so-input-reactive-form/so-input-reactive-form.component';
import { SoInputEmailReactiveFormComponent } from './components/atomics/so-input-email-reactive-form/so-input-email-reactive-form.component';
import { SoPendingMenuComponent } from './components/cellular/so-navbar/components/so-pending-menu/so-pending-menu.component';
import { SoNotificationMenuComponent } from './components/cellular/so-navbar/components/so-notification-menu/so-notification-menu.component';
import { SoCardNotificationsComponent } from './components/atomics/so-card-notifications/so-card-notifications.component';
import { SkeletonComponent } from '@common/components/atomics/skeleton/skeleton.component';
import { SoSliderCheckComponent } from '@shared/components/atomics/so-slider-check/so-slider-check.component';
import { SoDragDropListComponent } from './components/cellular/so-drag-drop-list/so-drag-drop-list.component';
import { SoCheckboxReactiveFormComponent } from './components/atomics/so-checkbox-reactive-form/so-checkbox-reactive-form.component';
import { NavbarSubMenuComponent } from './components/cellular/so-navbar/components/navbar-sub-menu/navbar-sub-menu.component';
import { SoSvgIconComponent } from "./components/atomics/so-svg-icon/so-svg-icon.component";
import { SoHistoryComponentComponent } from './components/cellular/so-summary/components/so-history-component/so-history-component.component';
import { SoSellsComponentComponent } from './components/cellular/so-summary/components/so-sells-component/so-sells-component.component';
import { SoValuationsComponentComponent } from './components/cellular/so-summary/components/so-valuations-component/so-valuations-component.component';
import { SoInputDateReactiveFormComponent } from './components/atomics/so-input-date-reactive-form/so-input-date-reactive-form.component';
import { SoAttachmentHistoryComponent } from './components/cellular/so-summary/components/so-attachment-history/so-attachment-history.component';

import { TranslatedPipe } from '@core/pipes/translated.pipe';
//Cellular
import { CarInterestNewComponent } from './components/cellular/car-interest-new/car-interest-new.component';
import { CarInterestPreOwnedComponent } from './components/cellular/car-interest-pre-owned/car-interest-pre-owned.component';
import { CarInterestFleetComponent } from './components/cellular/car-interest-fleet/car-interest-fleet.component';
import { SoOrganismCarInterestBusinessComponent } from './components/organism/so-organism-car-interest-business/so-organism-car-interest-business.component';
import { SoTabsComponent } from './components/cellular/so-tabs/so-tabs.component';
import { SoDropFilesComponent } from './components/cellular/so-drop-files/so-drop-files.component';
import { SoInputSearchComponent } from './components/atomics/so-input-search/so-input-search.component';
import { SoNavbarSimpleComponent } from './components/atomics/so-navbar-simple/so-navbar-simple.component';
import { SanitizeInputDirective } from './helpers/sanitize-input.directive';
import { SoIndicatorTextComponent } from './components/atomics/so-indicator-text/so-indicator-text.component';



import { SoPaginationComponent } from './components/atomics/so-pagination/so-pagination.component';
import { SoSelectMultiReactiveComponent } from './components/atomics/so-select-multi-reactive/so-select-multi-reactive.component';
import { SoFileDropZoneComponent } from './components/cellular/so-file-drop-zone/so-file-drop-zone.component';
import { SoDropSimpleFileComponent } from './components/cellular/so-drop-simple-file/so-drop-simple-file.component';

import { MatTooltipModule } from '@angular/material/tooltip'; 

import { MatSliderModule } from '@angular/material/slider'; 
import { SoSidebarSimpleComponent } from './components/cellular/so-sidebar-simple/so-sidebar-simple.component';
import { SoLoadingAnimationComponent } from "./components/atomics/so-loading-animation/so-loading-animation.component";
import { SoFilePreviewComponent } from './components/atomics/so-file-preview/so-file-preview.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/lang_', '.json');
}

const standaloneComponents = [
  SoIconComponent,
  ButtonComponent,
  CardComponent,
  TitleComponent,
  IconButtonComponent,
  InputComponent,
  SoAccordionComponent,
  SoUserIconComponent,
  SkeletonComponent,
  SoSliderCheckComponent,
  SoIndicatorTextComponent
];

const atomicsComponents = [
  SoInputSearchComponent,
  SoNavbarSimpleComponent,
  SoSelectMultiReactiveComponent,
  SoSidebarSimpleComponent,
  SoFilePreviewComponent,
];

const cellularComponents = [
  CarInterestNewComponent,
  CarInterestPreOwnedComponent,
  CarInterestFleetComponent,
  SoTabsComponent,
  SoDropFilesComponent,
  SoFileDropZoneComponent,
  SoDropSimpleFileComponent,
];

const organismComponents = [
  SoOrganismCarInterestBusinessComponent,
  SoPaginationComponent,
];

const directives = [
  SanitizeInputDirective,
]

@NgModule({
  declarations: [
    SoLateralMenuComponent,
    SoLinkComponent,
    SoBadgeComponent,
    SoBubbleWarningComponent,
    SoCardBgComponent,
    SoReplaceComponent,
    CardInfoComponent,
    SoSelectComponent,
    OrganismModalAddCarInventoryComponent,
    LiComponent,
    SoSessionHistoryComponent,
    SoInputComponent,
    SoModalComponent,
    SoFormConfigComponent,
    SoColorReplaceComponent,
    SoCardComponent,
    SimpleLeadCaptureComponent,
    SoNavbarComponent,
    SoAvatarComponent,
    SoInputGroupDropdownComponent,
    SoIconWithBadgeComponent,
    SoChartDoughnutComponent,
    SoChartDoughnutSimpleComponent,
    SoButtonGroupRadioComponent,
    SoButtonGroupRadioFiltersComponent,
    SofloatingIconComponent,
    SoDinamicTableComponent,
    SoTextStateComponent,
    SoChartBarLineComponent,
    SoChartLineCurveComponent,
    SoChartLineComponent,
    SoCloseSessionComponent,
    SoMessageComponent,
    SoToggleChatComponent,
    SoMessageFileComponent,
    SoFollowUpComponent,
    SoTextAreaComponent,
    SoRadioComponent,
    SoSwitchComponent,
    SoAccordionPanelComponent,
    SoCardListComponent,
    SoCheckComponent,
    SoToggleButtonComponent,
    SoTitleIconComponent,
    ModalUploadFromPcComponent,
    ModalDefaultImageComponent,
    ModalPhotoFromCameraComponent,
    SoCheckboxComponent,
    SoCardBgMultiComponent,
    ModalSureConfirmComponent,
    ModalConfirmCreateComponent,
    NavbarMarketingComponent,
    NavbarMulticanalComponent,
    NavbarLineBusinessComponent,
    SharedLayoutComponent,
    BodyLoadingComponent,
    SoMatIconComponent,
    AppAlertComponent,
    SoSummaryComponent,
    AccordionProspectDataComponent,
    AccordionCommunicationChannelsComponent,
    AccordionSalesOpportunityComponent,
    AccordionPrivacyLogComponent,
    AccordionFollowUpHistoryComponent,
    ModalRejectProspectComponent,
    ModalSaleOrderComponent,
    ModalValuationRequestComponent,
    ModalAddFileComponent,
    SoModalEditarProspectoComponent,
    SoModalAsignarVentaComponent,
    SoNewFollowUpComponent,
    ModalFolloupsHistoryComponent,
    SoReplaceTextColorComponent,
    SoRequiredMarkComponent,
    SoSpinnerComponent,
    NavbarUserComponent,
    NavbarPanelComponent,
    SoExecutiveSelectComponent,
    SoSearchFiltersComponent,
    SoAdvanceFiltersComponent,
    ModalFilterAdvanceComponent,
    LeadCaptureStepsComponent,
    SimpleCaptureComponent,
    DetailCaptureComponent,
    SoSelectCustomReactiveComponent,
    SoSelectReactiveFormComponent,
    SoSelectGroupedReactiveFormComponent,
    SoGroupSelectReactiveForm,
    SoInputReactiveFormComponent,
    SoInputEmailReactiveFormComponent,
    SoPendingMenuComponent,
    SoNotificationMenuComponent,
    SoCardNotificationsComponent,
    SoDragDropListComponent,
    SoCheckboxReactiveFormComponent,
    NavbarSubMenuComponent,
    SoHistoryComponentComponent,
    SoSellsComponentComponent,
    SoValuationsComponentComponent,
    SoInputDateReactiveFormComponent,
    ModalRejectProspectComponent,
    ModalSaleOrderComponent,
    ModalValuationRequestComponent,
    ModalAddFileComponent,
    ModalFolloupsHistoryComponent,
    SoModalEditarProspectoComponent,
    SoModalAsignarVentaComponent,
    SoNewFollowUpComponent,
    SoAttachmentHistoryComponent,
    ...cellularComponents,
    ...organismComponents,
    ...atomicsComponents,
    ...directives,
  ],
  exports: [
    MatIconModule,
    TitleComponent,
    HttpClientModule,
    TranslateModule,
    ReactiveFormsModule,
    SoLateralMenuComponent,
    SoBadgeComponent,
    SoBubbleWarningComponent,
    SoCardBgComponent,
    SoReplaceComponent,
    CardInfoComponent,
    SoSelectComponent,
    OrganismModalAddCarInventoryComponent,
    LiComponent,
    SoSessionHistoryComponent,
    SoInputComponent,
    SoModalComponent,
    SoFormConfigComponent,
    SoColorReplaceComponent,
    SoLinkComponent,
    SoCardComponent,
    SimpleLeadCaptureComponent,
    SoNavbarComponent,
    SoAvatarComponent,
    SoChartDoughnutComponent,
    SoChartDoughnutSimpleComponent,
    SoButtonGroupRadioComponent,
    SoButtonGroupRadioFiltersComponent,
    SofloatingIconComponent,
    SoDinamicTableComponent,
    SoTextStateComponent,
    SoChartBarLineComponent,
    SoChartLineCurveComponent,
    SoChartLineComponent,
    SoCloseSessionComponent,
    SoMessageComponent,
    SoToggleChatComponent,
    SoMessageFileComponent,
    SoFollowUpComponent,
    SoTextAreaComponent,
    SoRadioComponent,
    SoSwitchComponent,
    SoTextAreaComponent,
    SoAccordionPanelComponent,
    SoCardListComponent,
    SoCheckComponent,
    SoToggleButtonComponent,
    SoTitleIconComponent,
    ModalUploadFromPcComponent,
    ModalDefaultImageComponent,
    ModalPhotoFromCameraComponent,
    SoCheckboxComponent,
    MatTabsModule,
    SoCardBgMultiComponent,
    SoInputGroupDropdownComponent,
    ModalSureConfirmComponent,
    ModalConfirmCreateComponent,
    BodyLoadingComponent,
    SoMatIconComponent,
    AppAlertComponent,
    SoSummaryComponent,
    SoExecutiveSelectComponent,
    SoSearchFiltersComponent,
    SoAdvanceFiltersComponent,
    ModalFilterAdvanceComponent,
    SoSelectCustomReactiveComponent,
    SoSelectReactiveFormComponent,
    SoSelectGroupedReactiveFormComponent,
    SoGroupSelectReactiveForm,
    SoInputReactiveFormComponent,
    SoInputEmailReactiveFormComponent,
    SoDragDropListComponent,
    SoCheckboxReactiveFormComponent,
    SoInputDateReactiveFormComponent,
    ...standaloneComponents,
    ModalRejectProspectComponent,
    ModalSaleOrderComponent,
    ModalValuationRequestComponent,
    ModalAddFileComponent,
    ModalFolloupsHistoryComponent,
    SoModalEditarProspectoComponent,
    SoModalAsignarVentaComponent,
    SoNewFollowUpComponent,
    SoAttachmentHistoryComponent,
    TranslatedPipe,
    ...cellularComponents,
    ...organismComponents,
    ...atomicsComponents,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatDialogModule,
    MatSliderModule,
    CoreModule,
    MatTooltipModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    textTransform,
    ...standaloneComponents,
    SoSvgIconComponent,
    SoLoadingAnimationComponent,
  ]
})
export class SharedModule {
  private readonly translateService = inject(TranslateService);

  constructor() {
    const storageLang = localStorage.getItem('browserLang');

    if (!storageLang) {
      const browserLang = this.translateService.getBrowserLang();
      this.translateService.use(browserLang?.match(/en|es/) ? browserLang : 'es');
    } else this.translateService.use(storageLang ? storageLang : 'es');
  }
}
