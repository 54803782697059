@if (requestStatus === PromiseStatus.Pending) {
<so-spinner />
}
@else if (requestStatus === PromiseStatus.Rejected) {
<span class="so-text-2 d-block text-center">{{ errorMessage }}</span>
}
@else if (!productsInterest || productsInterest.productosDeInteres.length === 0) {
<span class="so-text-2 d-block text-center">{{ 'no_data' | translated }}</span>
}
@else {
<div>
  <h5 class="title-according">{{'sales_process_started_on'|translate}}</h5>
  <h6 class="subtitle-according"> {{ productsInterest.primerContacto }} </h6>
  <so-input-reactive-form 
    [label]="'status' | translate" 
    [value]="productsInterest.status" 
    [isDisabled]="true" >
  </so-input-reactive-form>

  @for (item of productsInterest.productosDeInteres; track $index) {
    <h6 class="ms-1 mt-2" style="font-weight: 400 !important; color: #272D3B; margin: 0;">
      {{'car_of_interest'|translate}}</h6>
    <div class="row ms-1">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-2 col-xxl-2 d-flex align-items-center " style="padding-right: 0;">
        <img src="./assets/img/avatar-random2x-car.png" alt="" style="width: 30px; ">
      </div>
      <div class="col-sm-10 col-md-12 col-lg-12 col-xl-10 col-xxl-10" style="padding-left: 0;">
  
        <so-input-reactive-form [value]="item.nombreAuto" [isDisabled]="true"></so-input-reactive-form>
      </div>
    </div>
    <div class="mt-1">
      <so-input-reactive-form [label]="'version' | translate" [value]="item.nombreVerion" [isDisabled]="true"></so-input-reactive-form>
    </div>
  }
</div>
}
