import { Component, EventEmitter, inject, Input, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@core/api/translate.service';
import { FileObject } from '@modules/configuration-ia/pages/submodules/kbd/interfaces/local-files.interfaces';
import { AlertType, DynamicComponentService } from '@shared/components/atomics/body-loading/dynamic-component-service.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-so-drop-files',
  templateUrl: './so-drop-files.component.html',
  styleUrl: './so-drop-files.component.scss'
})
export class SoDropFilesComponent {
  public isDragging = false;
  public files: FileObject[] = [];
  public dynamicComponentService = inject(DynamicComponentService);
  public readonly translateService = inject(TranslateService);

  @Output() saveFilesEvent = new EventEmitter<File[]>();

  @Input() filesToAdd: File[] = [];
  @Input() showSaveButton: boolean = true;
  @Input() showCleanButton: boolean = false;
  @Input() isMultiple: boolean = true;
  @Input() accept: string = '';
  @Input() label: string = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filesToAdd']) {
      if (this.filesToAdd.length > 0) {
        this.handleSingleFile(this.filesToAdd);
      }else{
        this.files = [];
      }
    }
  }

  handleSingleFile(files: File[]) {
    files.forEach(file => {
      this.files.push({
        file: file,
        markedToSave: true
      });
    })
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = false;

    if (event.dataTransfer?.files) {
      this.handleFiles(event.dataTransfer.files);
    }
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.handleFiles(input.files);
    }
  }

  handleFiles(files: FileList) {
    Array.from(files).forEach(file => {
      this.files.push({
        file: file,
        markedToSave: true
      });
    });
  }

  saveFiles() {
    let filesToSaveCount = this.files.reduce((count, element) => element.markedToSave ? count + 1 : count, 0);
    this.dynamicComponentService.showAlert({
      title: this.translateService.instant('save_files_alert', '¿Deseas guardar %s archivos?').replace('%s', filesToSaveCount.toString()),
      message: this.translateService.instant('save_checked_files', 'Se guardarán los archivos marcados'),
      alertType: AlertType.warning,
      autohide: false,
      textSuccessButton: this.translateService.instant('accept', 'Aceptar'),
    }).pipe(take(1))
    .subscribe((response) => {
      if (response) {
        this.dynamicComponentService.destroyAlert();
        let filesToSave = this.files.filter(file => file.markedToSave).map(file => file.file);
        this.saveFilesEvent.emit(filesToSave);
      } else {
        this.dynamicComponentService.destroyAlert();
      }
    });
  }

  cleanFiles(){
    this.files = [];
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ''; // Esto limpia el input
    }
  }

}
