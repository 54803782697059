import { Injectable, inject } from '@angular/core';
import { Observable, tap } from 'rxjs';
import type { ConfigInterface } from '@core/interfaces';
import type { FollowUpsSummaryResponse, FollowUpInfoResponse, FollowUpGoalsResponse, FollowUpGoal } from '../interfaces';
import { Request } from '@core/models/requests.model';

@Injectable({
  providedIn: 'root'
})
export class FollowUpsService {
  private readonly URL_FOLLOW_UPS_SUMMARY = `ventas/resumen-seguimientos/search`;
  private readonly URL_FOLLOW_UP_INFO = `ventas/status-seguimientos/search`;
  private readonly URL_FOLLOW_UP_GOALS = `ventas/objetivos-seguimientos/list`;
  private readonly request: Request = inject(Request);
  private readonly configUser: ConfigInterface & { husoHorario: any } = this.request.getConfigUser();

  // Obtener el resumen de seguimientos del prospecto indicado
  getFollowUpsSummary(idProspecto: string): Observable<FollowUpsSummaryResponse> {
    const { basesDeDatos, idioma } = this.configUser;
    const body: { configuracion: ConfigInterface, idProspecto: string } = {
      configuracion: { basesDeDatos, idioma },
      idProspecto,
    }
    return this.request.Post<FollowUpsSummaryResponse>(this.URL_FOLLOW_UPS_SUMMARY, body);
  }

  // Obtener información del seguimiento indicado
  getFollowUpInformation(idSeguimiento: string): Observable<FollowUpInfoResponse> {
    const body: { configuracion: ConfigInterface, idSeguimiento: string } = {
      configuracion: this.configUser,
      idSeguimiento
    }
    return this.request.Post<FollowUpInfoResponse>(this.URL_FOLLOW_UP_INFO, body);
  }

  // Obtener los objetivos del seguimiento indicado
  getFollowUpGoals(idSeguimiento: string, ordered = true): Observable<FollowUpGoalsResponse> {
    const body: { configuracion: ConfigInterface, idSeguimiento: string } = {
      configuracion: this.configUser,
      idSeguimiento
    }
    return this.request.Post<FollowUpGoalsResponse>(this.URL_FOLLOW_UP_GOALS, body)
    .pipe(tap((data: FollowUpGoalsResponse) => {
      if (ordered)
        data.response.objetivos.sort((a: FollowUpGoal, b: FollowUpGoal) => a.orden - b.orden);
    }));
  }
}
