import { ItemsLateralMenu } from "@shared/interfaces/items-lateral-menu.interface";

export const MARKETING_LINKS: ItemsLateralMenu = {
    items: [
      {
        type: 'toggle',
        show: false,
        svg: 'mdi-megamenu.svg',
        config: {
          colors: {
            background: '',
            icon: '',
            label: '',
          },
          size: {
            width: '',
            height: '',
          },
        },
        contentLinks: [
          {
            type: 'link',
            url: '/custom-link',
            icon: 'custom_icon',
            label: 'Custom Link for pruebaMKT',
          },
          // Agrega otros enlaces personalizados según sea necesario
        ],
      },
      {
        type: 'link',
        url: '/marketing',
        icon: 'home',
      },
      {
        type: 'link',
        url: '/marketing/roleConfig',
        svg: 'mdi-shield-account.svg',
      },
      {
        type: 'link',
        url: '/marketing/configUser',
        svg: 'mdi-account-group-default.svg',
      },
      {
        type: 'link',
        url: '/marketing/stateAccount',
        svg: 'mdi-file-chart.svg',
      },
      {
        type: 'link',
        url: '/marketing/emailDist',
        svg: 'mdi-email-seal.svg',
      },
      {
        type: 'link',
        url: '/marketing/mailPackage',
        svg: 'mdi-shopping-cart-white.svg',
      },
      {
        type: 'link',
        url: '',
        icon: 'settings',
      },
    ],
    actionsItems: [
      {
        icon: 'add_circle_outline',
        event: 'open-plus-menu',
      },
    ],
  };