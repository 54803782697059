import { Injectable } from '@angular/core';
import { Autos} from '@core/interfaces/catalogos.intefaces';
import { Request } from '@core/models/requests.model';

@Injectable({
  providedIn: 'root',
})
export class AutosSeminuevosService{

  private url = `ventas/catalogos/seminuevos/auto/search`;

  constructor(
    private request:Request
  ) { }

  getAutos(body:any) {
    const options = { headers: this.request.headers };
    return this.request.Post<Autos>( this.url,body, options);
  }

  formatValues(data: any[], keyProp: string, valueProp: string): { key: any; value: any }[] {
    return data.map(item => ({ key: item[keyProp], value: item[valueProp] }));
  }


}

