import { Injectable } from '@angular/core';
import { Request } from '@core/models/requests.model';
// import { CurrentStatusResponse } from '@core/interfaces/catalogos.intefaces';
import { StorageService } from '@core/services/storages.service';

@Injectable({
  providedIn: 'root'
})
export class CurrentStatusService {
  private url = `global/estado`;

  constructor(
    private request: Request,
    private StorageService: StorageService,
  ) { }


  getStatusCatalog(){

    const storageData = this.StorageService.getLocalStorage('userInfo').data;

    const body = { 
      configuracion: {
            basesDeDatos: {
              dbEmpresa: storageData.idBasDeDatos
            },
          idioma: {
            idIdioma: storageData.empleado.empresa.idioma.idIdioma,
            idPais: storageData.empleado.empresa.pais.idPais
          },
          husoHorario: {
              idHuso: storageData.empleado.empresa.husoHorario.idHuso,
              nombre: storageData.empleado.empresa.husoHorario.nombre
          }
      }
    }

    const options = {
      headers: this.request.headers,
    }

    return this.request.Post(`${this.url}/catalogo/all`, body, options);
  }

  getCurrentUserStatus(){
    const storageData = this.StorageService.getLocalStorage('userInfo').data;

    const body = { 
      idUsuario: storageData.empleado.idEmpleado,
      configuracion: {
            basesDeDatos: {
              dbEmpresa: storageData.idBasDeDatos
            },
          idioma: {
            idIdioma: storageData.empleado.empresa.idioma.idIdioma,
            idPais: storageData.empleado.empresa.pais.idPais
          },
          husoHorario: {
              idHuso: storageData.empleado.empresa.husoHorario.idHuso,
              nombre: storageData.empleado.empresa.husoHorario.nombre
          }
      }
    }

    const options = {
      headers: this.request.headers,
    }

    return this.request.Post(`${this.url}/usuario/search`, body, options);
  }

  updateCurrentUserStatus(idEstado: string | number){

    const storageData = this.StorageService.getLocalStorage('userInfo').data;

    const body = { 
      idUsuario: storageData.empleado.idEmpleado,
      idEstado: idEstado,
      configuracion: {
            basesDeDatos: {
              dbEmpresa: storageData.idBasDeDatos
            },
          idioma: {
            idIdioma: storageData.empleado.empresa.idioma.idIdioma,
            idPais: storageData.empleado.empresa.pais.idPais
          },
          husoHorario: {
              idHuso: storageData.empleado.empresa.husoHorario.idHuso,
              nombre: storageData.empleado.empresa.husoHorario.nombre
          }
      }
    }

    const options = {
      headers: this.request.headers,
    }

    return this.request.Post(`${this.url}/usuario/update`, body, options);
  }

  transformValues(data: any[], keyProp: string, valueProp: string, colorProp: string): { key: any; value: any; color?:any }[] {
    return data.map(item => ({ key: item[keyProp], value: item[valueProp], color: item[colorProp] }));
  }


}
