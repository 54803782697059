export function formatTimezone(timezone: string): string {
    if (!timezone) {
      return '';
    }
  
    // Separar la parte antes y después de la barra '/'
    const parts = timezone.split('/');
  
    // Capitalizar la primera letra de cada palabra antes y después de la barra '/'
    const formattedParts = parts.map(part =>
      part
        .split('_') // No cambiar el guion bajo, solo capitalizar la primera letra de cada palabra
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join('_')
    );
  
    return formattedParts.join('/');
  }