<div class="shadow rounded d-flex flex-column card-container" dataList="dataList" description="description">
  <div class="card__info-header w-100 d-flex justify-content-around align-items-center"
    style="height:20%;min-height: 83px;">
    <app-so-title-icon [title]="cardTitle" [logo]="iconCard" fontSize="normal" [customStyle]="customTitleStyle" [iconSize]="iconSize" 
      [firstIcon]="false" [onlyPath]="onlyPath" ></app-so-title-icon>
  </div>

  <div class="card__info-body"
    style="background-image: url({{bgImg}}); background-position: center;background-size: cover; position: relative;border-radius: .5rem;height:40%;opacity: .75!important;min-height: 167px;">
    <div class="gradient-transparent" style="    background: transparent linear-gradient(185deg, #FFFFFF00 32%, #FFFFFF 100%) 6% 0% no-repeat padding-box;
    position: absolute;
    width: 100%;
    height: 100%;min-height: 167px;"></div>
  </div>

  <div class="card__info-footer d-flex flex-column"
    style="height:40%;gap: .5rem!important;border-bottom-left-radius: .5rem;border-bottom-right-radius: .5rem;">
    <p class="footer_title " style="font-size: 14px;">{{description}}</p>
    <app-li class="pl-10" [dataList]="dataList"></app-li>
    <div class=""
      style="margin-right: 14px;display: flex; justify-content: end; align-items: center; padding-right: 14px; padding: 2;">
      <app-button text="{{'more_about'|translate}}" svg="arrow_right" iconAlignment="right"
        [injection]="{ label: 'pt-2' }" [scale]=".95"></app-button>
    </div>
  </div>

</div>
