@switch (typeCard) {
@case ('Message') {
<div class="card  card-border mt-2" [ngClass]="['BG-'+ bgColor , hover ? 'hover' : ''] " [class.active]="active">
  <div class="card-body ">

    <button type="button" class="btn btn-sm btn-icon position-relative">
      <img [src]="getImgIcon(bgColor)" class="img-icons ">
      <span class="indicator" *ngIf="valIcon">{{valIcon}}</span>
    </button>

    <label class="title">{{ title | translate}}</label>

  </div>
</div>
}
@case ('General') {
<div class="card  card-border mt-2" [ngClass]="['BG-'+ bgColor , hover ? 'hover' : ''] " [class.active]="active">
  <div class="card-body ">
    <img [src]="urlImg + img" alt="" class="img">
    <label class="title">{{title | translate}}</label>
    <img [src]="isVip ? urlImg + 'mdi-vip.svg' : urlImg + 'mdi-vip-disabled.svg' " class="img">

    <div class="mt-3" *ngIf="icons[0]">

      <button type="button" class="btn btn-sm btn-icon position-relative me-2" *ngFor="let icons of icons ;" (click)="onClickIcon(icons.type)">
        <img [src]="getImgIcon(icons.type)" class="img-icons ">
        <span class="indicator">{{icons.value}}</span>
      </button>

      <button type="button" class="btn btn-sm btn-icon float-end" *ngIf="!icons[4]">
        <img [src]="urlImg + 'add-circle-outline-secondary.svg'" class="img-visiility ">
      </button>
    </div>

    <div class="mt-3 truncate-text" *ngIf="textMessage">
      {{textMessage}}
    </div>

  </div>
</div>
}
@case ('OnlyIcon'){
<div class="card  card-border mt-2" [ngClass]="['BG-'+ bgColor , hover ? 'hover' : ''] " [class.active]="active">
  <div class="card-body centered">

    <button type="button" class="btn btn-sm btn-icon position-relative">
      <img [src]="getImgIcon(bgColor)" class="img-icons ">
      <span class="indicator" *ngIf="valIcon">{{valIcon}}</span>
    </button>

  </div>
</div>
}
@case ('Tab') {
  <div class="card card-border gap-4" [ngClass]="['BG-'+ bgColor , hover ? 'hover' : ''] " [class.active]="active">
    <div class="card-body d-flex align-items-center justify-content-start">
      @if (img != 'avatar-random-0.png') {
        <app-so-svg-icon [src]="'assets/img/' + img + '.svg'" [applyChange]="bgColor" [size]="iconSize" [color]="'--color-icon'"></app-so-svg-icon>
      }
      <label class="title">{{ title | translate}}</label>
    </div>
  </div>
  }
}
