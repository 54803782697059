<input type="radio" class="btn-check" name="options-outlined" id="success-outlined" autocomplete="off" checked
  (change)="toggleBoolean('0')">
<label class="btn btn-multicanal" for="success-outlined">
  <so-icon svg="mdi-headphones-mic" [size]="0.8" />
  <span  class="spn"> {{'channels' | translate}}</span>
</label>


<input type="radio" class="btn-check" name="options-outlined" id="danger-outlined" autocomplete="off"
  (change)="toggleBoolean('1')">
<label class="btn btn-multicanal" for="danger-outlined">
  <so-icon svg="mdi-account-multiple" [size]="0.8" />
  <span class="spn">{{'prospects' | translate}}</span>
</label>
