import { Component, inject } from '@angular/core';
import { TiposNegocio } from '@core/interfaces/catalogos.intefaces';
import { GlobalSessionService } from '@core/services/global-session.service';
import { StorageService } from '@core/services/storages.service';

@Component({
  selector: 'app-navbar-line-business',
  templateUrl: './navbar-line-business.component.html',
  styleUrls: ['./navbar-line-business.component.scss']
})
export class NavbarLineBusinessComponent {

  private readonly storageService = inject(StorageService);

  private readonly globalSession: GlobalSessionService = inject(GlobalSessionService);

  tiposDeNegocio: TiposNegocio[] = [];

  tiposDeNegocio_selected_Name = 'new';
  tiposDeNegocio_selected = 'TN00000001';
  tiposDeNegocio_selected_Icon = 'mdi-directions-car';
  idUsuario = '';

  constructor() {
    const storageData = this.storageService.getLocalStorage('userInfo').data;
    this.tiposDeNegocio = storageData.tiposDeNegocio;
    this.idUsuario = storageData.idUsuario;

    if (localStorage.getItem('businessType_' + this.idUsuario) !== null) {
      const tipoUsuarioSelectedd = localStorage.getItem('businessType_' + this.idUsuario);
      if (typeof tipoUsuarioSelectedd === 'string') {
        this.get_idTipoNegocio_Selected(tipoUsuarioSelectedd);
        this.cambiarSeleccion(tipoUsuarioSelectedd);
      } else {
        console.log('Error');
      }
    }
  }


  get_idTipoNegocio_Selected(idTipoNegocio_Selected: string | null) {
    switch (idTipoNegocio_Selected) {
      case 'TN00000002': // Semiuevos
        this.tiposDeNegocio_selected_Name = 'pre_owned';
        this.tiposDeNegocio_selected_Icon = 'mdi-car-electric';
        break;
      case 'TN00000003': // Flotilla
        this.tiposDeNegocio_selected_Name = 'existing_fleet';
        this.tiposDeNegocio_selected_Icon = 'mdi-car-multiple';
        break;
      case 'TN00000004': // Seguros
        this.tiposDeNegocio_selected_Name = 'insurances';
        this.tiposDeNegocio_selected_Icon = 'car-wireless';
        break;
      default:
        this.tiposDeNegocio_selected_Name = 'new';
        this.tiposDeNegocio_selected_Icon = 'mdi-directions-car';
        break;
    }
  }

  cambiarSeleccion(opcion: string) {
    this.tiposDeNegocio_selected = opcion;
    switch (opcion) {
      case 'TN00000002': // Semiuevos
        this.tiposDeNegocio_selected_Name = 'pre_owned';
        this.tiposDeNegocio_selected_Icon = 'mdi-car-electric';
        break;
      case 'TN00000003': // Flotilla
        this.tiposDeNegocio_selected_Name = 'existing_fleet';
        this.tiposDeNegocio_selected_Icon = 'mdi-car-multiple';
        break;
      case 'TN00000004': // Seguros
        this.tiposDeNegocio_selected_Name = 'insurances';
        this.tiposDeNegocio_selected_Icon = 'car-wireless';
        break;
      default:
        this.tiposDeNegocio_selected_Name = 'new';
        this.tiposDeNegocio_selected_Icon = 'mdi-directions-car';
        break;
    }

    localStorage.setItem('businessType_' + this.idUsuario, opcion);
    this.globalSession.businessType.set(opcion);
    this.globalSession.setBusinessType(opcion);
  }

  getIcon(idTipoNegocio: string) {
    var icon = '';
    switch (idTipoNegocio) {
      case 'TN00000002': // Semiuevos
        icon = 'mdi-car-electric';
        break;
      case 'TN00000003': // Flotilla
        icon = 'mdi-car-multiple';
        break;
      case 'TN00000004': // Seguros
        icon = 'car-wireless';
        break;
      default:
        icon = 'mdi-directions-car';
        break;
    }
    return icon;
  }

  getName(idTipoNegocio: string) {
    var name = '';
    switch (idTipoNegocio) {
      case 'TN00000002': // Semiuevos
        name = 'pre_owned';
        break;
      case 'TN00000003': // Flotilla
        name = 'existing_fleet';
        break;
      case 'TN00000004': // Seguros
        name = 'car-wireless';
        break;
      default:
        name = 'new';
        break;
    }
    return name;
  }

  /*  multiNegocio = [
     {
       //title: 'Nuevos',
       id: 'TN00000001',
       title: 'new',
       icon: 'mdi-directions-car'
     },
     {
       //title: 'Semiuevos',
       id: 'TN00000002',
       title: 'pre_owned',
       icon: 'mdi-car-electric'
     },
     {
       // title: 'Flotilla',
       id: 'TN00000003',
       title: 'existing_fleet',
       icon: 'mdi-car-multiple'
     },
     {
       // title: 'Seguros',
       id: 'TN00000004',
       title: 'insurances',
       icon: 'car-wireless'
     },
     {
       //  title: 'Financieros',
       id: 'TN00000005',
       title: 'financial',
       icon: 'credit-card-refresh'
     },
     {
       //  title: 'Postventa',
       id: 'TN00000006',
       title: 'after_sale',
       icon: 'Trazado 4806'
     },
   ] */
}
