<div class="container--input">
    <label id="label-input" for="search" class="{{ errorMessage ? 'so-danger' : 'text-gray-700' }}">
        {{ text }}
        {{ errorMessage ? 'X' : '' }}
    </label>
    
    <div class="container--input__element" *ngIf="!formGroup">
        <input type="{{currentType}}"
            class="border"
            placeholder="{{ placeholder }}" required
            name="{{ name }}"
            (keypress)="emitValue($event)"
            (blur)="emitValue($event)"
        >
        <mat-icon 
            class="{{ currentIconColor }}  {{  icons.length > 1 ? 'cursor-pointer' : '' }}"
            *ngIf="currentIcon"
            (click)="changeNextIcon()"
        >{{ currentIcon }}</mat-icon>
    </div>

    <div class="container--input__element" *ngIf="formGroup" [formGroup]="formGroup">
        <input type="{{currentType}}"
            class="border"
            placeholder="{{ placeholder }}" required
            name="{{ name }}"
            formControlName="{{ name }}"
            (keypress)="emitValue($event)"
            (blur)="emitValue($event)"
        >
        <mat-icon 
            class="{{ currentIconColor }} {{  icons.length > 1 ? 'cursor-pointer' : '' }}"
            *ngIf="currentIcon"
            (click)="changeNextIcon()"
        >{{ currentIcon }}</mat-icon>
    </div>

    <div class="container--input__error so-danger">
        <label>{{ errorMessage }}</label>
    </div>
</div>