import { Pipe, PipeTransform, inject } from '@angular/core';
import { StorageService } from '@core/services/storages.service';
@Pipe({
  name: 'utcToLocal'
})
export class UtcToLocalPipe implements PipeTransform {

private storageService = inject(StorageService);
private timezone:string =  this.storageService.getLocalStorage("userInfo").config.husoHorario.nombre;

  transform(value: string, includeTime: boolean = true, timeZone: string =""): string {
    const date = new Date(value);
    timeZone = this.timezone;
    console.log("timezone",timeZone);

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour12: false,
      timeZone: timeZone
    };

    if (includeTime) {
      options.hour = '2-digit';
      options.minute = '2-digit';
      options.second = '2-digit';
    }

    const formatter = new Intl.DateTimeFormat('es-ES', options);
    let formattedDate = formatter.format(date);

    // Reemplazar las diagonales por guiones
    formattedDate = formattedDate.replace(/\//g, '-');

    return formattedDate;
  }
}
