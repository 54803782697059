import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-so-card-bg',
  templateUrl: './so-card-bg.component.html',
  styleUrls: ['./so-card-bg.component.scss']
})
export class SoCardBgComponent {
  @Input() text: string = '';
  @Input() title: string = '';
  @Input() titleStyle: string = '';
  @Input() brColor: string = '';
  @Input() bgColor: string = '--background-card';
  @Input() badgeText: string = '';
  @Input() description: string = '';
  @Input() descriptionStyle: string = '';
  @Input() date: string = '';
  @Input() icon: string = '';
  @Input() isMini: boolean = true;
  @Input() sizeCard: 'small' | 'medium' | 'large' | 'extraLarge' | 'notifications' | 'deleteOption' | 'deleteEditOptions' | 'auto'  = 'large';
  @Input() checkCircle: boolean = true;
  @Input() icons: any[] = [];
  @Input() lowOpacity: boolean = false;
  @Input() allowTextWrap: boolean = true;
  @Output() deleteEvent =  new EventEmitter<boolean>();
  @Output() editEvent = new EventEmitter<void>();

  deleteRegister(){
    this.deleteEvent.emit(true);
  }

  editRegister() {
    this.editEvent.emit();
  }
}
