import { Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, Output, Renderer2, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'so-select-multi-reactive',
  templateUrl: './so-select-multi-reactive.component.html',
  styleUrl: './so-select-multi-reactive.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SoSelectMultiReactiveComponent),
      multi: true
    }
  ]
})
export class SoSelectMultiReactiveComponent implements ControlValueAccessor{

  @Output() selectionChange = new EventEmitter<{key: any, value: any, isSelected?: boolean}[]>();
  @Output() unselectItemChange =  new EventEmitter<{key: any, value: any, isSelected?: boolean}>();
 
  @Input({required: true}) items: {key: any, value: any, isSelected?: boolean}[] = [];
  @Input() label: string = '';
  @Input() isLoading: boolean = false;
  @Input() textColor: string = '';
  @Input() requiredMark: boolean = false;
  @Input() defaultItems: {key: string | number, value: string | number, isSelected?: boolean}[] = [];

  selectedItems: {key: any, value: any, isSelected?: boolean}[] = [];
  isDropdownOpen = false;
  
  public dropdownStyles: any;

  onChange: any = (value: any) => {};
  onTouched: any = () => {};

  constructor(
    private elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      if (this.defaultItems && this.defaultItems.length > 0) {
        this.selectedItems = this.defaultItems;
        this.onChange(this.selectedItems);
        this.selectionChange.emit(this.selectedItems);
      }
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['defaultItems'] && changes['defaultItems'].currentValue) {
      this.selectedItems = [...this.defaultItems];
      this.selectionChange.emit(this.selectedItems);
    }
  }

  setDefaultItems(): void {
    if (this.defaultItems && this.defaultItems.length > 0) {
      this.selectedItems = this.defaultItems;
      this.onChange(this.selectedItems);
      this.selectionChange.emit(this.selectedItems);
    }
  }

  toggleSelection(key: string | number, item: any) {
    const index = this.selectedItems.findIndex(option => option.key === key);
    if (index > -1) {
      this.selectedItems.splice(index, 1);  // Elimina la opción si ya está seleccionada
    } else {
      const option = this.items.find(option => option.key === key);
      if (option) {
        this.selectedItems.push(option);  // Agrega la opción si no está seleccionada
      }
    }
    // this.isSelected(key);  
    this.onChange(this.selectedItems);  // Emite el array completo de opciones seleccionadas    
    this.selectionChange.emit(this.selectedItems);
   
  }

  isSelected(key: string | number): boolean {
    return this.selectedItems.some(option => option.key === key);
  }

  getOptionNameById(key: string | number): string | undefined {
    return this.items.find(option => option.key === key)?.value;
  }

  removeSelection(key: string | number, item: any, event: Event,) {
    event.stopPropagation();  // Evita que se cierre el dropdown al hacer clic en la "x"
    this.toggleSelection(key, item);
    this.unselectItemChange.emit(item);
  }

  writeValue(value: any[]): void {
    if (value) {
      this.selectedItems = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;

    if (this.isDropdownOpen) {
      const rect = this.elementRef.nativeElement.querySelector('.select-box').getBoundingClientRect();
      this.dropdownStyles = {
        position: 'fixed',
        top: `${rect.bottom + 3}px`,
        left: `${rect.left}px`,
        width: `${rect.width}px`,
        zIndex: '1000',
      };
    }
    
  }

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: HTMLElement) {
    // Verifica si targetElement es parte del documento actual
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);

    // Si el clic no está dentro del componente, cierra el dropdown
    if (!clickedInside) {
      this.isDropdownOpen = false;
    }
  }


}
