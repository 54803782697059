import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'so-toggle-button',
  templateUrl: './so-toggle-button.component.html',
  styleUrls: ['./so-toggle-button.component.scss']
})
export class SoToggleButtonComponent {
  @Output() clicked = new EventEmitter<any>();
  
  @Input() color: string = '';
  @Input() value: string = '';
  @Input() label: string = '';
  @Input() selected: boolean = false;


  active() {
    this.selected = true;
    this.clicked.emit();
  }
}