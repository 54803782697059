import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-so-mat-icon',
  templateUrl: './so-mat-icon.component.html',
  styleUrl: './so-mat-icon.component.scss',
})
export class SoMatIconComponent {
  @Input() icon: string = '';
  @Input() classColor: string = '';
  @Input() size: string = '';
}
