import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AniosSeminuevosService } from '@core/services/catalogos/autos-de-interes/seminuevos/aniosSeminuevos.service';
import { OrganismModalAddCarInventoryComponent } from '@shared/components/organism/organism-modal-add-car-inventory/organism-modal-add-car-inventory.component';
import { Request } from '@core/models/requests.model';
import { MarcasSeminuevosService } from '@core/services/catalogos/autos-de-interes/seminuevos/marcasSeminuevos.service';
import { AutosSeminuevosService } from '@core/services/catalogos/autos-de-interes/seminuevos/autosSminuevos.service';
import { VersionesSeminuevosService } from '@core/services/catalogos/autos-de-interes/seminuevos/versionesSeminuevos.service';
import { ColoresSeminuevosService } from '@core/services/catalogos/autos-de-interes/seminuevos/coloresSeminuevos.service.';

@Component({
  selector: 'so-car-interest-pre-owned',
  templateUrl: './car-interest-pre-owned.component.html',
  styleUrl: './car-interest-pre-owned.component.scss'
})
export class CarInterestPreOwnedComponent  implements OnInit{

  @Input() form!: FormGroup;
  @Input() yearsControl: string = '';
  @Input() carBrandControl: string = '';
  @Input() carInterestControl: string = '';
  @Input() carVersionsControl: string = '';
  @Input() colorsControl: string = '';
  @Input() directionItems: 'row' | 'column' = 'row';

  public loadingYears: boolean = false;
  public loadingCarBrand: boolean = false;
  public loadingCarInterest: boolean = false;
  public loadingCarVersion: boolean = false;  
  public loadingColors: boolean = false;  
  
  public listYears: any[] = [];
  public listCarsBrand: any[] = [];
  public listCarsInterest: any[] = [];
  public listCarVersions: any[] = [];
  public listColors: any[] = [];

  private selectedModelCar: string = '';
  private selectedYearCar: string = '';

  constructor(
    public dialog: MatDialog,
    private aniosSeminuevosService: AniosSeminuevosService,
    private request: Request,
    private marcasSeminuevosService: MarcasSeminuevosService,
    private autosSeminuevosService: AutosSeminuevosService,
    private versionesSeminuevosService: VersionesSeminuevosService,
    private coloresSeminuevosService: ColoresSeminuevosService
  ){}

  ngOnInit(): void {
    this.loadControlsForm();

    this.loadYearsPreOwned();
  }

  loadControlsForm(){
    if (!this.form.get(this.carBrandControl)) {
      this.form.addControl(this.carBrandControl, new FormControl());
    }
    if (!this.form.get(this.yearsControl)) {
      this.form.addControl(this.yearsControl, new FormControl());
    }
    if (!this.form.get(this.carVersionsControl)) {
      this.form.addControl(this.carVersionsControl, new FormControl());
    }
    if (!this.form.get(this.carInterestControl)){
      this.form.addControl(this.carInterestControl, new FormControl());
    }
    if (!this.form.get(this.colorsControl)) {
      this.form.addControl(this.colorsControl, new FormControl());
    }
  }
  
  loadYearsPreOwned(){

    this.loadingYears = true;

    const paramsYears = {
      configuracion: this.request.getConfigUser(),
    }

    this.aniosSeminuevosService.getAnios(paramsYears).subscribe({
      next: (res) => {
        const response =  res.response;
        this.listYears = this.aniosSeminuevosService.formatValues(response, 'anio', 'anio');
        this.loadingYears = false;
      },
      error: (err) => {
        console.log(err);
        this.loadingYears = false;
      }
    })
  }

  onChangeCarYears(value: any){
    this.listCarsBrand = [];
    this.listCarsInterest = [];
    this.listCarVersions = [];
    this.listColors = [];

    this.selectedYearCar = value;

    this.loadBrandPrewOwned(value)
  }

  loadBrandPrewOwned(value: any){
    this.loadingCarBrand = true;

    const paramsBrand = {
      configuracion: this.request.getConfigUser(),
      anio: value,
    }

    this.marcasSeminuevosService.getMarcas(paramsBrand).subscribe({
      next: (res) => {
        const response = res.response;
        this.listCarsBrand =  this.marcasSeminuevosService.formatValues(response, 'idmarca','alias');
        this.loadingCarBrand = false;
      },
      error: (err) => {
        console.log(err);
        this.loadingCarBrand = false;
      }
    })
  }

  onChangeCarBrand(value: any){
    this.listCarsInterest = [];
    this.listCarVersions = [];
    this.listColors = [];

    this.selectedModelCar = value;

    this.loadCarInterestPrewOwned(value)
  }

  loadCarInterestPrewOwned(value: any){

    this.loadingCarInterest = true;

    const paramsCarInterest = {
      configuracion: this.request.getConfigUser(),
      idMarca: value,
      anio: this.selectedYearCar,
    }

    this.autosSeminuevosService.getAutos(paramsCarInterest).subscribe({
      next: (res) => {
        const response = res.response;
        this.listCarsInterest = this.autosSeminuevosService.formatValues(response, 'idauto', 'nombre');
        this.loadingCarInterest = false;
      },
      error: (err) => {
        console.log(err);
        this.loadingCarInterest = false;
      }
    })
  }

  onChangeCarInterest(value: any){
    this.listCarVersions = [];
    this.listColors = [];

    this.loadVersionCarsPreOwned(value);

  }

  loadVersionCarsPreOwned(value: any){
    this.loadingCarVersion = true;

    const paramsVersionsCar = {
      configuracion: this.request.getConfigUser(),
      idMarca: this.selectedModelCar ,
      anio: this.selectedYearCar,
      idAuto: value,
    }

    this.versionesSeminuevosService.getVersiones(paramsVersionsCar).subscribe({
      next: (res) => {
        const response = res.response;
        this.listCarVersions = this.versionesSeminuevosService.formatValues(response, 'idproducto', 'nombre');
        this.loadingCarVersion = false;
      },
      error: (err) => {
        console.log(err);
        this.loadingCarVersion = false;
      }
    })
  }

  onChangeCarVersion(value: any){
    this.listColors = [];
    this.loadColorsPreOwned();
  }

  loadColorsPreOwned(){
    this.loadingColors = true;

    const paramsColorsCar = {
      configuracion: this.request.getConfigUser(),
    }

    this.coloresSeminuevosService.getColores(paramsColorsCar).subscribe({
      next: (res) => {
        const response = res.response;
        this.listColors = this.coloresSeminuevosService.formatValues(response, 'idColor', 'nombre', 'color');
        this.loadingColors = false;
      },
      error: (err) => {
        console.log(err);
        this.loadingColors = false;
      }
    })
  }

  openAddCarToInventory() {
    this.dialog.open(OrganismModalAddCarInventoryComponent, {
      data: {},
    });
  }

}
