import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-reject-prospect',
  templateUrl: './modal-reject-prospect.component.html',
  styleUrls: ['./modal-reject-prospect.component.scss']
})
export class ModalRejectProspectComponent {
options = ['Motivo 1','Motivo 2'];
}
