import { Component, ElementRef, ViewChild } from '@angular/core';
import { FileListInterface } from '@modules/lead-management/submodules/directory/interfaces/file-list.interface';

@Component({
  selector: 'app-modal-add-file',
  templateUrl: './modal-add-file.component.html',
  styleUrls: ['./modal-add-file.component.scss']
})
export class ModalAddFileComponent {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  fileList: FileListInterface[] = [];

  onUploadClick() {
    // Cuando se hace clic en el <section>, activamos el input de tipo file
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: any) {
    // Aquí puedes manejar la lógica de carga de la imagen
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      
      reader.onload = () => {

        const newFile: FileListInterface = {
          name: selectedFile.name,
          size: selectedFile.size,
          file: reader.result as string,
        }

        this.fileList.push(newFile);
      };
      reader.readAsDataURL(selectedFile);

      this.showSnackBar('Imagen subida correctamente');
    } else {
      this.showSnackBar('No se ha seleccionado ninguna imagen');
    }
  }

  private showSnackBar(message: string) {

  }
}
