import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-so-loading-animation',
  standalone: true,
  imports: [],
  templateUrl: './so-loading-animation.component.html',
  styleUrl: './so-loading-animation.component.scss'
})
export class SoLoadingAnimationComponent {
  @Input() size: string = '80px';
}
