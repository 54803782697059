<div class="w-100" style="min-width: 500px;">
  <div class="p-4 align-items-center">
    <ng-content select=".dinamic-header">

    </ng-content>
  </div>
  <table class="w-100 table__prospects-conversations">
    <thead>
      <tr class="fw-bold text-black celda-table" scope="col">
        <th class="fs-6 text-center" *ngFor="let header of ConvProsH">
          <div class="d-flex align-items-center flex-nowrap w-fit center-element text-uppercase titles-table" style="font-size: var(--so-text-4); width: fit-content;">
            <p style="margin: 0;">
              {{header|translate}}
            </p>
            <mat-icon>unfold_more</mat-icon>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr class="celda-table border-right mb-5" *ngFor="let rows of ConvProsV">
        <td style="width: 34%!important;">
          <div class="d-flex flex-columns justify-content-start align-items-center gap-4 flex-nowrap w-fit mr-3 pb-2 prospect-name-table">
            <img src="{{rows.image}}" alt="" style="width: 35px; height: 35px;">

            <app-so-text-state *ngIf="rows.isRegistred === 'true' else prospectRegister" text="{{rows.prospect}}"
              fontWeigth="bold" size="13" color="primary"></app-so-text-state>

            <ng-template #prospectRegister style="width: fit-content;">
              <app-so-text-state text="Prospecto sin Registrar - 656 75 65 76" color="danger" style="opacity: 0.5; width: fit-content;" fontWeigth="bold"
                size="14"></app-so-text-state>
            </ng-template>
          </div>
        </td>
        <td >
          <div class="center-element" style="padding: 0 60px;width: fit-content;">
            <mat-icon [ngClass]="'so-primary'" *ngIf="rows.isRegistred === 'true'; else myRegister"
              [ngStyle]="{'font-weight':'500','font-size':'35px','width':'35px','height':'35px'}">check_circle_outline</mat-icon>

            <ng-template #myRegister>
              <mat-icon [ngClass]="'so-secondary'"
                [ngStyle]="{'font-weight':'500','font-size':'35px','width':'35px','height':'35px'}">add_circle_outline</mat-icon>
            </ng-template>
          </div>
        </td>
        <td style="padding: 0 60px;width: fit-content;">
          <span *ngIf="rows.messages > 0; else elseMessages"
            class="d-block so-bg-secondary rounded-circle text-center text-white center-element"
            [ngStyle]="{'line-height':'24px'}" style="border-radius: 50%!important; width: 23px;height: 23px;">{{rows.messages}}</span>

          <ng-template #elseMessages style="padding: 0 60px;">
            <span
              class="d-block so-bg-primary rounded-circle text-center text-white center-element"
              [ngStyle]="{'line-height':'24px'}" style="border-radius: 50%!important;width: 23px;height: 23px;">{{rows.messages}}</span>

          </ng-template>

        </td>
        <td>
          <div class="w-100 ">
            <app-so-text-state class="w-100" text="1d 12h 23m 12s" size="14"></app-so-text-state>
          </div>
        </td>
        <td>
          <div class="w-100">
            <app-so-text-state class="w-100" text="1d 12h 23m 12s" size="14"></app-so-text-state>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
