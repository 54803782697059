import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { catalogs } from '../interfaces/filter-catalogs.interface';

const catalogsObject: catalogs = {
    tiposDeFechas: [{}],
    tiposDeOrden: [{}],
    tiposDeMostrar: [{}],
    tiposDePersona: [{}],
    motivosRechazos: [{}],
    tiposDeRechazos: [{}],
    estadosDeRechazo: [{}],
};

export const FilterCatalogs = signalStore(
    { providedIn: 'root' },
    withState(catalogsObject),
    withMethods(( { ...store }) => ({
        loadCatalogsData(catalogsData: catalogs) {
            patchState(store, catalogsData);
        }
    }))
);