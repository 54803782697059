<div class="p-1">
    @if( label !== ''){
        <label class="fw-medium p-1" [style.color]="textColor" >{{ label }}<app-so-required-mark [show]="requiredMark" /></label>
    }
    <div class="select-container" [ngClass]="{'dropdown-open': isDropdownOpen}">
        <div class="select-box" (click)="toggleDropdown()">
          <div class="selected-options">
            @for (item of selectedItems; track $index) {
                <span class="tag">
                    {{ getOptionNameById(item.key) }}
                    <span class="remove" (click)="removeSelection(item.key, item, $event)">×</span>
                </span>
            }
            <input 
              type="text" 
              [placeholder]="selectedItems.length ? '' : ('selected' | translated) " 
              readonly>
          </div>
          @if(isLoading){
            <div class="spinner"></div>
          }@else {
            <span class="arrow"></span>
          }
        </div>
        @if(isDropdownOpen){
            <div class="dropdown-options" [ngStyle]="dropdownStyles" >
            @for (item of items; track $index) {
                <div (click)="toggleSelection(item.key, item)"  [ngClass]="{'disabled-option': item.isSelected}">
                    <label>
                      <input 
                        type="checkbox"
                        [checked]="isSelected(item.key)" 
                        (click)="toggleSelection(item.key, item)"
                        >
                      {{ item.value }}
                    </label>
                </div>  
            }
            </div>
        }
    </div>
</div>
