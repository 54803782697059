import { Injectable } from '@angular/core';
import { Request } from '@core/models/requests.model';
import { Usuario } from '@core/interfaces/catalogos.intefaces';
import { AllUsers } from '@core/interfaces/catalogos.intefaces';
import { StorageService } from '@core/services/storages.service';

@Injectable({
  providedIn: 'root',
})
export class UsuariosService {
  private url = `global/usuario`;
  private urlU = `global/catalogos/usuario`;

  constructor(
    private request: Request,
    private StorageService: StorageService
  ) {}

  getAllUsuarios() {
    const options = { headers: this.request.headers };
    return this.request.Get<Usuario>(`${this.url}/all`, options);
  }
  searchUsuario(idUsuario: string) {
    const options = {
      headers: this.request.headers,
      body: { idUsuario: idUsuario },
    };
    return this.request.Post<Usuario>(`${this.url}/search`, options);
  }
  // addUsuario(usuario:Usuario) {
  //   const options = { headers: this.request.headers,body:{"idUsuario":idUsuario} };
  //   return this.request.Post<Usuario>('json',`${this.url}/search`, options);
  // }
  // updateUsuario(idUsuario:string) {
  //   const options = { headers: this.request.headers,body:{"idUsuario":idUsuario} };
  //   return this.request.Post<Usuario>('json',`${this.url}/search`, options);
  // }
  // editUsuario(idUsuario:string) {
  //   const options = { headers: this.request.headers,body:{"idUsuario":idUsuario} };
  //   return this.request.Post<Usuario>('json',`${this.url}/search`, options);
  // }

  getAllUsers() {
    const storageData = this.StorageService.getLocalStorage('userInfo').data;
    const body = {
      basesDeDatos: {
        dbEmpresa:
          this.StorageService.getLocalStorage('userInfo').config.basesDeDatos
            .dbEmpresa,
      },
      husoHorario: this.StorageService.getLocalStorage('userInfo').config.husoHorario,
      idioma: {
        idIdioma: storageData.empleado.empresa.idioma.idIdioma,
        idPais: storageData.empleado.empresa.pais.idPais,
      },
    };
    const options = { headers: this.request.headers };
    return this.request.Post<AllUsers>(`${this.urlU}/all`, body, options);
  }

  formatValues(
    data: any[],
    keyProp: string,
    valueProp: string
  ): { key: any; value: any }[] {
    return data.map((item) => ({ key: item[keyProp], value: item[valueProp] }));
  }
}
