import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@core/api/translate.service';
import { Observable } from 'rxjs';

@Pipe({
  name: 'translated',
})
export class TranslatedPipe implements PipeTransform {
  private readonly translateService = inject(TranslateService);
  public label = '';
  constructor() {
    const localStorageChanges$: Observable<StorageEvent> = new Observable(
      (observer) => {
        const handler = (event: StorageEvent) => observer.next(event);
        window.addEventListener('storage', handler);
        return () => window.removeEventListener('storage', handler);
      }
    );

    localStorageChanges$.subscribe((event) => {
      // console.log(event.target);
    });
  }

  transform(value: string, fallback: string = value): string {
    this.label = value;
    if (this.label === 'print') {
      console.log(this.label);
    }
    return this.translateService.instant(value, fallback);
  }
}
