import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { TiposDeVenta } from '@core/interfaces/catalogos.intefaces';
import { Request } from '@core/models/requests.model';
// import { environment as env } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TiposDeVentaService {

  public token = localStorage.getItem('Token');
  private url = `global/catalogos/tipodeventa/all`;

  constructor(
    private http: HttpClient,
    private request:Request
  ) { }

  getAllTiposDeVenta() {
    const options = { headers: this.request.headers };
    return this.request.Get<TiposDeVenta>( this.url,options);
  }

}
