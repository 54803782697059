<div class="d-flex align-items-center" [ngStyle]="{ 'style': divStyle }">
  <label [class]="!invertLabelsWeight ? (isChecked ? '' : 'fw-bold') : (!isChecked ? '' : 'fw-bold') " *ngIf="labels" [ngStyle]="labelLeftStyle">{{ labelLeft }}</label>
  <div class="form-check form-switch form-switch-lg" style="margin-left: 30px;">
    <input
      class="form-check-input"
      type="checkbox"
      role="switch"
      id="flexSwitch"
      [style]="'font-size: ' + size + 'px;'"
      [disabled]="disabled"
      [checked]="isChecked"
      [ngClass]="getSwitchClasses()"
    (change)="fnCheck($event);" />
  </div>
  <label [class]="!invertLabelsWeight ? (isChecked ? 'fw-bold' : '') : (!isChecked ? 'fw-bold' : '')" *ngIf="labels" [ngStyle]="labelRightStyle">{{ labelRight }}</label>
</div>
