import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@core/services/storages.service';
import { ValuationsHistoryService } from './valuations-history.service';
import { ValuationsHistory } from '@core/interfaces/catalogos.intefaces';
import { PromiseStatus } from '@shared/enums';

@Component({
  selector: 'app-so-valuations-component',
  templateUrl: './so-valuations-component.component.html',
  styleUrl: './so-valuations-component.component.scss',
})
export class SoValuationsComponentComponent implements AfterViewInit {
  PromiseStatus = PromiseStatus;
  requestStatus = PromiseStatus.Pending;
  errorMessage: string = '';

  constructor(
    private storageService: StorageService,
    private translateService: TranslateService,
    private valuationsHistoryService: ValuationsHistoryService
  ) {}

  @Input() responseData: any = {
    response: [],
  };

  ngAfterViewInit(): void {
    const currentProspect = this.storageService.getLocalStorage(
      'currentProspectDirectory'
    );
    this.valuationsHistoryService
    .getValuatiosHistory(currentProspect.idProspecto)
    .subscribe({
      next: (data: ValuationsHistory) => {
        this.responseData = data;
        this.requestStatus = PromiseStatus.Fulfilled;
      },
      error: ({ error }) => {
        this.errorMessage = error.message;
        this.requestStatus = PromiseStatus.Rejected;
      },
    });
  }

  translate(text: string) {
    return this.translateService.instant(text);
  }
}
