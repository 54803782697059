import {
  ChangeDetectorRef,
  Component,
  OnInit,
  Input,
  QueryList,
  ViewChildren,
  inject,
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MarcasService } from '@core/services/catalogos/autos-de-interes/nuevos/marcas.service';
import { GlobalSessionService } from '@core/services/global-session.service';

import { StorageService } from '@core/services/storages.service';
import { TiposDeVentaService } from '@core/services/tiposDeVenta/tipos-de-venta.service';

import { Request } from '@core/models/requests.model';
import { FormStepsService } from '@shared/services/observables/form-steps.service';
import { AutosService } from '@core/services/catalogos/autos-de-interes/nuevos/autos.service';
import { AniosService } from '@core/services/catalogos/autos-de-interes/nuevos/anios.service';
import { VersionesService } from '@core/services/catalogos/autos-de-interes/nuevos/versiones.service';
import { ColoresService } from '@core/services/catalogos/autos-de-interes/nuevos/colores.service.';
import { CampaignService } from '@core/services/catalogos/campaign.service';
import { FuentesService } from '@core/services/Fuentes/fuentes.service';
import { MarcasFlotillasService } from '@core/services/catalogos/autos-de-interes/flotillas/marcas-flotillas.service';
import { AutosFlotillasService } from '@core/services/catalogos/autos-de-interes/flotillas/autos-flotillas.service';
import { AniosFlotillasService } from '@core/services/catalogos/autos-de-interes/flotillas/anios-flotillas.service';
import { VersionesFlotillasService } from '@core/services/catalogos/autos-de-interes/flotillas/versiones-flotillas.service';
import { ColoresFlotillasService } from '@core/services/catalogos/autos-de-interes/flotillas/colores-flotillas.service';
import { AniosSeminuevosService } from '@core/services/catalogos/autos-de-interes/seminuevos/aniosSeminuevos.service';
import { MarcasSeminuevosService } from '@core/services/catalogos/autos-de-interes/seminuevos/marcasSeminuevos.service';
import { AutosSeminuevosService } from '@core/services/catalogos/autos-de-interes/seminuevos/autosSminuevos.service';
import { VersionesSeminuevosService } from '@core/services/catalogos/autos-de-interes/seminuevos/versionesSeminuevos.service';
import { ColoresSeminuevosService } from '@core/services/catalogos/autos-de-interes/seminuevos/coloresSeminuevos.service.';
import { SimpleLead } from '@modules/lead-management/submodules/home/interfaces/simple-lead-interface';
import { ClosureTypesService } from '@core/services/closureType/closure-types.service';
import { FirstContactTypeService } from '../../captura-prospecto/firstContactType/first-contact-type.service';
import { SoInputComponent } from '@shared/components/atomics/so-input-form/so-input-form.component';

@Component({
  selector: 'simple-capture',
  templateUrl: './simple-capture.component.html',
  styleUrl: './simple-capture.component.scss',
})
export class SimpleCaptureComponent implements OnInit {
  @ViewChildren(SoInputComponent) soInputForms?: QueryList<SoInputComponent>;
  @Input() isAdmin: boolean = false;
  formSimple!: FormGroup;
  isStep2: boolean = false;
  isDetailSteps: boolean = false;
  firstContactType?: any;
  fonts?: any;
  closuretypes?: any;

  public storageData?: any = [];
  public tiposDeNegocio?: any = [];
  public showEmail: boolean = false;
  public importantLead: boolean = false;
  public divFlotillaSimple: boolean = false;
  public loadClosure?: any = true;

  public typeOfSale?: any;

  private readonly request = inject(Request);

  public loadTypeFirstContact?: any = true;

  userInfo = this.globalSessionService.userInfo().data;

  private capture: SimpleLead = {
    idEjecutivo: '',
    observaciones: '',
    prospecto: {
      nombre: '',
      segundoNombre: '',
      materno: '',
      paterno: '',
      duplicado: false,
      cp: 0,
      importante: false,
      correos: [],
      telefonos: [],
    },
    segmentacion: {
      idTipoDePrimerContacto: '0000000000',
      idFuente: '0000000000',
      idCampania: '0000000000',
      idTipoDeCierre: '0000000000',
    },
    productoDeInteres:{},
    configuracion: {
      basesDeDatos: {
        dbEmpresa: '',
      },
      idioma: {
        idIdioma: 'ES',
        idPais: 'MX',
      },
    },
  };

  emailRows: number[] = [1];
  emails: any = [
    { value: 'Personal', personal: 'Personal' },
    { value: 'Trabajo', trabajo: 'Trabajo' },
    { value: 'Oficina', trabajo: 'Oficina' },
  ];

  phoneRows: number[] = [1];
  telefonos: any = [
    { value: 'Celular', celular: 'Celular' },
    { value: 'Oficina', oficina: 'Oficina' },
    { value: 'Casa', casa: 'Casa' },
  ];

  flotillaSimpleRows: number[] = [1];
  optiones: string[] = ['Valor 1', 'Valor2'];

  //Autos SemiNuevos
  //TODO: Mover a otro componenten
  public autos: any;
  public anios: any;
  public versiones: any;
  public colores: any;
  public campaigns: any;
  public marcas: any;
  public idAuto?: string = '';
  public auto?: string = '';
  public modelo?: string = '';
  public idUsuario?: string = '';
  public idProducto?: string = '';
  public producto?: string = '';
  public idColor?: string = '';
  public idColorSemi?: string = '';
  public idModeloSemi?: string = '';
  public idMarcaSemi?: string = '';
  public idAutoSemi?: string = '';
  public idProductoSemi?: string = '';
  public modeloSemi?: string = '';
  public marcaSemi?: string = '';
  public productoSemi?: string = '';
  public autoSemi?: string = '';
  public color?: string = '';

  public aniosSemi?: any = [];
  public autosSemi?: any = [];
  public marcasSemi?: any = [];
  public productosSemi?: any = [];
  public coloresSemi?: any = [];
  public colorSemi?: any = '';

  //Autos Flotillas
  public marcasFlotillasData?: any = [];
  public autosFlotillasData?: any = [];
  public aniosFlotillasData?: any = [];
  public versionesFlotillasData?: any = [];
  public coloresFlotillasData?: any = [];
  public marcaFlotilla?: string = '';
  public idMarcaFlotilla?: string = '';
  public autoFlotilla?: string = '';
  public idAutoFlotilla?: string = '';
  public anioFlotilla?: string = '';
  public idAnioFlotilla?: string = '';
  public versionFlotilla?: string = '';
  public idVersionFlotilla?: string = '';
  public colorFlotilla?: string = '';
  public idColorFlotilla?: string = '';
  public cantidadFlotilla?: string = '';
  public idCantidadFlotilla?: string = '';

  //Autos Seminuevos
  private readonly marcasFlotillas = inject(MarcasFlotillasService);
  private readonly autosFlotillas = inject(AutosFlotillasService);
  private readonly aniosFlotillasService = inject(AniosFlotillasService);
  private readonly versionesFlotillasService = inject(
    VersionesFlotillasService
  );
  private readonly coloresFlotillasService = inject(ColoresFlotillasService);

  //Flotillas
  private readonly marcasSeminuevos = inject(MarcasSeminuevosService);
  private readonly aniosSeminuevos = inject(AniosSeminuevosService);
  private readonly autosSeminuevos = inject(AutosSeminuevosService);
  private readonly versionesSeminuevos = inject(VersionesSeminuevosService);
  private readonly coloresSeminuevos = inject(ColoresSeminuevosService);

  constructor(
    private formBuilder: FormBuilder,
    private StorageService: StorageService,
    private tiposDeVentaService: TiposDeVentaService,
    private marcasService: MarcasService,
    private globalSessionService: GlobalSessionService,
    private formStepsServices: FormStepsService,
    private ClosureTypesService: ClosureTypesService,
    private FirstContactTypeService: FirstContactTypeService,
    //Autos
    private AutosService: AutosService,
    private AniosService: AniosService,
    private VersionesService: VersionesService,
    private ColoresService: ColoresService,
    private campaignService: CampaignService,
    private FuentesService: FuentesService,

    private cdRef: ChangeDetectorRef
  ) {
    this.getUserInfo();
  }

  ngOnInit() {
    this.loadFormSimple();

    this.loadTypeFirtsContact();
    this.loadClosureTypes();

    this.loadCatalogs();
  }

  validForm() {
    this.formStepsServices.validForm(this.formSimple);
  }

  loadFormSimple() {
    this.formSimple = this.formBuilder.group({
      nombre: ['', Validators.required],
      segundoNombre: [''],
      paterno: ['', Validators.required],
      materno: [''],
      company: [''],
      observations: [''],
      cp: [''],
      email: [''],
      email_2: [''],
      officeEmail: [''],
      officeEmail_2: [''],
      tel0: [''],
      emails: this.formBuilder.array([
        this.formBuilder.control('', [Validators.required, this.emailDomainValidator()])
      ]),
      phones: this.formBuilder.array([
        this.formBuilder.control('', [Validators.minLength(10)] )
      ])
    });

    this.formStepsServices.setForm(this.formSimple);
  }

  get emailArray() {
    return this.formSimple.get('emails') as FormArray;
  }

  get phoneArray(){
    return this.formSimple.get('phones') as FormArray;
  }

  addEmail() {
    this.emailArray.push(this.formBuilder.control('', [Validators.required, this.emailDomainValidator()]));
  }

  removeEmail(index: number) {
    this.emailArray.removeAt(index);
  }

  emailDomainValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const email = control.value;
      if (email) {
        const domain = email.substring(email.lastIndexOf('@') + 1);
        if (/^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(domain)) {
          return null; // Válido
        } else {
          return { invalidDomain: true }; // Inválido
        }
      }
      return null; // Cuando el campo está vacío
    };
  }

  addPhone(){
    this.phoneArray.push(this.formBuilder.control(''));
  }

  removePhone(index: number){
    this.phoneArray.removeAt(index);
  }

  getUserInfo() {
    this.storageData = this.StorageService.getLocalStorage('userInfo').data;
    this.tiposDeNegocio = this.storageData.tiposDeNegocio;

    if (this.storageData.perfiles[0].titulo == 'Administrador') {
      this.isAdmin = true;
    }
  }

  loadCatalogs() {
    this.loadTypesOfSale();
    this.activeOptionalFields();
  }

  loadTypeFirtsContact() {
    this.FirstContactTypeService?.getAllFirstContactTypes({
      configuracion: this.request.getConfigUser(),
    }).subscribe((rta: any) => {
      if (typeof rta.response === 'object') {
        this.loadTypeFirstContact = false;
        this.firstContactType = this.FirstContactTypeService.formatValues(
          rta.response,
          'idtipoprimercontacto',
          'nombre'
        );
        this.cdRef.detectChanges();
      }
    });
  }

  loadClosureTypes() {
    this.ClosureTypesService?.getAllClosureTypes({
      configuracion: this.request.getConfigUser(),
    }).subscribe((rta: any) => {
      if (typeof rta.response === 'object') {
        this.loadClosure = false;
        this.closuretypes = this.ClosureTypesService.formatValues(
          rta.response,
          'idtipodecierre',
          'nombre'
        );
        this.cdRef.detectChanges();
      }
    });
  }

  loadTypesOfSale() {
    this.tiposDeVentaService?.getAllTiposDeVenta().subscribe((rta: any) => {
      if (typeof rta.response === 'object') {
        const valores = rta.response['Tipos De Venta'] as any[];
        this.typeOfSale = valores;
      }
    });
  }

  onChange(e: Event) {
    console.warn(e);
  }

  loadMultiMarca() {
    this.marcasService
      .getMarcas({
        configuracion: this.request.getConfigUser(),
        idEmpresa: this.userInfo.empleado.empresa.idEmpresa,
        idTipoDeNegocio: this.userInfo.tiposDeNegocio[0].idTipoDeNegocio,
      })
      .subscribe((data) => {
        this.marcas = this.marcasService.formatValues(
          data.response,
          'idMarca',
          'alias'
        );
      });
  }

  getIsDetail() {
    this.formStepsServices.isStep2$.subscribe((res) => {
      this.isDetailSteps = res;
    });
  }

  // Email
  /**
   * TODO: Mover emails a un componente cellular
   */

  // addEmail() {
  //   const newIndex = this.emailRows.length + 1;
  //   if (this.emailRows.length < 5) {
  //     this.emailRows.push(newIndex);
  //   }
  //   this.formSimple.addControl(
  //     `email__${newIndex}`,
  //     this.formBuilder.control('')
  //   );
  //   this.formSimple.addControl(
  //     `email__${newIndex}_2`,
  //     this.formBuilder.control('')
  //   );
  //   this.getMultipleEmail();
  // }

  // removeEmail(index: number) {
  //   this.emailRows.splice(index, 1);
  // }

  getMultipleEmail(): any[] {
    let correos: any[] = [];
    let count = 0;
    const emailArray = this.formSimple.get('emails') as FormArray;
    emailArray.controls.forEach((control, i) => {
      const correo = control.value;
      let tipo = document.querySelector(
        'select#type_email__' + count
      ) as HTMLSelectElement;

      if (tipo) {
        if (!correos[i]) {
          correos[i] = { email: '', tipo: '', principal: false };
        }
        correos[i].email = correo;
        correos[i].tipo =
          tipo.options[tipo.selectedIndex].innerText.toString();
      }

      correos[i].principal = document.querySelector(
        'app-so-select #type_email__' +
          count +
          " app-icon-button[data-vip = 'true']"
      )
        ? true
        : false;
      count++;            
    });

    correos = correos.filter((correo) => {
      return correo !== null && correo !== undefined;
    });
    return correos;
  }

  showEmailFn(event: any) {
    this.showEmail = event.target.checked;
    this.assignValidControl('email');
    this.assignValidControl('email_2');
  }

  assignValidControl(control: string) {
    this.formSimple.get(control)?.setErrors(null);
  }

  isVipMail(Event: any) {
    console.log(Event.EventTarget.target);
  }

  activateImportantLead() {
    this.importantLead = !this.importantLead;
    console.log(this.importantLead);
  }

  // Telefonos
  /**
   * TODO: Mover Telefonos a un componente cellular
   */

  getMultiplePhones(): any {
    let phones: any[] = [];
    let count = 0;

    const phoneArray = this.formSimple.get('phones') as FormArray;
    phoneArray.controls.forEach((control, i) => {
      const tel = control.value;
      if (tel) {
        let tipo = document.querySelector(
          'select#type_tel__' + count
        ) as HTMLSelectElement;

        if (tipo) {
          if (!phones[i]) {
            phones[i] = { telefono: '', tipo: '', principal: false };
          }
          phones[i].telefono = tel;
          phones[i].tipo = tipo.selectedIndex;
        }

        phones[i].principal = document.querySelector(
          'app-so-select #type_tel__' +
            count +
            " app-icon-button[data-vip = 'true']"
        )
          ? true
          : false;

        count++;
      }
    })
    
    phones = phones.filter((phone) => {
      return phone !== null && phone !== undefined;
    });
    return phones;
  }

  isVipTel(Event: any) {
    let selectedIndex = Event.EventTarget.target
      .closest('app-so-select')
      .querySelector('select').selectedIndex;
    let id = Event.EventTarget.target
      .closest('app-so-select')
      .querySelector('select').id;

    console.log(selectedIndex);
    console.log(id);
  }

  //Flotilla

  showdivFlotillaSimple() {
    this.divFlotillaSimple = !this.divFlotillaSimple;
  }

  //Flotilla Simple
  addFlotillaSimple() {
    this.flotillaSimpleRows.push(this.flotillaSimpleRows.length + 1);
  }

  removeFlotillaSimple(index: number) {
    this.flotillaSimpleRows.splice(index, 1);
  }

  //TODO: Separar
  onSelect(data: any) {
    this.buildDataCapture(data);
    switch (data.event.target.id) {
      case 'idMarca':
        this.AutosService.getAllAutos({
          configuracion: this.request.getConfigUser(),
          idMarca: data.value,
          idTipoDeNegocio: this.userInfo.tiposDeNegocio[0].idTipoDeNegocio,
        }).subscribe((data) => {
          this.autos = this.AutosService.formatValues(
            data.response,
            'idauto',
            'nombre'
          );
        });
        this.autos = [];
        this.versiones = [];
        this.anios = [];
        this.colores = [];
        break;
      case 'idAuto':
        this.AniosService.getAnios({
          configuracion: this.request.getConfigUser(),
          idAuto: data.value,
          idMarca: '153',
          idTipoDeNegocio: 'TN00000001',
        }).subscribe((rta) => {
          this.anios = this.AniosService.formatValues(
            rta.response,
            'anio',
            'anio'
          );
        });
        this.versiones = [];
        this.anios = [];
        this.colores = [];
        break;
      case 'modelo':
        this.VersionesService.getVersiones({
          configuracion: this.request.getConfigUser(),
          idAuto: this.idAuto,
          anio: this.modelo,
          idMarca: '153',
          idTipoDeNegocio: 'TN00000001',
        }).subscribe((rta) => {
          this.versiones = this.VersionesService.formatValues(
            rta.response,
            'idProducto',
            'nombre'
          );
        });
        break;
      case 'idProducto':
        this.ColoresService.getAllColores({
          configuracion: this.request.getConfigUser(),
        }).subscribe((rta) => {
          this.colores = this.ColoresService.formatValues(
            rta.response,
            'idcolor',
            'nombre', 
            'color'
          );
        });
        break;
      case 'type_of_first_contact':
        this.fonts = [];
        this.FuentesService?.getFuentes({
          configuracion: this.request.getConfigUser(),
          idPrimerContacto: data.value,
        }).subscribe((data: any) => {
          this.fonts = this.FuentesService.formatValues(
            data.response,
            'idfuente',
            'nombre'
          );
        });
        break;
      case 'fuentes_simple_lead':
        this.campaignService
          .getCampaign({
            configuracion: this.request.getConfigUser(),
            idFuente: data.value,
          })
          .subscribe((data) => {
            this.campaigns = this.campaignService.formatValues(
              data.response,
              'idCampana',
              'nombre'
            );
          });
        break;
      // Seminuevos
      case 'idModeloSemi':
        this.marcasSeminuevos
          .getMarcas({
            configuracion: this.request.getConfigUser(),
            anio: data.value,
          })
          .subscribe((data) => {
            this.marcasSemi = this.marcasSeminuevos.formatValues(
              data.response,
              'idmarca',
              'alias'
            );
          });
        this.marcasSemi = [];
        this.autosSemi = [];
        this.productosSemi = [];
        this.coloresSemi = [];
        this.cdRef.detectChanges();
        break;
      case 'idMarcaSemi':
        this.autosSeminuevos
          .getAutos({
            configuracion: this.request.getConfigUser(),
            idMarca: data.value,
            anio: this.modeloSemi,
          })
          .subscribe((data) => {
            this.autosSemi = this.autosSeminuevos.formatValues(
              data.response,
              'idauto',
              'nombre'
            );
          });
        this.autosSemi = [];
        this.productosSemi = [];
        this.coloresSemi = [];
        this.cdRef.detectChanges();
        break;
      case 'idAutoSemi':
        this.versionesSeminuevos
          .getVersiones({
            configuracion: this.request.getConfigUser(),
            idMarca: this.idMarcaSemi,
            anio: this.modeloSemi,
            idAuto: this.idAutoSemi,
          })
          .subscribe((data) => {
            this.productosSemi = this.versionesSeminuevos.formatValues(
              data.response,
              'idproducto',
              'nombre'
            );
          });
        this.productosSemi = [];
        this.coloresSemi = [];
        this.cdRef.detectChanges();
        break;
      case 'idProductoSemi':
        this.coloresSeminuevos
          .getColores({
            configuracion: this.request.getConfigUser(),
          })
          .subscribe((data) => {
            this.coloresSemi = this.coloresSeminuevos.formatValues(
              data.response,
              'idcolor',
              'nombre',
              'color'
            );
          });
        this.coloresSemi = [];
        this.cdRef.detectChanges();
        break;
      // Flotillas
      case 'marcaFlotilla':
        this.autosFlotillas
          .getAutos({
            configuracion: this.request.getConfigUser(),
            idMarca: data.value,
          })
          .subscribe((data) => {
            this.autosFlotillasData = this.marcasSeminuevos.formatValues(
              data.response,
              'idauto',
              'nombre'
            );
          });
        this.aniosFlotillasData = [];
        this.versionesFlotillasData = [];
        this.coloresFlotillasData = [];
        this.cdRef.detectChanges();
        break;
      case 'idAutoFlotilla':
        this.aniosFlotillasService
          .getAniosFlotillas({
            configuracion: this.request.getConfigUser(),
            idAuto: this.idAutoFlotilla,
            idMarca: this.idMarcaFlotilla,
          })
          .subscribe((data) => {
            this.aniosFlotillasData = this.marcasSeminuevos.formatValues(
              data.response,
              'anio',
              'anio'
            );
          });
        this.versionesFlotillasData = [];
        this.coloresFlotillasData = [];
        this.cdRef.detectChanges();
        break;
      case 'carYearFlotilla':
        this.versionesFlotillasService
          .getVersionesFlotillas({
            configuracion: this.request.getConfigUser(),
            idAuto: this.idAutoFlotilla,
            anio: this.idAnioFlotilla,
            idMarca: this.idMarcaFlotilla,
          })
          .subscribe((data) => {
            this.versionesFlotillasData = this.marcasSeminuevos.formatValues(
              data.response,
              'idProducto',
              'nombre'
            );
          });
        this.coloresFlotillasData = [];
        this.cdRef.detectChanges();
        break;
      case 'versionFlotilla':
        this.coloresFlotillasService
          .getAllColoresFlotillas({
            configuracion: this.request.getConfigUser(),
          })
          .subscribe((data) => {
            this.coloresFlotillasData = this.coloresSeminuevos.formatValues(
              data.response,
              'idcolor',
              'nombre',
              'color'
            );
          });
        this.cdRef.detectChanges();
        break;
    }
  }

  buildDataCapture(data: any) {
    switch (data.event.target.id) {
      case 'idAuto':
        const idAuto = data.value;
        this.auto = this.getSelectedName(data.event);
        this.idAuto = idAuto;

        // if (this.capture.productoDeInteres?.nuevos) {
        //   this.capture.productoDeInteres.nuevos[0].productos[0].idAuto = idAuto;
        // }
        break;
      case 'modelo':
        const modelo = data.value;
        this.modelo = modelo;

        // if (this.capture.productoDeInteres?.nuevos) {
        //   this.capture.productoDeInteres.nuevos[0].productos[0].modelo = modelo;
        // }
        break;
      case 'idProducto':
        const producto = data.value;
        this.producto = this.getSelectedName(data.event);
        this.idProducto = producto;

        // if (this.capture.productoDeInteres?.nuevos) {
        //   this.capture.productoDeInteres.nuevos[0].productos[0].idProducto =
        //     producto;
        // }
        break;
      case 'idColor':
        const color = data.value;
        this.color = this.getSelectedName(data.event);
        this.idColor = color;

        // if (this.capture.productoDeInteres?.nuevos) {
        //   this.capture.productoDeInteres.nuevos[0].productos[0].idColor = color;
        // }
        break;

      // Seminuevos
      case 'idModeloSemi':
        const modeloSemi = data.value;
        this.modeloSemi = this.getSelectedName(data.event);
        this.idModeloSemi = modeloSemi;
        // if (this.capture.productoDeInteres?.seminuevos) {
        //   this.capture.productoDeInteres.seminuevos.productos[0].modelo =
        //     modeloSemi;
        // }
        break;
      case 'idMarcaSemi':
        const marcaSemi = data.value;
        this.marcaSemi = this.getSelectedName(data.event);
        this.idMarcaSemi = marcaSemi;
        if (this.capture.productoDeInteres?.seminuevos) {
          this.capture.productoDeInteres.seminuevos.productos[0].idMarca =
            marcaSemi;
        }
        break;
      case 'idAutoSemi':
        const autoSemi = data.value;
        this.autoSemi = this.getSelectedName(data.event);
        this.idAutoSemi = autoSemi;
        if (this.capture.productoDeInteres?.seminuevos) {
          this.capture.productoDeInteres.seminuevos.productos[0].idAuto =
            autoSemi;
        }
        break;
      case 'idProductoSemi':
        const productoSemi = data.value;
        this.productoSemi = this.getSelectedName(data.event);
        this.idProductoSemi = productoSemi;
        if (this.capture.productoDeInteres?.seminuevos) {
          this.capture.productoDeInteres.seminuevos.productos[0].idProducto =
            productoSemi;
        }
        break;
      case 'idColorSemi':
        const colorSemi = data.value;
        this.colorSemi = this.getSelectedName(data.event);
        this.idColorSemi = colorSemi;
        if (this.capture.productoDeInteres?.seminuevos) {
          this.capture.productoDeInteres.seminuevos.productos[0].idColor =
            colorSemi;
        }
        break;
      // Flotillas
      case 'idMarcaFlotilla':
        const marcaFlotilla = data.value;
        this.marcaFlotilla = this.getSelectedName(data.event);
        this.idMarcaFlotilla = marcaFlotilla;
        if (this.capture.productoDeInteres?.flotilla) {
          this.capture.productoDeInteres.flotilla.productos[0].idMarca =
            marcaFlotilla;
        }
        break;
      case 'idAutoFlotilla':
        const autoFlotilla = data.value;
        this.autoFlotilla = this.getSelectedName(data.event);
        this.idAutoFlotilla = autoFlotilla;

        if (this.capture.productoDeInteres?.flotilla) {
          this.capture.productoDeInteres.flotilla.productos[0].idAuto =
            autoFlotilla;
        }
        break;
      case 'modeloFlotilla':
        const anioFlotilla = data.value;
        this.anioFlotilla = this.getSelectedName(data.event);
        this.idAnioFlotilla = anioFlotilla;

        // if (this.capture.productoDeInteres?.flotilla) {
        //   this.capture.productoDeInteres.flotilla.productos[0].modelo =
        //     anioFlotilla;
        // }
        break;
      case 'idProductoFlotilla':
        const versionFlotilla = data.value;
        this.versionFlotilla = this.getSelectedName(data.event);
        this.idVersionFlotilla = versionFlotilla;

        if (this.capture.productoDeInteres?.flotilla) {
          this.capture.productoDeInteres.flotilla.productos[0].version =
            versionFlotilla;
        }
        break;
      case 'idColorFlotilla':
        const colorFlotilla = data.value;
        this.colorFlotilla = this.getSelectedName(data.event);
        this.idColorFlotilla = colorFlotilla;

        if (this.capture.productoDeInteres?.flotilla) {
          this.capture.productoDeInteres.flotilla.productos[0].idColor =
            colorFlotilla;
        }
        break;
      case 'cantidadFlotilla':
        const cantidadFlotilla = data.value;
        this.cantidadFlotilla = this.getSelectedName(data.event);
        this.idCantidadFlotilla = cantidadFlotilla;

        if (this.capture.productoDeInteres?.flotilla) {
          this.capture.productoDeInteres.flotilla.productos[0].idColor =
            cantidadFlotilla;
        }
        break;

      case 'fuentes_simple_lead':
        this.formStepsServices.setIdFuente(data.value);
        if (this.capture.segmentacion) {
          this.capture.segmentacion.idFuente = data.value;
        }
        break;
      case 'subcampaign_simple_lead':
        this.formStepsServices.setIdCampana(data.value);
        if (this.capture.segmentacion) {
          this.capture.segmentacion.idCampania = data.value;
        }
        break;
      case 'type_of_first_contact':
        this.formStepsServices.setIdTipoDePrimerContacto(data.value);

        if (this.capture.segmentacion) {
          this.capture.segmentacion.idTipoDePrimerContacto = data.value;
        }
        break;
      case 'closure_type':
        this.formStepsServices.setIdTipoDeCierre(data.value);
        if (this.capture.segmentacion) {
          this.capture.segmentacion.idTipoDeCierre = data.value;
        }
        break;
    }
  }

  getSelectedName(event: any): string {
    let select = event.target.options[event.target.selectedIndex];
    if (select) {
      return select.innerText.trim();
    } else {
      return '';
    }
  }

  activeOptionalFields() {
    document
      .querySelectorAll('#simple-lead app-so-input-form input')
      .forEach((e) => {
        var element = e as HTMLInputElement;
        var requiredValue = element.required;
        if (!requiredValue) {
          e.closest('div.input__form')?.classList.add('valid');
        }
      });
    document
      .querySelectorAll('#simple-lead app-so-text-area textarea')
      .forEach((e) => {
        var element = e as HTMLInputElement;
        var requiredValue = element.required;
        if (!requiredValue) {
          e.closest('div.input__form')?.classList.add('valid');
        }
      });
  }

  formSubmitted = false;
  printForm() {

    console.warn(this.getMultiplePhones());
    
    this.formSubmitted = true;
    if (this.formSimple.invalid) {
      // Marcar los campos como tocados para mostrar los mensajes de error
      Object.values(this.formSimple.controls).forEach(control => {
        control.markAsTouched();
      });
      const emailArray = this.formSimple.get('emails') as FormArray;
      emailArray.controls.forEach(control => {
        control.markAsTouched();
      });
      console.warn(this.formSimple)
    } else {
      // Lógica para enviar el formulario si es válido
      console.log(this.formSimple.value)
    }
  }

}
