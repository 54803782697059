<div class="w-100 h-100 p-4">
    <div>
        <p class="so-text-1-bold so-primary">{{ 'image_cropper' | translated: 'Recorta la imagen' }}</p>
    </div>
    <div class="img-container">
        <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [aspectRatio]="type == 'background' ? 9 / 16 : 1 / 1"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded($event)"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"
            [allowMoveImage]="false"
            [roundCropper]="false"
            [transform]="{ scale: zoom }"
        ></image-cropper>
    </div>
    <div class="w-100 d-flex justify-content-between">
        <div class="d-flex gap-2 align-items-center">
            <app-so-svg-icon src="assets/img/mdi-magnify-minus.svg" (click)="removeZoom()" style="cursor: pointer;"></app-so-svg-icon>
            <mat-slider min="0.1" max="2" step="0.1" value="1">
                <input matSliderThumb value="1" [(ngModel)]="zoom">
            </mat-slider>
            <app-so-svg-icon src="assets/img/mdi-magnify-plus.svg" (click)="addZoom()" style="cursor: pointer;"></app-so-svg-icon>
        </div>
        <div>
            <app-button 
                    [text]="'save' | translated: 'Guardar'"
                    svg="mdi-check-circle-outline"
                    [type]="'button'"
                    [iconAlignment]="'right'"
                    [injection]="{ label: 'pt-2', button: 'pb-4', icon: 'position: relative; bottom: 2px;'}"
                    (click)="saveCroppedImage()"
            ></app-button>
        </div>
    </div>
</div>