import { Injectable, inject } from '@angular/core';
import { StorageService } from '@core/services/storages.service';
import { Request } from '@core/models/requests.model';
import { DemosHistory } from '@core/interfaces/catalogos.intefaces';

@Injectable({
  providedIn: 'root',
})
export class DemosHistoryService {
  private url = `ventas/historial/demos/search`;
  private readonly storageService = inject(StorageService);
  private readonly request = inject(Request);

  getDemosHistory(idProspecto: string) {
    const body = {
      configuracion: this.request.getConfigUser(),
      idProspecto: idProspecto,
    };
    const options = {
      headers: this.request.headers,
    };
    return this.request.Post<DemosHistory>(`${this.url}`, body, options);
  }
}
