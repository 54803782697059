import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthStatus } from '@core/interfaces';
import { GlobalSessionService } from '@core/services/global-session.service';

export const isAuthenticatedGuard: CanActivateFn = () => {
  const globalSession = inject(GlobalSessionService);
  const router = inject(Router);

  if(globalSession.authStatus() === AuthStatus.authenticated) return true;

  router.navigateByUrl('/auth/login');

  return false;
};