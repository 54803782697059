<div>
  <app-so-modal modalId="modal-photo-from-camera" verticalPosition="center">
    <div class="modal__header">
    </div>
    <div class="modal__body" [style]="{'padding':'0px'}">
      <div class="d-flex justify-content-center">
        <img src="./assets/img/mdi-webcam.svg" alt="" style="width: 34px; height: 30px;">
        <p class="fw-bold" style="font-size: 18px; padding-left: 8px; padding-top: 2px;">
          {{'capture_photo'|translate}}</p>
      </div>
      <div class="webcam">
        <img class="" src="assets/img/webcam-grey.svg">
      </div>
      <section (click)="onUploadClick()">
        <div>
          <mat-icon>camera_enhance</mat-icon>
          <input type="file" accept="image/*" style="display: none" (change)="onFileSelected($event)" />
        </div>
        <span>{{ "take_photo" | translate }}</span>
      </section>
      <div class="text-center">
        <app-button svg="mdi-check-circle-outline" iconAlignment="right" [scale]=".8" text="{{ 'confirm' | translate }}"
          [injection]="{ label: 'pt-2' }" data-bs-toggle="modal" />
      </div>
    </div>
  </app-so-modal>
</div>
