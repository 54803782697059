<div class="d-flex justify-content-start align-items-center {{iconReverse}}" [style]="'gap:'+gap+';'">
  <app-so-svg-icon [class]="iconClass" [src]="logo!" [color]="iconColor" [applyChange]="applyIconChange" height="26" width="26" [size]="iconSize" [onlyPath]="onlyPath"></app-so-svg-icon>
  <p 
    class="{{size}}"
    style="margin: 0; color: var(--so-text-2);font-weight: bold;"
    [style]="customStyle"
  >
    {{title}}
  </p>
</div>
