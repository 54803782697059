import { Component, Input } from '@angular/core';

@Component({
  selector: 'so-message',
  templateUrl: './so-message.component.html',
  styleUrls: ['./so-message.component.scss'],
})
export class SoMessageComponent {
  @Input() message: string = '';
  @Input() transmitter: 'me' | 'other' = 'me';
  @Input() icon: boolean = false;
  @Input() position: 'left' | 'right' = 'left';
}
