import { Component, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-so-dinamic-table',
  templateUrl: './so-dinamic-table.component.html',
  styleUrls: ['./so-dinamic-table.component.scss']
})
export class SoDinamicTableComponent {
  @Input() headers?: any[];
  @Input() title?: string;
  @Input() id?: string;

  @Input() ConvProsH: string[] = [];

  @Input() ConvProsV: any[] = [];



}
