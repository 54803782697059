import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-so-text-state',
  templateUrl: './so-text-state.component.html',
  styleUrls: ['./so-text-state.component.scss'],
})
export class SoTextStateComponent implements OnInit {
  @Input() color?: 'primary' | 'success' | 'danger' | 'warning' | 'default' =
    'default';
  @Input() type?: string;
  @Input() text?: string;
  @Input() icon?: string = ' ';
  @Input() bgIcon?: string = '#f4f4f4';
  @Input() gap?: string;
  @Input() opacity?: string = '1';
  @Input() size?: string = '14';
  @Input() fontWeigth?: 'bold' | 'semibold' | 'italic' | 'lightner' | 'normal'|'100'|'200'|'300'|'400'|'500'|'600'|'800'|'900' =
    'normal';

  ngOnInit(): void {
    this.validateType();
  }

  validateType() {
    switch (this.color) {
      case 'primary':
        this.type = 'so-primary';
        break;
      case 'success':
        this.type = 'so-success';
        break;
      case 'warning':
        this.type = 'so-warning';
        break;
      case 'danger':
        this.type = 'so-danger';
        break;
      case 'default':
        this.type = 'so-black';
        break;
    }
  }
}
