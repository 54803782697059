import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'so-file-preview',
  templateUrl: './so-file-preview.component.html',
  styleUrl: './so-file-preview.component.scss'
})
export class SoFilePreviewComponent {
  @Input() fileUrl!: string;
  @Input() mediaType?: string = '';

  @Output() iframeLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  private iframeLoadedOnce: boolean = false

  constructor(public sanitizer: DomSanitizer) {}

  public onIframeLoad(){
    if (!this.iframeLoadedOnce){
      this.iframeLoadedOnce = true
      this.iframeLoaded.emit(true);
    }
  }

  public isPdf(url: string): boolean {
    return url.toLowerCase().endsWith('.pdf');
  }

  public isVideo(url: string): boolean {
    const videoExtensions = ['.mp4', '.webm', '.ogg', '.mov', '.avi', '.mkv', '.flv', 
    '.wmv', '.mpg', '.mpeg', '.3gp', '.vob', '.ts', '.m4v', 
    '.asf', '.rm', '.swf', '.f4v', '.hevc'];    
    const hasVideoExtension = videoExtensions.some(ext => url.toLowerCase().endsWith(ext));
  
    // Si mediaType está definido, comprobar si empieza con 'video/'
    const isVideoType = this.mediaType ? this.mediaType.startsWith('video/') : false;
    
    return hasVideoExtension || isVideoType;
  }

  public openFile(pdfUrl: string): void {
    window.open(pdfUrl, '_blank'); // Abre el archivo en una nueva pestaña
  }
  
  downloadFile(pdfUrl: string): void {
    // Usamos fetch para descargar el archivo
    const link = document.createElement('a');

    // Asegurarnos de que la URL es válida
    link.href = pdfUrl;
    
    // Obtener el nombre del archivo desde la URL
    link.download = this.getFileNameFromUrl(pdfUrl);

    // Asegurarnos de que la descarga ocurra en lugar de abrir en nueva ventana
    link.target = '_self'; // Esto no debe ser necesario, pero lo ponemos para asegurar el comportamiento

    // Simular un clic en el enlace para activar la descarga
    link.click();
  }
  
  private getFileNameFromUrl(url: string): string {
    // Extraer el nombre del archivo desde la URL
    const fileName = url.split('/').pop() || 'archivo.pdf';
    return fileName;
  }

}
