<div class="avatar d-flex" >
  <app-navbar-panel class="me-3" (click)="desactiveMenu()"></app-navbar-panel>
  <div class="mt-4 me-2 d-sm-none d-md-none d-lg-none d-xl-inline dataUser">
    <h4 class="label-User">{{name}}</h4>
    <h4 class="label-Desc text-end">{{perfil}}</h4>
  </div>
  <div class='div-avatar'>
    <app-so-avatar [src]="img" (click)="activeMenu()"></app-so-avatar>
    <div class='div-status-circle'  [ngStyle]="{'background-color': statusColor}"></div>
    <div class="menu justify-content-center py-3 px-2" [ngClass]="actMenu ? 'd-flex' : 'd-none'"
      (mouseleave)="desactiveMenu()">
      <div class="div-con-pico"></div>
      <div class="d-flex flex-column w-100 px-2" #optionsUser>
        @if(statusRequest()){
        <div class="status-list-item" style="margin-left: 5px;">
          @for (item of statusList; track $index) {
            <div (click)="active(item)" class="d-flex align-items-center mb-2" style="cursor: pointer;">
              <div class='div-menu-status-circle' [ngStyle]="{'background-color': item.color}"></div>
              <label class="menu-labels" [ngClass]="{'menu-selected': item.idEstado == actualUserStatus}"   style="cursor: pointer;"> 
                {{item.nombre}}
              </label>
            </div>
          }
        </div>
        }@else {
          <section class="skeleton-section">
            <app-skeleton type="glow" borderRadius="15px" height="10%"/>
            <app-skeleton type="glow" borderRadius="15px" height="10%"/>
            <app-skeleton type="glow" borderRadius="15px" height="10%"/>
          </section>
        }
        <hr class="menu-divider">
        <div class="d-flex align-items-center justify-content-starts mb-1" style="cursor: pointer;" (click)="redirectTo('/settings')">
          <so-icon svg="mdi-account-cog-outline" style="width: 23px; margin-right: 9px;" [size]="0.8"/>
          <label class="menu-labels menu-selected"  style="cursor: pointer;">{{'go_to_my_profile'|translate}}
          </label>
        </div>
        <div class="d-flex align-items-center" style="cursor: pointer;" data-bs-toggle="modal" data-bs-target="#modal-close-session">
          <so-icon svg="mdi-logout-danger" style="width: 23px; margin: 0 6px 0 3px;" [size]="0.65"  />
          <label class="menu-labels menu-selected" style="cursor: pointer;">{{'sign_off'|translate}}
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
