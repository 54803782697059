import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-sure-confirm',
  templateUrl: './modal-sure-confirm.component.html',
  styleUrls: ['./modal-sure-confirm.component.scss']
})
export class ModalSureConfirmComponent {

}
