import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedStateService {

  constructor() { }

  idProspect = signal<string>('');
  nameProspect = signal<string>('');

}
