<h2 mat-dialog-title> 
    <div class="d-flex align-items-center justify-content-center gap-2 mt-4">
        <ng-content select="[titleModal]"></ng-content>
        <!-- <button class="custom-close-btn"  (click)="onCloseClick()">
            <so-icon
                svg="mdi-close"
                color="--color-icon"
                [size]="1.0715"
                [applyChange]="true"
            ></so-icon>
        </button> -->
    </div>   
</h2>
<div mat-dialog-content>
    <ng-content select="[subTitle]" ></ng-content>
    
    <ng-content select="[contentModal]"></ng-content>
</div>