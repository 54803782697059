<div #sessionContainer class="main-container">
  <div class="w-full d-flex flex-column p-4" style="background-color: var(--background-card); border-radius: 20px; box-shadow: var(--box-shadow); height: 100%;">
    <div class="d-flex gap-2 m-2">
      <div style="position: relative; bottom: 4px;">
        <app-so-svg-icon src="./assets/img/reload-alt.svg" color="--color-icon" width="25" >
        </app-so-svg-icon>
      </div>
      <p class="so-text-1-bold">{{ 'session_history' | translate }}</p>
    </div>
    <div class="container-div mt-2"> 
      @for (session of history; track $index) {
        <ul class="list-container d-flex flex-column">
          <li class="items-list">
            <div class="icon" style="position: relative; left: 4px;">
              <app-so-svg-icon src="./assets/img/mdi-cellphone.svg"  color="--color-icon" height="25" width="25"></app-so-svg-icon>
            </div>
            <span class="text"> {{ session.sistemaOperativo + " "+session.navegador }} </span>
          </li>
          <li class="items-list">
            <div class="icon" style="position: relative; left: 3px;">
              <app-so-svg-icon src="./assets/img/mdi-timelapse-primary.svg" color="--color-icon" height="22" width="22"></app-so-svg-icon>
            </div>
            <span class="text"> {{ (session.fecha | date: 'HH:mm') + 'h' }}</span>
          </li>
          <li class="items-list">
            <div class="icon" style="position: relative;">
              <app-so-svg-icon src="./assets/img/mdi-calendar-text-outline-primary.svg" color="--color-icon" height="22" width="22" [size]=".7"></app-so-svg-icon>
            </div>
            <span class="text"> {{ session.fecha | date: 'dd/MM/yyyy' }} </span>
          </li>
          <li class="items-list">
            <div class="icon">
              <so-icon icon="location_on" style="color: var(--color-icon);"/>
            </div>
            <span class="text"> San Miguel de Allende, México </span>
          </li>
        </ul>
      }
    </div>
  </div>
</div>
