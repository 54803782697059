import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { Chart, registerables } from 'node_modules/chart.js';
Chart.register(...registerables);

@Component({
  selector: 'app-so-chart-doughnut',
  templateUrl: './so-chart-doughnut.component.html',
  styleUrls: ['./so-chart-doughnut.component.scss']
})
export class SoChartDoughnutComponent implements AfterViewInit {

  newArray: any[] | undefined;
  @Input() size: string = "190";
  @Input() id!: string;
  @Input() title!: string;
  @Input() subTitle: string = "";
  @Input() footer: string = "";
  @Input() footerNumber: string = "";
  @Input() data: any[] = [];
  suma: any;
  @Input() legentType: '' | 'footerTwoColumns' | 'right' | 'leftTwoColumns' = '';

  @Input() bgColorType = '';

  backgroundColor = [''];

  ngAfterViewInit() {
    this.getBackgroundColor();
    this.newArray = this.data.map(data => data.numb);
    this.suma = this.newArray.reduce((total, numero) => total + numero, 0);
    this.chartDoughnut();
  }

  chartDoughnut() {
    new Chart('doughnut-' + this.id, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: this.newArray,
            backgroundColor:
              this.backgroundColor
            ,
          },
        ]
      },
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                return `${context.raw} %`;
              }
            }
          }
        },
        cutout: '70%',
        responsive: true,
      },
    });

  }

  numberToWords(num: number): string {
    const words: string[] = ['cero', 'one', 'two', 'three', 'four', 'five', 'six'
    ];

    if (num >= 0 && num <= 20) {
      return words[num];
    }
    return 'Número no soportado';
  }

  numberToWordsRight(num: number): string {
    return 'color-' + num;
  }

  getBackgroundColor() {
    switch (this.bgColorType) {
      case 'Message':
        this.backgroundColor = [
          'rgb(37, 211, 102)', // WhatsApp
          'rgb(59, 89, 152)', // FB
          'rgb(42, 166, 154)', // SMS
          'rgb(243, 140, 46)', // EMAIL
          'rgb(239, 0, 183)', // CALL
          'rgb(229, 229, 229  )',
        ]
        break;

      default:
        this.backgroundColor = [
          'rgb(0, 42, 127)',
          'rgb(72, 82, 175)',
          'rgb(113, 141, 0)',
          'rgb(163, 189, 54 )',
          'rgb(215, 240, 104 )',
          'rgb(229, 229, 229  )',
        ]
        break;
    }
  }

  getMat(datos: any) {
    return Math.ceil(datos / 2);
  }

}
