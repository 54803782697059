<div class="row" *ngIf="legentType == ''">
  <div class="mr-4 col-sm-5 col-md-5 col-lg-5 col-xl-5 col-xxl-5" style=" max-height:200px; max-width:200px">
    <canvas id="doughnut-{{id}}"></canvas>
  </div>

  <div class="col-sm-7 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
    <h1 class=" title-chart">{{title}}</h1>
    <h3 class=" mt-3 stages "> {{subTitle}} </h3>
    <div class="row">
      <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
        <div class="d-flex  align-items-center mt-3" *ngFor="let data of data.slice(0, getMat(data.length)); let i = index">
          <div class='status  {{ numberToWordsRight(i) }}-{{bgColorType}}'></div>
          <h6 class="labels">{{data.label|translate}}</h6>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
        <div class="d-flex  align-items-center mt-3" *ngFor="let data of data.slice(getMat(data.length)); let i = index">
          <div class='status  cl-{{ numberToWordsRight(i) }}-{{bgColorType}}'></div>
          <h6 class="labels">{{data.label|translate}}</h6>
        </div>
      </div>
    </div>
    <div class="mt-4 d-flex align-items-center  ">
      <h2 class="footerNumber">{{footerNumber}}</h2>
      <h2 class="footer mt-2">{{footer}}</h2>
    </div>
  </div>
</div>


<div *ngIf="legentType == 'footerTwoColumns'">
  <div class="row w-100">
    <div class="mr-4 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6" style=" max-height:200px; max-width:200px">
      <canvas id="doughnut-{{id}}"></canvas>
    </div>

    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6  d-flex align-items-center">
      <h2 class="footerNumber">{{footerNumber}}</h2>
      <h2 class="so-text-3-bold so-neuter-thick">{{footer}}</h2>
    </div>
  </div>

  <div class="row mt-3 mb-3 text-center">
    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
      <div class="d-flex  align-items-center mt-3" *ngFor="let data of data.slice(0, getMat(data.length)); let i = index">
        <div class='status  {{ numberToWordsRight(i) }}-{{bgColorType}}'></div>
        <label class="labels text-start ">{{data.label|translate}}</label>
      </div>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
      <div class="d-flex  align-items-center mt-3" *ngFor="let data of data.slice(getMat(data.length)); let i = index">
        <div class='status  cl-{{ numberToWordsRight(i) }}-{{bgColorType}}'></div>
        <label class="labels">{{data.label|translate}}</label>
      </div>
    </div>
  </div>
</div>


<div *ngIf="legentType == 'right'">
  <div class="d-flex justify-content-between">
    <div class=" col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6" style=" max-height:200px; max-width:200px">
      <canvas id="doughnut-{{id}}"></canvas>
    </div>

    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
      <div class="d-flex  align-items-center mt-3" *ngFor="let data of data; let i = index">
        <div class='status  {{ numberToWordsRight(i) }}-{{bgColorType}}'></div>
        <label class="labels">{{data.label|translate}}</label>
      </div>
    </div>
  </div>
</div>
