import { Component, OnInit, inject } from '@angular/core';
import { Response } from '@modules/lead-management/submodules/directory/interfaces/privacy-log';
import { SharedStateService } from '@modules/lead-management/submodules/directory/services/shared-state.service';
import { SummaryProspectService } from '@modules/lead-management/submodules/directory/services/summary-prospect.service';
import { PromiseStatus } from '@shared/enums';

@Component({
  selector: 'app-accordion-privacy-log',
  templateUrl: './accordion-privacy-log.component.html',
  styleUrls: ['./accordion-privacy-log.component.scss']
})
export class AccordionPrivacyLogComponent implements OnInit {
  private sharedStateServices = inject(SharedStateService);
  private summaryProspectService = inject(SummaryProspectService);

  public privacyLogs?: Response[];
  PromiseStatus = PromiseStatus;
  requestStatus = PromiseStatus.Pending;
  errorMessage: string = '';

  ngOnInit(): void {
    this.loadPrivacyLog();
  }

  loadPrivacyLog(){
    let idProspect = this.sharedStateServices.idProspect();
    this.summaryProspectService
    .getPrivacyLog(idProspect)
    .subscribe({ 
      next: (data) => {
        const response = data.response;
        this.privacyLogs = response;
        this.requestStatus = PromiseStatus.Fulfilled;
      },
      error: ({ error }) => {
        this.errorMessage = error.message;
        this.requestStatus = PromiseStatus.Rejected;
      },
    });
  }
}
