import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-so-avatar',
  templateUrl: './so-avatar.component.html',
  styleUrls: ['./so-avatar.component.scss']
})
export class SoAvatarComponent {
  @Input() src: string = '';
  @Input() width: string = '';
  @Input() height: string = '';
  @Input() size: number | undefined ;
}
