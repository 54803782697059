import { Component, ElementRef, HostListener, effect, inject, signal } from '@angular/core';
import { UserStore } from '@core/stores/session.store';
import { getState } from '@ngrx/signals';
import { CurrentStatusService } from '@modules/settings/services/currentStatus/current-status.service';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';
import { DynamicComponentService, AlertType } from '@shared/components/atomics/body-loading/dynamic-component-service.service';
import { CurrentStatus } from '@modules/settings/stores/current-status.store';
import { StatusList } from '@modules/settings/stores/status-list.store';
import { Router } from '@angular/router';
import { openAlertFn } from '@shared/helpers/closures';

@Component({
  selector: 'app-navbar-user',
  templateUrl: './navbar-user.component.html',
  styleUrl: './navbar-user.component.scss'
})
export class NavbarUserComponent {
  private readonly userStore = inject(UserStore);
  private readonly currentStatusService = inject(CurrentStatusService);
  private readonly translateService = inject(TranslateService);
  private readonly dynamicComponentService = inject(DynamicComponentService);
  private readonly currentStatus = inject(CurrentStatus);
  private readonly statusListStore = inject(StatusList);

  private openAlert = openAlertFn(this.dynamicComponentService, 'message');

  public statusRequest = signal<boolean>(false);
  private readonly router = inject(Router);

  public actMenu = false;
  public status: any = 'online';
  public perfil = '';
  public name = '';
  public img = '';  public statusColor = '';


  public statusList: any[] = [];
  public actualUserStatus: string = '';

  constructor(
    private elRef: ElementRef
  ) {
    effect(() => {
      //UserState
      const state = getState(this.userStore);
      this.name = this.nameValidation(
        state.data.empleado.nombre,
        state.data.empleado.paterno,
      );
      this.perfil = state.data.perfiles[0].titulo;
      this.img = state.data.empleado.foto;
      //StatusState
      const statusstate = getState(this.currentStatus);
      this.statusColor = statusstate.color;
      this.actualUserStatus = statusstate.idEstado;
    });
    this.loadStatus();
  }

  loadStatus(){
    //Cargar el listado de estatus
    this.currentStatusService.getStatusCatalog().subscribe({
      next: (res: any) => {
        const data = res.response.Estado;
        this.statusList = data;
        this.statusListStore.setCurrentStatusList(data);
        this.statusRequest.update(() => true);
      },error: (error: any) => {
        console.error(error);
      }
    })
    //Cargar el estatus actual del usuario en el store
    this.currentStatusService.getCurrentUserStatus().subscribe({
      next: (res:any) =>{
        const estado = res.response.Usuario.estado;
        this.currentStatus.setCurrentStatus(estado);
      }
    })
  }
  activeMenu() {
    this.actMenu = !this.actMenu;
  }

  desactiveMenu() {
    this.actMenu = false;
  }

  active(itemSeleccionado: any) {
    this.currentStatusService.updateCurrentUserStatus(itemSeleccionado.idEstado).subscribe({
      next: (res: any) => {
        const successTitle = this.translateService.instant('success');
        const message = this.translateService.instant('status_update_success');
        const estado = res.response.Usuario.estado
        this.currentStatus.setCurrentStatus(estado);
        this.openAlert(successTitle, message, AlertType.success , true).pipe(take(1));
      }, error: (err: any) => {
        const errorTitle = this.translateService.instant('error');
        const message = this.translateService.instant('status_update_error');
        this.openAlert(errorTitle, message, AlertType.error , true).pipe(take(1));
      }
    })
  }

  nameValidation(nombre:string, paterno:string):string{
    const nombreCompuesto = nombre.split(" ")[0] + ' ' + paterno;
    const limite = 20;
    return nombreCompuesto.length > limite ? nombreCompuesto.substring(0,20) + '...' : nombreCompuesto
  }

  redirectTo(seg: String){
    this.actMenu = false;
    this.router.navigate([seg]);
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.desactiveMenu();
    }
  }
  
}
