import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { GlobalSessionService } from '@core/services/global-session.service';

@Component({
  selector: 'so-organism-car-interest-business',
  templateUrl: './so-organism-car-interest-business.component.html',
  styleUrl: './so-organism-car-interest-business.component.scss'
})
export class SoOrganismCarInterestBusinessComponent implements OnInit{

  @Input() showAddButton: boolean = false;

  public businessType!: string;
  public form!: FormGroup;

  constructor(
    private globalSessionService: GlobalSessionService,
    private fb: FormBuilder,
  ){
    this.loadBusinessType();
    this.loadForm();
  }

  ngOnInit(): void {
    this.addCarInterestNew();
    this.addCarInteresFleet();
    this.addCarInterestPreOwned();
  }

  public loadForm(){
    this.form = this.fb.group({
      carInterestNew: this.fb.array([]),
      carInterestPreOwned: this.fb.array([]),
      carInterestFleet: this.fb.array([])
    })
  }

  get carInterestNew(){
    return this.form.get('carInterestNew') as FormArray;
  }

  get carInterestPreOwned(){
    return this.form.get('carInterestPreOwned') as FormArray;
  }

  get carInterestFleet(){
    return this.form.get('carInterestFleet') as FormArray;
  }

  public asFomrGroup(control: AbstractControl): FormGroup{
    return control as FormGroup;
  }

  public addCarInterestNew(){
    const carInterest = this.fb.group({
      marca: [''],
      auto: [''],
      anio: [''],
      versiones: [''],
      colores: ['']
    });
    this.carInterestNew.push(carInterest);
  }

  public addCarInterestPreOwned(){
    const carInterest = this.fb.group({
      anios: [''],
      marca: [''],
      auto: [''],
      versiones: [''],
      colores: [''],
    });
    this.carInterestPreOwned.push(carInterest);
  }

  public addCarInteresFleet(){
    const carInterest = this.fb.group({
      marca: [''],
      auto: [''],
      anios: [''],
      versiones: [''],
      colores: [''],
      cantidad: ['']
    });
    this.carInterestFleet.push(carInterest);
  }

  public removeCarInterestNew(index: number) {
    this.carInterestNew.removeAt(index);
  }

  public removeCarInterestPreOwned(index: number) {
    this.carInterestPreOwned.removeAt(index);
  }

  public removeCarInterestFleet(index: number) {
    this.carInterestFleet.removeAt(index);
  }

  private loadBusinessType(){
    this.globalSessionService.businessType$.subscribe({
      next: (value) => {
        this.businessType = value;
        this.resetForm();
        this.updateCarInterestForms();
      }
    });
  }

  private resetForm(){
    this.carInterestNew.clear();
    this.carInterestPreOwned.clear();
    this.carInterestFleet.clear();
  }

  private updateCarInterestForms() {
    if (this.businessType === 'TN00000002') {
      this.addCarInterestPreOwned();
    } else if (this.businessType === 'TN00000003') {
      this.addCarInteresFleet();
    } else {
      this.addCarInterestNew();
    }
  }
}
