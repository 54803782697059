import { Injectable } from '@angular/core';
import { Versiones } from '@core/interfaces/catalogos.intefaces';
import { Request } from '@core/models/requests.model';

@Injectable({
  providedIn: 'root'
})
export class VersionesFlotillasService {
  private url = `ventas/catalogos/flotillas/version/search`;

  constructor(
    private request: Request
  ) { }

  getVersionesFlotillas(body: any) {
    const options = { headers: this.request.headers };
    return this.request.Post<Versiones>(this.url, body, options);
  }

  formatValues(data: any[], keyProp: string, valueProp: string): { key: any; value: any }[] {
    return data.map(item => ({ key: item[keyProp], value: item[valueProp] }));
  }

}
