<app-so-modal modalId="modal-sure-confirm" verticalPosition="center">
  <div class="modal__header" [style]="{'display':'flex','width':'100%','margin-top':'30px'}">
  </div>
  <div class="modal__body" [style]="{'padding':'0px'}">
    <div class="d-flex justify-content-center mt-2">
      <img src="./assets/img/mdi-report-outline.svg" alt="" style="width: 34px; height: 30px;">
      <p style="font: normal normal bold 22px/28px Montserrat; padding-left: 8px; padding-top: 2px;">
        {{'are_you_sure'|translate}}</p>
    </div>
    <div class="d-flex justify-content-center" style="height: 190px;padding-top: 40px;width: 458px;margin: auto;
    text-align: center;">
      <p>{{'you_will_abandon_the_capture'|translate}} <span
          class="negrita">{{('prospect_data'|translate).toLowerCase()}}</span> {{'like_their'|translate}} <span class="negrita">
            {{('products_of_interest'|translate).toLowerCase()}}</span>.</p>
    </div>
    <div class="d-flex justify-content-center gap-2">
      <button class="d-flex mt-1">
        <label
          style="color: #F44336; font: normal normal normal 13px/14px Montserrat; margin-top: 8px; margin-right: 3px;">
          {{'i_am_not_sure'|translate}}
        </label>
        <mat-icon [ngStyle]="{'font-size':'32px','width': '30px','height': '30px', 'color':'#F44336'}">
          highlight_off</mat-icon>
      </button>
      <button class="d-flex mt-1">
        <label style="font: normal normal normal 13px/14px Montserrat;margin-top: 8px;margin-right: 3px;
        margin-left: 10px;">
          {{'i_am_sure'|translate}}
        </label>
        <img class="" src="assets/img/mdi-check-circle-outline.svg"
          style="display: inline;margin-left: 12px;margin-bottom: 3px; width: 28px; height: 28px;">
      </button>
    </div>
  </div>
</app-so-modal>
