@if (requestStatus === PromiseStatus.Pending) {
<so-spinner />
}
@else if (requestStatus === PromiseStatus.Rejected) {
<span class="so-text-2 d-block text-center">{{ errorMessage }}</span>
}
@else if (responseData.response.length === 0) {
<span class="so-text-2 d-block text-center">{{ 'no_data' | translated }}</span>
}
@else {
<div class="content-registro">
  @for(data of responseData.response; track $index) {
  <div class="registro">
    <ng-content select=".header">
    </ng-content>
    <div class="sub-header">
      <div class="estado-venta">
        <div style="text-align: left;">
          <b>{{'billing'|translate}}</b>
          <p>{{ data.fechaFacturacion | utcToLocal:false}}</p>
        </div>
        <div>
          <b>{{'delivery'|translate}}</b>
          <p>{{data.fechaEntregaFisica | utcToLocal:false}}</p>
        </div>
      </div>
    </div>
    <div class="info-auto seccion">
      <b>{{'car'|translate}}</b>
      <div class="content-image-desc">
        <div class="image-component circle"></div>
        <p>{{data.auto}}</p>
      </div>
    </div>
    <div class="numero-vin seccion">
      <b>{{'vin_abbreviation'|translate}}</b>
      <p>{{data.vin}}</p>
    </div>
    <div class="nombre-ejecutor seccion">
      <b>{{'payment_method'|translate}}</b>
      <p>{{data.formaPago}}</p>
    </div>
    <div class="adicionales">
      <div class="seccion">
        <b>{{'warranty'|translate}}</b>
        <app-so-badge [color]="data.garantia? 'danger-light':'danger-light'" [text]="data.garantia"></app-so-badge>
      </div>
      <div class="seccion">
        <b>{{'insurance'|translate}}</b>
        <app-so-badge [color]="data.realizado? 'danger-light':'danger-light'"
          [text]="data.seguro === 'true' ? translate('yes'):translate('no')"></app-so-badge>
      </div>
    </div>
    <ng-content select=".footer">
    </ng-content>
  </div>
  }
</div>
}
