<div class="container-navigation">
    @if(showText){
        <div class="text-center so-text-3-bold so-neuter">
            {{ textShowRecords }}
        </div>
    }
    <div class="d-flex justify-content-end content-pagination">
      <ul class="pagination">
        <button
          class="btn mx-1 pagination-button d-flex justify-content-center align-items-center transparent no-padding"
          (click)="goToPage(1)" [disabled]="currentPage === 1">
          <mat-icon class="so-secondary" style="transform: scale(2);rotate: -180deg;">skip_next</mat-icon>
        </button>
        <button
          class="btn mx-1 pagination-button d-flex justify-content-center align-items-center transparent no-padding"
          (click)="goToPreviousPage()" [disabled]="currentPage === 1">
          <mat-icon class="so-secondary" style="transform: scale(2);">arrow_left</mat-icon>
        </button>
        
        <ng-container *ngIf="currentPage > 1">
          <button class="btn mx-1 pagination-button" (click)="goToPreviousPage()">
            {{ currentPage - 1 }}
          </button>
        </ng-container>
        
        <button class="btn mx-1 pagination-button active">
          {{ currentPage }}
        </button>
        
        <ng-container *ngIf="currentPage < getTotalPages()">
          <button class="btn mx-1 pagination-button" (click)="goToNextPage()">
            {{ currentPage + 1 }}
          </button>
        </ng-container>
        
        <button
          class="btn mx-1 pagination-button d-flex justify-content-center align-items-center transparent no-padding"
          (click)="goToNextPage()" [disabled]="currentPage === getTotalPages()">
          <mat-icon class="so-secondary arrow" style="transform: scale(2);">arrow_right</mat-icon>
        </button>
        <button
          class="btn mx-1 pagination-button d-flex justify-content-center align-items-center transparent no-padding"
          (click)="goToPage(getTotalPages())" [disabled]="currentPage === getTotalPages()">
          <mat-icon class="so-secondary arrow" style="transform: scale(2);">skip_next</mat-icon>
        </button>
      </ul>
    </div>
</div>
