import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  private isProspect = new BehaviorSubject<string>('0');
  isProspect$ = this.isProspect.asObservable();

  setBooleanValue(newValue: string) {
    this.isProspect.next(newValue);
  }
}
