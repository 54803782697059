import { Injectable } from '@angular/core';
import { Request } from '@core/models/requests.model';
import { StorageService } from '@core/services/storages.service';
import { Response } from '@shared/interfaces/notifications';
import { take } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  
  private url: string = 'global/catalogos/tipodenotificacion';
  private urlVentas: string ='ventas/notificacion';

  constructor(
    private request: Request,
    private StorageService: StorageService,
  ) {}

  getTypeNotification(){
    
    const storageData = this.StorageService.getLocalStorage('userInfo').data;

    const body = { 
      basesDeDatos: {
        dbEmpresa: storageData.idBasDeDatos
        },
      idioma: {
        idIdioma: storageData.empleado.empresa.idioma.idIdioma,
        idPais: storageData.empleado.empresa.pais.idPais
      }
    }

    const options = {
      headers: this.request.headers,
    }

    return this.request.Post(`${this.url}/all`, body, options).pipe(take(1));
  }

  getNotifications(idTipo: string | number){
    const storageData = this.StorageService.getLocalStorage('userInfo').data;

    const body = { 
      idEmpleado: storageData.empleado.idEmpleado,
      idTipo: idTipo,
      configuracion: {
            basesDeDatos: {
              dbEmpresa: storageData.idBasDeDatos
            },
          idioma: {
            idIdioma: storageData.empleado.empresa.idioma.idIdioma,
            idPais: storageData.empleado.empresa.pais.idPais
          },
          husoHorario: {
              idHuso: storageData.empleado.empresa.husoHorario.idHuso,
              nombre: storageData.empleado.empresa.husoHorario.nombre
          }
      }
    }

    const options = {
      headers: this.request.headers,
    }

    return this.request.Post<Response>(`${this.urlVentas}/search/empleado`, body, options);

  }

  updateNotification(idNotificacion: string){
    const storageData = this.StorageService.getLocalStorage('userInfo').data;

    const body = { 
      idNotificacion: idNotificacion,
      visto: true,
      cumplido: false,
      configuracion: {
            basesDeDatos: {
              dbEmpresa: storageData.idBasDeDatos
            },
          idioma: {
            idIdioma: storageData.empleado.empresa.idioma.idIdioma,
            idPais: storageData.empleado.empresa.pais.idPais
          },
          husoHorario: {
              idHuso: storageData.empleado.empresa.husoHorario.idHuso,
              nombre: storageData.empleado.empresa.husoHorario.nombre
          }
      }
    }

    const options = {
      headers: this.request.headers,
    }

    return this.request.Post(`${this.urlVentas}/update`, body, options)

  }

}
