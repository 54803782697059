<!-- Modal -->
<div class="modal fade {{size}}" id="{{modalId}}" data-bs-keyboard="false" tabindex="-1" [ngStyle]="containerStyles">
  <div class="modal-dialog {{verticalPositionValidated}} {{sizeValidated}}  modal-side modal-top-right"
    [style]="'width:'+width+'!important;max-width:'+width+''">
    <div class="modal-content" [style]="'height:'+height+''">
      <div class="modal-header" [style]="'background-color:'+bgColorHeader+';'">
        <ng-content select=".modal__header">

        </ng-content>

        <button class="custom-close-btn" *ngIf="preventClose else default" (click)="onClose($event)">
          <img src="./assets/img/mdi-close.svg" class="img-icons">
        </button>
        <ng-template #default>
          <button class="custom-close-btn" *ngIf="!hideButtonClose" data-bs-dismiss="modal" >
            <img src="./assets/img/mdi-close.svg" class="img-icons">
          </button>
        </ng-template>

      </div>

      <div class="modal-body" [ngStyle]="styles">
        <ng-content select=".modal__body">

        </ng-content>
      </div>


      <div class="modal-footer" *ngIf="activeFooter">

        <ng-content select=".modal__footer">

        </ng-content>

      </div>
    </div>
  </div>
</div>
