import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal-sale-order',
  templateUrl: './modal-sale-order.component.html',
  styleUrls: ['./modal-sale-order.component.scss']
})
export class ModalSaleOrderComponent {
  options = ['Option 1', 'Option 2'];

  @Input() product: string = '';
  @Input() colorState: string = 'success';
  @Input() state: string = '';
  @Input() title: any = '';
  @Input() activeButton: string = 'false';
  @Input() id: string = 'tickets';

  headers: string[] = ['CLAVE DEL PEDIDO', 'NOMBRE', 'TELÉFONO', 'CORREO', 'AUTO', 'PRODUCTO'];
  rows: any[] = [
    {
      orderkey: '98799300000000000006',
      name: 'Maria Grisa',
      phone: '5585263650',
      email: 'maria-grisa@gmail.com',
      auto: 'NP300 FRONTIER',
      img: '../../../../../assets/img/avatar-random_path.png',
      product: 'V 4.1',
    },
    {
      orderkey: '92546800000000000035',
      name: 'Astuto Guzver Rico',
      phone: '5585263650',
      email: 'astuto@gmail.com',
      auto: 'PATHFINDER',
      img: '../../../../../assets/img/pathfinder.png',
      product: 'V 2.1',
    },
    {
      orderkey: '79930000000000006976',
      name: 'Raimond Artesio',
      phone: '5585365056',
      email: 'astuto@gmail.com',
      auto: 'X-TRAIL',
      img: '../../../../../assets/img/avatar-random@2x.png',
      product: 'V 2.3',
    }
  ]
}
