import { Component, Input, OnInit } from '@angular/core';
import { StorageService } from '@core/services/storages.service';
import { DemosHistoryService } from './demos-history.service';
import { DemosHistory } from '@core/interfaces/catalogos.intefaces';
import { TranslateService } from '@ngx-translate/core';
import { PromiseStatus } from '@shared/enums';

@Component({
  selector: 'app-so-history-component',
  templateUrl: './so-history-component.component.html',
  styleUrls: ['./so-history-component.component.scss'],
})
export class SoHistoryComponentComponent implements OnInit {
  // @Input() responseData: any = [];
  @Input() responseData: any = {
    response: [],
  };

  PromiseStatus = PromiseStatus;
  requestStatus = PromiseStatus.Pending;
  errorMessage: string = '';

  constructor(
    private storageService: StorageService,
    private demosHistoryService: DemosHistoryService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    const currentProspect = this.storageService.getLocalStorage(
      'currentProspectDirectory'
    );

    this.demosHistoryService
      .getDemosHistory(currentProspect.idProspecto)
      .subscribe({
        next: (data: DemosHistory) => {
          this.responseData = data;
          this.requestStatus = PromiseStatus.Fulfilled;
        },
        error: ({ error }) => {
          this.errorMessage = error.message;
          this.requestStatus = PromiseStatus.Rejected;
        },
      });
  }

  translate(text: string) {
    return this.translateService.instant(text);
  }
}
