<div class="menu-items">
    <so-icon 
        (click)="openContainer()"
        icon="menu" 
        style="color: var(--so-secondary); font-size: 38px; height: 38px; width: 38px;"
    />
    <div class="menu-container justify-content-center py-3 px-2"
        [ngClass]="showContainer ? 'd-flex' : 'd-none'"
        (mouseleave)="leaveContainer()">
        <div class="div-con-pico"></div>
        <div class="d-flex">
            <div class="pending-menu">
                <div [ngClass]="showPendings ? '' : 'd-none'">
                  <so-pending-menu></so-pending-menu>
                </div>
            </div>
            
            <div class="notification-menu">
                <div [ngClass]="showNotifications ? '': 'd-none'">
                  <so-notification-menu></so-notification-menu>
                </div>
            </div>
            <button class="btn-with-icon" (click)="openPendingMenu()">
                <span class="notification-count badge">
                    9
                    <span class="visually-hidden"></span>
                </span>
                <so-icon svg="account-clock-success" [size]="1" style="position: relative; bottom: 2px;"></so-icon>
            </button>
            <button class="btn-with-icon" (click)="openNotificationMenu()" >
                <span class="notification-count badge">
                    9
                    <span class="visually-hidden"></span>
                </span>
                <so-icon svg="mdi-bell-success" [size]="1"></so-icon>
            </button>
            <button class="btn-with-icon" (click)="hiddenElements()">
                <span class="notification-count badge">
                    9
                    <span class="visually-hidden"></span>
                </span>
                <so-icon svg="account-voice" [size]="1.5"></so-icon>
            </button>
        </div>
    </div>
</div>