<div class="card card-border" [ngClass]="!viewed ? 'br-not-viewed ': ''">
    <div class="card-body card-body-padding-notificacions">
        <div class="row" style="min-height: 80px;">
            <div class="col-2 ml-col-1">
                @if(namePerson){
                    <div class="circle-initials" [ngClass]="color">
                        {{ obtenerLetraNombreApellido(namePerson) }}
                    </div>
                }@else {
                    <div class="circle-initials" [ngClass]="'bg-system-blue'">
                        <div class="icon-position">
                            <so-icon svg="chat-config" [size]=".6" ></so-icon>
                        </div>
                    </div>
                }
            </div> 
            <div class="col-7 ml-col-7">
                <div class="custom-container" >
                    <p class="title-container">{{ title }} </p>
                    <p class="subtitle-container"> {{ namePerson }} <p>
                    <p> {{ description }} </p>
                </div>
            </div>
            <div class="col-3 ">
                @if(!viewed){
                    <div class="circle-new">
                    </div>
                }@else {
                    <div class="false-space"></div>
                }
                <p class="date-notification"> {{ date | date: 'dd-MM-yy' }} </p>
            </div>
        </div>
        <div class="row">
            <div class="col-9">
                <app-so-badge [text]="typeNotification" [color]="'success-blue'" fontWeight="bold" />
            </div>
            <div class="col-3 d-flex align-items-center justify-content-end">
                <div class="icon-eye" >
                    <so-icon svg="eye-success"></so-icon>
                </div>
            </div>
        </div>
    </div>
</div>