import { Component, OnInit, inject, Input } from '@angular/core';
import { StorageService } from '@core/services/storages.service';
import { DataProspectService } from './data-prospect.service';
import { DataProspect } from '@core/interfaces/catalogos.intefaces';
import { PromiseStatus } from '@shared/enums';

@Component({
  selector: 'app-accordion-prospect-data',
  templateUrl: './accordion-prospect-data.component.html',
  styleUrls: ['./accordion-prospect-data.component.scss'],
})
export class AccordionProspectDataComponent implements OnInit {
  private storageService = inject(StorageService);
  private dataProspectService = inject(DataProspectService);
  PromiseStatus = PromiseStatus;
  requestStatus = PromiseStatus.Pending;
  errorMessage: string = '';

  @Input() data: any = {};

  ngOnInit(): void {
    this.loadDataProspect();
  }

  loadDataProspect() {
    const currentProspect = this.storageService.getLocalStorage('currentProspectDirectory');
    this.dataProspectService
    .getDataProspect(currentProspect.idProspecto)
    .subscribe({
      next: (data: DataProspect) => {
        this.data = data;
        this.requestStatus = PromiseStatus.Fulfilled;
      },
      error: ({ error }) => {
        this.errorMessage = error.message;
        this.requestStatus = PromiseStatus.Rejected;
      },
    });
  }
}
