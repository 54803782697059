<div class="container-lateral-menu" [style]="customStyle">
  @if (showLogo) {
    <section class="logotipo">
      <img src="assets\img\op.png" width="54" />
      <div style="height: 6px;"></div>
      @if(showVersionApp){
        <p> V.10 </p>
      }
    </section>
  }
    @if(showTextApp){
      <div class="vertical-text visible" [style]="'top: ' + textAppTop + ';'">
        {{ textApp | uppercase }}
      </div> 
    }
</div>
