import { Injectable, signal } from '@angular/core';
import { SimpleLead } from '../interfaces/simple-lead-interface';

@Injectable({
  providedIn: 'root'
})
export class SharedStateLeadService {

  private nameLead = signal<string>('');

  public getNameLead(): string{
    return this.nameLead();
  }

  public setNameLead(value: string): void{
    this.nameLead.set(value);
  }

  private payloadDuplicateProspect = signal<any>('');

  public get duplicateProspect(): SimpleLead[]{
    return this.payloadDuplicateProspect();
  }

  public setPayloadDuplicateProspect(payload: SimpleLead){
    this.payloadDuplicateProspect.set(payload);
  }

  private idsDuplicateProspect = signal<{idPredominante?: string, idDuplicado?: string, idProspecto?: string}>({idPredominante: '', idDuplicado: '', idProspecto: ''});

  public get $idsDuplicateProspect(): { idPredominante?: string, idDuplicado?: string, idProspecto?: string }{
    return this.idsDuplicateProspect();
  }

  public setIdsDuplicateProspect(ids: {idPredominante?: string, idDuplicado?: string, idProspecto?: string}){
    this.idsDuplicateProspect.set(ids);
  }
  
  constructor() { }
}
