import { patchState, signalStore, withMethods, withState,  } from '@ngrx/signals';
import { SeguimientoInterface } from '../interfaces';

interface ObjetoSeguimientosInterface {
    cantidad: number,
    seguimientos: SeguimientoInterface[]
}

const objetoSeguimientos: ObjetoSeguimientosInterface ={
    cantidad: 0,
    seguimientos: []
}
export const DatosSeguimientosStore = signalStore(
    { providedIn: 'root' },
    withState(objetoSeguimientos),
    withMethods((store) => ({
        addSeguimiento(seguimiento: SeguimientoInterface) {
            const currentState = store;
            const newSeguimientos = [...(currentState.seguimientos() || []), seguimiento];
            const newCantidad = currentState.cantidad() + 1;            
            patchState(store, {
                cantidad: newCantidad,
                seguimientos: newSeguimientos
            });
        },
    }))
);