import { Component, Input } from '@angular/core';

@Component({
  selector: 'so-message-file',
  templateUrl: './so-message-file.component.html',
  styleUrls: ['./so-message-file.component.scss']
})
export class SoMessageFileComponent {
  @Input() name: string = 'Datos de prospectos';
  @Input() size: string | undefined = '';
}
