<form [formGroup]="form">
    <div [ngClass]="directionItems" >
        <div class="col">
            <so-select-reactive-form
                [selectLabel]="('car_model' | translate) + ' *'"
                [items]="listYears"
                [isLoading]="loadingYears"
                [formControlName]="yearsControl"
                (selectionChange)="onChangeCarYears($event)"
            ></so-select-reactive-form>
        </div>
        <div class="col">
            <so-select-reactive-form
                [selectLabel]="('interest_car_brand' | translate) + ' *'"
                [items]="listCarsBrand"
                [isLoading]="loadingCarBrand"
                [formControlName]="carBrandControl"
                (selectionChange)="onChangeCarBrand($event)"
            ></so-select-reactive-form>
        </div>
        <div class="col">
            <so-select-reactive-form
                [selectLabel]="('car_of_interest' | translate) + ' *'"
                [items]="listCarsInterest"
                [isLoading]="loadingCarInterest"
                [formControlName]="carInterestControl"
                (selectionChange)="onChangeCarInterest($event)"
            ></so-select-reactive-form>
        </div>
    </div>
    <div [ngClass]="directionItems">
        <div class="col">
            <so-select-reactive-form
                [selectLabel]="('car_version' | translate) + ' *'"
                [items]="listCarVersions"
                [isLoading]="loadingCarVersion"
                [formControlName]="carVersionsControl"
                (selectionChange)="onChangeCarVersion($event)"
            ></so-select-reactive-form>
        </div>
        <div class="col">
            <app-so-select-custom-reactive
                [selectLabel]="('car_color' | translate) +' *'"
                [isSelectColor]="true"
                [items]="listColors"
                [formControlName]="colorsControl"
            >
            </app-so-select-custom-reactive>
        </div>
        <div class="col">
            <div style="margin: 30px 0px 0px 8px;">
              <button class="flex mt-1" (click)="openAddCarToInventory()">
                <label
                  style="color: #A3BD36; font: normal normal 600 13px/14px Montserrat; margin-top: 3px; margin-right: 3px;">
                  <span style="font: normal normal normal 13px/14px Montserrat;">{{'load_car_from'|translate}}</span>
                  <span>&nbsp;{{('inventory'|translate).toLowerCase()}}</span>
                </label>
                <img src="./assets/img/Grupo-4620-secondary.svg" alt=""
                  style="width: 38px; height: 38px;margin-top: -11px;">
              </button>
            </div>
          </div>

    </div>
</form>