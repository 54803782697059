import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReloadDataService {

  private reload = new BehaviorSubject<boolean>(false);
  reload$ = this.reload.asObservable();

  setReload(update: boolean ){
    this.reload.next(update);
  }

}
