export const environment = {
    PRODUCTION: false,
    EXPIRATION_COOKIE: 30,
    API_URL: 'https://qa.backend.aws.sicopweb.net',
    API_URL_IA_TEXT: 'https://ia-qa.sicopweb.net',
    SECRET_KEY: 'KkYbBi885cpxgi',
    CLIENT_ID: '29',
    API_MDM_URL: 'https://api.sicopweb.com',
    SECRET_KEY_MDM: "Rra5bJdMAYTRLSn",
    CLIENT_ID_MDM: "2",
    ORIGIN:"1",
    CRYPTO_KEY: "PRIVATE_KEY",
    API_VERSION: "10.0.0",
    FIREBASE_CONFIG: {
        apiKey: "AIzaSyDMTisFsVxAp3LJXU4xpEem7jPyS8HZG3E",
        authDomain: "seek-op.firebaseapp.com",
        databaseURL: "https://seek-op.firebaseio.com",
        projectId: "seek-op",
        storageBucket: "seek-op.appspot.com",
        messagingSenderId: "616442447052",
        appId: "1:616442447052:web:d9aa0e60e6179d44d2ae69",
        measurementId: "G-VCXJSBQP3S"
    },
    VAPID_KEY: "BJi_u-41lNv8Dfr-w1T7K6JZsT0N219FLqmIpfX8l0IdWep31Q87xkTBWn4pS5oHUL8g81TcAipc5kFEl4vUcn0"
};
