<div class="card  card-border">
  <div class="card-body">
    <div class="row">
      <div class="d-flex align-items-center col-12 col-sm-12 col-lg-12 col-xl-12">
        <div class="me-3" *ngIf="isSuccess">
          <so-icon svg="mdi-check-circle-outline-success" [size]="0.9" />
        </div>
        <div>
          <h6 class="card-description  {{ isSuccess ? 'color-success' : 'color-primary' }} ">{{date}}</h6>
          <h6 class="card-title ">{{title}}</h6>
        </div>
      </div>
      <div class=" col-12 col-sm-12 col-lg-12 col-xl-12">
        <h6 class="card-Prospect">{{prospect}}</h6>
      </div>
    </div>
    <div class="row" *ngIf="!isSuccess">
      <div class="col-6 d-flex align-items-center">
        <app-so-badge text="{{'pending' | translate}} " color="primary" fontSize="12px"></app-so-badge>
      </div>
      <div class="col-6 d-flex align-items-center">
        <button type="button" class="btn btn-sm  float-right" (click)="emmitClickEvent()">
            {{ 'to_accomplish' | translate }}
            <span class="ml-2">
              <so-icon svg="mdi-check-circle-outline" [size]="0.7" />
            </span>
        </button>
      </div>
    </div>
  </div>
</div>
