import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DuplicateLeaflet } from '@modules/lead-management/submodules/home/services/duplicate leaflets';
import { SharedStateLeadService } from '@modules/lead-management/submodules/home/services/shared-state-lead.service';
import { DynamicComponentService } from '@shared/components/atomics/body-loading/dynamic-component-service.service';
import { ModalServiceService } from '@shared/components/atomics/so-modal/modal-service.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-alert-join-prospects',
  templateUrl: './alert-join-prospects.component.html',
  styleUrl: './alert-join-prospects.component.scss'
})
export class AlertJoinProspectsComponent implements OnInit{

  public nameProspect: string = '';

  constructor(
    private dialogRef: MatDialogRef<AlertJoinProspectsComponent>,
    private route: Router,
    private modalService: ModalServiceService,
    private sharedStateLeadService: SharedStateLeadService,
    private duplicateLeaflet: DuplicateLeaflet,
    private dynamicComponentService: DynamicComponentService,
  ){}

  ngOnInit(): void {
    console.log('modal abierto');
    
    this.getNameLead();
  }

  getNameLead(){
    this.nameProspect = this.sharedStateLeadService.getNameLead();
  }

  redirectTo(){
    this.dynamicComponentService.showBodyLoading();
    const prospect = this.sharedStateLeadService.duplicateProspect;
    const payloadDuplicate = JSON.stringify(prospect, null , 2);
    
    this.duplicateLeaflet.prospectExist(payloadDuplicate).pipe(take(1)).subscribe({
      next: (res: any) => {
        const idsDuplicateLeaflets = {
          idPredominante: res.response.idPredominante,
          idDuplicado: res.response.idDuplicado,
          idProspecto: res.response.idProspecto,
        }
        this.sharedStateLeadService.setIdsDuplicateProspect(idsDuplicateLeaflets);

        this.route.navigate(['/lead-management/home/duplicate-leaflets']);
        this.closeDialog();
        this.modalService.cerrarModal('modal-simple');
        this.dynamicComponentService.destroyBodyLoading();
      },
      error: (err) => {
        console.log(err);
        this.dynamicComponentService.destroyBodyLoading();
      }
    });

  
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
  
}
