import { Component, Input, OnInit, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { trigger, transition, style, animate } from '@angular/animations';
import { DynamicComponentService, AlertType } from '@shared/components/atomics/body-loading/dynamic-component-service.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-alert',
  templateUrl: './so-dynamic-alert.html',
  styleUrls: ['./so-dinamic-alert.scss'],
  encapsulation: ViewEncapsulation.None,// Asegúrate de que la ruta a tu archivo SCSS sea correcta
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-out', style({ transform: 'translateY(-100%)' }))
      ])
    ])
  ]
})
export class AppAlertComponent implements OnInit {
  @Input() title: string = "";
  @Input() message: string = "";
  @Input() textSuccessButton: string = "Aceptar";
  @Input() textCancelButton: string = "Cancelar";
  @Input() description: string = "listo!";
  @Input() htmlContent: string = "";
  @Input() dynamicComponent: any;
  @Input() alertType?: AlertType;
  @Input() inputRegEx?: RegExp;
  @Input() animate?: boolean = true;
  @Input() autohide?: boolean = true;
  @Input() width?: string = "500px";
  @Input() inputAlert? = false; // Para que salga un campo de texto que se devuelve en la alerta
  @Input() nextButton?: boolean = false; //Para que salga el botón de siguiente en la alerta success
  @Input() showButtons?: boolean = true; // Para Mostrar Los Botones Si Son Necesarios
  @Input() showButtonClose?: boolean = false; // Para Mostrar Boton para cerrar la alerta
  @ViewChild('backdrop') backdrop!: ElementRef;

  inputTextFG: FormGroup | null = null; // FormGroup para el input de texto
  inputTextControl: FormControl | null = null;

  constructor(public dialog: MatDialog, private dynamicComponentService: DynamicComponentService, private fb: FormBuilder) {}

  private prospectExistSubscription: Subscription | undefined;

  ngOnDestroy() {
    if (this.prospectExistSubscription) {
      this.prospectExistSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    if (this.inputAlert) { // Crear el FormGroup para el input de texto
      this.inputTextFG = this.fb.group({
        text: [ '', (!this.inputRegEx ? [ Validators.required ] : [ Validators.required, Validators.pattern(this.inputRegEx) ]) ],
      });
      this.inputTextControl = this.inputTextFG.controls['text'] as FormControl;
    }
  }

  closeAlert({ target }: MouseEvent) {
    if (target == this.backdrop.nativeElement) { // Cierra el diálogo cuando se hace click fuera de la alerta
      this.onConfirm(false);
      this.dynamicComponentService.destroyAlert();
    }
  }

  onConfirm(result: boolean) {
    if (this.inputAlert) {
      this.dynamicComponentService.confirmationSource.next({ result: result, text: this.inputTextFG?.controls['text']?.value ?? '' });
    } else {
      this.dynamicComponentService.confirmationSource.next(result);
    }
  }

  closeAlertButton(){ // Cerrar Alerta por medio de Botoon
    this.dynamicComponentService.destroyAlert()
  }
}
