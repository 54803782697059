import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckboxService {
  private checkboxValueSubject = new Subject<boolean>();
  public checkboxValue$: Observable<boolean> = this.checkboxValueSubject.asObservable();

  private chatValueSubject = new Subject<boolean>();
  public chatboxValue$: Observable<boolean> = this.chatValueSubject.asObservable();

  updateCheckboxValue(value: boolean) {
    this.checkboxValueSubject.next(value);
  }

  updateCloseChat(value: boolean) {
    this.chatValueSubject.next(value);
  }
}
