import { Component, Input, effect, inject } from '@angular/core';
import { Router } from '@angular/router'
import { getState } from '@ngrx/signals';
import { showFilters } from '@shared/components/cellular/so-advance-filters/stores/advance-filters.store'

@Component({
    selector: 'app-so-advance-filters',
    standalone: false,
    templateUrl: './so-advance-filters.component.html',
    styleUrl: './so-advance-filters.component.scss',
})
export class SoAdvanceFiltersComponent {
    @Input() showFilter: boolean = false;
    @Input() iconButton: string = '';
    public title: string | null = '';
    public channelIcon: string | null = '';
    public showTitle: boolean = false;
    iconsProspects = [
        {
            icon: 'mdi-pencil-outline-white',
            visible: true,
            event: 'modalEditarProspecto',
        },
        {
            icon: 'mdi-hand-coin-outline-white',
            visible: true,
            event: 'modalAsignarVenta',
        },
        {
            icon: 'add-circle-outline-white',
            visible: true,
            event: 'sumary-new-follow-up',
        },
        {
            icon: 'mdi-minus-circle-outline-white',
            visible: true,
            event: 'modal-reject-prospect',
        },
        {
            icon: 'mdi-print-white',
            visible: true,
            event: '',
        },
        {
            icon: 'mdi-account-switch-outline',
            visible: true,
            event: '',
        },
        {
            icon: 'mdi-shopping-cart-white',
            visible: true,
            event: 'modal-sale-order',
        },
        {
            icon: 'mdi-file-document-outline-white',
            visible: true,
            event: '',
        },
        {
            icon: 'mdi-car-key-white',
            visible: true,
            event: '',
        },
        {
            icon: 'mdi-car-info',
            visible: true,
            event: 'modal-valuation-request',
        },
        {
            icon: 'mdi-file-plus-outline',
            visible: true,
            event: 'modal-add-file-directory',
        },
    ];
    private readonly showFilters = inject(showFilters);
    constructor(private router: Router){
        effect(() => {
            const state = getState(this.showFilters);
            this.showFilter = state.showFilter
            this.iconButton = state.iconButton
            this.channelIcon = state.icon
            this.showTitle = state.showTitle
        });
    }

    actionMenu() {
        const menu = document.querySelector('.filters') as HTMLElement;
        if (menu) {
            if(this.router.url == '/lead-management/directory'){
                this.showTitle = false
            }
            this.showFilters.changeFilterDisplay({showFilter:!this.showFilter, iconButton:"", icon: this.channelIcon, showTitle: this.showTitle});
        }

    }
}