<div class="content-filters"> 
  <div class="filters">
    <div class="container-form" [formGroup]="formAdvanceFilters">
      @if (showTitle) {
        <div class="filters-header">
          {{'filter_on_channel' | translate}}
          <img src="./assets/img/{{channelIcon}}" style="width: 20px; height: 20px;">
        </div>
      }
      <app-so-accordion [styleAccordion]="styleAccordion">

        <!-- Datos principales -->
        <app-so-accordion-panel panelId="main_information" panelTitle="main_information" svg="mdi-contact-info" [small]="true">
          <app-so-input-form label="{{'key'|translate}} " type="number" name="nombre"></app-so-input-form>
          <app-so-input-form label="{{'name'|translate}} " type="name" name="nombre"></app-so-input-form>
          <app-so-input-form label="{{'last_name'|translate}} " type="name" name="nombre"
            [required]="true"></app-so-input-form>
          <app-so-input-form label="{{'mothers_last_name'|translate}} " type="name" name="nombre"
            [required]="true"></app-so-input-form>
          <app-so-input-form label="{{'company'|translate}} " type="text" name="nombre" [required]="true"></app-so-input-form>
          <app-so-input-form label="{{'email'|translate}} " type="email" name="nombre" [required]="true"></app-so-input-form>
          <so-input-date-reactive-form label="{{'birthdate'|translate}} " type="date" name="nombre"></so-input-date-reactive-form>
        </app-so-accordion-panel>
        <!-- End Datos principales -->
      
        <!-- ============================================================== -->
      
        <!-- Criterios de fecha y otros modificadores -->
        <app-so-accordion-panel panelId="date_criteria_other_modifiers" panelTitle="date_criteria" [small]="true"
          svg="mdi-clipboard-text-clock-outline-primary" formGroupName="formDates" >
          <so-input-date-reactive-form label="{{'start_date'|translate}} " name="nombre" formControlName="startDate"></so-input-date-reactive-form>
          <so-input-date-reactive-form label="{{'end_date'|translate}} " type="date" name="nombre" formControlName="endDate"></so-input-date-reactive-form>
          <so-select-reactive-form selectLabel="{{'date_type'|translate}}" [items]="catalogs.tiposDeFechas" formControlName="idTipoFecha"></so-select-reactive-form>
          <so-select-reactive-form selectLabel="{{'sort_by'|translate}}" [items]="catalogs.tiposDeOrden" formControlName="idTipoOrden"></so-select-reactive-form>
          <so-select-reactive-form selectLabel="{{'show'|translate}}" [items]="catalogs.tiposDeMostrar" formControlName="idTipoMostrar"></so-select-reactive-form>
          <!-- Radios -->
          <div class="content-ratio">
            @for (check of dateChecks; track $index) {
              <so-checkbox-reactive-form 
                name="{{ check.text |translate}}" 
                nameGroup="for_type"  
                formControlName="{{check.id}}" >
                <span class="so-text-3 mb-2" ngClass="{{formAdvanceFilters.value.formDates[check.id] ? 'opacity-100': 'opacity-50'}}">{{ check.text |translate}}</span>
              </so-checkbox-reactive-form>
            }
          </div>
        </app-so-accordion-panel>
        <!-- End Criterios de fecha y otros modificadores -->
      
        <!-- ============================================================== -->
      
        <!-- Distribuidor -->
        <app-so-accordion-panel panelId="distributor" panelTitle="distributor" svg="mdi-car-arrow-left-primary" [small]="true">
          <app-so-select label="{{'survey'|translate}}"></app-so-select>
        </app-so-accordion-panel>
        <!-- End Distribuidor -->
      
        <!-- ============================================================== -->
      
        <!-- Auto -->
        <app-so-accordion-panel panelId="car" panelTitle="car" svg="mdi-car" [small]="true">
          <app-so-select label="{{'car'|translate}}"></app-so-select>
          <app-so-select label="{{'model'|translate}}"></app-so-select>
          <app-so-select label="{{'version'|translate}}"></app-so-select>
          <app-so-select label="{{'car_color'|translate}}"></app-so-select>
        </app-so-accordion-panel>
        <!-- End Auto -->
      
        <!-- ============================================================== -->
      
        <!-- Ciclo de venta -->
        <app-so-accordion-panel panelId="sales_cycle" panelTitle="sales_cycle" svg="mdi-recycle-variant-primary" [small]="true">
          <app-so-select label="{{'status'|translate}}"></app-so-select>
          <app-so-select label="{{'sub_status'|translate}}"></app-so-select>
          <app-so-select label="{{'sale_type'|translate}}"></app-so-select>
          <app-so-select label="{{'payment_method'|translate}}"></app-so-select>
          <app-so-select label="{{'temperature_min'|translate}}"></app-so-select>
          <app-so-select label="{{'temperature_max'|translate}}"></app-so-select>
          <app-so-select label="{{'possibility_of_closure'|translate}}"></app-so-select>
          <app-so-select label="{{'credit_status'|translate}}"></app-so-select>
          <app-so-select label="{{'application_number'|translate}}"></app-so-select>
          <app-so-select label="{{'valuation'|translate}}"></app-so-select>
        </app-so-accordion-panel>
        <!-- End Ciclo de venta -->
      
        <!-- ============================================================== -->
      
        <!-- Facturacion -->
        <app-so-accordion-panel panelId="billing" panelTitle="billing" svg="mdi-file-sign"
          [style]="{'padding':'0!important'}" [small]="true" formGroupName="formBilling"> 
          <so-input-reactive-form label="{{'RFC'|translate}} " type="text" name="nombre" [required]="true" formControlName="rfc"></so-input-reactive-form>
          <so-input-reactive-form label="{{'legal_name'|translate}} " type="text" name="nombre" [required]="true" formControlName="legalName"></so-input-reactive-form>
          <so-select-reactive-form selectLabel="{{'person_type'|translate}}" [items]="catalogs.tiposDePersona" formControlName="idTipoPersona"></so-select-reactive-form>
        </app-so-accordion-panel>
        <!-- End Facturacion -->
      
        <!-- ============================================================== -->
      
        <!-- Segmentacion -->
        <app-so-accordion-panel panelId="segmentation" panelTitle="segmentation" svg="mdi-chart-pie-outline-primary" [small]="true">
          <app-so-select label="{{'business_type'|translate}}"></app-so-select>
          <app-so-select label="{{'type_of_first_contact'|translate}}"></app-so-select>
          <app-so-select label="{{'font'|translate}}"></app-so-select>
          <app-so-select label="{{'subcampaign'|translate}}"></app-so-select>
        </app-so-accordion-panel>
        <!-- End Segmentacion -->
      
        <!-- ============================================================== -->
      
        <!-- Rechazo -->
        <app-so-accordion-panel panelId="rejection" panelTitle="rejection" svg="mdi-minus-circle-outline" [small]="true">
          <so-select-reactive-form selectLabel="{{'reason_for_rejection' | translated}}" [items]="catalogs.motivosRechazos" />
          <so-select-reactive-form selectLabel="{{'rejection_type' | translated}}" [items]="catalogs.tiposDeRechazos" />
          <so-select-reactive-form selectLabel="{{ 'rejected' | translated }}" [items]="catalogs.estadosDeRechazo" />
        </app-so-accordion-panel>
        <!-- End Rechazo -->

        @if (showTitle) {
        <!-- ============================================================== -->
      
        <!-- Mensajes -->
        <app-so-accordion-panel panelId="messages" panelTitle="messages" svg="mdi-message-outline-primary" [small]="true">
          <app-so-select label="{{'messages'|translate}}"></app-so-select>
        </app-so-accordion-panel>
        <!-- End Mensajes -->
      
        <!-- ============================================================== -->

        <!-- color labels -->
        <app-so-accordion-panel panelId="color_labels" panelTitle="color_labels" svg="Grupo-multichannel" [small]="true">
          <app-so-select label="{{'color_labels'|translate}}"></app-so-select>
        </app-so-accordion-panel>
        <!-- color labels -->
        }
        <!-- ============================================================== -->      
      </app-so-accordion>
      <div class="footer-button">
        <app-icon-button class="" size="13px" icon="./assets/img/eraser.svg" text="{{'clear_filters'|translate}}"
          widthIcon="30px" heightIcon="30px" fontWeight="bold" (click)="clearAdvancedFilters()" *ngIf="activeFilterAdvance"></app-icon-button>
    
        <app-icon-button class="" size="13px" icon="./assets/img/mdi-filter-alt-success.svg" text="{{'filter'|translate}}"
        widthIcon="25px" heightIcon="25px" fontWeight="bold" (click)="loadFormData()"></app-icon-button>
      </div>
    </div>
  </div>
</div>

