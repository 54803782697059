<app-so-modal modalId="modal-close-session" [hideButtonClose]="true" verticalPosition="center">
  <div class="modal__body">
    <div class="modal__header">
      <div>
        <app-title
          icon="logout"
          text="{{ 'log_out_tittle' | translate }}"
          weight="bold"
          size="base"
          [iconScale]="1.2"
          [injection]="{
            label: 'font-weight-bold mb-2',
            icon: 'so-danger m-auto'
          }"
        />
      </div>
    </div>

    <div class="modal__body--container">
      <p class="message">{{ 'log_out_message' | translate }}</p>

      <div class="container--button" data-bs-dismiss="modal">
        <app-button
          text="{{ 'cancel' | translate }}"
          [scale]="0.8"
          iconAlignment="right"
          svg="mdi-close-circle-outline"
          [injection]="{ icon: 'margin-top: 1px; margin-left: -9px;' }"
        />

        <app-button
          (clicked)="signOut()"
          [scale]="1.23"
          text="{{ 'confirm' | translate }}"
          iconAlignment="right"
          icon="check_circle_outline"
          [injection]="{ icon: 'margin-top: 6px;' }"
        />
      </div>
    </div>
  </div>
</app-so-modal>