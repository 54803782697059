<div class="container--layout">
  <section class="container-lateral">
    <so-lateral-menu [itemsMenu]="itemsMenu" (clickedItemActions)="showClickItemEvent($event)" />
  </section>
  <section class="container--page">
    <app-so-navbar></app-so-navbar>
    <router-outlet></router-outlet>
  </section>
</div>

<app-so-floating-icon />
<so-close-session />
<app-so-floating-icon />
