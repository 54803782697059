<div>
  <app-so-modal modalId="modal-valuation-request" [verticalPosition]="'center'" size="modal-xl">
    <div class="modal__header" [style]="{'display':'flex','width':'100%','margin-top':'30px'}">
    </div>
    <div class="modal__body" [style]="{'padding':'10px'}">
      <div class="d-flex justify-content-center gap-2">
        <so-icon svg="mdi-car-info-primary" [size]="0.8" />
        <p class="fw-bold ml-2" style="font-size: 19px;">{{'valuation_request'| translate}}</p>
      </div>
      <div class="mt-3">
        <div class="row gap-2 mt-3">
          <div class="col d-flex justify-content-start">
            <img src="./assets/img/mdi-clipboard-account-outline.svg" alt="" style="width: 27px; height: 27px;">
            <p class="fw-bold" style="font-size: 20px; padding-left: 8px;">{{'prospect_data'|translate}}</p>
          </div>
          <div class="col d-flex justify-content-end">
            <label class="lbl">
              <a>{{('inactive'|translate).toUpperCase()}} | {{'valuation_status'|translate}}:</a>
              {{'valuation_request'|translate}}
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <app-so-input-form label="{{'name'|translate}}"></app-so-input-form>
          </div>
          <div class="col">
            <app-so-input-form label="{{'second_name'|translate}}"></app-so-input-form>
          </div>
          <div class="col">
            <app-so-input-form label="{{'key'|translate}}"></app-so-input-form>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <app-so-input-form label="{{'last_name'|translate}}"></app-so-input-form>
          </div>
          <div class="col">
            <app-so-input-form label="{{'mothers_last_name'|translate}}"></app-so-input-form>
          </div>
          <div class="col">
          </div>
        </div>
        <div class="row">
          <div class="col">
            <app-so-text-area label="{{'observations'|translate}}" rows="3"></app-so-text-area>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <div class="row gap-2 mt-3">
          <div class="col d-flex justify-content-start">
            <img src="./assets/img/mdi-clipboard-account-outline.svg" alt="" style="width: 27px; height: 27px;">
            <p class="fw-bold" style="font-size: 20px; padding-left: 8px;">{{'distributor_data'|translate}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <app-so-select label="{{'assignment_type'|translate}}" [options]="options"></app-so-select>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <app-so-text-area label="{{'observations'|translate}}" rows="3"></app-so-text-area>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <div class="row gap-2 mt-3">
          <div class="col d-flex justify-content-start">
            <so-icon svg="mdi-car-info-primary" [size]="0.8" />
            <p class="fw-bold" style="font-size: 20px; padding-left: 8px;">{{'data_of_the_car_to_be_valued'|translate}}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <app-so-select label="{{'current_model'|translate}}" [options]="options"></app-so-select>
          </div>
          <div class="col">
            <app-so-select label="{{'current_brand'|translate}}" [options]="options"></app-so-select>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <app-so-select label="{{'current_car'|translate}}" [options]="options"></app-so-select>
          </div>
          <div class="col">
            <app-so-select label="{{'current_version'|translate}}" [options]="options"></app-so-select>
          </div>
        </div>
      </div>
      <div class="col d-flex mt-5">
        <div class="col d-flex justify-content-start align-items-center">
          <img class="float-start" src="./assets/img/mdi-alert-outline.svg" style="width: 26px; height:30px">
          <label>{{ 'required_fields' | translate }}</label>
        </div>
        <div class="text-center">
          <app-button svg="car-arrow-right" iconAlignment="right" [scale]="1" text="{{ 'send_valuation' | translate }}"
            [injection]="{ label: 'pt-2' }" data-bs-toggle="modal" />
        </div>
      </div>

    </div>
  </app-so-modal>
</div>
