import { Component, EventEmitter, Input, Output} from '@angular/core';
@Component({
  selector: 'app-so-switch',
  templateUrl: './so-switch.component.html',
  styleUrls: ['./so-switch.component.scss']
})
export class SoSwitchComponent {
  @Output() statusChecked = new EventEmitter<boolean>();

  @Input() labels: boolean = false;
  @Input() labelLeft: string = "";
  @Input() labelRight: string = "";
  @Input() isChecked: boolean | number = false;
  @Input() disabled: boolean = false;
  @Input() customStyleOption: string = '';
  @Input() labelLeftStyle: { [key: string]: string } = {};
  @Input() labelRightStyle: { [key: string]: string } = {};
  @Input() divStyle: string = '';
  @Input() size: string = '25';
  @Input() invertLabelsWeight: boolean = false;

  getSwitchClasses() {
    return {
      'form-check-input': true,
      'custom-style-verde': this.customStyleOption === 'verde',
      'custom-style-azul':  this.customStyleOption === 'azul',
    };
  }

  fnCheck(event: any){
    if (event.target.checked == true) {
      this.isChecked = true;
    } else {
      this.isChecked = false;
    }

    this.statusChecked.emit(this.isChecked);
  }
}
