<div class="mt-3 input-group justify-content-center all-Input-Search search-container" ngClass="{{ receivedValue ? 'all-Input-Search-active' : 'all-Input-Search-disabled' }}">

  <div class="me-1" *ngIf="receivedValue">
    <i class="bi bi-chevron-left" style=" font-size: 1.25rem; cursor: pointer; color: var(--so-primary);" (click)="returnMenu()"></i>
  </div>

  <button class="btn btn-outline-secondary btn-sm btnDropdown" type="button" data-bs-toggle="dropdown"
    aria-expanded="false" *ngIf="text === ''; else emptyText">{{'search_prospect' | translate}}
    <i class="fas fa-chevron-down" style="padding-left: 0.3rem; font-size: 13px; "></i>
  </button>

  <ng-template #emptyText>
    <button class="btn btn-outline-secondary btn-sm  btnDropdown" type="button" data-bs-toggle="dropdown"
      aria-expanded="false">{{text}}
      <i class="fas fa-chevron-down" style="padding-left: 0.3rem; font-size: 13px; "></i>
    </button>
  </ng-template>

  <ul class="dropdown-menu">
    <li><a class="dropdown-item">Buscar Marca</a></li>
    <li><a class="dropdown-item">Buscar Auto</a></li>
    <li>
      <hr class="dropdown-divider">
    </li>
    <li><a class="dropdown-item">Buscar por Id Prospecto</a></li>
  </ul>

  <input type="text" class="form-control form-control-sm inputSearch" placeholder="{{ placeholder | translate }}" [ngClass]=" receivedValue ? 'inputSearch-active' : 'inputSearch-disabled'">
  <button class="btn btn-sm btn-outline-secondary btnSearch" type="button" id="button-addon2">
    <mat-icon class="icon-search">search</mat-icon>
  </button>
</div>

<!-- ONLY ICON -->
<div class="mt-2 onlyIcon" style="min-width: 60px;" ngClass="{{ receivedValue ? 'onlyIcon-disabled' : 'onlyIcon-active' }}">
  <button class="btn btn-sm btn-outline-secondary btnSearch-icon" type="button" id="button-addon2" (click)="mostrarTodo()">
    <mat-icon class="icon-search">search</mat-icon>
  </button>
</div>
