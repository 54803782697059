import { Component, OnInit, Input, inject } from '@angular/core';
import { SummaryProspectService } from '@modules/lead-management/submodules/directory/services/summary-prospect.service';
import { PromiseStatus } from '@shared/enums';
import type { ProspectFile, ProspectFilesResponse } from '@modules/lead-management/submodules/directory/interfaces/prospect-files.interface';

@Component({
  selector: 'app-so-attachment-history',
  templateUrl: './so-attachment-history.component.html',
  styleUrl: './so-attachment-history.component.scss'
})
export class SoAttachmentHistoryComponent implements OnInit {
  @Input({ required: true }) idProspecto!: string;
  readonly summaryProspectService = inject(SummaryProspectService);
  prospectFiles: ProspectFile[] = [];
  PromiseStatus = PromiseStatus;
  requestStatus = PromiseStatus.Pending;
  message: string = '';

  ngOnInit() {
    this.summaryProspectService
    .getProspectFiles(this.idProspecto)
    .subscribe({
      next: (data: ProspectFilesResponse) => {
        this.message = data.message;
        this.prospectFiles = data.response.Archivos;
        this.requestStatus = PromiseStatus.Fulfilled;
      },
      error: ({ error }) => {
        this.message = error.message;
        this.requestStatus = PromiseStatus.Rejected;
      },
    });
  }
}
