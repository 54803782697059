import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FirstContactType } from '@core/interfaces/catalogos.intefaces';
import { Request } from '@core/models/requests.model';
import { GlobalSessionService } from '@core/services/global-session.service';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirstContactTypeService {

  public token = localStorage.getItem('Token');
  private url = `ventas/catalogos/segmentacion/tipo-primer-contacto/search`;

  constructor(
    private http: HttpClient,
    private request: Request,
    private globalService: GlobalSessionService
  ) { }

  getAllFirstContactTypes(body:any) {
    const options = { headers: this.request.headers };
    return this.request.Post<FirstContactType>(this.url,body, options)
    .pipe(take(1));
  }

  formatValues(data: any[], keyProp: string, valueProp: string): { key: any; value: any }[] {
    return data.map(item => ({ key: item[keyProp], value: item[valueProp] }));
  }
}
