<div class="accordion-item" [ngClass]="isLastItem ? ' button-radius': ''">
  <h2 class="accordion-header" id="heading{{ panelId }}">
    <button class="accordion-button collapsed text-title" type="button" data-bs-toggle="collapse"
      [attr.data-bs-target]="'#collapse' + panelId" aria-expanded="true" [attr.aria-controls]="'collapse'+panelId" [ngClass]="{'small-component' : small , 'button-radius': isLastItem}">
      <so-icon [icon]="icon" [svg]="svg"
        [ngStyle]="{'transform' : rotateIcon != '' ? ' rotate('+rotateIcon+'deg)' : ''}" 
        [ngStyle]="{'margin-right' : small ? '0.2rem' : '0.5rem'}"
        [applyChange]="true"/>
        <p class="ps-2 mb-0">{{panelTitle | translate }}</p>
      
    </button>
  </h2>
  <div [attr.id]="'collapse' + panelId" class="accordion-collapse collapse" [ngClass]="{ 'show': isOpen }"
    [attr.data-bs-parent]="'#Accordion-'+idAccordion_Referece" [attr.aria-labelledby]="'heading'+panelId">
    <div class="accordion-body">
      <ng-content></ng-content>
    </div>
  </div>
</div>


<!-- <div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item"*ngFor="let item of data; let i = index" >
    <h2 class="accordion-header">
      <button class="accordion-button collapsed text-title" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#Collapse-' + item.title" aria-expanded="false" [attr.aria-controls]="'Collapse-' + item.title">
        <so-icon [name]="item.icon" [size]="0.8" class="mr-2"/>
             {{item.title | translate }}
      </button>
    </h2>
    <div [id]="'Collapse-' + item.title" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        <div [innerHTML]="getHtmlContent(item.htmlElement)"></div>
      </div>
    </div>
  </div>
</div> -->
