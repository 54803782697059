export interface ChatMessagesList {
  blockDates: Date;
  messages: MessagesInfo[];
}

export interface MessagesInfo {
  senderName: string;
  senderType: number;
  received: boolean;
  read: boolean;
  date: Date;
  messageText: string;
  urlAttach?: string;
  mediaType?: string;
};