import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ButtonGroupData } from '@shared/interfaces/button-group-data.interface';

@Component({
  selector: 'app-so-button-group-radio',
  templateUrl: './so-button-group-radio.component.html',
  styleUrls: ['./so-button-group-radio.component.scss']
})
export class SoButtonGroupRadioComponent {
  @Input() id: string = "";
  @Input() alignment: 'start' | 'end' | 'center' = 'start';
  @Input() data: ButtonGroupData[] = [];

  @Output() filterClicked = new EventEmitter<ButtonGroupData>();

  removeSpaces(texto: string): string {
    return texto.replace(/\s+/g, '');
  }

}
