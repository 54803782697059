import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-so-bubble-warning',
  templateUrl: './so-bubble-warning.component.html',
  styleUrls: ['./so-bubble-warning.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(400)),
    ]),
  ],
})
export class SoBubbleWarningComponent {
  @Input() content: string = '';
  @Input() parrafos: string[] = [];
  @Input() icon: string = 'close';
  @Input() title: string = '';
  @Input() visible: boolean = false;
  @Input() width: string = '';

  onClick(): void {
    this.visible = false;
  }

  infoSeguimeinto(): void {
    this.visible = !this.visible;
  }
}
