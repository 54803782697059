import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReloadService {

  private recargarComponenteEmitter: EventEmitter<string> = new EventEmitter<string>();

  // Método para suscribirse a eventos de recarga
  onReloadComponent(componenteKey: string, callback: () => void): void {
    this.recargarComponenteEmitter.subscribe((key: string) => {
      if (key === componenteKey) {
        callback();
      }
    });
  }

  // Método para emitir eventos de recarga
  emitReload(componenteKey: string): void {
    this.recargarComponenteEmitter.emit(componenteKey);
  }
}
