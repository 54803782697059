<div class="container-follow-up {{remarked}} d-flex w-100 h-[40px]" [style]="'border-left: 3px solid '+borderActive+'; ' + exStyle"
  (click)="openTarget($event)">
  <div class="description-follow-up d-flex w-100" style="margin-left: 5px;">
    <div class="step-up d-flex justify-content-between gap-2 align-items-center w-100">

      <div class="d-flex align-items-center gap-3 align-items-center">
        <div class="w-[15%] d-flex align-items-center">
          <input type="radio" (click)="validarStatus($event)" class="center-element" *ngIf="!checked; else noChecked">
          <ng-template #noChecked>
            <mat-icon (click)="validarStatus($event)" class="so-success center-element">check_circle_outline</mat-icon>
          </ng-template>
        </div>
        <p class="w-[65%] {{marked}}" *ngIf="!checked; else noCheckedText" style="margin: 0; height: 100%; font-size: 15px;">{{text}}</p>
        <ng-template #noCheckedText>
          <div class="d-flex flex-column w-100">
            <p class="w-[100%] title-follow-up" style="margin: 0; height: 100%;">{{text}}</p>
            <p class="w-[100%] subtitle-follow-up" style="margin: 0;">{{'date_reschedule'|translate}}: {{date}}</p>
          </div>
        </ng-template>
      </div>

      <div class="d-flex align-items-center h-100">
        <div class="w-[20%] p-1 badge-container">
          <app-so-badge [color]="colorBadge" important class="so-neuter-thick" text="{{ (type == 'Opcional' ? 'optional' : 'mandatory') | translate }}"
            *ngIf="!completed"></app-so-badge>
        </div>
        <div class="expand">

          <mat-icon class="opacity-25" *ngIf="!completed; else noCheckedExpand">{{toggle}}</mat-icon>
          <ng-template #noCheckedExpand>
            <mat-icon class="center-element opacity-25">remove_red_eye</mat-icon>
          </ng-template>
        </div>
      </div>

    </div>
  </div>
</div>
