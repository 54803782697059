
<div class="modal-container">
  <div class="d-flex justify-content-center gap-2">
    <img src="./assets/img/mdi-account-edit.svg" alt="" style="width: 27px; height: 27px;">
    <p class="so-text-1-bold mt-1">{{'edit_personal_info'|translated}}</p>
  </div>
  
  <div class="mt-3">
    <form [formGroup]="miFormularioC" id="form-config">
      <app-so-input-form label="{{'name'|translated}}" type="name" name="nombreC"
        [placeholder]="globalSession.userInfo().data.empleado.nombre" [required]="false" (event)="onChange($event)"
        [formGroup]="miFormularioC"></app-so-input-form>
  
      <app-so-input-form label="{{'second_name'|translated}}" type="name" name="segundoC"
        [placeholder]="globalSession.userInfo().data.empleado.segundo" (event)="onChange($event)"
        [formGroup]="miFormularioC" [required]="false"></app-so-input-form>
  
      <app-so-input-form label="{{'last_name'|translated}}" type="name" name="paternoC" [formGroup]="miFormularioC"
        [placeholder]="globalSession.userInfo().data.empleado.paterno" (event)="onChange($event)"
        [required]="false"></app-so-input-form>
  
      <app-so-input-form label="{{'mothers_last_name'|translated}}" type="name" name="maternoC" [formGroup]="miFormularioC"
        [placeholder]="globalSession.userInfo().data.empleado.materno" (event)="onChange($event)" [required]="false"></app-so-input-form>
  
      <app-so-input-form type="tel" [showVip]="false" [showTrash]="false" label="{{'phone'|translated}}" name="telC"
        [placeholder]="globalSession.userInfo().data.empleado.celular" [formGroup]="miFormularioC" [required]="false"></app-so-input-form>
  
      <so-input-date-reactive-form label="{{'birthday'|translated}}" type="date" [formGroup]="miFormularioC" formControlName="nacimientoC"
        name="nacimientoC" [initDate]="getBirthday()"></so-input-date-reactive-form>
    </form>
    <div class="d-flex justify-content-center">
      <app-button svg="mdi-check-circle-outline" iconAlignment="right" [scale]=".8" text="{{ 'confirm' | translated }}"
        [injection]="{ label: 'pt-2' }" (click)="fnSave($event)"/>
    </div>
  </div>
</div>

