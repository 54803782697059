import { Component, Inject, Input, inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { AddCarInventoryService } from '@core/services/catalogos/addcarinventory.service';

interface rowInventoryData {
  noSerie: string;
  model: string;
  brand: string;
  car: string;
  version: string;
}

@Component({
    selector: 'organism-modal-add-car-inventory',
    templateUrl: './organism-modal-add-car-inventory.component.html',
    styleUrl: './organism-modal-add-car-inventory.component.scss'
})
export class OrganismModalAddCarInventoryComponent {

  private readonly addCarInventoryService = inject(AddCarInventoryService);

  @Input() rows: rowInventoryData[] = [{
    noSerie: '98799300000000000006',
    model: '2019',
    version: '4 PTS LT, L4, 25L, TA, A/AC, PANTALLA TOUCH',
    brand: 'assets/img/avatar-random_path.png',
    car: 'assets/img/avatar-random_path.png',
  },
  {
    noSerie: '98799300000000000006',
    model: '2019',
    version: '4 PTS LT, L4, 25L, TA, A/AC, PANTALLA TOUCH',
    brand: 'assets/img/avatar-random_path.png',
    car: 'assets/img/avatar-random_path.png',
  }];
  optiones: string[] = ['Valor 1', 'Valor2'];
  public headers = signal<string[]>(['no_serie', 'brand', 'car', 'model', 'version']);

  public yearSelected = signal<string>('');
  public brandSelected = signal<string>('');
  public carSelected = signal<string>('');
  public versionSelected = signal<string>('');
  
  public loadingYearly = signal<boolean>(true);
  public dataYearList = signal<{ key: any; value: any }[]>([]);
  public loadingBrand = signal<boolean>(false);
  public dataBrandList = signal<{ key: any; value: any }[]>([]);
  public loadingCarInterest = signal<boolean>(false);
  public dataCarInterestlist = signal<{ key: any; value: any }[]>([]);
  public loadingVersion = signal<boolean>(false);
  public dataVersionList = signal<{ key: any; value: any }[]>([]);
  public loadingColor = signal<boolean>(false);
  public dataColorList = signal<{ key: any; value: any }[]>([]);

  constructor(
    public dialogRef: MatDialogRef<OrganismModalAddCarInventoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.addCarInventoryService.getYearList().subscribe({
      next: (res) => {
        this.loadingYearly.update(() => false);
        this.dataYearList.set(res);
      }
    })
  }

  getBrandList(year: {value: string, event: Event, EventTarget: Event, vip: boolean}) {
    this.loadingBrand.update(() => true);
    this.dataBrandList.set([]);
    this.yearSelected.update(() => year.value);

    this.addCarInventoryService.getBrandList(year.value).subscribe({
      next: (res) => {
        this.loadingBrand.update(() => false);
        this.dataBrandList.set(res);
      }
    })
  }

  getCarList(brand: {value: string, event: Event, EventTarget: Event, vip: boolean}) {
    this.loadingCarInterest.update(() => true);
    this.brandSelected.update(() => brand.value);
    this.dataCarInterestlist.set([]);

    this.addCarInventoryService.getCarList(this.yearSelected(), brand.value).subscribe({
      next: (res) => {
        this.loadingCarInterest.update(() => false);
        this.dataCarInterestlist.set(res);
      }
    })
  }

  getVersionList(car: {value: string, event: Event, EventTarget: Event, vip: boolean}) {
    this.loadingVersion.update(() => true);
    this.carSelected.update(() => car.value);
    this.dataVersionList.set([]);

    this.addCarInventoryService.getVersionList(this.yearSelected(), this.brandSelected(), car.value).subscribe({
      next: (res) => {
        this.loadingVersion.update(() => false);
        this.dataVersionList.set(res);
      }
    })
  }

  getColorList(version: {value: string, event: Event, EventTarget: Event, vip: boolean}) {
    this.loadingColor.update(() => true);
    this.versionSelected.update(() => version.value);
    this.dataColorList.set([]);

    this.addCarInventoryService.getColorList().subscribe({
      next: (res) => {
        this.loadingColor.update(() => false);
        this.dataColorList.set(res);
      }
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
