import { Component, Input, OnInit, booleanAttribute } from '@angular/core';

@Component({
  selector: 'app-so-title-icon',
  templateUrl: './so-title-icon.component.html',
  styleUrls: ['./so-title-icon.component.scss']
})
export class SoTitleIconComponent implements OnInit {

  @Input() logo?: string;
  @Input() title?: string;
  @Input() fontSize?: 'small' | 'normal' | 'medium' | 'large' | 'medium-large' | 'extra-large';
  @Input() textSize?: string;
  @Input() gap?: string = "8px";
  @Input() size?: string;
  @Input() firstIcon?: boolean = true;
  @Input() iconColor?: string = '--color-icon';
  @Input({ transform: booleanAttribute }) applyIconChange: boolean = false;
  @Input() iconReverse?: string;
  @Input() iconSize: number = 1;
  @Input() customStyle: {} = {};
  @Input() onlyPath: boolean = false;
  @Input() iconClass: string = '';

  ngOnInit(): void {

    if(!this.firstIcon){
      this.iconReverse = "flex-row-reverse";
    }

    if (this.fontSize === 'small') {
      this.size = 'fs-6';
    } else if (this.fontSize === 'normal') {
      this.size = 'fs-5';
    } else if (this.fontSize === 'medium') {
      this.size = 'fs-4';
    } else if (this.fontSize === 'large') {
      this.size = 'fs-3';
    } else if (this.fontSize === 'medium-large') {
      this.size = 'fs-2';
    } else if (this.fontSize === 'extra-large') {
      this.size = 'fs-1';
    }
  }




}
