<div class="p-1" [ngClass]="{'d-flex gap-2': display === 'horizontal'}">
    @if( label !== ''){
        <label class="fw-medium p-1" [style.color]="textColor" [style]="labelStyle">{{ label }}<app-so-required-mark [show]="requiredMark" [isValid]="touched && valid" /></label>
    }
    <div class="d-flex align-items-center" [ngClass]="{'w-100': fullWidth}">
        <div class="position-relative d-inline-flex w-100">
            <input class="border border-secondary-subtle w-100 custom-input-reactive"
                [ngClass]="{ bigger }"
                [type]="typeInput" 
                [placeholder]="placeholder" 
                [value]="value"
                [minLength]="minlength"
                [maxLength]="maxlength"
                [disabled]="isDisabled"
                [ngStyle]="customStyle"
                [style]="customBaseStyle"
                [attr.id]="inputId"
                [max]="maxNumber"
                [min]="minNumber"
                (input)="updateValue(getValue($event))"
                [autocomplete]="autocomplete"
                (paste)="onPaste($event)"
                (keypress)="onKeyPress($event)"
            >
            @if(typeInput === 'tel'){
                <span class="phone">
                    <div>
                        <app-so-svg-icon src="./assets/img/mdi-cellphone.svg" color="--color-icon" width="22" height="20"></app-so-svg-icon>
                    </div>  
                    <!-- <img src="./assets/img/mdi-cellphone.svg"> -->
                </span>
            }
            @if(typeInput === 'password' || isPassword){
                <mat-icon
                    class="cursor-pointer position-absolute end-0 top-50 translate-middle-y"
                    style="right: 0.75rem !important;" 
                    [ngClass]="showPassword ? 'text-so-blue': 'text-so-grey'"
                    (click)="changeTypeInput()"
                >
                    {{ icon }}
                </mat-icon>
            }
        </div>
    </div>
    @if(isValid){
        <span class="status">{{statusFieldText}}</span>
    }
</div>