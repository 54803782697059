import { Component } from '@angular/core';

@Component({
  selector: 'app-so-modal-asignar-venta',
  templateUrl: './so-modal-asignar-venta.component.html',
  styleUrls: ['./so-modal-asignar-venta.component.scss']
})
export class SoModalAsignarVentaComponent {

}
