<div>
  <app-so-modal
    modalId="modal-add-file-directory"
    [verticalPosition]="'center'"
  >
    <div
      class="modal__header"
      [style]="{ display: 'flex', width: '100%', 'margin-top': '30px' }"
    ></div>
    <div class="modal__body" [style]="{ padding: '10px' }">
      <div class="d-flex justify-content-center gap-2 modal__body--header">
        <svg
          style="margin: auto 0 auto 0"
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          viewBox="0 0 24 24"
        >
          <path
            fill="#002a7f"
            d="M19.903 8.586a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.952.952 0 0 0-.051-.259c-.01-.032-.019-.063-.033-.093zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"
          />
          <path fill="#002a7f" d="M8 12h8v2H8zm0 4h8v2H8zm0-8h2v2H8z" />
        </svg>

        <p style="font-size: 19px; margin: auto 0 auto 0" class="fw-bold ml-2">
          {{ "attach_files" | translate }}
        </p>
      </div>

      <div class="file--list">
        <ul>
          <li *ngFor="let file of fileList">
            <p class="name">{{ file.name }}</p>
            <p class="size">{{ file.size | fileSize }}</p>
          </li>
        </ul>
      </div>

      <section (click)="onUploadClick()">
        <div>
          <mat-icon>cloud_upload</mat-icon>
          <input
            type="file"
            style="display: none;"
            (input)="onFileSelected($event)"
            #fileInput
          />
        </div>
        <span>{{ "upload_files" | translate }}</span>
      </section>

      <button data-dismiss="modal" data-bs-toggle="modal">
        <app-button
          icon="check_circle_outline"
          iconAlignment="right"
          text="{{ 'confirm' | translate }}"
        />
      </button>
    </div>
  </app-so-modal>
</div>
