<div class="h-100">
    @if (label) {
        <label class="fw-medium"> {{ label }} </label>
    }
    <input type="file" [multiple]="isMultiple" [accept]="accept" (change)="onFileSelected($event)" hidden #fileInputIA id="fileInput">
    <div
      class="file-drop"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)"
    >
        @if (files.length == 0) {
            <p class="message" (click)="fileInputIA.click()">{{'grag_drop_message_first_part' | translated : 'Arrastra y suelta tus archivos aquí'}} {{'or' | translated : 'o'}} <strong class="so-text">{{'grag_drop_message_second_part' | translated : 'haz clic aquí para seleccionar'}}</strong></p>
        }@else{
            <div class="file-list">
                @for (fileRegister of files; track $index) {
                    <div class="file-item">
                        <app-so-checkbox [size]="20" [checked]="fileRegister.markedToSave"  (click)="fileRegister.markedToSave = !fileRegister.markedToSave"></app-so-checkbox>
                        <p>{{fileRegister.file.name}}</p>
                    </div>
                }
            </div>
        }
    </div>
    <div class="button-save">
        @if(files.length > 0){
            <p class="info-message">{{'select_files_to_save_message' | translated : 'Puedes seleccionar los archivos a guardar'}}</p>
            <div class="d-flex justify-content-end " style="gap: 15px;">
                @if(showCleanButton){
                    <app-button
                        icon="cleaning_services"
                        iconAlignment="right"
                        [text]="'clean' | translated : 'Cancelar'"
                        [injection]="{ label: 'pt-2', icon: 'color: var(--so-warning) !important; position: relative; top:3px;' }"
                        [applyChanges]="true"
                        [scale]="1.2"
                        (click)="cleanFiles()"
                        >
                    </app-button>
                }
                @if(showSaveButton){
                    <app-button
                        svg="mdi-check-circle-outline"
                        iconAlignment="right"
                        text="{{'save' | translated : 'Guardar'}}"
                        [injection]="{ label: 'pt-2' }"
                        (click)="saveFiles()">
                    </app-button>
                }
            </div>
        }
    </div>
</div>
