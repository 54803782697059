import { Component } from '@angular/core';
import { SoMatModalComponent } from '@shared/components/atomics/so-mat-modal/so-mat-modal.component';
import { MatIconModule } from '@angular/material/icon';
import { RegisteredProspects } from '@modules/lead-management/submodules/home/interfaces/registered-prospects';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-modal-registered-prospects',
  standalone: true,
  imports: [
    SoMatModalComponent,
    MatIconModule,
    TranslateModule,
    CommonModule
  ],
  templateUrl: 'modal-registered-prospects.component.html',
  styleUrl: 'modal-registered-prospects.component.scss'
})
export class ModalRegisteredProspectsComponent {

  public prospects: RegisteredProspects[] = [
    { id: 1, prospecto: 'María Grisa Hernández', pictureProspecto:'assets/img/avatar-random-3.png', rfc: 'FAKEVIN6ASD236533', ejecutivo: 'Ricardo Aguilar Riera', pictureEjecutivo:'assets/img/avatar-random.png', registro: '2024-04-01T10:00:00', status: 'Compra por crédito/Arrendamiento' },
    { id: 2, prospecto: 'María Grisa Hernández', pictureProspecto:'assets/img/avatar-random-3.png', rfc: 'FAKEVIN6ASD236533', ejecutivo: 'Ricardo Aguilar Riera', pictureEjecutivo:'assets/img/avatar-random.png', registro: '2024-04-01T10:00:00', status: 'Perfilamiento' },
    { id: 3, prospecto: 'María Grisa Hernández', pictureProspecto:'assets/img/avatar-random-3.png', rfc: 'FAKEVIN6ASD236533', ejecutivo: 'Ricardo Aguilar Riera', pictureEjecutivo:'assets/img/avatar-random.png', registro: '2024-04-01T10:00:00', status: 'Primera cita' },
    { id: 4, prospecto: 'María Grisa Hernández', pictureProspecto:'assets/img/avatar-random-3.png', rfc: 'FAKEVIN6ASD236533', ejecutivo: 'Ricardo Aguilar Riera', pictureEjecutivo:'assets/img/avatar-random.png', registro: '2024-04-01T10:00:00', status: 'Perfilamiento' },
    { id: 5, prospecto: 'María Grisa Hernández', pictureProspecto:'assets/img/avatar-random-3.png', rfc: 'FAKEVIN6ASD236533', ejecutivo: 'Ricardo Aguilar Riera', pictureEjecutivo:'assets/img/avatar-random.png', registro: '2024-04-01T10:00:00', status: 'Compra por crédito/Arrendamiento' },
    { id: 6, prospecto: 'María Grisa Hernández', pictureProspecto:'assets/img/avatar-random-3.png', rfc: 'FAKEVIN6ASD236533', ejecutivo: 'Ricardo Aguilar Riera', pictureEjecutivo:'assets/img/avatar-random.png', registro: '2024-04-01T10:00:00', status: 'Primera cita' },
    { id: 7, prospecto: 'María Grisa Hernández', pictureProspecto:'assets/img/avatar-random-3.png', rfc: 'FAKEVIN6ASD236533', ejecutivo: 'Ricardo Aguilar Riera', pictureEjecutivo:'assets/img/avatar-random.png', registro: '2024-04-01T10:00:00', status: 'Compra por crédito/Arrendamiento' },
    { id: 8, prospecto: 'María Grisa Hernández', pictureProspecto:'assets/img/avatar-random-3.png', rfc: 'FAKEVIN6ASD236533', ejecutivo: 'Ricardo Aguilar Riera', pictureEjecutivo:'assets/img/avatar-random.png', registro: '2024-04-01T10:00:00', status: 'Compra por crédito/Arrendamiento' },
    { id: 9, prospecto: 'María Grisa Hernández', pictureProspecto:'assets/img/avatar-random-3.png', rfc: 'FAKEVIN6ASD236533', ejecutivo: 'Ricardo Aguilar Riera', pictureEjecutivo:'assets/img/avatar-random.png', registro: '2024-04-01T10:00:00', status: 'Compra por crédito/Arrendamiento' },
    { id: 10, prospecto: 'María Grisa Hernández', pictureProspecto:'assets/img/avatar-random-3.png', rfc: 'FAKEVIN6ASD236533', ejecutivo: 'Ricardo Aguilar Riera', pictureEjecutivo:'assets/img/avatar-random.png', registro: '2024-04-01T10:00:00', status: 'Perfilamiento' },
    { id: 11, prospecto: 'María Grisa Hernández', pictureProspecto:'assets/img/avatar-random-3.png', rfc: 'FAKEVIN6ASD236533', ejecutivo: 'Ricardo Aguilar Riera', pictureEjecutivo:'assets/img/avatar-random.png', registro: '2024-04-01T10:00:00', status: 'Compra por crédito/Arrendamiento' },
    { id: 12, prospecto: 'María Grisa Hernández', pictureProspecto:'assets/img/avatar-random-3.png', rfc: 'FAKEVIN6ASD236533', ejecutivo: 'Ricardo Aguilar Riera', pictureEjecutivo:'assets/img/avatar-random.png', registro: '2024-04-01T10:00:00', status: 'Primera cita' },
  ];
  

}
