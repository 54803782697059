import {
  Input,
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  inject,
  signal,
  SimpleChanges,
} from '@angular/core';
import { take } from 'rxjs/operators';
import { TiposDeVentaService } from '@core/services/tiposDeVenta/tipos-de-venta.service';
import { TiposDePersonaService } from '@core/services/tiposDePersona/tipos-de-persona.service';
import { TiposDeVenta } from '@core/interfaces/catalogos.intefaces';
import { FuentesService } from '@core/services/Fuentes/fuentes.service';
import { AutosService } from '@core/services/catalogos/autos-de-interes/nuevos/autos.service';
import { AniosService } from '@core/services/catalogos/autos-de-interes/nuevos/anios.service';
import { MarcasService } from '@core/services/catalogos/autos-de-interes/nuevos/marcas.service';
import { VersionesService } from '@core/services/catalogos/autos-de-interes/nuevos/versiones.service';
import { ColoresService } from '@core/services/catalogos/autos-de-interes/nuevos/colores.service.';
import { CampaignService } from '@core/services/catalogos/campaign.service';
import { SimpleLeadService } from '../../services/simple-lead.service';
import { FirstContactTypeService } from './captura-prospecto/firstContactType/first-contact-type.service';
import { ClosureTypesService } from '@core/services/closureType/closure-types.service';
import { MarcasSeminuevosService } from '@core/services/catalogos/autos-de-interes/seminuevos/marcasSeminuevos.service';
import { AniosSeminuevosService } from '@core/services/catalogos/autos-de-interes/seminuevos/aniosSeminuevos.service';
import { AutosSeminuevosService } from '@core/services/catalogos/autos-de-interes/seminuevos/autosSminuevos.service';
import { VersionesSeminuevosService } from '@core/services/catalogos/autos-de-interes/seminuevos/versionesSeminuevos.service';
import { ColoresSeminuevosService } from '@core/services/catalogos/autos-de-interes/seminuevos/coloresSeminuevos.service.';
import { MarcasFlotillasService } from '@core/services/catalogos/autos-de-interes/flotillas/marcas-flotillas.service';
import { AutosFlotillasService } from '@core/services/catalogos/autos-de-interes/flotillas/autos-flotillas.service';
import { AniosFlotillasService } from '@core/services/catalogos/autos-de-interes/flotillas/anios-flotillas.service';
import { VersionesFlotillasService } from '@core/services/catalogos/autos-de-interes/flotillas/versiones-flotillas.service';
import { ColoresFlotillasService } from '@core/services/catalogos/autos-de-interes/flotillas/colores-flotillas.service';
import { Request } from '@core/models/requests.model';
import {
  DynamicComponentService,
  AlertType,
} from '@shared/components/atomics/body-loading/dynamic-component-service.service';
import { ReloadService } from '@core/reload.service';
import { DuplicateLeaflet } from '../../services/duplicate leaflets';
import { FormBuilder, FormArray, FormGroup } from '@angular/forms';
import {
  SimpleLead,
  Producto,
  productoValues,
} from '../../interfaces/simple-lead-interface';
import { GlobalSessionService } from '@core/services/global-session.service';
import { StorageService } from '@core/services/storages.service';
import { TranslateService } from '@ngx-translate/core';

import { OrganismModalAddCarInventoryComponent } from '@shared/components/organism/organism-modal-add-car-inventory/organism-modal-add-car-inventory.component';
import { MatDialog } from '@angular/material/dialog';
import { ReloadDataService } from '@shared/services/observables/reload-data.service';
import { FormStepsService } from '@shared/services/observables/form-steps.service';
import { Subscription } from 'rxjs';
import { SimpleCaptureComponent } from './components/simple-capture/simple-capture.component';
import { LeadCaptureGeneralService } from '../../services/lead-capture-general.service';
import { AlertJoinProspectsComponent } from './components/alert-join-prospects/alert-join-prospects.component';
import { SharedStateLeadService } from '../../services/shared-state-lead.service';

@Component({
  selector: 'app-simple-lead-capture',
  templateUrl: './simple-lead-capture.component.html',
  styleUrls: ['./simple-lead-capture.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleLeadCaptureComponent implements OnInit {
  @Input() businessType: string = '';
  private readonly translateService = inject(TranslateService);
  private readonly request = inject(Request);
  private readonly marcasSeminuevos = inject(MarcasSeminuevosService);
  private readonly aniosSeminuevos = inject(AniosSeminuevosService);
  private readonly autosSeminuevos = inject(AutosSeminuevosService);
  private readonly versionesSeminuevos = inject(VersionesSeminuevosService);
  private readonly coloresSeminuevos = inject(ColoresSeminuevosService);

  private readonly marcasFlotillas = inject(MarcasFlotillasService);
  private readonly autosFlotillas = inject(AutosFlotillasService);
  private readonly aniosFlotillasService = inject(AniosFlotillasService);
  private readonly simpleLeadService = inject(SimpleLeadService);
  private readonly versionesFlotillasService = inject(
    VersionesFlotillasService
  );
  private readonly coloresFlotillasService = inject(ColoresFlotillasService);

  public dataYearList = signal<{ new: any; preOwned: any; fleet: any }>({
    new: [],
    preOwned: [],
    fleet: [],
  });
  public dataVersionList = signal<{ new: any; preOwned: any; fleet: any }>({
    new: [],
    preOwned: [],
    fleet: [],
  });
  public dataColorList = signal<{ new: any; preOwned: any; fleet: any }>({
    new: [],
    preOwned: [],
    fleet: [],
  });
  public carOfInterestClosure = signal<{
    new: string;
    preOwned: string;
    fleet: string;
  }>({
    new: '',
    preOwned: '',
    fleet: '',
  });
  public yearClosure = signal<{ new: string; preOwned: string; fleet: string }>(
    {
      new: '',
      preOwned: '',
      fleet: '',
    }
  );
  public versionClosure = signal<{
    new: string;
    preOwned: string;
    fleet: string;
  }>({
    new: '',
    preOwned: '',
    fleet: '',
  });
  public colorClosure = signal<{ new: any; preOwned: string; fleet: string }>({
    new: '',
    preOwned: '',
    fleet: '',
  });

  isCheckedValue: boolean = false;
  importantLead: boolean = false;
  fonts?: any;
  typeOfSale?: any;
  typeOfPerson?: any;
  tiposdeVenta?: TiposDeVenta[];
  firstContactType?: any;
  closuretypes?: any;

  users?: any;
  private capture: SimpleLead = {
    idEjecutivo: '',
    observaciones: '',
    prospecto: {
      nombre: '',
      segundoNombre: '',
      materno: '',
      paterno: '',
      duplicado: false,
      cp: 0,
      importante: false,
      correos: [],
      telefonos: [],
    },
    segmentacion: {
      idTipoDePrimerContacto: '0000000000',
      idFuente: '0000000000',
      idCampania: '0000000000',
      idTipoDeCierre: '0000000000',
    },
    productoDeInteres: {},
    configuracion: {
      basesDeDatos: {
        dbEmpresa: '',
      },
      idioma: {
        idIdioma: 'ES',
        idPais: 'MX',
      },
    },
  };

  public autos: any;
  public anios: any;
  public versiones: any;
  public colores: any;
  public campaigns: any;
  public marcas: any;
  public idAuto?: string = '';
  public auto?: string = '';
  public modelo?: string = '';
  public idUsuario?: string = '';
  public idProducto?: string = '';
  public producto?: string = '';
  public idColor?: string = '';
  public idColorSemi?: string = '';
  public idModeloSemi?: string = '';
  public idMarcaSemi?: string = '';
  public idAutoSemi?: string = '';
  public idProductoSemi?: string = '';
  public modeloSemi?: string = '';
  public marcaSemi?: string = '';
  public productoSemi?: string = '';
  public autoSemi?: string = '';
  public color?: string = '';
  public validForm?: boolean = false;
  public loadTypeFirstContact?: any = true;
  public loadClosure?: any = true;
  public aniosSemi?: any = [];
  public autosSemi?: any = [];
  public marcasSemi?: any = [];
  public productosSemi?: any = [];
  public coloresSemi?: any = [];
  public colorSemi?: any = '';
  public storageData?: any = [];
  public tiposDeNegocio?: any = [];
  public isAdmin: boolean = false;
  public marcasFlotillasData?: any = [];
  public autosFlotillasData?: any = [];
  public aniosFlotillasData?: any = [];
  public versionesFlotillasData?: any = [];
  public coloresFlotillasData?: any = [];
  public marcaFlotilla?: string = '';
  public idMarcaFlotilla?: string = '';
  public autoFlotilla?: string = '';
  public idAutoFlotilla?: string = '';
  public anioFlotilla?: string = '';
  public idAnioFlotilla?: string = '';
  public versionFlotilla?: string = '';
  public idVersionFlotilla?: string = '';
  public colorFlotilla?: string = '';
  public idColorFlotilla?: string = '';
  public cantidadFlotilla?: string = '';
  public idCantidadFlotilla?: string = '';
  public productosInteres: productoValues = {
    nuevos: [],
    seminuevos: [],
    flotilla: [],
  };

  isDetailCapture: boolean = false;
  isStep2: boolean = false;
  userInfo = this.globalSessionService.userInfo().data;
  isMultimarca = this.userInfo.empleado.empresa.habilitarMultimarca;

  private subscription$?: Subscription;
  public formLeadCapture!: FormGroup;
  constructor(
    private TiposDeVentaService: TiposDeVentaService,
    private TiposDePersonaService: TiposDePersonaService,
    private FuentesService: FuentesService,
    private AutosService: AutosService,
    private AniosService: AniosService,
    private VersionesService: VersionesService,
    private ColoresService: ColoresService,
    private campaignService: CampaignService,
    private dynamicComponentService: DynamicComponentService,
    private duplicateLeaflet: DuplicateLeaflet,
    private FirstContactTypeService: FirstContactTypeService,
    private ClosureTypesService: ClosureTypesService,
    private cdRef: ChangeDetectorRef,
    private marcasService: MarcasService,
    private globalSessionService: GlobalSessionService,
    private reloadService: ReloadService,
    private StorageService: StorageService,
    private elementRef: ElementRef,
    public dialog: MatDialog,
    private reloadDataService: ReloadDataService,
    private formStepsServices: FormStepsService,
    private leadCaptureGeneralService: LeadCaptureGeneralService,
    private formBuilder: FormBuilder,
    private sharedStateLead: SharedStateLeadService,
  ) {}

  ngAfterViewInit() {
    this.loadCatalogos();
    this.activeOptionalFields();
    this.activeCaptureType();
  }

  ngOnInit(): void {
    this.storageData = this.StorageService.getLocalStorage('userInfo').data;
    this.tiposDeNegocio = this.storageData.tiposDeNegocio;

    this.loadTypeFirtsContact();
    this.loadClosureTypes();

    if (this.storageData.perfiles[0].titulo == 'Administrador') {
      this.isAdmin = true;
    }
    this.subscription$ = new Subscription();
    this.subscribeToObservables();
    this.getSegmentacionValues();
    this.loadFormLeadCapture();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['businessType']) {
      this.activeCaptureType();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }
  }

  optiones: string[] = ['Valor 1', 'Valor2'];
  quantity: any = [
    { value: '1', quantity: '1' },
    { value: '2', quantity: '2' },
    { value: '3', quantity: '3' },
    { value: '4', quantity: '4' },
    { value: '5', quantity: '5' },
    { value: '6', quantity: '6' },
    { value: '7', quantity: '7' },
    { value: '8', quantity: '8' },
    { value: '9', quantity: '9' },
    { value: '10', quantity: '10' },
  ];
  telefonos: any = [
    { value: 'Celular', celular: 'Celular' },
    { value: 'Oficina', oficina: 'Oficina' },
    { value: 'Casa', casa: 'Casa' },
  ];
  emails: any = [
    { value: 'Personal', personal: 'Personal' },
    { value: 'Trabajo', trabajo: 'Trabajo' },
    { value: 'Oficina', trabajo: 'Oficina' },
  ];

  checkEvent(event: any) {
    if (event.target.checked == true) {
      this.isCheckedValue = true;
    } else {
      this.isCheckedValue = false;
    }
  }

  showEmail: boolean = false;
  showEmailFn(event: any) {
    this.showEmail = event.target.checked;
    this.assignValidControl('email');
    this.assignValidControl('email_2');
  }

  showBtn: boolean = false;
  onCheckboxChange(event: any) {
    this.showBtn = event.element.checked;
  }

  showFlotilla: boolean = false;
  fnShowFlotilla(event: any) {
    this.showFlotilla = true;
  }

  fnHideFlotilla(event: any) {
    this.showFlotilla = false;
  }

  nextStep() {
    this.formStepsServices.setStep2(true);
  }

  private subscribeToObservables() {
    if (this.subscription$) {
      this.subscription$.add(
        this.formStepsServices.isStep2$.subscribe((res) => {
          this.isStep2 = res;
        })
      );

      this.subscription$.add(
        this.formStepsServices.isDetailCapture$.subscribe((res) => {
          this.isDetailCapture = res;
        })
      );
    }
  }

  fnisStep2(event: any) {
    this.obtenerControlesNoValidos();
    if (this.validFieldsRequired() > 0) {
      const errorTitle = this.translateService.instant('error');
      const messageError = this.translateService.instant(
        'missing_required_fields'
      );
      this.dynamicComponentService.showAlert({
        title: errorTitle,
        message: messageError,
        htmlContent: '',
        alertType: AlertType.error,
        autohide: true,
        animate: true,
      });
    } else {
      if (this.isStep2) {
        if (!this.divSemiNuevos && !this.divFlotilla && !this.divNuevos) {
          this.showEmptyProductAlert();
        }
        if (this.divNuevos) {
          this.capture.productoDeInteres.nuevos = { productos: this.addValues('nuevos', 'values') };          
          const valuacionNuevos = this.formLeadCapture.get('valuacionNuevos')?.value;
          if (!this.isObjectEmpty(valuacionNuevos)) {
            this.capture.productoDeInteres.nuevos.valuacion = this.formLeadCapture.get('valuacionNuevos')?.value;
          }
          this.productosInteres.nuevos = this.addValues('nuevos', 'names');
        }
        if (this.divSemiNuevos) {
          const valuacionSemi = this.formLeadCapture.get('valuacionSemi')?.value;
          this.capture.productoDeInteres.seminuevos = {productos: this.addValues('seminuevos', 'values')};
          if (!this.isObjectEmpty(valuacionSemi)) {
            this.capture.productoDeInteres.seminuevos.valuacion = this.formLeadCapture.get('valuacionSemi')?.value;
          }
          this.productosInteres.seminuevos = this.addValues('seminuevos','names');
        }
        if (this.divFlotilla) {
          const valuacionFlotilla = this.formLeadCapture.get('valuacionFlotilla')?.value;
          this.capture.productoDeInteres.flotilla = {productos: this.addValues('flotilla', 'values')};
          if (!this.isObjectEmpty(valuacionFlotilla)) {
            this.capture.productoDeInteres.flotilla.valuacion = this.formLeadCapture.get('valuacionFlotilla')?.value;
          }
          this.productosInteres.flotilla = this.addValues('flotilla', 'names');
        }
        this.createLeadAlert();
      } else {
        this.validForm = this.isFormValid();
      }
      this.isStep2 = true;
      this.nextStep();
    }
  }

  isObjectEmpty(obj: any): boolean {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (
          value === null ||
          value === undefined ||
          value === '' ||
          (typeof value === 'number' && isNaN(value))
        ) {
          return true;
        }
      }
    }
    return false;
  }

  createLeadAlert() {
    this.validForm = this.isFormValid();

    const title = this.translateService.instant(
      'you_are_going_to_create_and_assign_the_following_products_to'
    );
    const sureTitle = this.translateService.instant('are_you_sure');
    const nuevos = this.translateService.instant('new');
    const seminuevos = this.translateService.instant('pre_owned');
    const flotilla = this.translateService.instant('existing_fleet');
    const seguros = this.translateService.instant('insurances');
    const financieros = this.translateService.instant('financial');
    const postventa = this.translateService.instant('after_sale');

    let autos = '',
    semi = '',
    flotillas = '',
    htmlcontent = ` <p>${title} <b>${this.simpleCaptureComponent?.formSimple.value.nombre} ${this.simpleCaptureComponent?.formSimple.value.paterno}</b>:<br>`;

    if (this.divNuevos) {
      this.productosInteres?.nuevos.forEach((e) => {
        autos += `${e.idAuto} | ${e.modelo} | ${e.idProducto} | ${e.idColor}<br>`;
      });
      htmlcontent += `<div class='descript-text'><b>${nuevos}</b>:<p>${autos}</p></div>`;
    }
    if (this.divSemiNuevos) {
      this.productosInteres?.seminuevos.forEach((e) => {
        semi += `${e.idAuto} | ${e.modelo} | ${e.idProducto} | ${e.idColor}<br>`;
      });
      htmlcontent += `<div class='descript-text'><b>${seminuevos}</b>:<p>${semi}</p></div>`;
    }
    if (this.divFlotilla) {
      this.productosInteres?.flotilla.forEach((e) => {
        flotillas += `${e.idAuto} | ${e.modelo} | ${e.idProducto} | ${e.idColor}<br>`;
      });
      htmlcontent += `<div class='descript-text'><b>${flotilla}</b>:<p>${flotillas}</p></div>`;
    }
    htmlcontent += ` </div>`;

    this.dynamicComponentService
      .showAlert({
        title: sureTitle,
        htmlContent: htmlcontent,
        alertType: AlertType.warning,
        autohide: false,
        animate: false,
        width: '750px',
      })
      .pipe(take(1))
      .subscribe((response) => {
        if (response) {
          this.dynamicComponentService.destroyAlert();
          this.enviarDatos();
        } else {
          this.dynamicComponentService.destroyAlert();
        }
      });
  }

  fnBack(event: any) {
    this.isStep2 = false;
    this.validForm = this.isFormValid();
  }

  nuevosRows: number[] = [1];
  addNuevos() {
    const newIndex = this.nuevosRows.length + 1;
    this.nuevosRows.push(this.nuevosRows.length + 1);
  }

  removeNuevos(index: number) {
    this.nuevosRows.splice(index, 1);
  }

  valuationNews: boolean = false;
  toggleValuationNews(event: any) {
    if (event.target.checked == true) {
      this.valuationNews = true;
      this.loadValuators();
    } else {
      this.valuationNews = false;
    }
  }

  valuationSemi: boolean = false;
  toggleValuationSemi(event: any) {
    if (event.target.checked == true) {
      this.valuationSemi = true;
      this.loadValuators();
    } else {
      this.valuationSemi = false;
    }
  }

  valuationFlotilla: boolean = false;
  toggleValuationFlotilla(event: any) {
    if (event.target.checked == true) {
      this.valuationFlotilla = true;
      this.loadValuators();
    } else {
      this.valuationFlotilla = false;
    }
  }

  divNuevos: boolean = false;
  showNuevos() {
    this.divNuevos = !this.divNuevos;
    if (this.notEmptyProductValidation()) {
      this.divNuevos = true;
      this.showEmptyProductAlert();
    }
  }

  divSemiNuevos: boolean = false;
  showdivSemiNuevos() {
    this.divSemiNuevos = !this.divSemiNuevos;
    if (this.notEmptyProductValidation()) {
      this.divSemiNuevos = true;
      this.showEmptyProductAlert();
    }
  }
  SemiNuevosRows: number[] = [1];
  addSemiNuevos() {
    this.SemiNuevosRows.push(this.SemiNuevosRows.length + 1);
  }
  removeSemiNuevos(index: number) {
    this.SemiNuevosRows.splice(index, 1);
  }

  divFlotilla: boolean = false;
  showdivFlotilla() {
    this.divFlotilla = !this.divFlotilla;
    if (this.notEmptyProductValidation()) {
      this.divFlotilla = true;
      this.showEmptyProductAlert();
    }
  }
  flotillaRows: number[] = [1];
  addFlotilla() {
    this.flotillaRows.push(this.flotillaRows.length + 1);
  }
  removeFlotilla(index: number) {
    this.flotillaRows.splice(index, 1);
  }

  divFlotillaSimple: boolean = false;
  showdivFlotillaSimple() {
    this.divFlotillaSimple = !this.divFlotillaSimple;
  }
  flotillaSimpleRows: number[] = [1];
  addFlotillaSimple() {
    this.flotillaSimpleRows.push(this.flotillaSimpleRows.length + 1);
  }
  removeFlotillaSimple(index: number) {
    this.flotillaSimpleRows.splice(index, 1);
  }

  divFlotillaDetallada: boolean = false;
  showdivFlotillaDetallada() {
    this.divFlotillaDetallada = !this.divFlotillaDetallada;
  }
  flotillaDetalladaRows: number[] = [1];
  addFlotillaDetallada() {
    this.flotillaDetalladaRows.push(this.flotillaDetalladaRows.length + 1);
  }
  removeFlotillaDetallada(index: number) {
    this.flotillaDetalladaRows.splice(index, 1);
  }

  divSeguro: boolean = false;
  showdivSeguro() {
    this.divSeguro = !this.divSeguro;
  }
  seguroRows: number[] = [1];
  addSeguro() {
    this.seguroRows.push(this.seguroRows.length + 1);
  }
  removeSeguro(index: number) {
    this.seguroRows.splice(index, 1);
  }

  divFinanciero: boolean = false;
  showdivFinanciero() {
    this.divFinanciero = !this.divFinanciero;
  }
  FinancieroRows: number[] = [1];
  addFinanciero() {
    this.FinancieroRows.push(this.FinancieroRows.length + 1);
  }
  removeFinanciero(index: number) {
    this.FinancieroRows.splice(index, 1);
  }

  divPostVenta: boolean = false;
  showdivPostVenta() {
    this.divPostVenta = !this.divPostVenta;
  }
  posVentaRows: number[] = [1];
  addPosVenta() {
    this.posVentaRows.push(this.posVentaRows.length + 1);
  }
  removePosVenta(index: number) {
    this.posVentaRows.splice(index, 1);
  }

  onSelectEvaluateNew(data: any) {
    this.carOfInterestClosure().new = data;

    this.AniosService.getAnios({
      configuracion: this.request.getConfigUser(),
      idAuto: this.carOfInterestClosure().new,
      idMarca: '153',
      idTipoDeNegocio: 'TN00000001',
    }).subscribe((rta) => {
      this.dataYearList().new = this.AniosService.formatValues(
        rta.response,
        'anio',
        'anio'
      );
    });
  }

  onSelectEvaluatePreOwned(data: any) {
    this.carOfInterestClosure().preOwned = data;

    this.AniosService.getAnios({
      configuracion: this.request.getConfigUser(),
      idAuto: this.carOfInterestClosure().preOwned,
      idMarca: '153',
      idTipoDeNegocio: 'TN00000001',
    }).subscribe((rta) => {
      this.dataYearList().preOwned = this.AniosService.formatValues(
        rta.response,
        'anio',
        'anio'
      );
    });
  }

  onSelectEvaluatePreOwnedYear(data: any) {
    this.yearClosure().preOwned = data;

    this.VersionesService.getVersiones({
      configuracion: this.request.getConfigUser(),
      idAuto: this.carOfInterestClosure().preOwned,
      anio: this.yearClosure().preOwned,
      idMarca: '153',
      idTipoDeNegocio: 'TN00000001',
    }).subscribe((rta) => {
      this.dataVersionList().preOwned = this.VersionesService.formatValues(
        rta.response,
        'idProducto',
        'nombre'
      );
    });
  }

  onSelectEvaluateNewYear(data: any) {
    this.yearClosure().new = data;

    this.VersionesService.getVersiones({
      configuracion: this.request.getConfigUser(),
      idAuto: this.carOfInterestClosure().new,
      anio: this.yearClosure().new,
      idMarca: '153',
      idTipoDeNegocio: 'TN00000001',
    }).subscribe((rta) => {
      this.dataVersionList().new = this.VersionesService.formatValues(
        rta.response,
        'idProducto',
        'nombre'
      );
    });
  }

  onSelectEvaluateNewColor(data: any) {
    this.colorClosure().new = data;

    this.ColoresService.getAllColores({
      configuracion: this.request.getConfigUser(),
    }).subscribe((rta) => {
      this.dataColorList().new = this.ColoresService.formatValues(
        rta.response,
        'idColor',
        'nombre',
        'color'
      );
    });
  }

  onSelectEvaluatePreOwnedColor(data: any) {
    this.colorClosure().preOwned = data;

    this.ColoresService.getAllColores({
      configuracion: this.request.getConfigUser(),
    }).subscribe((rta) => {
      this.dataColorList().preOwned = this.ColoresService.formatValues(
        rta.response,
        'idColor',
        'nombre',
        'color'
      );
    });
  }

  selectedItemValue = '';
  onSelect(data: any) {
    this.buildDataCapture(data);
    switch (data.event.target.id) {
      case 'idMarca':
        this.AutosService.getAllAutos({
          configuracion: this.request.getConfigUser(),
          idMarca: data.value,
          idTipoDeNegocio: this.userInfo.tiposDeNegocio[0].idTipoDeNegocio,
        }).subscribe((data) => {
          this.autos = this.AutosService.formatValues(
            data.response,
            'idauto',
            'nombre'
          );
        });
        this.autos = [];
        this.versiones = [];
        this.anios = [];
        this.colores = [];
        this.selectedItemValue = '';
        break;
      case 'idAuto':
        this.AniosService.getAnios({
          configuracion: this.request.getConfigUser(),
          idAuto: data.value,
          idMarca: '153',
          idTipoDeNegocio: 'TN00000001',
        }).subscribe((rta) => {
          this.anios = this.AniosService.formatValues(
            rta.response,
            'anio',
            'anio'
          );
        });
        this.versiones = [];
        this.anios = [];
        this.colores = [];
        this.selectedItemValue = '';
        break;
      case 'modelo':
        this.VersionesService.getVersiones({
          configuracion: this.request.getConfigUser(),
          idAuto: this.idAuto,
          anio: this.modelo,
          idMarca: '153',
          idTipoDeNegocio: 'TN00000001',
        }).subscribe((rta) => {
          this.versiones = this.VersionesService.formatValues(
            rta.response,
            'idProducto',
            'nombre'
          );
        });
        this.versiones = [];
        this.colores = [];
        this.selectedItemValue = '';
        break;
      case 'idProducto':
        this.ColoresService.getAllColores({
          configuracion: this.request.getConfigUser(),
        }).subscribe((rta) => {
          this.colores = this.ColoresService.formatValues(
            rta.response,
            'idColor',
            'nombre',
            'color'
          );
        });
        this.selectedItemValue = '';
        break;
      case 'type_of_first_contact':
        this.fonts = [];
        this.FuentesService?.getFuentes({
          configuracion: this.request.getConfigUser(),
          idPrimerContacto: data.value,
        }).subscribe((data: any) => {
          this.fonts = this.FuentesService.formatValues(
            data.response,
            'idfuente',
            'nombre'
          );
        });
        break;
      case 'fuentes_simple_lead':
        this.campaignService
          .getCampaign({
            configuracion: this.request.getConfigUser(),
            idFuente: data.value,
          })
          .subscribe((data) => {
            this.campaigns = this.campaignService.formatValues(
              data.response,
              'idCampana',
              'nombre'
            );
          });
        break;
      // Seminuevos
      case 'idModeloSemi':
        this.marcasSeminuevos
          .getMarcas({
            configuracion: this.request.getConfigUser(),
            anio: data.value,
          })
          .subscribe((data) => {
            this.marcasSemi = this.marcasSeminuevos.formatValues(
              data.response,
              'idmarca',
              'alias'
            );
          });
        this.marcasSemi = [];
        this.autosSemi = [];
        this.productosSemi = [];
        this.coloresSemi = [];
        this.cdRef.detectChanges();
        break;
      case 'idMarcaSemi':
        this.autosSeminuevos
          .getAutos({
            configuracion: this.request.getConfigUser(),
            idMarca: data.value,
            anio: this.modeloSemi,
          })
          .subscribe((data) => {
            this.autosSemi = this.autosSeminuevos.formatValues(
              data.response,
              'idauto',
              'nombre'
            );
          });
        this.autosSemi = [];
        this.productosSemi = [];
        this.coloresSemi = [];
        this.cdRef.detectChanges();
        break;
      case 'idAutoSemi':
        this.versionesSeminuevos
          .getVersiones({
            configuracion: this.request.getConfigUser(),
            idMarca: this.idMarcaSemi,
            anio: this.modeloSemi,
            idAuto: this.idAutoSemi,
          })
          .subscribe((data) => {
            this.productosSemi = this.versionesSeminuevos.formatValues(
              data.response,
              'idproducto',
              'nombre'
            );
          });
        this.productosSemi = [];
        this.coloresSemi = [];
        this.cdRef.detectChanges();
        break;
      case 'idProductoSemi':
        this.coloresSeminuevos
          .getColores({
            configuracion: this.request.getConfigUser(),
          })
          .subscribe((data) => {
            this.coloresSemi = this.coloresSeminuevos.formatValues(
              data.response,
              'idColor',
              'nombre',
              'color'
            );
          });
        this.coloresSemi = [];
        this.cdRef.detectChanges();
        break;
      // Flotillas
      case 'idMarcaFlotilla':
        this.idMarcaFlotilla = data.value;
        this.autosFlotillas
          .getAutos({
            configuracion: this.request.getConfigUser(),
            idMarca: data.value,
          })
          .subscribe((data) => {
            this.autosFlotillasData = this.marcasSeminuevos.formatValues(
              data.response,
              'idauto',
              'nombre'
            );
          });
        this.aniosFlotillasData = [];
        this.versionesFlotillasData = [];
        this.coloresFlotillasData = [];
        this.cdRef.detectChanges();
        break;
      case 'idAutoFlotilla':
        this.aniosFlotillasService
          .getAniosFlotillas({
            configuracion: this.request.getConfigUser(),
            idAuto: this.idAutoFlotilla,
            idMarca: this.idMarcaFlotilla,
          })
          .subscribe((data) => {
            this.aniosFlotillasData = this.marcasSeminuevos.formatValues(
              data.response,
              'anio',
              'anio'
            );
          });
        this.versionesFlotillasData = [];
        this.coloresFlotillasData = [];
        this.cdRef.detectChanges();
        break;
      case 'modeloFlotilla':
        this.versionesFlotillasService
          .getVersionesFlotillas({
            configuracion: this.request.getConfigUser(),
            idAuto: this.idAutoFlotilla,
            anio: data.value,
            idMarca: this.idMarcaFlotilla,
          })
          .subscribe((data) => {
            this.versionesFlotillasData = this.marcasSeminuevos.formatValues(
              data.response,
              'idProducto',
              'nombre'
            );
          });
        this.coloresFlotillasData = [];
        this.cdRef.detectChanges();
        break;
      case 'idProductoFlotilla':
        this.coloresFlotillasService
          .getAllColoresFlotillas({
            configuracion: this.request.getConfigUser(),
          })
          .subscribe((data) => {
            console.log(data);

            this.coloresFlotillasData = this.ColoresService.formatValues(
              data.response,
              'idColor',
              'nombre',
              'color'
            );
          });
        this.cdRef.detectChanges();
        break;
    }
  }

  loadCatalogos() {
    if (this.isMultimarca) {
      this.marcasService
        .getMarcas({
          configuracion: this.request.getConfigUser(),
          idEmpresa: this.userInfo.empleado.empresa.idEmpresa,
          idTipoDeNegocio: this.userInfo.tiposDeNegocio[0].idTipoDeNegocio,
        })
        .subscribe((data) => {
          this.marcas = this.marcasService.formatValues(
            data.response,
            'idMarca',
            'alias'
          );
          this.cdRef.detectChanges();
        });
    } else {
      this.AutosService.getAllAutos({
        configuracion: this.request.getConfigUser(),
        idMarca: '153',
        idTipoDeNegocio: this.userInfo.tiposDeNegocio[0].idTipoDeNegocio,
      }).subscribe((data) => {
        this.autos = this.AutosService.formatValues(
          data.response,
          'idauto',
          'nombre'
        );
        this.cdRef.detectChanges();
      });
    }
    this.TiposDeVentaService?.getAllTiposDeVenta().subscribe((rta: any) => {
      if (typeof rta.response === 'object') {
        const valores = rta.response['Tipos De Venta'] as any[];
        this.typeOfSale = valores;
      }
    });
    this.TiposDePersonaService?.getAllTiposDePersona().subscribe((rta: any) => {
      if (typeof rta.response === 'object') {
        const valores = rta.response['Tipos De Persona'] as any[];
        this.typeOfPerson = valores;
      }
    });

    this.aniosSeminuevos
      ?.getAnios({
        configuracion: this.request.getConfigUser(),
      })
      .subscribe({
        next: (data) => {
          this.aniosSemi = this.aniosSeminuevos.formatValues(
            data.response,
            'anio',
            'anio'
          );
          this.cdRef.detectChanges();
        },
        error: (e) => {
          this.aniosSemi = this.aniosSeminuevos.formatValues(
            [{ anio: 2019, idAuto: '2019' }],
            // [{ anio: 1, idAuto: e.error.message }],
            'anio',
            'idAuto'
          );
          this.cdRef.detectChanges();
        },
      });

    this.marcasFlotillas
      ?.getMarcasFlotillas({
        configuracion: this.request.getConfigUser(),
        idEmpresa: this.userInfo.empleado.empresa.idEmpresa,
      })
      .subscribe((data) => {
        this.marcasFlotillasData = this.marcasFlotillas.formatValues(
          data.response,
          'idmarca',
          'alias'
        );
        this.cdRef.detectChanges();
      });
  }

  loadClosureTypes() {
    this.ClosureTypesService?.getAllClosureTypes({
      configuracion: this.request.getConfigUser(),
    }).subscribe((rta: any) => {
      if (typeof rta.response === 'object') {
        this.loadClosure = false;
        this.closuretypes = this.ClosureTypesService.formatValues(
          rta.response,
          'idtipodecierre',
          'nombre'
        );
        this.cdRef.detectChanges();
      }
    });
  }

  loadTypeFirtsContact() {
    this.FirstContactTypeService?.getAllFirstContactTypes({
      configuracion: this.request.getConfigUser(),
    }).subscribe((rta: any) => {
      if (typeof rta.response === 'object') {
        this.loadTypeFirstContact = false;
        this.firstContactType = this.FirstContactTypeService.formatValues(
          rta.response,
          'idtipoprimercontacto',
          'nombre'
        );
        this.cdRef.detectChanges();
      }
    });
  }

  buildDataCapture(data: any) {
    switch (data.event.target.id) {
      case 'idAuto':
        const idAuto = data.value;        
        this.auto = this.getSelectedName(data.event);
        this.idAuto = idAuto;
        // if (this.capture.productoDeInteres?.nuevos) {
        //   this.capture.productoDeInteres.nuevos[0].productos[0].idAuto = idAuto;
        // }
        break;
      case 'modelo':
        const modelo = data.value;
        this.modelo = modelo;
        // if (this.capture.productoDeInteres?.nuevos) {
        //   this.capture.productoDeInteres.nuevos[0].productos[0].modelo = modelo;
        // }
        break;
      case 'idProducto':
        const producto = data.value;
        this.producto = this.getSelectedName(data.event);
        this.idProducto = producto;

        // if (this.capture.productoDeInteres?.nuevos) {
        //   this.capture.productoDeInteres.nuevos[0].productos[0].idProducto =
        //     producto;
        // }
        break;
      case 'idColor':
        const color = this.selectedItemValue;
        this.color = this.getSelectedName(data.event);
        this.idColor = color;
        // if (this.capture.productoDeInteres?.nuevos) {
        //   this.capture.productoDeInteres.nuevos[0].productos[0].idColor = color;
        // }
        break;

      // Seminuevos
      case 'idModeloSemi':
        const modeloSemi = data.value;
        this.modeloSemi = this.getSelectedName(data.event);
        this.idModeloSemi = modeloSemi;
        // if (this.capture.productoDeInteres?.seminuevos) {
        //   this.capture.productoDeInteres.seminuevos.productos[0].modelo =
        //     modeloSemi;
        // }
        break;
      case 'idMarcaSemi':
        const marcaSemi = data.value;
        this.marcaSemi = this.getSelectedName(data.event);
        this.idMarcaSemi = marcaSemi;
        // if (this.capture.productoDeInteres?.seminuevos) {
        //   this.capture.productoDeInteres.seminuevos.productos[0].idMarca =
        //     marcaSemi;
        // }
        break;
      case 'idAutoSemi':
        const autoSemi = data.value;
        this.autoSemi = this.getSelectedName(data.event);
        this.idAutoSemi = autoSemi;
        if (this.capture.productoDeInteres?.seminuevos) {
          this.capture.productoDeInteres.seminuevos.productos[0].idAuto =
            autoSemi;
        }
        break;
      case 'idProductoSemi':
        const productoSemi = data.value;
        this.productoSemi = this.getSelectedName(data.event);
        this.idProductoSemi = productoSemi;
        if (this.capture.productoDeInteres?.seminuevos) {
          this.capture.productoDeInteres.seminuevos.productos[0].idProducto =
            productoSemi;
        }
        break;
      case 'idColorSemi':
        const colorSemi = data.value;
        this.colorSemi = this.getSelectedName(data.event);
        this.idColorSemi = colorSemi;
        if (this.capture.productoDeInteres?.seminuevos) {
          this.capture.productoDeInteres.seminuevos.productos[0].idColor =
            colorSemi;
        }
        break;
      // Flotillas
      case 'marcaFlotilla':
        const marcaFlotilla = data.value;
        this.marcaFlotilla = this.getSelectedName(data.event);
        this.idMarcaFlotilla = marcaFlotilla;
        if (this.capture.productoDeInteres?.flotilla) {
          this.capture.productoDeInteres.flotilla.productos[0].idMarca =
            marcaFlotilla;
        }
        break;
      case 'idAutoFlotilla':
        const autoFlotilla = data.value;
        this.autoFlotilla = this.getSelectedName(data.event);
        this.idAutoFlotilla = autoFlotilla;

        if (this.capture.productoDeInteres?.flotilla) {
          this.capture.productoDeInteres.flotilla.productos[0].idAuto =
            autoFlotilla;
        }
        break;
      case 'carYearFlotilla':
        const anioFlotilla = data.value;
        this.anioFlotilla = this.getSelectedName(data.event);
        this.idAnioFlotilla = anioFlotilla;

        // if (this.capture.productoDeInteres?.flotilla) {
        //   this.capture.productoDeInteres.flotilla.productos[0].modelo =
        //     anioFlotilla;
        // }
        break;
      case 'versionFlotilla':
        const versionFlotilla = data.value;
        this.versionFlotilla = this.getSelectedName(data.event);
        this.idVersionFlotilla = versionFlotilla;
        if (this.capture.productoDeInteres?.flotilla) {
          this.capture.productoDeInteres.flotilla.productos[0].version =
            versionFlotilla;
        }
        break;
      case 'colorFlotilla':
        const colorFlotilla = data.value;
        this.colorFlotilla = this.getSelectedName(data.event);
        this.idColorFlotilla = colorFlotilla;

        if (this.capture.productoDeInteres?.flotilla) {
          this.capture.productoDeInteres.flotilla.productos[0].idColor =
            colorFlotilla;
        }
        break;
      case 'cantidadFlotilla':
        const cantidadFlotilla = data.value;
        this.cantidadFlotilla = this.getSelectedName(data.event);
        this.idCantidadFlotilla = cantidadFlotilla;

        if (this.capture.productoDeInteres?.flotilla) {
          this.capture.productoDeInteres.flotilla.productos[0].idColor =
            cantidadFlotilla;
        }
        break;

      case 'fuentes_simple_lead':
        // this.capture.segmentacion.idFuente = data.value;
        break;
      case 'subcampaign_simple_lead':
        // this.capture.segmentacion.idCampania = data.value;
        break;
      case 'type_of_first_contact':
        // this.capture.segmentacion.idTipoDePrimerContacto = data.value;
        break;
      case 'closure_type':
        // this.capture.segmentacion.idTipoDeCierre = data.value;
        break;
    }
  }

  private combineSubscription: Subscription = new Subscription();

  getSegmentacionValues() {
    this.combineSubscription.add(
      this.formStepsServices.idCampana$.subscribe((res) => {
        if (this.capture.segmentacion) {
          this.capture.segmentacion.idCampania = res;
        }
      })
    );

    this.combineSubscription.add(
      this.formStepsServices.idFuente$.subscribe((res) => {
        if (this.capture.segmentacion) {
          this.capture.segmentacion.idFuente = res;
        }
      })
    );

    this.combineSubscription.add(
      this.formStepsServices.idTipoDeCierre$.subscribe((res) => {
        if (this.capture.segmentacion) {
          this.capture.segmentacion.idTipoDeCierre = res;
        }
      })
    );

    this.combineSubscription.add(
      this.formStepsServices.idTipoDePrimerContacto$.subscribe((res) => {
        if (this.capture.segmentacion) {
          this.capture.segmentacion.idTipoDePrimerContacto = res;
        }
      })
    );
  }

  enviarDatos() {
    const form = this.simpleCaptureComponent?.formSimple.value;
    const prospecto = this.capture.prospecto;

    if (prospecto) {
      this.capture.idEjecutivo = this.userInfo.empleado.idEmpleado;
      prospecto.nombre = form.nombre;
      prospecto.segundoNombre = form.segundoNombre;
      prospecto.paterno = form.paterno;
      prospecto.cp = form.cp;
      prospecto.duplicado = false;
      prospecto.telefonos = this.simpleCaptureComponent?.getMultiplePhones();
      prospecto.importante = this.importantLead;

      prospecto.correos = this.simpleCaptureComponent?.getMultipleEmail() ?? [];

      if (form.officeEmail && form.officeEmail_2) {
        prospecto.correos.push({
          email: `${form.officeEmail}@${form.officeEmail_2}`,
          tipo: 1,
          principal: false,
        });
      } else {
        console.log(form);
        
        form.officeEmail.valid;
        form.officeEmail_2.valid;
      }
    }

    this.capture.configuracion = this.request.getConfigUser();
    this.simpleCaptureComponent?.formSimple.dirty;
    this.dynamicComponentService.showBodyLoading();

    JSON.stringify(this.eliminarCamposNulos(this.capture), null, 2);

    if (this.validForm) {
      localStorage.setItem(
        'jsonCapture',
        JSON.stringify(this.eliminarCamposNulos(this.capture), null, 2)
      );
      this.eliminarCamposNulos(this.capture);

      const payloadProspect = JSON.stringify(this.eliminarCamposNulos(this.capture), null, 2)

      this.duplicateLeaflet.prospectExist(payloadProspect)
      .pipe(take(1))
      .subscribe((success: any) => {
            if (success.response.duplicado) {
              const response = success.response.duplicado;

              const cleanedFields = this.eliminarCamposNulos(this.capture);

              cleanedFields.prospecto.duplicado = response; 
              
              this.dynamicComponentService.destroyBodyLoading();
              const name = prospecto?.nombre + ' ' + prospecto?.paterno;
              this.sharedStateLead.setNameLead(name);
              this.sharedStateLead.setPayloadDuplicateProspect(cleanedFields)

              const dialogRef = this.dialog.open(AlertJoinProspectsComponent, {
                width : '500px',
                height: '260px',
                panelClass: 'alert-unify-prospects'
              });
            } else {
              this.dynamicComponentService.destroyBodyLoading();
              this.dynamicComponentService.showAlert({
                title: this.translateService.instant('success'),
                message: success.message,
                htmlContent: '',
                alertType: AlertType.success,
                autohide: true,
                animate: true,
              });
              this.reloadComponent();
              this.reloadDataService.setReload(true);
              this.cerrarModal('modal-simple');
            }
          },
          (rta: any) => {
            this.dynamicComponentService.destroyBodyLoading();
            if (rta.status === 403) {
              const alertOptions: any = {
                title: this.translateService.instant('error'),
                message: rta.error.message,
                alertType: AlertType.warning,
                autohide: false,
                animate: true,
              };
              this.reloadDataService.setReload(true);
              this.dynamicComponentService
                .showAlert(alertOptions)
                .pipe(take(1))
                .subscribe((response) => {
                  if (response) {
                    this.dynamicComponentService.destroyAlert();
                    this.captureSimpleLead();
                  } else {
                    this.dynamicComponentService.destroyAlert();
                  }
                });
            } else {
              const alertOptions: any = {
                title: this.translateService.instant('error'),
                message: rta.error.message,
                alertType: AlertType.error,
                autohide: false,
                animate: true,
              };

              this.dynamicComponentService.showAlert(alertOptions);
            }
          }
        );
    } else {
      this.dynamicComponentService.destroyBodyLoading();
      const alertOptions: any = {
        title: this.translateService.instant('error'),
        message: this.translateService.instant('missing_required_fields'),
        alertType: AlertType.error,
        autohide: false,
        animate: true,
      };

      console.log(this.simpleCaptureComponent?.formSimple);

      this.dynamicComponentService.showAlert(alertOptions);
    }
    this.cdRef.detectChanges();
  }

  @ViewChild(SimpleCaptureComponent)
  simpleCaptureComponent!: SimpleCaptureComponent;

  isFormValid(): boolean | undefined {
    return this.simpleCaptureComponent?.formSimple.valid;
  }

  captureSimpleLead() {
    this.simpleLeadService
      .registerProspect(JSON.stringify(this.capture, null, 2))
      .pipe(take(1))
      .subscribe(
        (success: any) => {
          this.dynamicComponentService.destroyBodyLoading();
          this.dynamicComponentService.showAlert({
            title: this.translateService.instant('success'),
            message: success.message,
            htmlContent: '',
            alertType: AlertType.success,
            autohide: true,
            animate: true,
          });
          this.reloadComponent();
          this.reloadDataService.setReload(true);
          this.cerrarModal('modal-simple');
        },
        (rta: any) => {
          this.dynamicComponentService.destroyBodyLoading();
          this.dynamicComponentService.showAlert({
            title: this.translateService.instant('error'),
            message: rta.error.message,
            htmlContent: '',
            alertType: AlertType.error,
            autohide: true,
            animate: true,
          });
          this.reloadDataService.setReload(true);
        }
      );
  }

  formSubmitted = false;
  validFieldsRequired() {
    this.formSimpleValidate();
    let count = 0;
    const appSoInputForms =
      this.elementRef.nativeElement.querySelectorAll('app-so-input-form');
    appSoInputForms.forEach((e: HTMLElement) => {
      if (!this.isHidden(e)) {
        const input = e.querySelector('input');
        const div = e.querySelector('div');
        if (input?.value === '') {
          if (input.required) {
            div?.classList.add('invalid');
            count++;
          } else {
            div?.classList.add('valid');
          }
        }
      }
    });
    const appSoSelects =
      this.elementRef.nativeElement.querySelectorAll('app-so-select');
    if (appSoSelects) {
      appSoSelects.forEach((e: HTMLElement) => {
        if (!this.isHidden(e)) {
          const select = e.querySelector('select');
          const div = e.querySelector('div');
          const selectedIndex = select?.selectedIndex;
          let value: any = '';
          if (selectedIndex !== -1) {
            value = select?.options[!selectedIndex ? 0 : selectedIndex].value;
          }
          if (selectedIndex === 0) {
            if (select?.required) {
              div?.classList.add('invalid');
              count++;
            } else {
              if (!(div?.id.includes('type_tel_') && value.trim() === '')) {
                div?.classList.add('valid');
              }
            }
          }
        }
      });
    }
    return count;
  }

  formSimpleValidate() {
    this.formSubmitted = true;
    if (this.simpleCaptureComponent.formSimple.invalid) {
      // Marcar los campos como tocados para mostrar los mensajes de error
      Object.values(this.simpleCaptureComponent.formSimple.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
      const emailArray = this.simpleCaptureComponent.formSimple.get(
        'emails'
      ) as FormArray;
      emailArray.controls.forEach((control) => {
        control.markAsTouched();
      });
      console.warn(this.simpleCaptureComponent.formSimple);
    } else {
      // Lógica para enviar el formulario si es válido
      console.log(this.simpleCaptureComponent.formSimple.value);
    }
  }

  isHidden(element: HTMLElement): boolean {
    return (
      (element.offsetWidth <= 0 && element.offsetHeight <= 0) ||
      element.style.display === 'none'
    );
  }

  public cerrarModal(modalId: string) {
    var modal = document.getElementById(modalId);
    var modalBackdrop = document.querySelector('.modal-backdrop');
    if (modal) {
      modal.style.display = 'none';
      if (modalBackdrop) {
        modalBackdrop.remove();
      }
    }
  }

  activeOptionalFields() {
    document
      .querySelectorAll('#simple-lead app-so-input-form input')
      .forEach((e) => {
        var element = e as HTMLInputElement;
        var requiredValue = element.required;
        if (!requiredValue) {
          e.closest('div.input__form')?.classList.add('valid');
        }
      });
    document
      .querySelectorAll('#simple-lead app-so-text-area textarea')
      .forEach((e) => {
        var element = e as HTMLInputElement;
        var requiredValue = element.required;
        if (!requiredValue) {
          e.closest('div.input__form')?.classList.add('valid');
        }
      });
  }

  getSelectedName(event: any): string {
    let select = event.target.options[event.target.selectedIndex];
    if (select) {
      return select.innerText.trim();
    } else {
      return '';
    }
  }

  reloadComponent() {
    this.reloadService.emitReload('app-home-latest-prospects');
  }

  assignValidControl(control: string) {
    this.simpleCaptureComponent?.formSimple.get(control)?.setErrors(null);
  }

  obtenerControlesNoValidos() {
    const controlesNoValidos = [];

    for (const controlName in this.simpleCaptureComponent?.formSimple
      .controls) {
      if (
        this.simpleCaptureComponent.formSimple.controls.hasOwnProperty(
          controlName
        )
      ) {
        const control = this.simpleCaptureComponent.formSimple.get(controlName);

        if (control?.invalid) {
          controlesNoValidos.push(controlName);
        }
      }
    }
  }

  isVipTel(Event: any) {
    let selectedIndex = Event.EventTarget.target
      .closest('app-so-select')
      .querySelector('select').selectedIndex;
    let id = Event.EventTarget.target
      .closest('app-so-select')
      .querySelector('select').id;
  }
  isVipMail(Event: any) {
    console.log(Event.EventTarget.target);
  }

  eliminarCamposNulos(objeto: any): any {
    if (objeto && typeof objeto === 'object') {
      for (let clave in objeto) {
        if (objeto.hasOwnProperty(clave)) {
          objeto[clave] = this.eliminarCamposNulos(objeto[clave]);
          if (objeto[clave] === null) {
            delete objeto[clave];
          }
        }
      }
    }
    return objeto;
  }

  activateImportantLead() {
    this.importantLead = !this.importantLead;
  }

  /**
   * Abrir modal del flujo de agregar auto desde el inventario en seminuevos
   */
  openAddCarToInventory() {
    this.dialog.open(OrganismModalAddCarInventoryComponent, {
      data: {},
    });
  }

  addValues(type: string, info: string): Producto[] {
    let data: Record<string, string> = {};
    document
      .querySelectorAll<HTMLDivElement>(
        "div[data-type='" +
          type +
          "'] app-so-select[data-lote], app-so-select-custom-reactive[data-lote]"
      )
      .forEach((e) => {
        if (e.dataset['lote']) {
          e.querySelectorAll<HTMLSelectElement>('select').forEach((e, i) => {
            if (e) {
              let id = String(e.id);
              if (e.selectedIndex !== -1) {
                if (id.includes('Semi')) {
                  id = id.replace('Semi', '');
                } else if (id.includes('Flotilla')) {
                  id = id.replace('Flotilla', '');
                }
                if (info === 'values') {
                  data[id] = this.cleanedSelectValues(e.options[e.selectedIndex].value);
                } else if (info === 'names') {
                  data[id] = e.options[e.selectedIndex].innerText.toUpperCase();
                }
              }
            }
          });
          e.querySelectorAll('.custom-select').forEach(
            (customSelectElement, i) => {
              const selectedElement =
                customSelectElement.querySelector('.selected');
              console.log(selectedElement);

              if (selectedElement) {
                let id = String(selectedElement.id);
                if (id.includes('Semi')) {
                  id = id.replace('Semi', '');
                } else if (id.includes('Flotilla')) {
                  id = id.replace('Flotilla', '');
                }
                if (info === 'values') {
                  data[id] =
                    selectedElement.getAttribute('data-value') ||
                    selectedElement.textContent?.trim() ||
                    '';
                } else if (info === 'names') {
                  data[id] = (
                    selectedElement.getAttribute('data-name') ||
                    selectedElement.textContent?.trim() ||
                    ''
                  ).toUpperCase();
                }
              }
            }
          );
        }
      });
    return this.separarPropiedades(data).filter((producto) => {
      return !Object.values(producto).every((value) => value === '');
    });
  }

  separarPropiedades(objeto: Record<string, string>): Producto[] {
    let arreglosPorSubfijo: Record<string, Record<string, string>> = {};
    let valoresSinSubfijo: Record<string, string> = {};
    for (const key in objeto) {
      const [baseKey, index] = key.split('__');
      if (index) {
        if (!arreglosPorSubfijo[index]) {
          arreglosPorSubfijo[index] = {};
        }
        arreglosPorSubfijo[index][key] = objeto[key];
      } else {
        valoresSinSubfijo[key] = objeto[key];
      }
    }
    let arreglosSeparados = Object.values(arreglosPorSubfijo);
    arreglosSeparados.unshift(valoresSinSubfijo);
    return arreglosSeparados.map(this.quitarSubfijos);
  }

  quitarSubfijos(objeto: Record<string, string>): Producto {
    const nuevoObjeto: Producto = {
      idAuto: '',
      idColor: '',
      idProducto: '',
      modelo: '',
    };
    for (const key in objeto) {
      const nuevaClave = key.replace(/__\d+$/, '');
      nuevoObjeto[nuevaClave] = objeto[key];
    }
    return nuevoObjeto;
  }

  activeCaptureType() {
    this.divNuevos = false;
    this.divSemiNuevos = false;
    this.divFlotilla = false;

    switch (this.businessType) {
      case 'TN00000001':
        this.divNuevos = true;
        break;
      case 'TN00000002':
        this.divSemiNuevos = true;
        break;
      case 'TN00000003':
        this.divFlotilla = true;
        break;
    }
  }

  notEmptyProductValidation() {
    return !this.divFlotilla && !this.divSemiNuevos && !this.divNuevos;
  }

  showEmptyProductAlert() {
    this.dynamicComponentService.showAlert({
      title: 'Error',
      message: 'Debe seleccionar al menos un producto.',
      htmlContent: '',
      alertType: AlertType.error,
      autohide: true,
      animate: true,
    });
  }

  selectColorChange(e: any) {
    this.idColor = e.key;
    this.color = e.labelValue;
  }

  selectColorChangeSemi(e: any) {
    this.idColorSemi = e.key;
    this.colorSemi = e.key;
  }
  selectColorChangeFlotillas(e: any) {
    this.idColorFlotilla = e.key;
    this.colorFlotilla = e.key;
  }

  appraisers: any;

  loadValuators() {
    const userInfo = this.request.getConfigUser();
    const bdd = userInfo.basesDeDatos.dbEmpresa;
    const idIdioma = userInfo.idioma.idIdioma;
    const idPais = userInfo.idioma.idPais;

    const body = {
      configuracion: {
        basesDeDatos: {
          dbEmpresa: bdd,
        },
        idioma: {
          idIdioma: idIdioma,
          idPais: idPais,
        },
      },
    };
    this.leadCaptureGeneralService.getValuators(body).subscribe({
      next: (data: any) => {
        const response = data.response;
        this.appraisers = this.leadCaptureGeneralService.transformValues(
          response,
          'idempleado',
          'nombre'
        );
      },
    });
  }

  loadFormLeadCapture() {
    this.formLeadCapture = this.formBuilder.group({
      valuacionNuevos: this.formBuilder.group({
        idValuador: [''],
        idMarca: [''],
        modelo: [''],
        idAuto: [''],
        idProducto: [''],
        idColor: [''],
        placas: [''],
        vin: [''],
      },
    ),
      valuacionSemi: this.formBuilder.group({
        idValuador: [''],
        idMarca: [''],
        modelo: [''],
        idAuto: [''],
        idProducto: [''],
        idColor: [''],
        placas: [''],
        vin: [''],
      }),
      valuacionFlotilla: this.formBuilder.group({
        idValuador: [''],
        idMarca: [''],
        modelo: [''],
        idAuto: [''],
        idProducto: [''],
        idColor: [''],
        placas: [''],
        vin: [''],
      }),
    });
  }

  cleanedSelectValues(value: any){
    const values = value.split(':');    
    const cleaned = values[1].trim();
    return cleaned;
  }

}
