import { Component, Input } from '@angular/core';
import { registerables } from 'node_modules/chart.js';
import { Chart } from 'node_modules/chart.js/auto';
Chart.register(...registerables);

@Component({
  selector: 'app-so-chart-line-curve',
  templateUrl: './so-chart-line-curve.component.html',
  styleUrls: ['./so-chart-line-curve.component.scss']
})
export class SoChartLineCurveComponent {
  @Input() id!: string;
  @Input() months: any[] = [];
  @Input() dataSets: any[] = [];
  @Input() title: string = "";
  @Input() label: string = "";
  @Input() filters: boolean = false;
  chart: any;

  ngAfterViewInit() {
    this.chartLine_curve();
  }

  chartLine_curve() {
    this.chart = new Chart('line_curve-' + this.id, {
      type: 'line',
      data: {
        labels: this.months,
        datasets: this.dataSets.map((dataset, index) => ({
          label: dataset.label || 'Dataset ' + (index + 1),
          borderColor: dataset.borderColor || "#042A7F",
          pointBackgroundColor: dataset.pointBackgroundColor || "#D7F068",
          pointBorderColor: dataset.pointBorderColor || "#042A7F",
          pointBorderWidth: dataset.pointBorderWidth || 2,
          data: dataset.data || [],
          fill: dataset.fill || false,
          borderWidth: dataset.borderWidth || 6,
          pointRadius: dataset.pointRadius || 5.5,
          tension: dataset.tension || 0.4,
          hidden: dataset.hidden || false
        })),
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: this.title,
            font: {
              size: 14
            }
          },
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawOnChartArea: false
            },
            ticks: {
              color: '#042A7F',
              font: {
                size: 14, // Cambia el tamaño de fuente en el eje X
                weight: 600,
                family: 'Montserrat'
              }
            }
          },
          y: {
            display: true,
            suggestedMin: 0,
            suggestedMax: 100,
            ticks: {
              color: '#A3BD36',
              stepSize: 10,
              callback: function (value, index, values) {
                if (value === 0 || value === 100) {
                  return value + ' % ';
                }
                return '';
              },
              font: {
                size: 14, // Cambia el tamaño de fuente en el eje Y
                weight: 600,
                family: 'Montserrat'
              }
            },
            grid: {
              display: true,
              drawOnChartArea: true
            },
            border: {
              display: false
            }
          }
        }
      }
    });
  }

  onCheckboxChange(isChecked: boolean, line: any) {
    this.chart.data.datasets[line].hidden = !this.chart.data.datasets[line].hidden;
    this.chart.update();
  }
}


