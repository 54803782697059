import { AfterViewInit, Component, Input } from '@angular/core';
import { Chart, registerables } from 'node_modules/chart.js';
Chart.register(...registerables);

@Component({
  selector: 'app-so-chart-bar-line',
  templateUrl: './so-chart-bar-line.component.html',
  styleUrls: ['./so-chart-bar-line.component.scss']
})

export class SoChartBarLineComponent implements AfterViewInit {
  @Input() id!: string;
  @Input() labels: any[] = [];
  @Input() dataMetas: any[] = [];
  @Input() dataCumplimientos: any[] = [];

  ngAfterViewInit() {
    this.chartBarLine();
  }

  chartBarLine() {
    new Chart('barline-' + this.id, {
      type: 'bar',
      data: {
        labels: this.labels,
        datasets: [{
          label: "Metas",
          type: "line",
          borderColor: "#042A7F",
          pointBackgroundColor: "#042A7F",
          data: this.dataMetas,
          fill: false,
          tension: 0,
          borderWidth: 1.5,
          pointRadius: 5, // Ajusta el tamaño de los puntos en la gráfica de línea
        }, {
          label: "Cumplimientos",
          type: "bar",
          backgroundColor: "#D7F068",
          data: this.dataCumplimientos,
        }]
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: false
          },
          legend: {
            display: false,
            position: 'bottom',
            labels: {
              boxWidth: 20,
              usePointStyle: true,
              font: {
                size: 12
              }
            }
          }
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawOnChartArea: false
            },
            ticks: {
              color: '#042A7F',
              font: {
                size: 13, // Cambia el tamaño de fuente en el eje X
                weight: 600,
                family: 'Montserrat'
              }
            }
          },
          y: {
            display: false, // Oculta las etiquetas del eje Y
            grid: {
              display: false,
              drawOnChartArea: false
            }
          }
        }
      }
    });
  }
}
