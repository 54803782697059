import { Injectable, inject, signal } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { User, AuthStatus, UserInfo, userInfoObject } from '../interfaces';
import { StorageService } from './storages.service';
import { CryptoService } from './crypto.service';
import { UserStore } from '@core/stores/session.store';

@Injectable({
  providedIn: 'root',
})
export class GlobalSessionService {
  private readonly storageService = inject(StorageService);
  private readonly cryptoService = inject(CryptoService);
  private readonly translateService = inject(TranslateService);
  private readonly userStore = inject(UserStore);

  public currentUser = signal<User | undefined>(undefined);
  public userInfo = signal<UserInfo>(userInfoObject);
  public authStatus = signal<AuthStatus>(AuthStatus.checking);

  public businessType = signal<String>('');

  private businessTypeRxjs = new BehaviorSubject<string>('');
  public businessType$ = this.businessTypeRxjs.asObservable();

  constructor() { }

  public setAuthentication(currentUser: User | undefined): void {
    this.currentUser.set(currentUser);
    this.authStatus.set(AuthStatus.authenticated);
    this.storageService.setSessionStorage(
      'authStatus',
      AuthStatus.authenticated
    );
  }

  public closeSession(): void {
    this.resetAuthentication();
    this.setLangByDefaultBroser();
  }

  public resetAuthentication(): void {
    this.authStatus.set(AuthStatus.notAuthenticated);
    this.currentUser.set(undefined);

    this.storageService.removeSessionStorage('authStatus');
    this.storageService.removeLocalStorage('currentUser');
    this.storageService.removeLocalStorage('userInfo');
    this.storageService.removeLocalStorage('browserLang');
    this.storageService.removeCookie('token');
    this.storageService.removeLocalStorage('token');
    this.storageService.removeCookie('refreshToken');
    this.storageService.removeLocalStorage('refreshToken');
  }

  public checkAuthStatus(): Observable<boolean> {
    const token: string | null =
      this.storageService.getCookie('token') ||
      this.storageService.getLocalStorage('token');
    const currentUser: User =
      this.storageService.getLocalStorage('currentUser');
    const userInfo = this.storageService.getLocalStorage('userInfo');

    if (!token || !currentUser || !userInfo) {
      this.resetAuthentication();
      return of(false);
    }

    this.validateUserInfo(userInfo);
    this.validateBusinessType();
    this.setAuthentication(currentUser);

    return of(true);
  }

  public saveSessionInStorage(
    token: string | undefined,
    currentUser: User | undefined,
    refreshToken: string | undefined
  ): void {
    this.storageService.setLocalStorage('currentUser', currentUser);
    this.storageService.setLocalStorage('userInfo', this.userInfo());
    this.storageService.setCookie('token', token);
    this.storageService.setLocalStorage('token', token);
    this.storageService.setCookie('refreshToken', refreshToken);
    this.storageService.setLocalStorage('refreshToken', refreshToken);
  }


  private validateBusinessType() {
    const businessType  = localStorage.getItem('businessType_' + this.userInfo().data.idUsuario);

    if (businessType) {
      this.businessType.set(businessType || '')
    }

    if (this.businessType() === '' && this.userInfo().data.tiposDeNegocio.length > 0) {
      localStorage.setItem('businessType_' + this.userInfo().data.idUsuario, this.userInfo().data.tiposDeNegocio[0].idTipoDeNegocio);
      this.businessType.set(this.userInfo().data.tiposDeNegocio[0].idTipoDeNegocio);
    }
  }

  private validateUserInfo(userInfo: any): void {
    if (this.userInfo().data.idUsuario === '') {
      this.userInfo.set(userInfo);
      this.userStore.setUser(this.userInfo());
    }
  }

  private setLangByDefaultBroser() {
    const browserLang = this.translateService.getBrowserLang();
    this.translateService.use(browserLang?.match(/en|es/) ? browserLang : 'es');
  }

  public setBusinessType(value: string){
    this.businessTypeRxjs.next(value);
  }

}
