import { Component, Input } from '@angular/core';

@Component({
  selector: 'so-spinner',
  templateUrl: './so-spinner.component.html',
})
export class SoSpinnerComponent {
  @Input() class: string = "d-flex justify-content-center";
  @Input() width: number = 30;
}
