import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-li',
  templateUrl: './so-li.component.html',
  styleUrls: ['./so-li.component.scss']
})
export class LiComponent {
  @Input() dataList: string[] = [''];

}
