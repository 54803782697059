import { Component, ElementRef, Input, Renderer2, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-so-replace-text-color',
  template: `{{ finalText }}`,
  styleUrl: './so-replace-text-color.component.scss'
})
export class SoReplaceTextColorComponent {
  private readonly translateService = inject(TranslateService);

  @Input() text: string = '';
  @Input() replacement: string = '';
  @Input() color: string = '';

  finalText = '';

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit() {
    this.updateLabelContent();
  }

  private updateLabelContent() {
    this.translateService.get(this.text).subscribe((res: string) => {
      const regexNumber = /%[a-zA-Z]/g;
      const replacedText = res.replace(regexNumber, this.replacement);

      const regexHighlighted = /\*(.*?)\*/g;
      this.finalText = replacedText.replace(regexHighlighted, '<a style="color: ' + this.color + '; font-weight: bold;">$1</a>');

      this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', this.finalText);
    });
  }
}
