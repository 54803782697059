import { Component, OnInit } from '@angular/core';
import { Notificaciones, Response } from '@shared/interfaces/notifications';
import { NotificationsService } from '@shared/services/notifications.service';

@Component({
  selector: 'so-notification-menu',
  templateUrl: './so-notification-menu.component.html',
  styleUrl: './so-notification-menu.component.scss'
})
export class SoNotificationMenuComponent implements OnInit{

  public typeNotifications: { key: any, value: string }[] = [];
  public listNotifications: Notificaciones[] = [];
  public loading: boolean = false;
  public isLoadingNotifications: boolean = false;
  public countNewNotifications: number = 0;
  public selectItem: string = '0';

  private sortAscending: boolean = false;

  constructor(
    private notificationsService: NotificationsService,
  ){}

  ngOnInit(): void {
    this.loadListTypeNotifications();
  }
  
  loadListTypeNotifications(){
    this.loading = true;
    this.notificationsService.getTypeNotification().subscribe({
      next: (data: any) => {
        const listTypeNotifications = data.response.tiposDeNotificacion;
        this.typeNotifications = [{key: '', value: 'all_campaigns'}, ... listTypeNotifications];
        this.selectItem = '';
        this.loading = false;
        this.loadNotifications(this.selectItem); 
      },
      error: (err: any) => {
          this.loading =  false;
      }
    });
  }

  loadNotifications(idType: string | number){
    this.isLoadingNotifications = true;
    this.notificationsService.getNotifications(idType).subscribe({
      next: (res: Response) => {
        const response =  res.response.notificaciones;
        this.listNotifications = response.map( (notification: any) => {
          return {
            ... notification,
            json: JSON.parse(notification.json)
          }
        });

        this.countNewNotifications = response.filter(notification => !notification.visto).length;
        this.isLoadingNotifications = false;
      },
      error: (err: any) => {
        this.listNotifications = [];
        this.isLoadingNotifications = false;
      }
    });
  }

  changeViewedNotification(idNotificacion: string){
    this.notificationsService.updateNotification(idNotificacion).subscribe({
      next: (res: any) => {
        const index = this.listNotifications.findIndex(notification => notification.idNotificacion === idNotificacion);
        if (index !== -1) {
          this.listNotifications[index].visto = true;
          this.countNewNotifications--;
        }
      },
      error: (err: any) => {
        console.log(err);
      }
    })
  }

  onChangeSelectTypeNotif(e: any){
    this.countNewNotifications = 0;
    this.listNotifications = [];
    this.sortAscending = false;
    this.loadNotifications(e);
  }

  sortNotificationsByDate(){
    this.listNotifications.sort((a,b) => {
      const dateA = new Date(a.fecha);
      const dateB = new Date(b.fecha);
      return this.sortAscending ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
    });
    this.sortAscending = !this.sortAscending;
  }

}
