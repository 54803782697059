import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpErrorResponse,
} from '@angular/common/http';
import { environment as env } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Injectable, inject } from '@angular/core';
import { StorageService } from '@core/services/storages.service';
import { formatTimezone } from '@shared/helpers/functions/timezone-format.helper';
@Injectable({
  providedIn: 'root',
})
export class Request {
  protected baseUrl: string = `${env.API_URL}/api/${env.API_VERSION}`;
  private readonly storageService = inject(StorageService);

  constructor(private http: HttpClient) {}

  public token = this.storageService.getCookie('refreshToken') ;
  headers = new HttpHeaders({
    Authorization: `Bearer ${this.token}`,
    'Content-Type': 'application/json; charset=utf-8',
  });

  getConfigUser() {
    try {
      const data = this.storageService.getLocalStorage('userInfo');

      // Si data o data.config es null, el catch manejará el error.
      const body = {
        basesDeDatos: {
          dbEmpresa: data.config.basesDeDatos.dbEmpresa,
        },
        idioma: {
          idIdioma: data.config.idioma.idIdioma,
          idPais: data.config.idioma.idPais,
        },
        husoHorario: {
          idHuso: data.config.husoHorario.idHuso,
          nombre: data.config.husoHorario.nombre,
        },
      };

      return body;

    } catch (error) {
      const data = this.storageService.getLocalStorage('userInfo');
      // Valores predeterminados en caso de error
      const defaultBody = {
        basesDeDatos: {
          dbEmpresa: data.IdGrupoCorporativo,
        },
        idioma: {
          idIdioma: 'ES',
          idPais: 'MX',
        },
        husoHorario: {
          idHuso: formatTimezone(data.ZonaHoraria),
          nombre: formatTimezone(data.ZonaHoraria),
        },
      };

      return defaultBody;
    }
  }

  private buildUrl(urlRequest: string): string {
    return `${this.baseUrl}/${urlRequest}`;
  }

  private makeHttpRequest<T>(
    method: string,
    urlRequest: string,
    body?: any,
    options?: { params?: HttpParams; headers?: HttpHeaders }
  ): Observable<T> {
    const urlCompuesta = this.buildUrl(urlRequest);
    return this.http.request<T>(method, urlCompuesta, { body, ...options });
  }

  private handleHttpError(error: HttpErrorResponse): Observable<never> {
    console.error('Error occurred:', error);
    return new Observable<never>();
  }
  public Get<T>(
    urlRequest: string,
    options?: { headers?: HttpHeaders }
  ): Observable<T> {
    return this.makeHttpRequest<T>('GET', urlRequest, options);
  }

  public Post<T>(
    urlRequest: string,
    body: any,
    options?: { headers?: HttpHeaders }
  ): Observable<T> {
    return this.makeHttpRequest<T>('POST', urlRequest, body, options);
  }

  public Put<T>(
    urlRequest: string,
    body: any,
    options?: { headers?: HttpHeaders }
  ): Observable<T> {
    return this.makeHttpRequest<T>('PUT', urlRequest, body, options);
  }

  public Delete<T>(
    urlRequest: string,
    body: any,
    options?: { headers?: HttpHeaders }
  ): Observable<T> {
    return this.makeHttpRequest<T>('DELETE', urlRequest, body, options);
  }

  public Patch<T>(
    urlRequest: string,
    body: any,
    options?: { headers?: HttpHeaders }
  ): Observable<T> {
    return this.makeHttpRequest<T>('PATCH', urlRequest, body, options);
  }
}
