<div class="chat-container">
  <section class="chat-container__header">
    <div>
      <so-icon svg="icono-compuesto-chat-soporte" [size]="1" />
      <p>{{ 'support_chat' | translate }}</p>
    </div>
    <button (click)="closeChatFunction()">
      <so-icon svg="mdi-close-circle-outline" [size]="0.8" />
    </button>
  </section>

  <section class="chat-container__message" #messageContainer>
    <div
      *ngFor="let m of messages"
      class="container__conversation {{ m.transmitter }}"
    >
      <so-message
        [message]="m.message"
        [transmitter]="m.transmitter"
        [icon]="m.transmitter == 'other'"
        *ngIf="m.type == 'message'"
      />

      <so-message-file
        [name]="m.message"
        [size]="m.size"
        *ngIf="m.type == 'file'"
      />
    </div>
  </section>

  <form
    (ngSubmit)="sendMessage()"
    [formGroup]="signInForm"
    class="chat-container__input"
  >
    <input
      type="text"
      formControlName="message"
      placeholder="{{ 'write_message' | translate }}"
    />

    <section (click)="onUploadClick()">
      <div>
        <so-icon svg="mdi-link-variant" />
        <input
          class="input-upload__file"
          type="file"
          style="display: none"
          (change)="onFileSelected($event)"
        />
      </div>
    </section>

    <so-icon svg="mdi-sticker-emoji" />
  </form>
</div>
