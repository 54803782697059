import { Observable } from "rxjs";
import type { DynamicComponentService, AlertType } from "@shared/components/atomics/body-loading/dynamic-component-service.service";

// Closure para hacer binding del servicio de componente dinámico a una función para abrir una alerta
export function openAlertFn(dynamicComponentService: DynamicComponentService, messageAttribute: 'message' | 'htmlContent') {
  return (
    title: string,
    message: string,
    alertType: AlertType,
    autohide: boolean,
    animate = true,
    showButtons = true,
    onAlertCloseCallback?: Function,
  ): Observable<boolean | { result: boolean, text: string }> => {
    const options: any = {
      title,
      alertType,
      autohide,
      animate,
      showButtons,
    }
    options[messageAttribute] = message;
    return dynamicComponentService.showAlert(options, onAlertCloseCallback);
  }
}