import { ChangeDetectorRef, Component, Input, OnInit, inject } from '@angular/core';
import { ModalRegisteredProspectsComponent } from '../../captura-prospecto/modal-registered-prospects/modal-registered-prospects.component';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuentesService } from '@core/services/Fuentes/fuentes.service';
import { CampaignService } from '@core/services/catalogos/campaign.service';
import { Request } from '@core/models/requests.model';
import { FirstContactTypeService } from '../../captura-prospecto/firstContactType/first-contact-type.service';
import { ClosureTypesService } from '@core/services/closureType/closure-types.service';


@Component({
  selector: 'detail-capture',
  templateUrl: './detail-capture.component.html',
  styleUrl: './detail-capture.component.scss'
})
export class DetailCaptureComponent implements OnInit{

  @Input() isAdmin: boolean = false;

  private readonly request = inject(Request);

  formDetail!: FormGroup;
  typeOfPerson?: any;
  public firstContactType?: any;
  public closuretypes?: any;
  public fonts?: any;
  public campaigns: any;

  public typeOfSale: any;

  public isStep2: boolean = false;

  public loadTypeFirstContact?: any = true;
  public loadClosure?: any = true;

  divFlotillaDetallada: boolean = false;
  flotillaDetalladaRows: number[] = [1];

  optiones: string[] = ['Valor 1', 'Valor2'];
  
  emailRows: number[] = [1];
  emails: any = [
    { value: 'Personal', personal: 'Personal' },
    { value: 'Trabajo', trabajo: 'Trabajo' },
    { value: 'Oficina', trabajo: 'Oficina' },
  ];

  showEmail: boolean = false;

  phoneRows: number[] = [1];
  telefonos: any = [
    { value: 'Celular', celular: 'Celular' },
    { value: 'Oficina', oficina: 'Oficina' },
    { value: 'Casa', casa: 'Casa' },
  ];

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private fuentesService: FuentesService,
    private campaignService: CampaignService,
    private firstContactTypeService: FirstContactTypeService,
    private closureTypesService: ClosureTypesService,
    private cdRef: ChangeDetectorRef,
  ){}

  ngOnInit(): void {
    this.loadFormDetail();
  }

  loadFormDetail(){
    this.formDetail = this.formBuilder.group({
      nombre: ['', Validators.required],
      segundoNombre: [''],
      paterno: ['', Validators.required],
      materno: [''],
      company: [''],
      observations: [''],
      cp: [''],
      email: [''],
      email_2: [''],
      officeEmail: [''],
      officeEmail_2: [''],
      tel0: [''],
      executive: [''],
    });

  }

  loadCatalogs(){
    this.loadClosureTypes();
    this.loadTypeFirtsContact();
  }

  openRegisteredProspects(): void {
    const dialogRef = this.dialog.open(ModalRegisteredProspectsComponent, {
      width: '1263px',
      height: '60%',
      panelClass: 'custom-modal',
    });
  }

  showdivFlotillaDetallada() {
    this.divFlotillaDetallada = !this.divFlotillaDetallada;
  }

  removeFlotillaDetallada(index: number) {
    this.flotillaDetalladaRows.splice(index, 1);
  }

  addFlotillaDetallada() {
    this.flotillaDetalladaRows.push(this.flotillaDetalladaRows.length + 1);
  }

  //Emails

  showEmailFn(event: any) {
    this.showEmail = event.target.checked;
    this.assignValidControl('email');
    this.assignValidControl('email_2');
  }

  assignValidControl(control: string) {
    this.formDetail.get(control)?.setErrors(null);
  }

  isVipMail(Event: any) {
    console.log(Event.EventTarget.target);
  }

  removeEmail(index: number) {
    this.emailRows.splice(index, 1);
  }

  addEmail() {
    const newIndex = this.emailRows.length + 1;
    if (this.emailRows.length < 5) {
      this.emailRows.push(newIndex);
    }
    this.formDetail.addControl(
      `email__${newIndex}`,
      this.formBuilder.control('')
    );
    this.formDetail.addControl(
      `email__${newIndex}_2`,
      this.formBuilder.control('')
    );
    this.getMultipleEmail();
  }

  getMultipleEmail(): any[] {
    let correos: any[] = [];
    let count = 0;
    Object.keys(this.formDetail.controls).forEach((key, i) => {
      if (key.includes('email')) {
        const value1 = this.formDetail.get(key)?.value;
        const value2 = this.formDetail.get(key + '_2')?.value;
        if (value1 && value2) {
          const correo = value1 + '@' + value2;
          let tipo = document.querySelector(
            'select#type_email__' + count
          ) as HTMLSelectElement;

          if (tipo) {
            if (!correos[i]) {
              correos[i] = { email: '', tipo: '', principal: false };
            }
            correos[i].email = correo;
            correos[i].tipo =
              tipo.options[tipo.selectedIndex].innerText.toString();
          }

          correos[i].principal = document.querySelector(
            'app-so-select #type_email__' +
              count +
              " app-icon-button[data-vip = 'true']"
          )
            ? true
            : false;
          count++;
        }
      }
    });
    correos = correos.filter((correo) => {
      return correo !== null && correo !== undefined;
    });
    return correos;
  }

  //Telefenos

  addPhone() {
    const newIndex = this.phoneRows.length + 1;
    if (this.phoneRows.length < 5) {
      this.phoneRows.push(newIndex);
    }
    this.formDetail.addControl(
      `tel${newIndex}`,
      this.formBuilder.control('')
    );
    this.getMultiplePhones();
  }

  isVipTel(Event: any) {
    let selectedIndex = Event.EventTarget.target
      .closest('app-so-select')
      .querySelector('select').selectedIndex;
    let id = Event.EventTarget.target
      .closest('app-so-select')
      .querySelector('select').id;

    console.log(selectedIndex);
    console.log(id);
  }

  getMultiplePhones(): any {
    let phones: any[] = [];
    let count = 0;
    Object.keys(this.formDetail.controls).forEach((key, i) => {
      if (key.includes('tel')) {
        const tel = this.formDetail.get(key)?.value;
        if (tel) {
          let tipo = document.querySelector(
            'select#type_tel__' + count
          ) as HTMLSelectElement;

          if (tipo) {
            if (!phones[i]) {
              phones[i] = { telefono: '', tipo: '', principal: false };
            }
            phones[i].telefono = tel;
            phones[i].tipo = tipo.selectedIndex;
          }

          phones[i].principal = document.querySelector(
            'app-so-select #type_tel__' +
              count +
              " app-icon-button[data-vip = 'true']"
          )
            ? true
            : false;

          count++;
        }
      }
    });
    phones = phones.filter((phone) => {
      return phone !== null && phone !== undefined;
    });
    return phones;
  }

  removePhone(index: number) {
    this.phoneRows.splice(index, 1);
  }

  onSelect(data: any) {
    this.buildDataCapture(data);
    switch (data.event.target.id) {
      case 'type_of_first_contact':
        this.fonts = [];
        this.fuentesService?.getFuentes({
          configuracion: this.request.getConfigUser(),
          idPrimerContacto: data.value,
        }).subscribe((data: any) => {
          this.fonts = this.fuentesService.formatValues(
            data.response,
            'idfuente',
            'nombre'
          );
        });
        break;
      case 'fuentes_simple_lead':
        this.campaignService
          .getCampaign({
            configuracion: this.request.getConfigUser(),
            idFuente: data.value,
          })
          .subscribe((data) => {
            this.campaigns = this.campaignService.formatValues(
              data.response,
              'idCampana',
              'nombre'
            );
          });
        break;
    }
  }

  buildDataCapture(data: any) {
    switch (data.event.target.id) {
      case 'fuentes_simple_lead':
        // this.capture.segmentacion.idFuente = data.value;
        break;
      case 'subcampaign_simple_lead':
        // this.capture.segmentacion.idCampania = data.value;
        break;
      case 'type_of_first_contact':
        // this.capture.segmentacion.idTipoDePrimerContacto = data.value;
        break;
      case 'closure_type':
        // this.capture.segmentacion.idTipoDeCierre = data.value;
        break;
    }
  }

  loadClosureTypes() {
    this.closureTypesService?.getAllClosureTypes({
      configuracion: this.request.getConfigUser(),
    }).subscribe((rta: any) => {
      if (typeof rta.response === 'object') {
        this.loadClosure = false;
        this.closuretypes = this.closureTypesService.formatValues(
          rta.response,
          'idtipodecierre',
          'nombre'
        );
        this.cdRef.detectChanges();
      }
    });
  }

  loadTypeFirtsContact() {
    this.firstContactTypeService?.getAllFirstContactTypes({
      configuracion: this.request.getConfigUser(),
    }).subscribe((rta: any) => {
      if (typeof rta.response === 'object') {
        this.loadTypeFirstContact = false;
        this.firstContactType = this.firstContactTypeService.formatValues(
          rta.response,
          'idtipoprimercontacto',
          'nombre'
        );
        this.cdRef.detectChanges();
      }
    });
  }

}
