import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textTransform',
  standalone: true
})
export class textTransform implements PipeTransform {
  transform(value: string, transformType: string): string {
    value = !!value?value:"";
    if (transformType === 'camelCase') {
      return value.toLowerCase().
      replace(/[-_]+(.)?|\b\w/g, (match, char) => char ? char.toUpperCase() : match.toLowerCase());
    } else if (transformType === 'capitalize') {
      return value.toLowerCase().replace(/^\p{L}/u, match => match.toUpperCase());
    } else {
      return value;
    }
  }
}
