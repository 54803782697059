import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-so-input-group-dropdown',
  templateUrl: './so-input-group-dropdown.component.html',
  styleUrls: ['./so-input-group-dropdown.component.scss'],
})
export class SoInputGroupDropdownComponent {
  @Input() text: string = '';
  @Input() placeholder: string = 'enter_the_prospect_name';

  @Output() mostrarCompleto = new EventEmitter<boolean>();
  validarCheck = false;

  @Input() receivedValue: boolean = false;

  constructor() {
    this.checkScreenSize();
  }

  ngOnInit(): void { }

  mostrarTodo() {
    this.validarCheck = !this.validarCheck
    this.mostrarCompleto.emit(this.validarCheck);
  }

  returnMenu() {
    this.validarCheck = false;
    this.mostrarCompleto.emit(false);
  }

  private checkScreenSize() {
    if (this.receivedValue) {
      if (window.innerWidth > 950) {
        this.returnMenu();
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkScreenSize();
  }
}
