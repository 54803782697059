import { Component, effect, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { getState } from '@ngrx/signals';
import { FilterCatalogs } from '../../../../core/stores/filter-catalogs.store';

@Component({
    selector: 'app-so-search-filters',
    templateUrl: './so-search-filters.component.html',
    styleUrl: './so-search-filters.component.scss',
})
export class SoSearchFiltersComponent {
  @Output() searchEvent = new EventEmitter<Object>();
  @Output() cleanEvent = new EventEmitter<null>();
  @Input() modifyAdvanceFilters: boolean = false;
  
  private readonly filterCatalogs = inject(FilterCatalogs);

  public activeQuickSearch: boolean = false;
  form: FormGroup;
  public typeOfDates?: any;
  private fields = {
    nombreCompleto: [''],
    telefono: [''],
    correo: [''],
    fechaInicio: [''],
    fechaFin: [''],
    idTipoFecha: ['']
  }

  constructor(
    private formBuilder: FormBuilder,
  ){
    effect(() => {
      const state = getState(this.filterCatalogs);
      this.typeOfDates = state.tiposDeFechas;
    });
    this.form = this.formBuilder.group(this.fields);
  }

  clearAdvancedFilters() {
    if(this.modifyAdvanceFilters){
      const labelBg = document.querySelector('.label-bg') as HTMLElement;
      const labelCheck = document.querySelector('.label-check') as HTMLElement;
      labelBg.classList.remove('checked');
      labelCheck.classList.remove('checked');
    }
    this.activeQuickSearch =!this.activeQuickSearch;
    this.cleanEvent.emit();
    this.form.reset()
    this.cleanFilters();
  }

  advancedFilters() {
    if(this.modifyAdvanceFilters){
      const labelBg = document.querySelector('.label-bg') as HTMLElement;
      const labelCheck = document.querySelector('.label-check') as HTMLElement;
      labelBg.classList.add('checked');
      labelCheck.classList.add('checked');
    }
    this.activeQuickSearch = true;
    this.searchEvent.emit(this.form.value);
  }
  
  cleanFilters(){
    var nombreCompleto = document.querySelector<HTMLInputElement>('input[name="nombreCompleto"]');
    var telefono = document.querySelector<HTMLInputElement>('input[name="telefono"]');
    var correo = document.querySelector<HTMLInputElement>('input[name="correo"]');
    var fechaInicio = document.querySelector<HTMLInputElement>('input[name="fechaInicio"]');
    var fechaFin = document.querySelector<HTMLInputElement>('input[name="fechaFin"]');
    var idTipoFecha = document.querySelector<HTMLSelectElement>('input[name="idTipoFecha"]');
    if(nombreCompleto) nombreCompleto.value = "";
    if(telefono) telefono.value = "";
    if(correo) correo.value = "";
    if(fechaInicio) fechaInicio.value = "";
    if(fechaFin) fechaFin.value = "";
    if(idTipoFecha) idTipoFecha.value = "";
  }
}
