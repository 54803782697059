import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

interface Estado {
    idEstado: string;
    nombre: string;
    color: string;
    activo: boolean;
}

const statusObject: Estado[] = []


export const StatusList = signalStore(
    { providedIn: 'root' },
    withState(statusObject),
    withMethods(({ ...store }) => ({
        setCurrentStatusList(statusList: Estado[]) {
            patchState(store, statusList);
        },
    }))
);