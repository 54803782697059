import { Injectable } from '@angular/core';
import { CurrentStatusResponse } from '@core/interfaces/catalogos.intefaces';
import { Request } from '@core/models/requests.model';
import { StorageService } from '@core/services/storages.service';

@Injectable({
  providedIn: 'root'
})
export class SessionHistoryService {

  private url = `historialdesesiones/all`;

  constructor(
    private request: Request,
    private storageService: StorageService
  ) { }


  getSessionHistory(){
    const storageData = this.storageService.getLocalStorage('userInfo').data;

    const body = {
      idempleado:storageData.empleado.idEmpleado,
      configuracion: {
          idioma: {
              idIdioma: storageData.empleado.empresa.idioma.idIdioma,
              idPais: storageData.empleado.empresa.pais.idPais
          },
          husoHorario: {
              idHuso: storageData.empleado.empresa.husoHorario.idHuso,
              nombre: storageData.empleado.empresa.husoHorario.nombre
          }
      }
    };

    const options = {
      headers: this.request.headers,
    }

    return this.request.Post<CurrentStatusResponse>( `${this.url}`, body, options )

  }


}
