import { Component, EventEmitter, Input, Output, computed, inject } from '@angular/core';
import {
  ItemsLateralMenu,
  toggleConfigObject,
} from '@shared/interfaces/items-lateral-menu.interface';
import { take } from 'rxjs/operators';
import {
  DynamicComponentService,
  AlertType,
} from '@shared/components/atomics/body-loading/dynamic-component-service.service';
import { ModalServiceService } from '@shared/components/atomics/so-modal/modal-service.service';
import { TranslateService } from '@ngx-translate/core';
import { FormStepsService } from '@shared/services/observables/form-steps.service';
import { GlobalSessionService } from '@core/services/global-session.service';
@Component({
  selector: 'so-lateral-menu',
  templateUrl: './so-lateral-menu.component.html',
  styleUrls: ['./so-lateral-menu.component.scss'],
})
export class SoLateralMenuComponent {
  private readonly translateService = inject(TranslateService);
  
  @Output() clickedItemActions = new EventEmitter<string>();

  @Input() itemsMenu: ItemsLateralMenu = {
    // toggleItems: [],
    // linkItems: [],
    items: [],
    actionsItems: [],
  };

  typeSizing?:  
  | 'modal-xxs'
  | 'modal-xs'
  | 'modal-xl'
  | 'modal-lg'
  | 'modal-sm'
  | 'modal-full-page'
  | 'modal-full-screen';

  public size?: this['typeSizing'];

  private readonly session = inject(GlobalSessionService);

  constructor(
    private dynamicComponentService: DynamicComponentService,
    private modalServiceService: ModalServiceService,
    private formStepsService: FormStepsService
  ) {}

  handleEvent(event: string) {
    this.clickedItemActions.emit(event);
    this.size = 'modal-xl';
  }

  showToggleMenu(id: number) {
    for (let i = 0; i < this.itemsMenu.items.length; i++)
      if (this.itemsMenu.items[i].type === 'toggle')
        if (i !== id)
          this.itemsMenu.items[i].show = false;

    for (let i = 0; i < this.itemsMenu.items.length; i++)
      if (this.itemsMenu.items[i].type === 'link')
        if(this.itemsMenu.items[i].show) this.itemsMenu.items[i].show = false;

    this.itemsMenu.items[id].show = !this.itemsMenu.items[id].show;
  }

  showToggleLinksMenu(id: number) {
    for (let i = 0; i < this.itemsMenu.items.length; i++)
      if (this.itemsMenu.items[i].type === 'link')
        if (i !== id)
          this.itemsMenu.items[i].show = false;

    for (let i = 0; i < this.itemsMenu.items.length; i++)
      if (this.itemsMenu.items[i].type === 'toggle')
        if(this.itemsMenu.items[i].show) this.itemsMenu.items[i].show = false;

    this.itemsMenu.items[id].show = !this.itemsMenu.items[id].show;
  }

  onClose(event: any) {
    const sureTitle = this.translateService.instant('are_you_sure');
    const msgAlertPart1: String  = this.translateService.instant('you_will_abandon_the_capture');
    const msgAlertPart2: String = this.translateService.instant('prospect_data');
    const msgAlertPart3: String  = this.translateService.instant('like_their');
    const msgAlertPart4: String  = this.translateService.instant('products_of_interest');

    const htmlContent = `<p style="font-size: 16px;">
      ${msgAlertPart1} <b>${msgAlertPart2.toLowerCase()}</b> ${msgAlertPart3} <b>${msgAlertPart4.toLowerCase()}</b>
      </p>`

    this.dynamicComponentService
      .showAlert({
        title: sureTitle,
        htmlContent: htmlContent,
        alertType:AlertType.warning,
        autohide:false,
        width: '400px'
      }
      ).pipe(
        take(1)
      )
      .subscribe((response) => {
        if (response) {
          this.formStepsService.setStep2(false);
          this.dynamicComponentService.destroyAlert();
         
          this.formStepsService.resetForm();
          this.formStepsService.setResetSelect([]);
          this.modalServiceService?.cerrarModal('modal-simple');
        } else {
          this.dynamicComponentService.destroyAlert();
        }
      });
  }

  closeAllToggle() {
    for (let i = 0; i < this.itemsMenu.items.length; i++)
      if (this.itemsMenu.items[i].type === 'toggle')
        if(this.itemsMenu.items[i].show) this.itemsMenu.items[i].show = false;
  }
  
  public businessType = computed(
    () => `${this.session.businessType()}`
  );
}
