<atomic-so-mat-modal>
    <div titleModal class="title-modal">
        <mat-icon class="searchIcon">search</mat-icon>
        {{ 'registered_prospects' | translate }}
    </div>
    <div subTitle class="d-flex align-items-center justify-content-center gap-2 list-title mt-3">
        <p>
            <mat-icon>list</mat-icon>
            {{ 'list_registered_prospects' | translate }} | 
            <span>  {{ ('total' | translate).toUpperCase() }}: {{ prospects.length }} {{ ('prospects' | translate).toLowerCase() }} </span>
        </p>
    </div>

    <div contentModal class="tableFixHead" >
        <table class="table">
            <thead>
                <tr>
                    <th class="text-center">
                        <div class="d-flex align-items-center gap-0">
                            N° 
                            <img src="./assets/img/arrow-neutral.svg" />
                        </div>
                    </th>
                    <th>
                        <div class="d-flex align-items-center gap-0">
                             {{ ('prospects' | translate).toUpperCase() }}
                            <img src="./assets/img/arrow-neutral.svg" />
                        </div>
                    </th>
                    <th>
                        <div class="d-flex align-items-center gap-0">
                             {{ 'rfc_abbreviation' | translate }}
                            <img src="./assets/img/arrow-neutral.svg" />
                        </div>
                    </th>
                    <th>
                        <div class="d-flex align-items-center gap-0">
                             {{ ('executive' | translate).toUpperCase() }}
                            <img src="./assets/img/arrow-neutral.svg" />
                        </div>
                    </th>
                    <th>
                        <div class="d-flex align-items-center gap-0">
                            {{ ('register' | translate ).toUpperCase() }}
                            <img src="./assets/img/arrow-neutral.svg" />
                        </div>
                    </th>
                    <th>
                        <div class="d-flex align-items-center gap-0">
                            {{ ('status' | translate ).toUpperCase() }}
                            <img src="./assets/img/arrow-neutral.svg" />
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                @for (prospect of prospects; track prospect; let index = $index) {
                    <tr>
                        <td class="list-color-blue align-middle text-center" > {{ index + 1 }} </td>
                        <td class="list-color-neutral align-middle">   
                            <img [src]="prospect.pictureProspecto" alt="prospecto">
                            {{ prospect.prospecto}} 
                        </td>
                        <td class="list-color-blue align-middle"> {{ prospect.rfc }} </td>
                        <td class="list-color-neutral align-middle"> 
                            <img [src]="prospect.pictureEjecutivo" class="mr-1" alt="Ejecutivo">
                            {{ prospect.ejecutivo }} 
                        </td>
                        <td class="align-middle" > 
                            <span>{{ prospect.registro | date: 'dd-MM-yy' }}</span> 
                            a las 
                            <span>{{ prospect.registro | date: 'HH:mm' }}</span>
                        </td>
                        <td class="list-color-blue align-middle"> {{ prospect.status  }} </td>
                    </tr>
                }
            </tbody>
        </table>
    </div>
</atomic-so-mat-modal>