import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-upload-from-pc',
  templateUrl: './modal-upload-from-pc.component.html',
  styleUrls: ['./modal-upload-from-pc.component.scss']
})
export class ModalUploadFromPcComponent {
  uploadedImageUrl: string | null = "assets/img/cover-bg-welcome.png";

  onUploadClick() {
    // Cuando se hace clic en el <section>, activamos el input de tipo file
    const inputElement: HTMLElement = document.querySelector('input[type="file"]') as HTMLElement;
    inputElement.click();
  }

  onFileSelected(event: any) {
    // Aquí puedes manejar la lógica de carga de la imagen
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = () => {
        this.uploadedImageUrl = reader.result as string;
      };
      reader.readAsDataURL(selectedFile);

      this.showSnackBar('Imagen subida correctamente');
    } else {
      this.showSnackBar('No se ha seleccionado ninguna imagen');
    }
  }

  private showSnackBar(message: string) {

  }
}
