import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SoIconComponent } from '@common/components/atomics/so-icon/so-icon.component';

@Component({
  selector: 'atomic-so-mat-modal',
  standalone: true,
  imports: [
    SoIconComponent
  ],
  templateUrl: './so-mat-modal.component.html',
  styleUrl: './so-mat-modal.component.scss'
})
export class SoMatModalComponent {
  
  constructor(public dialogRef: MatDialogRef<SoMatModalComponent>) { }

  onCloseClick(): void {
    this.dialogRef.close();
  }

}
