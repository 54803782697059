import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  // Guardar un valor en el localStorage
  setLocalStorage(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  // Obtener un valor del localStorage
  getLocalStorage(key: string): any {
    const value = localStorage.getItem(key);
    if (value !== 'undefined') {
      return value ? JSON.parse(value) : null;
    } else {
      return {};
    }
  }

  // Eliminar un valor del localStorage
  removeLocalStorage(key: string): void {
    localStorage.removeItem(key);
  }

  // Guardar un valor en el sessionStorage
  setSessionStorage(key: string, value: any): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  // Obtener un valor del sessionStorage
  getSessionStorage(key: string): any {
    const value = sessionStorage.getItem(key);
    if (value !== 'undefined') {
      return value ? JSON.parse(value) : null;
    } else {
      return {};
    }
  }

  // Eliminar un valor del sessionStorage
  removeSessionStorage(key: string): void {
    sessionStorage.removeItem(key);
  }

  // Guardar una cookie
  setCookie(name: string, value: string | undefined | null): void {
    const expirationDate = new Date();
    expirationDate.setDate(
      expirationDate.getDate() + environment.EXPIRATION_COOKIE
    );
    const cookieValue =
      encodeURIComponent(value || '') +
      (environment.EXPIRATION_COOKIE
        ? `; expires=${expirationDate.toUTCString()}`
        : '');
    document.cookie = `${name}=${cookieValue}`;
  }

  // Obtener el valor de una cookie
  getCookie(name: string): string | null {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return decodeURIComponent(cookie.substring(name.length + 1));
      }
    }
    return null;
  }

  // Eliminar una cookie
  removeCookie(name: string): void {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
}
