
<!-- captura detallada -->
<div [hidden]=" !isAdmin || isStep2 " class="mt-3" style="padding: 5px 20px 0px 20px;">
  <div class="d-flex  gap-2 mt-3">
    <img src="./assets/img/mdi-person-outline.svg" alt="" style="width: 27px; height: 24px;">
    <p style="font: normal normal bold 20px/29px Montserrat;letter-spacing: 0px;
     color: #272D3B;margin-top: 0px;">{{'executive_to_assign'|translate}}</p>
  </div>
  <div class="row">
    <div class="col-6">
      <app-so-executive-select label="{{'select_admin'|translate}} *" [showLoadingIcon]="true"
        [required]="true"></app-so-executive-select>
    </div>
    <div class="col-6">
      <app-so-select label="{{'sale_type'|translate}} *" [data]="typeOfSale" [required]="true"></app-so-select>
    </div>
  </div>
</div>
<div class="mt-3" style="padding: 10px 20px;">
    <div class="row gap-2 mt-3">
      <div class="col d-flex justify-content-start">
        <img src="./assets/img/mdi-clipboard-account-outline.svg" alt="" style="width: 27px; height: 27px;">
        <p style="font: normal normal bold 20px/29px Montserrat;letter-spacing: 0px;
       color: #272D3B;margin-top: 0px; margin-left: 8px;">{{'prospect_main_information'|translate}}</p>
      </div>
      <div class="col d-flex justify-content-end">
        <button class="btnSearch" (click)="openRegisteredProspects()">
          <mat-icon class="searchIcon">search</mat-icon>
          <a style="font-size: 16px;">{{ 'search_prospect' | translate }}</a>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-so-select label="{{'person_type'|translate}} *" [data]="typeOfPerson"></app-so-select>
      </div>
      <div class="col">
        <app-so-input-form label="{{'company'|translate}}"></app-so-input-form>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-so-input-form label="{{'name'|translate}} *" [formGroup]="formDetail" name="name"></app-so-input-form>
      </div>
      <div class="col">
        <app-so-input-form label="{{'second_name'|translate}}"></app-so-input-form>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-so-input-form label="{{'last_name'|translate}} *"></app-so-input-form>
      </div>
      <div class="col">
        <app-so-input-form label="{{'mothers_last_name'|translate}}"></app-so-input-form>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-so-input-form label="{{'postal_code_abbreviation'|translate}}"></app-so-input-form>
      </div>
      <div class="col">
        <app-so-input-form label="{{'rfc_abbreviation'|translate}}"></app-so-input-form>
      </div>
      <div class="col">
        <app-so-input-form label="{{'curp_abbreviation'|translate}}"></app-so-input-form>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-2 ml-2">
        <label>{{'data'|translate}}:</label>
      </div>
    </div>
    <div class="row">
      <div class="col mt-1 d-flex gap-2">
        <app-so-checkbox [size]="20"></app-so-checkbox>
        <label style="color: #C5C5C5;">
          {{'important_lead'|translate}}
        </label>
      </div>
      <div class="col">
      </div>
    </div>
   
    <!-- <div class="row gap-2 mt-4">
      <div class="col d-flex justify-content-start">
        <img src="./assets/img/account-details-outline.svg" style="margin-top: -5px;">
        <p style="font: normal normal bold 20px/29px Montserrat;letter-spacing: 0px;
       color: #272D3B;margin-top: 10px; margin-left: 8px;">{{'photo'|translate}}</p>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center" style="margin: 10px 130px 20px 0px;">
      <app-icon-button class="" firstIcon="true" size="13px" icon="./assets/img/mdi-foto-library.svg"
        text="{{'default_photo'|translate}}" widthIcon="20px" heightIcon="20px" data-bs-toggle="modal"
        data-bs-target="#modal-default-image"></app-icon-button>
      <app-icon-button class="" firstIcon="true" size="13px" icon="./assets/img/mdi-cloud-upload-outline-green.svg"
        text="{{'upload_photo_from_computer'|translate}}" widthIcon="24px" heightIcon="24px" data-bs-toggle="modal"
        data-bs-target="#modal-upload-from-pc"></app-icon-button>
      <app-icon-button class="" firstIcon="true" size="13px" icon="./assets/img/mdi-webcam-green.svg"
        text="{{'capture_photo'|translate}}" widthIcon="22px" heightIcon="20px" data-bs-toggle="modal"
        data-bs-target="#modal-photo-from-camera"></app-icon-button>
    </div> -->
    <div class="row gap-2 mt-3">
      <div class="col d-flex justify-content-start">
        <img src="./assets/img/mdi-file-plus.svg" alt="" style="width: 27px; height: 25px;">
        <p style="font: normal normal bold 20px/29px Montserrat;letter-spacing: 0px;
        color: #272D3B;margin-top: 0px; margin-left: 8px;">{{'additional_data'|translate}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-so-input-form label="{{'bancomer_folio'|translate}}"></app-so-input-form>
      </div>
      <div class="col">
        <app-so-input-form label="{{'contact_hours_start'|translate}} *" type="time"></app-so-input-form>
      </div>
      <div class="col">
        <app-so-input-form label="{{'contact_hours_end'|translate}} *" type="time"></app-so-input-form>
      </div>
    </div>
    <div class="row gap-2 mt-3">
      <div class="col-3 d-flex justify-content-start">
        <img src="./assets/img/mdi-cellphone.svg" alt="" style="width: 27px; height: 25px;">
        <p style="font: normal normal bold 20px/29px Montserrat;letter-spacing: 0px;
        color: #272D3B;margin-top: 0px; margin-left: 8px;">{{'phones'|translate}}</p>
      </div>
      <div class="col-3 d-flex gap-2" style="padding-left: 8px;">
        <img src="./assets/img/mdi-email-outline.svg" alt="" style="width: 27px; height: 25px;">
        <p style="font: normal normal bold 20px/29px Montserrat;letter-spacing: 0px;
        color: #272D3B;margin-top: 0px;">{{'emails'|translate}}</p>
      </div>
    </div>
    <!-- Multiple Emails -->
    <div class="row" *ngFor="let row of emailRows; let i = index">
      @if(!showEmail){
      <div class="col-3">
        <app-so-select [showVip]="true" label="{{'type_follow_up'|translate}} *" [data]="emails" [required]="!showEmail"
          [name]="'type_email__'+i" data-component="type-email" (clickVip)="isVipMail($event)"></app-so-select>
      </div>
      <div class="col-6">
        <app-so-input-form label="{{'email'|translate}} *" type="email" placeholderCorreo="Correo"
          [name]="'email' + (i > 0 ? '__' + (i+1) : '')" [formGroup]="formDetail" placeholderDominio="dominio.com"
          [required]="!showEmail"></app-so-input-form>
      </div>
      <div class="col">
        <div class="d-flex justify-content-start" style="margin-top: 17px;">
          <div class="col-2"> <button (click)="removeEmail(i)" *ngIf="emailRows.length > 1">
              <img src="./assets/img/mdi-minus-circle-outline-danger.svg" style="width: 25px; height: 54px;">
            </button>
          </div>
          <div class="col-2">
            @if(emailRows.length < 5 && (row==1 && emailRows.length==1) || (row>= emailRows.length && emailRows.length < 5
                )) { <button (click)="addEmail()">
                <mat-icon [ngClass]="'so-secondary'" [ngStyle]="{'font-weight':'500','font-size':'30px','width':'35px',
                'height':'35px','margin-top': '12px','margin-right': '4px'}">add_circle_outline</mat-icon>
                </button>
                }
          </div>
        </div>
      </div>
      }
    </div>
    <div class="row gap-2">
      <div class="col-3 mt-2 d-flex gap-2">
      </div>
      <div class="col-3 mt-2 d-flex gap-2" style="padding-left: 5px;">
        <app-so-checkbox [size]="20" (click)="showEmailFn($event)"></app-so-checkbox>
        <label style="color: #C5C5C5;">
          {{'does_not_provide_email'|translate}}
        </label>
      </div>
    </div>
    <!-- End Multiple Emails -->
    <div class="row" *ngFor="let row of phoneRows; let i = index">
      <div class="col-3">
        <app-so-select [showVip]="true" label="{{'type_follow_up'|translate}}" (clickVip)="isVipTel($event)"
          [data]="telefonos" [name]="'type_tel__'+i" data-component="type-tel"></app-so-select>
      </div>
      <div class="col-6">
        <app-so-input-form type="tel" [showVip]="false" [showTrash]="false" label="{{'phone'|translate}}" name="tel"
          [name]="'tel' +i" [formGroup]="formDetail"></app-so-input-form>
      </div>
      <div class="col">
        <div class="d-flex justify-content-start" style="margin-top: 17px;">
          <div class="col-2"> <button (click)="removePhone(i)" *ngIf="phoneRows.length > 1">
              <img src="./assets/img/mdi-minus-circle-outline-danger.svg" style="width: 25px; height: 54px;">
            </button>
          </div>
          <div class="col-2">
            @if(phoneRows.length < 5 && (row==1 && phoneRows.length==1) || (row>= phoneRows.length && phoneRows.length < 5
                )) { <button (click)="addPhone()">
                <mat-icon [ngClass]="'so-secondary'" [ngStyle]="{'font-weight':'500','font-size':'30px','width':'35px',
              'height':'35px','margin-top': '12px','margin-right': '4px'}">add_circle_outline</mat-icon>
                </button>
                }
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex gap-2 mt-2">
      <img src="./assets/img/chart.svg" alt="" style="width: 27px; height: 27px;">
      <p style="font: normal normal bold 20px/29px Montserrat;letter-spacing: 0px;
        color: #272D3B;margin-top: 0px;">{{'segmentation'|translate}}</p>
    </div>
    <div class="">
      <div class="row">
        <div class="col">
          <app-so-select label="{{'type_of_first_contact'|translate}} *" [data]="firstContactType"></app-so-select>
          <app-so-select class="mt-1" label="{{'subcampaign'|translate}}" [data]="campaigns"></app-so-select>
        </div>
        <div class="col">
          <app-so-select [data]="fonts" label="{{'contact_source'|translate}} *"
            (event)="onSelect($event)"></app-so-select>
          <app-so-select class="mt-1" label="{{'closure_type'|translate}} *" [data]="closuretypes" ></app-so-select>
        </div>
        <div class="col">
          <app-so-text-area label="{{'observations'|translate}}" rows="3"></app-so-text-area>
        </div>
      </div>
    </div>
  </div>