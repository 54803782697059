import { Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalSessionService } from '@core/services/global-session.service';
import { StorageService } from '@core/services/storages.service';
import { NavbarService } from '@shared/services/observables/navbar.service';

@Component({
  selector: 'app-so-navbar',
  templateUrl: './so-navbar.component.html',
  styleUrls: ['./so-navbar.component.scss']
})
export class SoNavbarComponent {


  private readonly router = inject(Router);
  typeModule: any = '';

  constructor() {
    this.typeModule = localStorage.getItem('module');
  }

  isRouteActive(routePath: string): boolean {
    return this.router.url === routePath;
  }

  receivedValue: boolean = false;
  onValueChanged(value: boolean) {
    this.receivedValue = value;
  }

}
