import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
  booleanAttribute,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { ElementRef } from '@angular/core';

@Component({
  selector: 'app-so-input-form',
  templateUrl: './so-input-form.component.html',
  styleUrls: ['./so-input-form.component.scss'],
})
export class SoInputComponent implements OnInit, OnChanges {
  @Output() event = new EventEmitter<any>();
  @Output() keyPress = new EventEmitter<string[]>();
  typeInput?:
    | 'text'
    | 'name'
    | 'date'
    | 'time'
    | 'datetime-local'
    | 'email'
    | 'date'
    | 'number'
    | 'color'
    | 'month'
    | 'password'
    | 'search'
    | 'tel'
    | 'url'
    | 'week'
    | 'postalCode'
    | 'email_name'
    | 'email_domain'
    | 'select';

  @Input() label: string = '';
  @Input() value: string = '';
  @Input() bgColor?: string = '';
  @Input() paddingClasses: string = 'p-1';
  @Input() placeholder?: string = '';
  @Input() placeholderCorreo?: string;
  @Input() placeholderDominio?: string;
  @Input() dataInput1?: string;
  @Input() dataInput2?: string;
  @Input() type?: this['typeInput'];
  @Input() id?: string;
  @Input() name: any = '';
  @Input() inputValue: string = '';
  @Input() nameControl?: any = 'init';
  @Input() width?: string = 'auto';
  @Input() maxWidth?: string = 'auto';
  @Input() vip?: boolean = false;
  // @Input() vip?: string = 'grayscale(1)';
  @Input() isValidName: string = '';
  @Input() statusField: string = '';
  @Input() isValid?: boolean = true;
  @Input() isVip?: boolean = true;
  @Input() showVip?: boolean = false;
  @Input() showTrash?: boolean = false;
  @Input() readOnly?: boolean = false;
  @Input() required?: boolean = false;
  @Input() formGroup?: FormGroup;
  @Input() minlength?: number = 0;
  @Input() maxlength?: number = 50;
  @Input() textColor?: string = 'var(--so-neuter-thick)';
  @Input() placeHolderColor?: string = 'var(--so-green)';
  @Input({ transform: booleanAttribute }) requiredMark: boolean = false;

  typeField?: string;
  element?: any;
  elementEvent?: any;
  suggestions: string[] = [
    'gmail.com',
    'yahoo.com',
    'outlook.com',
    'hotmail.com',
  ];
  filteredSuggestions: string[] = [];
  public elementoDOM: any;
  public componenteDOM: any;
  private textRegex = /^[a-zA-Z0-9_ áéíóúÁÉÍÓÚüÜ]*$/;
  private nameRegex = /^[a-zA-Z áéíóúÁÉÍÓÚüÜ]*$/;
  private email_name = /^[a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)*$/;
  private email_domain = /[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;
  private tel = /^\d{10,15}$/;
  private number = /^\d{10}$/;
  private postalCode = /^\d{5}$/;
  private emailRegex = /^[a-zA-Z0-9._%+-]{1,15}(?<!@!<!-!)$/;
  private dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
  private email: string[] = ['', ''];
  private fechaOriginal: string = this.buildDate(new Date());
  fechaFormateada: string = '';

  constructor(private formBuilder: FormBuilder, private el: ElementRef) {}

  ngAfterViewInit() {
    this.componenteDOM = this.el.nativeElement;
    if (this.name) {
      this.elementoDOM = document.getElementById(this.name);
      if (this.elementoDOM) {
        this.inputValue = this.elementoDOM.value;
        if (!this.required) {
          this.elementoDOM.classList.add('valid');
        }
      }
    }
  }

  ngOnInit(): void {
    this.fechaFormateada = this.buildDate(new Date());
  }

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.type === 'date') {
      const date = this.dateRegex.test(this.value) ? new Date(this.value) : new Date();
      this.fechaFormateada = this.buildDate(date);
    }
  }

  onChange(event: any) {
    this.event.emit({
      value: event.target.value,
      event: event,
    });
  }

  onKeyPress(key: string, first: boolean) {
    if (first) this.email[0] = key;
    else this.email[1] = key;

    this.keyPress.emit(this.email);
  }

  activeVip($event: any) {
    this.isVip = !this.isVip;
    this.vip = this.validateVip();
    $event.target.style.filter = this.vip;
  }

  onInputChange(event: any) {
    this.inputValue = event.target.value;
    this.element = event.target;
    this.elementEvent = event;
    this.filteredSuggestions = this.suggestions.filter((suggestion) =>
      suggestion.includes(this.inputValue)
    );
    let type;
    let name = event.target.name;
    const typeField = !event.target.dataset.type
      ? this.type
      : event.target.dataset.type;

    if (typeField === 'email_name') {
      type = 'email_name';
    } else if (event.target.dataset.type === 'email_domain') {
      type = 'email_domain';
      name = 'email_2';
      event.target.name = 'email_domain';
    } else if (typeField === 'postalCode') {
      type = 'postalCode';
    } else if (typeField === 'tel') {
      const telRegex = /^[0-9]+$/;
      if (!telRegex.test(this.inputValue)) {
        // Puedes bloquear la entrada o realizar alguna otra acción
        console.log('Entrada no válida para teléfono');
        this.inputValue = this.inputValue.slice(0, -1);
        this.element.value = this.inputValue;
        return;
      } else {
        if (this.element.value.length > 15) {
          this.inputValue = this.element.value.slice(0, 15);
          this.element.value = this.inputValue;
        }
      }
      type = this.type;
    } else if (typeField === 'date') {
      this.fechaFormateada = this.buildDate(new Date(this.element.value));
      type = this.type;
    } else {
      type = typeField;
    }
    this.typeField = type;
    if (this.inputValue === '' && this.required) {
      this.isValidName = '';
      this.statusField = '';
      this.isValidName = '';
      // if () {
      this.isValid = false;
      // }
    } else if (this.inputValue === '' && !this.required) {
      this.isValid = true;
      this.validInput(this.isValid);
    } else {
      this.isValid = this.validateInput(this.inputValue, type);
      this.validInput(this.isValid);
    }
  }

  validateVip() {
    return !this.isVip;
    // return this.isVip ? 'grayscale(0)' : 'grayscale(1)';
  }

  private setupForm() {
    if (this.formGroup) {
      Object.keys(this.formGroup.controls).forEach((controlName) => {
        if (this.formGroup) {
          const control = this.formGroup.get(controlName);
          if (control) {
            // this.applyValidators(control);
            if (this.required) {
              control.setValidators(Validators.required);
            }
          }
        }
      });
    }
  }

  private getTypeForControl(control: AbstractControl): string {
    if (control.value instanceof Date) {
      return 'date';
    } else if (typeof control.value === 'number') {
      return 'number';
    } else if (typeof control.value === 'string') {
      return 'text';
    } else {
      return 'default';
    }
  }

  private applyValidators(control: AbstractControl) {
    switch (this.type) {
      case 'text':
        control.setValidators([
          // Validators.required,
          this.createRegexValidator(this.textRegex),
        ]);
        break;
      case 'email_domain':
        control.setValidators([
          // Validators.required,
          Validators.pattern(this.email_name),
        ]);
        break;
      case 'email_name':
        control.setValidators([
          // Validators.required,
          Validators.pattern(this.email_name),
        ]);
        break;
      case 'email':
        // control.setValidators([Validators.required]);
        break;
      case 'number':
        control.setValidators([
          // Validators.required,
          Validators.min(0),
        ]);
        break;
    }
    control.updateValueAndValidity();
  }

  private createRegexValidator(regex: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value ? control.value.toString() : '';
      if (!value) {
        return null;
      }
      if (!regex.test(value)) {
        return { regex: true };
      }
      return null;
    };
  }

  private validateInput(value: string, type: any): boolean {
    console.log("type:" + type, "value:"+value);

    const regex = this.getRegexForControl(type);
    return regex.test(value);
  }

  public getRegexForControl(type?: string): RegExp {
    switch (type) {
      case 'text':
        return this.textRegex;
      case 'name':
        return this.nameRegex;
      case 'email':
        return this.emailRegex;
      case 'number':
        return this.number;
      case 'tel':
        return this.tel;
      case 'postalCode':
        return this.postalCode;
      case 'email_name':
        return this.email_name;
      case 'email_domain':
        return this.email_domain;
      case 'date':
        return this.dateRegex;
      default:
        return this.textRegex;
    }
  }

  isFormValid(): boolean {
    return this.formGroup ? this.formGroup.valid : false;
  }

  validInput(isValid: boolean) {
    const elementoPadre = this.elementoDOM;
    if (elementoPadre) {
      if (isValid) {
        elementoPadre.closest('div.input__form').classList.remove('invalid');
        this.isValidName = 'valid';
        this.statusField = '';
      } else {
        elementoPadre.closest('div.input__form').classList.remove('valid');
        this.isValidName = 'invalid';
        this.statusField = 'Campo requerido.';
      }
    }
  }

  getHtmlElement() {
    return this.elementoDOM;
  }

  selectSuggestion(suggestion: string) {
    if (this.formGroup && this.elementEvent && this.elementEvent.target) {
      var targetId = this.elementEvent.target.id;
      var control = this.formGroup.get(targetId);
      if (control) {
        control.setValue(suggestion);
        this.onInputChange(this.elementEvent);
      }
    }
    this.filteredSuggestions = [];
  }

  removeClass(element: any, className: string) {
    if (element) {
      element.classList.remove(className);
    }
  }
  formatearFecha(fechaOriginal: string) {
    this.fechaFormateada = this.buildDate(
      new Date(!this.fechaOriginal ? fechaOriginal : this.fechaOriginal)
    );
  }

  buildDate(fecha: Date) {
    const dia = fecha.getDate() + 1;
    const mes = fecha.getMonth() + 1;
    const año = fecha.getFullYear();
    return `${dia < 10 ? '0' : ''}${dia}-${mes < 10 ? '0' : ''}${mes}-${año}`;
  }
}
