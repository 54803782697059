import { AfterViewInit, Component, Input, inject } from '@angular/core';
import { StorageService } from '@core/services/storages.service';
import { Chart, registerables } from 'node_modules/chart.js';
Chart.register(...registerables);

@Component({
  selector: 'app-so-chart-doughnut-simple',
  templateUrl: './so-chart-doughnut-simple.component.html',
  styleUrls: ['./so-chart-doughnut-simple.component.scss']
})
export class SoChartDoughnutSimpleComponent implements AfterViewInit {
  private readonly storageService = inject(StorageService);

  newArray: any[] | undefined;
  @Input() id!: string;
  @Input() title: string = '';
  @Input() data: any[] = [];
  @Input() footer: string = "";
  @Input() color: 'success' | 'primary' = 'primary';
  @Input() alignment: 'start' | 'end' | 'center' | 'between' = 'center';
  currentColor!: string;
  @Input() percentage!: number;

  private primaryColor = 'rgb(0, 42, 127)';
  private chartColorGreen = 'rgb(215, 240, 104 )';
  private textColor = 'rgb(0, 0, 0)';
  private chartColorOffset = 'rgb(229, 229, 229)';
  ngAfterViewInit() {
    this.setColorsByTheme();
    const [numero1, numero2] = this.data;
    const total = numero1 + numero2;
    this.newArray = this.data.map(data => data.numb);
    this.currentColor = this.primaryColor;
    switch (this.color) {
      case 'success':
        this.currentColor = this.chartColorGreen;
        break;
      case 'primary':
        this.currentColor = this.primaryColor;
        break;
    }
    this.chartDoughnut(this.percentage);
  }

  chartDoughnut(porce: number) {
    let textcolor = this.textColor;
    new Chart('doughnut-' + this.id, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: this.data,
            backgroundColor: [
              this.currentColor,
              this.chartColorOffset,
            ],
          },
        ]
      },
      options: {
        cutout: '70%',
        responsive: true,
      },
      plugins: [
        {
          id: 'text',
          beforeDraw: function (chart, a, b) {
            var width = chart.width,
              height = chart.height,
              ctx = chart.ctx;

            ctx.restore();
            var fontSize = (height / 114).toFixed(2);
            ctx.font = fontSize + 'em sans-serif';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = textcolor;
            var text = porce + '%',
              textX = Math.round((width - ctx.measureText(text).width) / 2),
              textY = height / 1.8;

            ctx.fillText(text, textX, textY);
            ctx.save();
          },
        },
      ],
    });
  }
  setColorsByTheme(){
    const { tema: theme } = this.storageService.getLocalStorage('userInfo').data;
    if (theme != 0) {
      this.textColor = 'rgb(255, 255, 255)';
      this.primaryColor = 'rgb(72, 82, 175)';
      this.chartColorOffset = 'rgb(255, 255, 255)';
    }
  }
}
