import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [ MatIconModule, CommonModule, ReactiveFormsModule ],
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent {
  @Output() event = new EventEmitter<any>();
  
  @Input() text: string = '';
  @Input() icons: string[] = [];
  @Input() name: string = '';
  @Input() type: string = 'text';
  @Input() errorMessage: string = '';
  @Input() placeholder: string = '';
  @Input() disabled: boolean = false;
  @Input() formGroup?: FormGroup;

  public currentIcon: string = '';
  public currentIconColor: string = '';
  public currentType: string = '';

  private indexIcon: number = 0;
  private arrayIconColors: string[] = ['text-so-grey', 'text-so-blue'];

  ngOnInit(): void {
    if (this.formGroup && this.disabled)
      this.formGroup.get(this.name)?.disable();

    if (this.icons.length > 0) {
      this.currentIcon = this.icons[0];
      this.currentIconColor = this.arrayIconColors[0];
    }

    this.currentType = this.type;
  }

  changeNextIcon() {
    this.currentIcon = this.icons[++this.indexIcon % this.icons.length];
    this.currentIconColor = this.arrayIconColors[this.indexIcon % this.arrayIconColors.length];

    if (this.type === 'password') switch (this.currentIconColor) {
      case 'text-so-grey': this.currentType = 'password'; break;
      case 'text-so-blue': this.currentType = 'text'; break;
    }
  }

  emitValue(event: any) {
    this.event.emit(event.target.value);
  }
}
