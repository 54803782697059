import { Component, OnInit, effect, inject } from '@angular/core';
import { Response } from '@modules/lead-management/submodules/directory/interfaces/summary-followups.interface';
import { SharedStateService } from '@modules/lead-management/submodules/directory/services/shared-state.service';
import { SummaryProspectService } from '@modules/lead-management/submodules/directory/services/summary-prospect.service';
import { PromiseStatus } from '@shared/enums';
import { Router } from '@angular/router';
import { DatosSeguimientosStore } from '@modules/lead-management/submodules/follow-ups/store/datos-seguimientos.store';
import { DynamicComponentService } from '@shared/components/atomics/body-loading/dynamic-component-service.service';
import { FollowUpInfoResponse } from '@modules/lead-management/submodules/follow-ups/interfaces';
import { FollowUpsService } from '@modules/lead-management/submodules/follow-ups/services/follow-ups.service';

@Component({
  selector: 'app-accordion-follow-up-history',
  templateUrl: './accordion-follow-up-history.component.html',
  styleUrls: ['./accordion-follow-up-history.component.scss']
})
export class AccordionFollowUpHistoryComponent implements OnInit {
  
  private sharedStateServices = inject(SharedStateService);
  private summaryProspectService = inject(SummaryProspectService);
  private readonly router = inject(Router);
  public readonly datosSeguimientosStore = inject(DatosSeguimientosStore);
  public readonly followUpsService = inject(FollowUpsService);
  private readonly dynamicComponentService = inject(DynamicComponentService);

  public summaryFollowups?: Response[] = [];

  PromiseStatus = PromiseStatus;
  requestStatus = PromiseStatus.Pending;
  errorMessage: string = '';
  ngOnInit(): void {
    this.loadSummaryFollowups()
  }

  loadSummaryFollowups() {
    let idProspect = this.sharedStateServices.idProspect();
    this.summaryProspectService
    .getSummaryFollowups(idProspect)
    .subscribe({
      next: (data) => {
        const response = data.response;
        this.summaryFollowups =  response;
        this.requestStatus = PromiseStatus.Fulfilled;
      },
      error: ({ error} ) => {
        this.errorMessage = error.message;
        this.requestStatus = PromiseStatus.Rejected;
      },
    });
  }
  goToFollowUp(event: boolean, item: Response) {
    if(event){
      const seguimiento = item.idSeguimiento
      this.dynamicComponentService.showBodyLoading();
      this.followUpsService.getFollowUpInformation(seguimiento).subscribe((rta: FollowUpInfoResponse) => {
        if(rta){
          this.datosSeguimientosStore.addSeguimiento({
            idSeguimiento: seguimiento, 
            datosTimeline: rta.response.seguimiento, 
            idEmpleado: this.sharedStateServices.idProspect(),
            nombreEmpleado: this.sharedStateServices.nameProspect()
          });
          this.dynamicComponentService.destroyBodyLoading();
          this.router.navigate(['/lead-management/followups']);   
        }
      });
    }
  }

}
