<form [formGroup]="form">
    <div [ngClass]="directionItems" >
        @if (isMultiBrand) {
            <div class="col" >
                <so-select-reactive-form
                    [selectLabel]="('brand' | translate) + ' *'"
                    [items]="listBrands"
                    [isLoading]="loadingBrand"
                    [formControlName]="brandControl"
                    (selectionChange)="onChangeBrand($event)"
                ></so-select-reactive-form>
            </div>
        }
        <div class="col">
            <so-select-reactive-form
                [selectLabel]="('car_of_interest' | translate) + ' *'"
                [items]="listCars"
                [isLoading]="loadingCars"
                [formControlName]="carsControl"
                (selectionChange)="onChangeCars($event)"
            ></so-select-reactive-form>
        </div>
        <div class="col">
            <so-select-reactive-form
                [selectLabel]="('car_model' | translate) + ' *'"
                [items]="listYears"
                [isLoading]="loadingYears"
                [formControlName]="yearsControl"
                (selectionChange)="onChangeCarYears($event)"
            ></so-select-reactive-form>
        </div>
        <div class="col">
            <so-select-reactive-form
                [selectLabel]="('car_version' | translate ) + ' *'"
                [items]="listVersions"
                [isLoading]="loadingVersions"
                [formControlName]="versionsControl"
                (selectionChange)="onChangeVersionCar($event)"
            ></so-select-reactive-form>
        </div>
    </div>
    <div [ngClass]="directionItems">
        <div class="col">
            <app-so-select-custom-reactive
                [selectLabel]="('car_color' | translate) +' *'"
                [isSelectColor]="true"
                [items]="listColors"
                [formControlName]="colorsControl"
            >
            </app-so-select-custom-reactive>
        </div>
        <div class="col">
        </div>
        <div class="col">
        </div>
        @if (isMultiBrand) {
            <div class="col" >
            </div>
        }  
    </div>
</form>
