import { Injectable } from '@angular/core';
import { Request } from '@core/models/requests.model';
import { SimpleLead } from '../interfaces/simple-lead-interface';
@Injectable({
  providedIn: 'root',
})
export class SimpleLeadService {
  private url = `ventas/captura-prospecto/quickadd`;

  constructor(private request: Request) {}

  registerProspect(body:any) {
    const options = { headers: this.request.headers };
    return this.request.Post<SimpleLead>(this.url,body, options);
  }

  formatValues(data: any[], keyProp: string, valueProp: string): { key: any; value: any }[] {
    return data.map(item => ({ key: item[keyProp], value: item[valueProp] }));
  }
}
