import { Component, Input } from '@angular/core';

@Component({
  selector: 'so-card-notifications',
  templateUrl: './so-card-notifications.component.html',
  styleUrl: './so-card-notifications.component.scss'
})
export class SoCardNotificationsComponent {

  @Input() title?: string = '';
  @Input() namePerson?: string = '';
  @Input() description?: string = '';
  @Input() date: string = '';
  @Input() typeNotification: string = '';
  @Input() viewed: boolean = false;
  @Input() color?: string = 'default';

  obtenerLetraNombreApellido(nombreCompleto: string): string {
    const partesNombre = nombreCompleto.trim().split(' ');
    const primeraLetraNombre = partesNombre[0].charAt(0);
    const primerApellido =
      partesNombre.length > 1 ? partesNombre[partesNombre.length - 1] : '';
    const primeraLetraApellido = primerApellido.charAt(0);
    return `${primeraLetraNombre}${primeraLetraApellido}`;
  }

}
