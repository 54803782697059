<form [formGroup]="form">
    <div [ngClass]="directionItems">
        <div class="col">
            <so-select-reactive-form
                [selectLabel]="('interest_car_brand'|translate) + ' *'"
                [items]="listCarInterestBrand"
                [isLoading]="loaddingCarInterestBrand"
                [formControlName]="interestBrandControl"
                (selectionChange)="onChangeCarInterestBrand($event)"
            ></so-select-reactive-form>
        </div>
        <div class="col">
            <so-select-reactive-form
                [selectLabel]="('car_of_interest'|translate) + ' *'"
                [items]="listCarInterest"
                [isLoading]="loaddingCarInterest"
                [formControlName]="carInterestControl"
                (selectionChange)="onChangeCarInterest($event)"
            ></so-select-reactive-form>
        </div>
        <div class="col">
            <so-select-reactive-form
                [selectLabel]="('year_of_the_car'|translate) + ' *'"
                [items]="listCarsYear"
                [isLoading]="loaddingCarsYear"
                [formControlName]="yearsControl"
                (selectionChange)="onChangeCarYears($event)"
            ></so-select-reactive-form>
        </div>
    </div>
    <div [ngClass]="directionItems">
        <div class="col">
            <so-select-reactive-form
                [selectLabel]="('car_version'|translate) + ' *'"
                [items]="listCarsVersion"
                [isLoading]="loaddingCarsVersion"
                [formControlName]="versionsControl"
                (selectionChange)="onChangeCarVersions($event)"
            ></so-select-reactive-form>
        </div>
        <div class="col">
            <app-so-select-custom-reactive
                [selectLabel]="('car_color' | translate) +' *'"
                [isSelectColor]="true"
                [items]="listColors"
                [formControlName]="colorsControl"
                (itemSelected)="onChangeColor($event)"
            >
            </app-so-select-custom-reactive>
        </div>
        <div class="col">
            <so-select-reactive-form
                [selectLabel]="('quantity'|translate) + ' *'"
                [items]="listQuantity"
                [isLoading]="loaddingQuantity"
                [formControlName]="quantityControl"
            ></so-select-reactive-form>
        </div>
    </div>
</form>