import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { Request } from '@core/models/requests.model';
import type { MotivosRechazos, TiposDeRechazos } from '@core/interfaces/catalogos.intefaces';

@Injectable({
  providedIn: 'root',
})
export class RechazosService {
  private URL_MOTIVOS_RECHAZOS = 'global-catalogos/motivo-rechazo/all';
  private URL_TIPOS_RECHAZOS = 'global-catalogos/tipo-rechazo/all';
  request_: Request = inject(Request);

  getAllMotivosRechazos(): Observable<MotivosRechazos> {
    const options = { headers: this.request_.headers };
    const body = this.request_.getConfigUser();
    return this.request_.Post<MotivosRechazos>(`${this.URL_MOTIVOS_RECHAZOS}`, body, options);
  }

  getAllTiposDeRechazos() {
    const options = { headers: this.request_.headers };
    const body = this.request_.getConfigUser();
    return this.request_.Post<TiposDeRechazos>(`${this.URL_TIPOS_RECHAZOS}`, body, options);
  }
}
