<div class="menu-notifications">
    <div class="div-con-pico"></div>

    <div class="d-flex gap-1 title-notifications">
        <div class="icon-notifications">
            <so-icon svg="mdi-bell" [size]=".9" />
        </div>
        <p class="so-title mt-3"> {{ 'notifications' | translate }} </p>
    </div>

    <div class="subtitle-notifications">
        <so-select-reactive-form 
        [selectLabel]="'notification_type' | translate"
        [items]="typeNotifications"
        [isLoading]="loading"
        [selectedItemId]="selectItem"
        (selectionChange)="onChangeSelectTypeNotif($event)"
        />
    </div>

    <div class="filter-notifications">
        <div class="d-flex justify-content-between align-items-center">
            <p class="so-title mb-0 d-flex align-items-center">
                {{ 'all_notifications' | translate }} 
                <app-so-badge [text]="countNewNotifications.toString()" [isCircle]="true" color="danger" badgeSize="2.5em"></app-so-badge>
            </p>
            <div class="d-flex align-items-center cursor-pointer press-animation" (click)="sortNotificationsByDate()">
                <label class="so-title cursor-pointer no-select">{{'date'| translate}}</label>
                <mat-icon >unfold_more</mat-icon>
            </div>
        </div>
    </div>

    <div class="card-notifications">
        @if(isLoadingNotifications){
            <div style="margin-bottom: 8px;">
                <div class="card skeleton-section" >
                    <div class="card-body" style="padding: 12px 18px !important;">
                        <app-skeleton type="glow" borderRadius="15px" height="70%" ></app-skeleton>
                        <app-skeleton type="glow" borderRadius="15px" height="70%" ></app-skeleton>
                        <app-skeleton type="glow" borderRadius="15px" height="70%" ></app-skeleton>
                        <app-skeleton type="glow" borderRadius="15px" height="70%" ></app-skeleton>
                    </div>
                </div>
            </div>
        }@else {
            @for (item of listNotifications; track $index) {
                <div style="margin-bottom: 8px;">
                    <so-card-notifications
                        [title]="item.json.titulo"
                        [namePerson]="item.prospecto?.nombreCompleto"
                        [description]="item.json.mensaje"
                        [date]="item.fecha"
                        [typeNotification]="item.tipoDeNotificacion.nombre"
                        [viewed]="item.visto"
                        (click)="!item.visto ? changeViewedNotification(item.idNotificacion): ''"
                    >
                    </so-card-notifications>
                </div>
            }
        }
    </div>

</div>