import { Component } from '@angular/core';

@Component({
  selector: 'so-navbar-simple',
  templateUrl: './so-navbar-simple.component.html',
  styleUrl: './so-navbar-simple.component.scss'
})
export class SoNavbarSimpleComponent {

}
