import { Component, Input } from '@angular/core';
import { TitleStyleInjection } from '@shared/dto/title.style-injection.dto';
import { SoIconComponent } from '../so-icon/so-icon.component';

@Component({
  selector: 'app-title',
  standalone: true,
  imports: [ SoIconComponent ],
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent {
  @Input() icon: string = '';
  @Input() svg: string = '';
  @Input() iconScale: number = 1;
  @Input() iconSpace: number = 1;
  @Input() text: string = '';
  @Input() weight: string = '';
  @Input() size: 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' = 'base';

  @Input() injection: TitleStyleInjection = {};
}
