import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-so-accordion',
  standalone: true,
  templateUrl: './so-accordion.component.html',
  styleUrls: ['./so-accordion.component.scss']
})
export class SoAccordionComponent {
  /*
   idAccordion_Referece: Es la referecnai para cuando se tenga mas de un "Grupo" Acordeon y que se cierre cuando se abra Uno.
   Si no se Declara o No se llaman igual que el Panel Siempre se mantendra abierto, si es mas de un "Grupo" de Acordeon.
   Se debe de declarar tanto en el "Padre" como en los "Hijos" (Panel)
   */
  @Input() idAccordion_Referece = '';
  @Input() styleAccordion = '';

}
