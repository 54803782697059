import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from '@core/services/storages.service';
import { UserInfoIA } from '@modules/configuration-ia/interfaces/token/token.interface';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private url = 'https://qa.sicopweb.com/auth/10.0.0';
  public urlToken: string = '';

  constructor(
    private http: HttpClient,
    private activetedRoute: ActivatedRoute,
    private storageService: StorageService
  ) { }

  public getTokenConfigIa(){
    let body = new HttpParams();

    body = body.set('Token', this.getUrlToken());

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http.post<UserInfoIA>(`${this.url}/login`, body,{ headers }).pipe(take(1));
  }

  private getUrlToken(): string | number | boolean{    
    let token: string | null = '';

    this.activetedRoute.queryParamMap.subscribe(params => {
      token = params.get('token');      
    });

    this.urlToken = this.removeSpace(token);

    return this.removeSpace(token);
  }

  public setUserIALocalStorage(values: any){
    this.storageService.setLocalStorage('userInfo', values);
  }

  public deleteUserAILocalStorage(){
    this.storageService.removeLocalStorage('userInfo');
  }

  public removeSpace(value: string): string{
    return value.replaceAll(' ', '+');
  }

}
