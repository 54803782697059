import { Component } from '@angular/core';

@Component({
  selector: 'so-pending-menu',
  templateUrl: './so-pending-menu.component.html',
  styleUrl: './so-pending-menu.component.scss'
})
export class SoPendingMenuComponent {

  actividades: any = [
    { value: '1', actividad: 'Actividad 1' },
    { value: '2', actividad: 'Actividad 2' },
    { value: '3', actividad: 'Actividad 3' },
    { value: '4', actividad: 'Actividad 4' },
    { value: '5', actividad: 'Actividad 5' }
  ];

  cardsData = [
    { title: "Maria Grisa", date: "12-03-24", brColor: "success-light", badgeText: "Activo", text: "Llamada de Reinvitacion" },
    { title: "Maria Grisa", date: "12-03-24", brColor: "danger-light", badgeText: "No Atendido", text: "Primer contacto piso en agencia cotización" },
    { title: "Maria Grisa", date: "12-03-24", brColor: "warning-light", badgeText: "Inactivo", text: "Agendar entrega de ficha técnica" },
    { title: "Maria Grisa", date: "12-03-24", brColor: "black", badgeText: "Rechazado", text: "Depósito de crédito" },
    { title: "Maria Grisa", date: "12-03-24", brColor: "success-blue", badgeText: "Postventa", text: "Revisión de neumáticos de servicio primera" },
    { title: "Maria Grisa", date: "12-03-24", brColor: "danger-light", badgeText: "No Atendido", text: "Primer contacto piso en agencia cotización" },
    { title: "Maria Grisa", date: "12-03-24", brColor: "success-light", badgeText: "Activo", text: "Llamada de Reinvitacion" },
  ];

}
