import { Component, inject, Input } from '@angular/core';
import { TranslateService } from '@core/api/translate.service';

@Component({
  selector: 'so-sidebar-simple',
  templateUrl: './so-sidebar-simple.component.html',
  styleUrl: './so-sidebar-simple.component.scss'
})
export class SoSidebarSimpleComponent {
  private readonly translateService = inject(TranslateService);

  @Input() showVersionApp: boolean = true;
  @Input() customStyle: string = '';
  @Input() showTextApp: boolean = false; 
  @Input() showLogo: boolean = true;
  @Input() textApp: string = this.translateService.instant('ai_configuration', 'Configuración IA');
  @Input() textAppTop: string = '96%';
}
