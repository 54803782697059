<app-so-modal modalId="sumary-new-follow-up" [hideButtonClose]="true">

  <!-- Modal header -->
  <div class="modal__header" [style]="{'display':'flex','width':'100%','margin':'45px 0 17px 0'}">
    <div class="w-fit center-element mt-3">
      <app-so-title-icon title="{{'new_tracking'|translate}}" gap="15px" fontSize="normal" logo="/assets/img/mdi-event_note.svg"></app-so-title-icon>
    </div>


  </div>
  <!-- End Modal header -->

  <div class="modal__body" [style]="{'padding':'10px 30px'}">

    <app-so-card-bg icon="" brColor="primary" sizeCard="large" [checkCircle]="true" title="Llamada de seguimiento"
    description="Descripción breve del seguimiento" date="02-06-22" icon="mdi-phone-sync"
    badgeText="{{'sales_advisor'|translate|uppercase}}"></app-so-card-bg>
    <app-so-card-bg brColor="primary" sizeCard="large" [checkCircle]="true" title="Solicitar asistencia de BM"
    description="Descripción breve del seguimiento" date="02-06-22" icon="mdi-credit-card-refresh-outline"
    badgeText="{{'sales_advisor'|translate|uppercase}}"></app-so-card-bg>
    <app-so-card-bg brColor="primary" sizeCard="large" [checkCircle]="true" title="Demostración"
      description="Descripción breve del seguimiento" date="02-06-22" icon="mdi-steering"
      badgeText="{{'sales_advisor'|translate|uppercase}}"></app-so-card-bg>
    <app-so-card-bg brColor="primary" sizeCard="large" [checkCircle]="true" title="Valuación"
      description="Descripción breve del seguimiento" date="02-06-22"
      icon="mdi-car-info-primary" badgeText="{{'sales_advisor'|translate|uppercase}}"></app-so-card-bg>
    <app-so-card-bg brColor="danger-light" sizeCard="large" [checkCircle]="true" title="Iniciar Compra"
      description="Descripción breve del seguimiento" date="02-06-22" icon="mdi-shopping-cart-danger"
      badgeText="{{'user_box'|translate|uppercase}}"></app-so-card-bg>
    <div class="w-100 d-flex justify-content-center mt-2">
      <button type="button" class="btn center-element"
        style="width:fit-content; display: flex; align-items: center;height: 60px; font-size: 12px;"
        data-bs-dismiss="modal" aria-label="Close">{{'cancel'|translate}} <mat-icon class="so-secondary"
          style="font-size: 25px;">highlight_off</mat-icon></button>
    </div>

  </div>


</app-so-modal>
