import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ImageCroppedEvent, ImageCropperComponent, LoadedImage } from 'ngx-image-cropper';
import { CoreModule } from "../../../../core/core.module";
import { SoSvgIconComponent } from "../../atomics/so-svg-icon/so-svg-icon.component";
import { ButtonComponent } from "../../../../common/components/atomics/button/button.component";

@Component({
  imports: [ImageCropperComponent, MatSliderModule, FormsModule, CoreModule, SoSvgIconComponent, ButtonComponent],
  standalone: true,
  templateUrl: './so-img-cropper.component.html',
  styleUrl: './so-img-cropper.component.scss'
})
export class SoImgCropperComponent {
  @Input() imageChangedEvent: Event | null = null;

  @Output() fileSelected = new EventEmitter<File>();

  croppedImage: SafeUrl  = '';
  croppedUrl: string = '';
  width:number = 256
  height:number = 256
  @Input() type: 'background' | 'avatar' = 'background';
  zoom: number = 1;
  constructor(
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data?: { event: Event, type: 'background' | 'avatar' },
  ) {}
  ngOnInit(): void {
    if(this.data){
      if(this.data.event){
        this.imageChangedEvent = this.data.event;
        this.type = this.data.type;
      }
    }
  }
  fileChangeEvent(event: Event): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl as string);
    this.croppedUrl = event.objectUrl ?? '';
  }
  imageLoaded(image: LoadedImage) {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }
  downloadCroppedImage(): void {
    const link = document.createElement('a');
    link.href = this.croppedUrl;
    link.download = 'cropped-image.png';
    link.click();
  }
  saveCroppedImage(): void {
    var input;
    if(this.data){
      input = this.data.event.target as HTMLInputElement;
    }else{
      input = this.imageChangedEvent?.target as HTMLInputElement;
    }
    if(input.files && input.files[0]){
      const file = input.files[0];
      this.convertUrlToBlob(this.croppedUrl).then((blob) => {
        const fileReturn = new File([blob], file.name, { type: file.type });
        this.fileSelected.emit(fileReturn);
        this.dialog.openDialogs[0].close(fileReturn);
      })
    }
  }
  convertUrlToBlob(url: string): Promise<Blob> {
    return fetch(url)
      .then((response) => response.blob())
      .catch((error) => {
        console.error('Error al convertir URL a Blob:', error);
        throw error;
      });
  }
  addZoom(): void {
    this.zoom < 2 ? this.zoom += 0.1 : this.zoom;
  }
  removeZoom(): void {
    this.zoom > 0.2 ? this.zoom -= 0.1 : this.zoom;
  }
}
