@if (requestStatus === PromiseStatus.Pending) {
<so-spinner />
}
@else if (requestStatus === PromiseStatus.Rejected) {
<span class="so-text-2 d-block text-center">{{ errorMessage }}</span>
}
@else if (responseData.response.length === 0) {
<span class="so-text-2 d-block text-center">{{ 'no_data' | translated }}</span>
}
@else {
<div class="content-registro">

  <div class="registro" *ngFor="let data of responseData.response">

    <ng-content select=".header">

    </ng-content>
    <div class="sub-header">
      <div class="estado-venta">
        <div style="text-align: left;">
          <b>{{'invoice_date'|translate}}</b>
          <p>{{ data.fechaValuacion}}</p>
        </div>
        <div>
          <app-so-badge [color]="data.status === 'Realizadas'? 'success-light':'success-light'"
            [text]="data.status"></app-so-badge>
        </div>
      </div>
    </div>
    <div class="info-auto seccion">
      <b>{{'distributor'|translate}}</b>
      <div class="content-image-desc">
        <div class="image-component circle"></div>
        <p>{{data.distribuidor}}</p>
      </div>
    </div>
    <div class="info-auto seccion">
      <b>{{'car'|translate}}</b>
      <div class="content-image-desc">
        <div class="image-component circle"></div>
        <p>{{data.auto}}</p>
      </div>
    </div>
    <div class="info-auto seccion">
      <b>{{'appraiser'|translate}}</b>
      <div class="content-image-desc">
        <div class="image-component circle"></div>
        <p>{{data.valuador}}</p>
      </div>
    </div>
    <div class="nombre-ejecutor seccion">
      <b>{{'license_plate'|translate}}</b>
      <p>{{data.placas}}</p>
    </div>
    <div class="adicionales">
      <div class="button-icon">
        <div class="image">
          <img src="./assets/img/mdi-print-white-green.svg" alt="">
        </div>
        <p>{{'print'|translated}}</p>
      </div>
    </div>
    <ng-content select=".footer">

    </ng-content>

  </div>
</div>
}
