import { Component, OnInit, ViewChild, inject, ElementRef } from '@angular/core';
import { SessionHistory } from '@modules/settings/interfaces/session-history';
import { SessionHistoryService } from '@modules/settings/services/session-history/session-history.service';

@Component({
  selector: 'app-so-session-history',
  templateUrl: './so-session-history.component.html',
  styleUrls: ['./so-session-history.component.scss'],
})
export class SoSessionHistoryComponent implements OnInit{
  @ViewChild('sessionContainer', {static: true}) sessionContainer?: ElementRef;

  resizeObserver?: ResizeObserver;
  columnClass: string = 'd-flex flex-row justify-content-between h-12 align-items-center';
  timeLocationClass: string = 'session-timezone';

  private sessionHistoryService = inject(SessionHistoryService);

  public history: SessionHistory[] = [];

  ngOnInit(): void {
    this.sessionHistoryService.getSessionHistory().subscribe(
      (res: any ) => {
        this.history = res.response.HistorialDeSesiones;
      }
    )
  }

  ngAfterViewInit() {
    this.resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.contentRect.width < 381) {
          this.columnClass = '';
          this.timeLocationClass = '';
        } else {
          this.columnClass = 'd-flex flex-row justify-content-between h-12 align-items-center';
          this.timeLocationClass = 'session-time-location'
        }
      }
    });

    this.resizeObserver.observe(this.sessionContainer?.nativeElement);
  }

  ngOnDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }
}
