<div class="container--message {{ transmitter }}">
  <div class="container--text">
    <p>
      {{ message }}
    </p>
  </div>

  <div class="container--icon {{ position }}" *ngIf="icon">
    <so-icon svg="icono-compuesto-chat-soporte" [size]="0.5" />
  </div>
</div>
