import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment as env } from '../../../../environments/environment';
import { GlobalSessionService } from '../global-session.service';
import { map } from 'rxjs';

interface ApiResponse {
  success: boolean;
  message: string;
  response: CarYear[];
}

interface CarYear {
  anio: number;
}

interface BrandResponse {
  success: boolean;
  message: string;
  response: Brand[];
}

interface Brand {
  idmarca: string;
  alias: string;
}

interface CarResponse {
  success: boolean;
  message: string;
  response: Car[];
}

interface Car {
  nombre: string;
  idauto: string;
}

interface VersionResponse {
  success: boolean;
  message: string;
  response: Product[];
}

interface Product {
  nombre: string;
  idproducto: string;
}

interface ColorResponse {
  success: boolean;
  message: string;
  response: Color[];
}

interface Color {
  idcolor: string;
  color: string;
  nombre: string;
  activo: string;
}

@Injectable({
  providedIn: 'root',
})
export class AddCarInventoryService {
  private readonly http = inject(HttpClient);
  private readonly global = inject(GlobalSessionService);
  private url = '/ventas/catalogos/seminuevos';

  getYearList() {
    const body = {
      configuracion: {
        basesDeDatos: {
          dbEmpresa: this.global.userInfo().config.basesDeDatos.dbEmpresa
        },
        idioma: {
          idIdioma: this.global.userInfo().config.idioma.idIdioma,
          idPais: this.global.userInfo().config.idioma.idPais
        }
      }
    };
    return this.http.post<ApiResponse>(`${env.API_URL}/api/${env.API_VERSION}${this.url}/anio/search`, body)
    .pipe(
      map(response => response.response.map(item => ({ key: item.anio, value: item.anio })))
    );
  }

  getBrandList(year: string) {
    const body = {
      configuracion: {
        basesDeDatos: {
          dbEmpresa: this.global.userInfo().config.basesDeDatos.dbEmpresa
        },
        idioma: {
          idIdioma: this.global.userInfo().config.idioma.idIdioma,
          idPais: this.global.userInfo().config.idioma.idPais
        },
      },
      anio: year
    };
    return this.http.post<BrandResponse>(`${env.API_URL}/api/${env.API_VERSION}${this.url}/marcas/search`, body)
    .pipe(
      map(response => response.response.map(item => ({ key: item.idmarca, value: item.alias })))
    );
  }

  getCarList(year: string, idBrand: string) {
    const body = {
      configuracion: {
        basesDeDatos: {
          dbEmpresa: this.global.userInfo().config.basesDeDatos.dbEmpresa
        },
        idioma: {
          idIdioma: this.global.userInfo().config.idioma.idIdioma,
          idPais: this.global.userInfo().config.idioma.idPais
        },
      },
      anio: year,
      idMarca: idBrand
    };
    return this.http.post<CarResponse >(`${env.API_URL}/api/${env.API_VERSION}${this.url}/auto/search`, body)
    .pipe(
      map(response => response.response.map(item => ({ key: item.idauto, value: item.nombre })))
    );
  }

  getVersionList(year: string, idBrand: string, idCar:string) {
    const body = {
      configuracion: {
        basesDeDatos: {
          dbEmpresa: this.global.userInfo().config.basesDeDatos.dbEmpresa
        },
        idioma: {
          idIdioma: this.global.userInfo().config.idioma.idIdioma,
          idPais: this.global.userInfo().config.idioma.idPais
        },
      },
      anio: year,
      idMarca: idBrand,
      idAuto: idCar
    };
    return this.http.post<VersionResponse>(`${env.API_URL}/api/${env.API_VERSION}${this.url}/version/search`, body)
    .pipe(
      map(response => response.response.map(item => ({ key: item.idproducto, value: item.nombre })))
    );
  }

  getColorList() {
    const body = {
      configuracion: {
        basesDeDatos: {
          dbEmpresa: this.global.userInfo().config.basesDeDatos.dbEmpresa
        },
        idioma: {
          idIdioma: this.global.userInfo().config.idioma.idIdioma,
          idPais: this.global.userInfo().config.idioma.idPais
        }
      }
    };
    return this.http.post<ColorResponse>(`${env.API_URL}/api/${env.API_VERSION}${this.url}/color/search`, body)
    .pipe(
      map(response => response.response.map(item => ({ key: item.idcolor, value: item.nombre })))
    );
  }
}