import { Component, Input } from '@angular/core';
import { ButtonComponent } from "../../../../common/components/atomics/button/button.component";
import { CoreModule } from "../../../../core/core.module";
import { NgClass } from '@angular/common';
import { SoSvgIconComponent } from "../so-svg-icon/so-svg-icon.component";

@Component({
  selector: 'app-so-indicator-text',
  standalone: true,
  imports: [ButtonComponent, CoreModule, NgClass, SoSvgIconComponent],
  templateUrl: './so-indicator-text.component.html',
  styleUrl: './so-indicator-text.component.scss'
})
export class SoIndicatorTextComponent {
  @Input() text!: string;
  @Input() textBold: boolean = false;
  @Input() subtext!: string;
  @Input() subtext2!: string;
  @Input() color!: string;
  @Input() background: string = '--so-timelime-background';
  @Input() number!: number;
  @Input() deleteButton: boolean = false;
  @Input() shadowText: boolean = false;
  @Input() isTemp: boolean = false;
  @Input() allowTextWrap: boolean = true;
  @Input() icon: string = '';
}
