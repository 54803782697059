import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { JwtToken } from '@core/interfaces/jwt.interface';
import { GlobalSessionService } from '@core/services/global-session.service';
import { StorageService } from '@core/services/storages.service';
import { TranslateService } from '@ngx-translate/core';
import { jwtDecode } from 'jwt-decode';
import { Observable, throwError } from 'rxjs';
import { environment as env } from '../../../environments/environment';

function isTokenExpired(token: JwtToken): boolean {
  // Obtener la fecha actual en milisegundos
  const currentTime = Date.now();

  // Obtener la fecha de creación del token en milisegundos
  const tokenCreationTime = token.iat * 1000; // token.iat es en segundos, lo convertimos a milisegundos

  // Calcular la diferencia en milisegundos
  const difference = currentTime - tokenCreationTime;

  // Convertir la diferencia a minutos
  const differenceInMinutes = difference / (1000 * 60);

  // Validar si han pasado más de 5 minutos desde la creación del token
  return differenceInMinutes > 5;
}

const renewToken = (
  token: string
): Observable<{ response: { token: string; refreshToken: string }}> => {
  const http = inject(HttpClient);

  if (!token) throwError;

  return http.post<{ response: { token: string; refreshToken: string }}>(
    env.API_URL + '/api/10.0.0/global-seguridad/usuario/refresh-token',
    {
      refreshToken: token,
      origin: '1',
    }
  );
};

export const isSessionGuard: CanActivateFn = ({}) => {
  const globalSession = inject(GlobalSessionService);
  const translateService = inject(TranslateService);
  const storageService = inject(StorageService);
  const router = inject(Router);

  const storageLang = localStorage.getItem('browserLang');
  translateService.use(storageLang ? storageLang : 'es');

  const token =
    storageService.getCookie('token') ||
    storageService.getLocalStorage('token');

  if(!token || 'object' == typeof(token))
    globalSession.resetAuthentication();
  else if (isTokenExpired(jwtDecode(token))) {
    renewToken(
      storageService.getCookie('refreshToken') ||
        storageService.getLocalStorage('refreshToken')
    ).subscribe({
      next: (data) => {
        storageService.setCookie('refreshToken', data.response.refreshToken);
        storageService.setLocalStorage('refreshToken', data.response.refreshToken);
        storageService.setCookie('token', data.response.token);
        storageService.setLocalStorage('token', data.response.token);
      },
      error: () => {
        globalSession.resetAuthentication();
        router.navigateByUrl('/auth/login');
      },
    });
  }

  globalSession.checkAuthStatus().subscribe();

  return true;
};
