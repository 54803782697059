import { Component, ElementRef, EventEmitter, HostListener, Input, Output, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-so-select-custom-reactive',
  templateUrl: './so-select-custom-reactive.component.html',
  styleUrl: './so-select-custom-reactive.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SoSelectCustomReactiveComponent),
      multi: true
    }
  ]
})
export class SoSelectCustomReactiveComponent implements ControlValueAccessor{

  @Input() set items(value: any[]) {
    if (value) {
      this._items = value.map(element => ({
        key: element.key,
        value: element.value,
        color: element.color
      }));
    } else {
      this._items = [];
    }
  }
  
  get items(): any[] {
    return this._items;
  }
  
  @Input() labelPosition?: 'izquierda' | 'derecha' | 'arriba';
  @Input() selectLabel?: string = '';
  @Input() widthLabel?: string = '';
  @Input() placeholder: string = '';
  @Input() isSelectColor? : boolean = false;
  @Input() name: string = '';
  @Input() isReactiveForm: boolean = true;
  @Input() defaultSelected: any = 3;
  @Output() itemSelected = new EventEmitter<{}>();
  @Output() event = new EventEmitter<any>();
  @ViewChild('customSelect') customSelect!: ElementRef;

  private _items: any[] = [];
  selectedItemValue: string | null = null;
  selectedItemKey: any | null = null;
  selectedItemColor: string | null = null;
  setKeySelectedItem: string | number | null = null; 

  isValid = false;
  showItems = false;
  touched = 'ng-untouched';
  theme: string | null | undefined;

  constructor() {
    this.theme = document.querySelector('html')?.getAttribute('data-theme');    
  }

  ngOnChanges() {
    if (this.defaultSelected !== null) {
      const defaultItem = this.items.find(item => item.key === this.defaultSelected);
      if (defaultItem) {
        this.writeValue(defaultItem.key); // Asignar valor por defecto usando writeValue
      }
    }
  }


  toggleItems() {
    this.showItems = !this.showItems;
  }

  selectItem(value: string, key: any, setColor?: any, event?: any, selectKey?: any) {
    this.setKeySelectedItem = selectKey;
    this.selectedItemValue = value;
    this.selectedItemKey = key;
    this.selectedItemColor = setColor;
    this.isValid = true;
    this.customSelect.nativeElement.blur();

    this.onChange(this.selectedItemKey);
    this.onTouched();
    this.touched = "ng-touched"
    
    this.itemSelected.emit({ key: this.selectedItemKey, labelValue: this.selectedItemValue });
    this.event.emit({
      value: this.selectedItemKey,
      event: event,
      EventTarget: event,
    })
  }
  
  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: any): void {
    // Actualizar el valor seleccionado cuando se llama externamente
    const selectedItem = this.items.find(item => item.key === value);
    if (selectedItem) {
      this.selectedItemValue = selectedItem.value;
      this.selectedItemKey = selectedItem.key;
      this.selectedItemColor = selectedItem.color;
    } else {
      this.selectedItemValue = null;
      this.selectedItemKey = null;
      this.selectedItemColor = null;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Puedes implementar esto si necesitas manejar el estado de deshabilitado
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.customSelect.nativeElement.contains(event.target)) {
      this.showItems = false;
      this.customSelect.nativeElement.blur();
    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault(); // Evita el comportamiento predeterminado (por ejemplo, desplazarse por la página)
      this.toggleItems(); // Activa el método para mostrar/ocultar las opciones
    }
  }

  getThemeColor(){
    const htmlElement = document.querySelector('html');
    const currentTheme = htmlElement?.getAttribute('data-theme');
    
  }

  @HostListener('window:storage', ['$event'])
  onStorageChange(event: any) {
    const newTheme = document.querySelector('html')?.getAttribute('data-theme');
    if (newTheme !== this.theme) {
      this.theme = newTheme;
      
      // Aquí puedes agregar el código para cambiar los colores de tu página web
      // dependiendo del nuevo valor de 'data-theme'
    }
  }

}
