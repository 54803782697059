import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-so-card-list',
  templateUrl: './so-card-list.component.html',
  styleUrls: ['./so-card-list.component.scss']
})
export class SoCardListComponent {
  @Input() title = '';
  @Input() date = '';
  @Input() prospect = '';
  @Input() isSuccess: boolean = false;
  @Output() buttonClick = new EventEmitter<boolean>();
  emmitClickEvent() {
    this.buttonClick.emit(true);
  }
}
