import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-so-follow-up',
  templateUrl: './so-follow-up.component.html',
  styleUrls: ['./so-follow-up.component.scss']
})

export class SoFollowUpComponent implements OnInit, OnChanges {
  @Input() checked: boolean = false;
  @Input() clicked: boolean = false;
  @Input() completed: boolean = false;
  @Input() multi: boolean = false;
  @Input() type?: 'Opcional' | 'Obligatorio' = 'Opcional';
  @Input() text: string = "Texto del objetivo";
  @Input() date: string | Date = "16-01-22";
  @Input() exStyle = '';
  public colorBadge: string = "default";
  public toggle: string = "keyboard_arrow_down";
  public borderActive: string = "transparent";
  public marked: string = "";
  public remarked: string = '';

  ngOnInit(): void {
    this.validateText();
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  validateText() {
    if (this.type === 'Opcional') {
      this.colorBadge = "default";
    } else if (this.type === 'Obligatorio') {
      this.colorBadge = "danger-light";
    }
    if (this.multi) {
      this.remarked = 'active';
    } else {
      this.remarked = '';
    }
  }

  validarStatus($event: any) {
    let check = $event.target.checked;
    this.checked = check;
  }

  openTarget(_$event: any) {
    this.clicked = !this.clicked;
    this.validateToggle();
  }

  validateToggle() {
    if (this.clicked) {
      this.toggle = "keyboard_arrow_right";
      this.marked = "font-semibold";
      this.borderActive = "rgb(0 42 127)";
    } else {
      this.toggle = "keyboard_arrow_down";
      this.marked = "";
      this.borderActive = "transparent";
    }
  }
}
