import { Component, ElementRef, HostListener } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navbar-panel',
  templateUrl: './navbar-panel.component.html',
  styleUrl: './navbar-panel.component.scss'
})
export class NavbarPanelComponent {
  typeModule: string | null = null;

  typeModuleV: string | null = null;

  showPendings: boolean = false;
  showNotifications: boolean = false;

  private routerSubscription: Subscription;

  constructor(
    private router: Router,
    private elRef: ElementRef
  ) {
    this.typeModuleV = localStorage.getItem('module');
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.typeModule = null;
      }
    });
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  openPendingMenu() {
    this.showNotifications = false;
    this.showPendings = !this.showPendings;
  }

  openNotificationMenu(){
    this.showPendings = false;
    this.showNotifications = !this.showNotifications;
  }

  hiddenElements() {
    this.showPendings = false;
    this.showNotifications = false;
  }

  navigateTo(route: string){
    const token = '+XrN1rRhTXA4tgvawShKdUKbZ6EmEbPOLS/rt9MrIlfZjk+x4yfapVXw8qKyEXVv';
    this.router.navigate([route], { queryParams: { token: token } });    
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.hiddenElements();
      const radioBtnPending = document.getElementById('three-home') as HTMLInputElement;
      if (radioBtnPending.checked) {
        radioBtnPending.checked = false;
      }
      const radioBtnNofications = document.getElementById('one-home') as HTMLInputElement;
      if(radioBtnNofications.checked){
        radioBtnNofications.checked = false;
      }
    }
  }

}

