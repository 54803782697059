<div>
  <app-so-modal modalId="modal-default-image" verticalPosition="center">
    <div class="modal__header">
    </div>
    <div class="modal__body" [style]="{'padding':'0px'}">
      <div class="d-flex justify-content-center">
        <img src="./assets/img/mdi-foto-library-blue.svg" alt="" style="width: 34px; height: 30px;">
        <p class="fw-bold" style="font-size: 18px; padding-left: 8px; padding-top: 2px;">
          {{'select_default_photo'|translate}}</p>
      </div>
      <section class="imgDefault">
        <div class="row">
          <img class="selectable-image" [ngClass]="{'selected': selectedImage === 'assets/img/mdi_avatar1.png'}"
            (click)="selectImage('assets/img/mdi_avatar1.png')" src="assets/img/mdi_avatar1.png">
          <img class="selectable-image" [ngClass]="{'selected': selectedImage === 'assets/img/mdi_avatar2.png'}"
            (click)="selectImage('assets/img/mdi_avatar2.png')" src="assets/img/mdi_avatar2.png">
          <img class="selectable-image" [ngClass]="{'selected': selectedImage === 'assets/img/mdi_avatar3.png'}"
            (click)="selectImage('assets/img/mdi_avatar3.png')" src="assets/img/mdi_avatar3.png">
        </div>
        <div class="row">
          <img class="selectable-image" [ngClass]="{'selected': selectedImage === 'assets/img/mdi_avatar4.png'}"
            (click)="selectImage('assets/img/mdi_avatar4.png')" src="assets/img/mdi_avatar4.png">
          <img class="selectable-image" [ngClass]="{'selected': selectedImage === 'assets/img/mdi_avatar5.png'}"
            (click)="selectImage('assets/img/mdi_avatar5.png')" src="assets/img/mdi_avatar5.png">
          <img class="selectable-image" [ngClass]="{'selected': selectedImage === 'assets/img/mdi_avatar6.png'}"
            (click)="selectImage('assets/img/mdi_avatar6.png')" src="assets/img/mdi_avatar6.png">
        </div>
      </section>
      <div class="text-center">
        <div class="col d-flex justify-content-center">
          <img class="float-start mr-3" src="./assets/img/mdi-alert-outline.svg" style="width: 26px; height:30px">
          <label>{{ 'select_and_confirm' | translate }}</label>
        </div>
        <div class="d-flex justify-content-center">
          <app-button svg="mdi-check-circle-outline" iconAlignment="right" [scale]=".8"
            text="{{ 'confirm' | translate }}" [injection]="{ label: 'pt-2' }" data-bs-target="#modal-simple" />
        </div>
      </div>
    </div>
  </app-so-modal>
</div>
