import { Component, Input, OnChanges, OnInit, SimpleChanges, inject } from '@angular/core';
import { StorageService } from '@core/services/storages.service';

@Component({
  selector: 'app-so-summary',
  templateUrl: './so-summary.component.html',
  styleUrl: './so-summary.component.scss',
})
export class SoSummaryComponent implements OnInit, OnChanges {
  private storageService = inject(StorageService);
  
  public currentProspect = this.storageService.getLocalStorage(
    'currentProspectDirectory'
  );

  @Input() name_Prospect = '';
  @Input() status_Prospect = '';
  @Input() styleAccordion = '';
  @Input() visible = true;
  notLoading: boolean = true;

  ngOnInit(): void {
    console.log("Current prospect",this.currentProspect);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('visible')) {
      if (this.visible)
        this.loadCurrentProspect();
    }
    else if (changes.hasOwnProperty('name_Prospect')) {
      this.notLoading = false;
      setTimeout(() => { this.notLoading = true; });
    }
  }

  loadCurrentProspect() {
    let currentProspect: { nombreCompleto: string; status: string; };
    new Promise((resolve) => {
      currentProspect = this.storageService.getLocalStorage(
        'currentProspectDirectory'
      );
      resolve(currentProspect);
    }).then(() => {
      this.name_Prospect = currentProspect.nombreCompleto;
      this.status_Prospect = currentProspect.status;
    })
  }

  @Input() iconsProspects = [
    {
      icon: 'mdi-pencil-outline-white',
      visible: true,
      event: 'modalEditarProspecto',
    },
    {
      icon: 'mdi-hand-coin-outline-white',
      visible: true,
      event: 'modalAsignarVenta',
    },
    {
      icon: 'add-circle-outline-white',
      visible: true,
      event: 'sumary-new-follow-up',
    },
    {
      icon: 'mdi-minus-circle-outline-white',
      visible: true,
      event: 'modal-reject-prospect',
    },
    {
      icon: 'mdi-print-white',
      visible: true,
      event: '',
    },
    {
      icon: 'mdi-account-switch-outline',
      visible: true,
      event: '',
    },
    {
      icon: 'mdi-shopping-cart-white',
      visible: true,
      event: 'modal-sale-order',
    },
    {
      icon: 'mdi-file-document-outline-white',
      visible: true,
      event: '',
    },
    {
      icon: 'mdi-car-key-white',
      visible: true,
      event: '',
    },
    {
      icon: 'mdi-car-info',
      visible: true,
      event: 'modal-valuation-request',
    },
    {
      icon: 'mdi-file-plus-outline',
      visible: true,
      event: 'modal-add-file-directory',
    },
    {
      icon: 'reload-alt-white',
      visible: true,
      event: 'modal-folloups-history',
    },
  ];
  public path = './assets/img/';
}
