@if (!imageSrc) {
    <div class="image-upload-container" [ngClass]="{ 'px-3': isOnlyInput }">
        <label class="image-upload-label" [style.width]="inputWidth" [style.height]="inputHeight">
            <input type="file" (change)="onImageSelected($event)" accept="image/*" [id]="idInptut" />
            <span class="upload-placeholder" > {{ label }} </span>
        </label>
    </div>
}@else {
    <div class="d-flex justify-content-start align-items-center" [ngClass]="{ 'px-3': isOnlyInput }">
        <img 
            [src]="imageSrc" 
            alt="Image Preview" class="image-preview" 
            [style.width]="imageWidth" 
            [style.height]="imageHeight" 
            [style.border-radius]="imageBorderRadius"
        />
        <div class="d-flex px-2">
            <div style="margin-right: 5px;"> 
                <app-button
                    type="button"
                    svg="mdi-pencil-outline-white"
                    [scale]=".7"
                    [injection]="{icon: 'margin-top: 0px !important' }"
                    [applyStyles]="false"
                    [applyChanges]="true"
                    (click)="triggerFileInput()"
                ></app-button> 
            </div>
            <app-button
                type="button"
                svg="mdi-delete-forever-outline-danger"
                [scale]=".7"
                [injection]="{icon: 'margin-top: 0px !important'}"
                [applyStyles]="false"
                (click)="resetImageInput()"
            ></app-button>
        </div>
        <input type="file" (change)="onImageSelected($event)" accept="image/*" [id]="idChangeInput" hidden />

    </div>
}