<div class="d-flex justify-content-center align-items-start">
    <app-card>
        <div class="container">
            <div class="pt-2">
                <div class="col d-flex gap-2" style="margin-top: 8px;">
                    <p class="so-text-1-bold mt-1"> {{ titleAvailableItems }} </p>
                </div>
                <div 
                    [style.width]="tablesWidth"  
                    [style.height]="tablesHeight"
                    (dragover)="onDragOver($event)"
                    (drop)="onDrop($event, 'itemsList')"
                    style="overflow-y: auto;"
                >
                    <ul class="list-group">
                        @for (item of items; track $index) {
                            <li 
                                class="list-group-item" 
                                (click)="selectItem(item)" 
                                [ngClass]="{'selected-row': item === selectedItem}"
                                draggable="true"
                                (dragstart)="onDragStart($event, item)"
                            >
                                {{ item.value }}
                            </li>
                        }
                    </ul>
                </div>
            </div>
        </div>
    </app-card>

    @if (showButtons) {
        <div class="d-flex flex-column align-items-center justify-content-center mx-3" [ngClass]="{'mt-5': tablesHeight >= '200px' }">
            <app-button 
                icon="chevron_right"
                type="button"
                (click)="addItem()"
            />
            <app-button
                icon="keyboard_double_arrow_right"
                type="button"
                (click)="addAllItems()"
            />
            <app-button
                icon="keyboard_double_arrow_left"
                type="button"
                (click)="removeAllItems()"
            />
            <app-button
                icon="chevron_left"
                type="button"
                (click)="removeItem()"
            />
        </div>
    }
    <app-card [ngClass]="{'mx-3': !showButtons}">
        <div class="container">
            <div class="pt-2">
                <div class="col d-flex gap-2" style="margin-top: 8px;">
                    <p class="so-text-1-bold mt-1"> {{ titleSelectedItems }} </p>
                </div>
                <div 
                    [style.width]="tablesWidth" 
                    [style.height]="tablesHeight"
                    (dragover)="onDragOver($event)"
                    (drop)="onDrop($event, 'itemsSelected')"
                    style="overflow-y: auto;"
                >
                    <ul class="list-group">
                        @for (item of itemsSelected; track $index) {
                            <li  
                                class="list-group-item" 
                                (click)="item.draggable ? selectItem(item): null" 
                                [ngClass]="{'selected-row': item.draggable && item === selectedItem}"
                                [draggable]="item.draggable"
                                (dragstart)="onDragStart($event, item)"
                                [attr.draggable]="item.draggable ? true : null"
                            >
                                {{ item.value }}
                            </li>
                        }
                    </ul>
                </div>
            </div>
        </div>
    </app-card>
</div>