import { Injectable } from '@angular/core';
import { Request } from '@core/models/requests.model';
import { Anios } from '@core/interfaces/catalogos.intefaces';

@Injectable({
  providedIn: 'root'
})
export class AniosFlotillasService {
  private url = `ventas/catalogos/flotillas/anio/search`;

  constructor(private request: Request) {}

  getAniosFlotillas(body:any) {
    const options = { headers: this.request.headers };
    return this.request.Post<Anios>(this.url,body, options);
  }
}
