import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-info',
  templateUrl: './so-card-info.component.html',
  styleUrls: ['./so-card-info.component.scss']
})
export class CardInfoComponent implements OnInit{

  @Input() width: string = this.validateWidth();
  @Input() height: string = "96";
  @Input() cardTitle: string = "Titulo aqui";
  @Input() buttonTitle: string = "";
  @Input() bgImg: string = "";
  @Input() iconCard: string = "";
  @Input() description: string = "";
  @Input() dataList: string[] = [''];
  @Input() iconSize: number = 1;
  @Input() customTitleStyle: string = "";
  @Input() onlyPath: boolean = false;

  ngOnInit(): void {
  }

  validateWidth(): string {

    switch (this.width) {
      case "50":
        this.width = "1/2";
        break;
      case "33":
        this.width = "2/3";
        break;
      case "25":
        this.width = "1/4";
        break;
      default:
        this.width = "1/4"
        break;
    }
    return this.width;
  }

}
