<div class="d-flex align-items-center justify-content-center gap-2" style="margin-bottom: 30px;">
    <img src="./assets/img/mdi-person-add-outline.svg" alt="" style="width: 40px;">
    <p style="font: normal normal bold 24px/29px Montserrat; color: #272D3B; margin-bottom: 0px;">
      {{'capture'|translate}}
    </p>
  </div>
  <div class="col-md-12 sombreado">
    <div class="card-content">
      <div style="height: 120px;">
        <div class="d-flex justify-content-center mt-3">
          <app-so-switch [labels]="true" labelLeft="{{'simple_capture'|translate}}"
            labelRight="{{'detail_capture'|translate}}" [isChecked]="isDetailCapture" [customStyleOption]="'verde'"
            (change)="changeDetailCapture();"></app-so-switch>
        </div>
        <div style="margin: 0px auto; width: 80%;">
          <div class="d-flex justify-content-between" style="margin: 3px auto;width: 90%;margin-bottom: -12px;">
            <button (click)="previousStep()">
              <img src="./assets/img/mdi-numeric-1-box-multiple-outline-primary.svg" style="width: 30px;">
            </button>
            <img [src]="isStep2 ? './assets/img/mdi-numeric-2-box-multiple-outline.svg' : './assets/img/mdi-numeric-2-box-multiple-outline-grey.svg'" >
          </div>
          <nav class="nav">
            <div class="marker">
            </div>
            <div class="nav__track">
              <ul class="nav__list">
                <div class="steps">
                  <div class="progress">
                    <div *ngIf="!isStep2" class="progress-bar progress-bar so-bg-secondary" role="progressbar"
                    [style]="isStep2 ? 'width:100%;' : 'width: 50%'" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" >
                    </div>
                  </div>
                  <li class="nav__link">
                    <a data-link class="complete"></a>
                  </li>
                  <li class="nav__link">
                    <a  data-link [ngClass]="isStep2 ? 'complete' : ''" ></a>
                  </li>
                </div>
              </ul>
            </div>
            <div class="timeline-desc">
              <span class="nav-label complete">{{'main_information'|translate}}</span>
              <span [ngClass]="isStep2 ? 'complete' : ''" class="nav-label">{{'interest_product'|translate}}</span>
            </div>
          </nav>
        </div>
        <div class="d-flex justify-content-center GreenBox">
          <label style="font: normal normal 800 16px/14px Montserrat;
            letter-spacing: 0px;color: #002A7F;">
            {{ 'step' | translate }} {{ isStep2 ? 2 : 1 }}
          </label>
          &nbsp;
          <label style="font: normal normal 600 16px/14px Montserrat; letter-spacing: 0px;color: #002A7F;
            opacity: 1;" >
            {{ isStep2 ?  ('interest_product'|translate).toUpperCase() : ('main_information'|translate).toUpperCase() }}
          </label>
        </div>
      </div>
    </div>
  </div>
  