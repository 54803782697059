<div 
    [class]="'p-1 select__form ' + touched + ' ng-dirty'" 
    [ngClass]="{'d-flex': labelPosition === 'izquierda', 'd-flex flex-row-reverse': labelPosition === 'derecha'}"
    [ngClass]="{
        'ng-valid': isValid && selectedItemKey && isReactiveForm,
        'ng-invalid': selectedItemKey == null && isReactiveForm
    }"
    >
    @if (selectLabel) {
        <label class="fw-medium p-1 custom-label" [style.width]="widthLabel">
            {{ selectLabel }}
        </label>
    }
    <div class="d-flex w-100">
        <div 
            class="custom-select border border-secondary-subtle w-100 form-select form-select-sm" 
            (click)="toggleItems()"
            (keydown)="onKeyDown($event)"
            tabindex="0"
            #customSelect
            [attr.data-value]="setKeySelectedItem"
            >
            <div class="selected" [id]="name" [attr.data-value]="setKeySelectedItem">
                @if (isSelectColor && selectedItemColor) {
                    <div class="circle" 
                        [style.background-color]="selectedItemColor"
                        [style]="{'border': selectedItemColor === '#FFFFFF' ? '1px solid' : 'none'}"
                        >
                    </div>
                }
                {{  selectedItemKey !== null ? selectedItemValue : 'selected' | translate }}
            </div>
            <ul class="items" [ngClass]="{ 'show': showItems }" [id]="name">
                @for (item of items; track $index) {
                    <li (click)="selectItem(item.value, item, item.color, $event, item.key)">
                        @if (isSelectColor) {
                            <div class="circle" 
                                [style.background-color]="item.color"
                                [style]="{'border': item.color === '#FFFFFF' ? '1px solid' : 'none'}">
                            </div>
                        }
                        {{ item.value | translate }} 
                    </li>
                }
            </ul>
        </div>
    </div>
</div>