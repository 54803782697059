<input type="radio" class="btn-check" name="options-mark" id="one-outlined" autocomplete="off" checked
  (change)="toggleBoolean()">
<label class="btn btn-marketing" for="one-outlined">
  <so-icon svg="database-search-outline-success" [size]="1" />
</label>

<input type="radio" class="btn-check" name="options-mark" id="two-outlined" autocomplete="off"
  (change)="toggleBoolean()">
<label class="btn btn-marketing" for="two-outlined">
  <so-icon svg="mdi-server-network-success" [size]="1" />
</label>

<input type="radio" class="btn-check" name="options-mark" id="three-outlined" autocomplete="off"
  (change)="toggleBoolean()">
<label class="btn btn-marketing" for="three-outlined">
  <so-icon svg="mdi-microsoft-excel-success" [size]="1" />
</label>

<input type="radio" class="btn-check" name="options-mark" id="four-outlined" autocomplete="off"
  (change)="toggleBoolean()">
<label class="btn btn-marketing" for="four-outlined">
  <so-icon svg="mdi-newspaper-variant-multiple-outline-success" [size]="1" />
</label>
