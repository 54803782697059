import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-so-card',
  templateUrl: './so-card.component.html',
  styleUrls: ['./so-card.component.scss']
})
export class SoCardComponent {
  @Input() iconAlignment: 'left' | 'right' = 'left';
  @Input() titleAlignment: 'left' | 'right' | 'center' | 'between' = 'left';
  @Input() icon: string = '';
  @Input() fontSize: string = '';
  @Input() title: string = '';
  @Input() styleCard: string = '';

  public iconsFolderPath: string = 'assets/img/';
  public iconImg: boolean = false;

  ngOnInit() {
    if (!this.customIconExists(this.icon)) {
       this.iconImg = true;
    }
  }

  private customIconExists(iconName: string): boolean {

    if(iconName === '') return false;

    const customIconUrl = `${this.iconsFolderPath}${iconName}.svg`;
    const request = new XMLHttpRequest();
    request.open('HEAD', customIconUrl, false);
    request.send();

    return request.status !== 404;
  }
}
