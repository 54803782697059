@if (typeModuleV === 'lead-management') {
  <div class="d-flex gap-3 lead-management-container-lg">
    <div class="pending-menu">
      <div [ngClass]="showPendings ? '' : 'd-none'">
        <so-pending-menu></so-pending-menu>
      </div>
    </div>
  
    <div class="notification-menu">
      <div [ngClass]="showNotifications ? '': 'd-none'">
        <so-notification-menu></so-notification-menu>
      </div>
    </div>
  
    <input type="radio" class="btn-check" autocomplete="off"
       (click)="navigateTo('configuration-ia/main/suppliers')"/>
    <label class="btn btn-home position-relative iconNotif" >
      <so-icon svg="mdi-robot-secondary" [size]="1.1" (click)="navigateTo('configuration-ia/main/suppliers')" />
    </label>

    <input type="radio" class="btn-check" name="options-home" id="three-home" autocomplete="off"
       (click)="openPendingMenu()" [checked]="showPendings"/>
    <label class="btn btn-home position-relative iconNotif" for="three-home">
      <so-icon svg="account-clock-success" [size]="1.2" />
      <span class="position-absolute translate-middle so-bg-danger rounded-circle badge spanLabel spanGeneral">
        9
        <span class="visually-hidden"></span>
      </span>
    </label>
  
    <input type="radio" class="btn-check" name="options-home" id="one-home" autocomplete="off" 
       (click)="openNotificationMenu()" [checked]="showNotifications"/>
    <label class="btn btn-home position-relative iconNotif" for="one-home">
      <div style="margin-top: 2px;">
        <so-icon svg="mdi-bell-success" [size]=".9" />
      </div>
      <span class="position-absolute translate-middle so-bg-danger rounded-circle badge spanLabel spanGeneral">
        9
        <span class="visually-hidden"></span>
      </span>
    </label>
  
    <input type="radio" class="btn-check" name="options-home" id="two-home" autocomplete="off" [(ngModel)]="typeModule"
      value="multicanal">
    <label class="btn btn-home position-relative iconVoice" for="two-home" routerLink="/multicanal"
      [class.active]="typeModule === 'multicanal'" (click)="hiddenElements()">
      <so-icon svg="account-voice" [size]="1.5" />
      <span class="position-absolute translate-middle so-bg-danger rounded-circle badge spanLabel spanVoice">
        9
        <span class="visually-hidden"></span>
      </span>
    </label>
  
  </div>
  <div class="d-flex gap-3 lead-management-container-sm">
    <so-navbar-sub-menu></so-navbar-sub-menu>
  </div>
}@else if (typeModuleV === 'marketing') {
  <!-- MARKETING -->
<div class="d-flex">

  <input type="radio" class="btn-check" name="options-marketing" id="one-marketing" autocomplete="off"
    [(ngModel)]="typeModule" value="marketing">
  <label class="btn btn-marketing position-relative" for="one-marketing" routerLink="/marketing"
    [class.active]="typeModule === 'marketing'">
    <so-icon svg="mdi-3p" [size]="1" />
    <span class="position-absolute translate-middle so-bg-danger rounded-circle badge spanLabel spanGeneral">
      9
      <span class="visually-hidden"></span>
    </span>
  </label>


  <input type="radio" class="btn-check" name="options-marketing" id="two-marketing" autocomplete="off"
    [(ngModel)]="typeModule" value="marketing">
  <label class="btn btn-marketing position-relative" for="two-marketing" routerLink="/marketing"
    [class.active]="typeModule === 'marketing'">
    <so-icon svg="mdi-email-multiple-primary" [size]="1.3" />
    <span class="position-absolute translate-middle so-bg-danger rounded-circle badge spanLabel spanGeneral">
      9
      <span class="visually-hidden"></span>
    </span>
  </label>


  <input type="radio" class="btn-check" name="options-marketing" id="tre-marketing" autocomplete="off"
    [(ngModel)]="typeModule" value="marketing">
  <label class="btn btn-marketing position-relative" for="tre-marketing" routerLink="/marketing"
    [class.active]="typeModule === 'marketing'">
    <so-icon svg="mdi-bell" [size]="1" />
    <span class="position-absolute translate-middle so-bg-danger rounded-circle badge spanLabel spanGeneral">
      9
      <span class="visually-hidden"></span>
    </span>
  </label>


</div>
}


