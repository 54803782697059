import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-so-check',
  templateUrl: './so-check.component.html',
  styleUrls: ['./so-check.component.scss']
})
export class SoCheckComponent {
  @Input() labelText: string = '';
  @Input() isChecked: boolean = false;
  @Input() selectedIconSrc: string = '';
  @Input() value: any;

  @Output() selectionChanged = new EventEmitter<boolean>();

  onChange(event: Event): void {
    this.isChecked = !this.isChecked;
    this.selectionChanged.emit(this.isChecked);
  }
}
