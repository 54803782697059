<div 
  class="d-flex gap-1 w-fit align-items-center check-seekop {{textAlign}} {{direction}}"
  [class.disabled-style]="disabled"
  [style]="'gap:'+gap+'!important;width:fit-content'"
  >
    <label 
      for="{{checkId}}"
      [style]="'color:'+textColor+';font-size:'+fontSize+';font-weight:'+fontWeight+'; '"
      [ngClass]="{ 'truncate-text': truncateText }"
      [title]="truncateText ? text : null"
      >
      {{text}}
    </label>
    @if(!checked){
      <input 
          type="checkbox" 
          id="{{checkId}}"
          class="center-element"  
          [disabled]="disabled"
          [style]="'width: ' + size + 'px; height:' + size +'px;'" 
          (change)="onCheckboxChange($event)"
        />
    }@else {
      <mat-icon 
          (click)="onCheckboxChange($event)" 
          class="so-success center-element"
          [ngClass]="{ 'disabled-icon': disabled }"
          [style.pointer-events]="disabled ? 'none' : 'auto'"
      >check</mat-icon>
    }
</div>
