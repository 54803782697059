import { Component, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'so-navbar-sub-menu',
  templateUrl: './navbar-sub-menu.component.html',
  styleUrl: './navbar-sub-menu.component.scss'
})
export class NavbarSubMenuComponent implements OnDestroy {

  private typeModuleV: string | null = null;
  public typeModule: string | null = null;
  public showContainer = false;
  public showPendings: boolean = false;
  public showNotifications: boolean = false;

  private routerSubscription: Subscription;

  constructor(private router: Router,
    private elRef: ElementRef
  ) {
    this.typeModuleV = localStorage.getItem('module');
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.typeModule = null;
      }
    });
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }
  
  openContainer() {
    this.showContainer = !this.showContainer;    
  }

  leaveContainer() {
    this.showContainer = false;
    this.showNotifications = false;
    this.showPendings =  false;
  }

  openPendingMenu() {
    this.showNotifications = false;
    this.showPendings = !this.showPendings;
  }

  openNotificationMenu(){
    this.showPendings = false;
    this.showNotifications = !this.showNotifications;
  }

  hiddenElements() {
    this.showPendings = false;
    this.showNotifications = false;
    this.showContainer = false;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.hiddenElements();
    }
  }

}
