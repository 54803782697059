import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, type Observable } from 'rxjs';
import type { KDBDocument } from '@modules/configuration-ia/interfaces';

@Injectable({
  providedIn: 'root'
})
export class SharedStateKbdService {

  constructor() { }

  private idMarcaSelected = signal<string | null>(null);

  public get $idMarcaSelected(): string | null{
    return this.idMarcaSelected();
  }

  public setIdMarcaSelected(value: string | null){
    this.idMarcaSelected.set(value);
  }

  private textSelectedBrandId = signal<string | null>(null);

  public get $textSelectedBrandId(): string | null {
    return this.textSelectedBrandId();
  }

  public setTextSelectedBrandId(value: string | null) {
    this.textSelectedBrandId.set(value);
  }

  private idAuto = signal<string | null>(null);

  public get $idAuto(): string | null{
    return this.idAuto();
  }

  public setIdAuto(value: string | null){
    this.idAuto.set(value);
  }

  private textSelectedCarId = signal<string | null>(null);

  public get $textSelectedCarId(): string | null {
    return this.textSelectedCarId();
  }

  public setTextSelectedCarId(value: string | null) {
    this.textSelectedCarId.set(value);
  }

  private idModelo = new BehaviorSubject<string | null>(null);

  idModelo$ = this.idModelo.asObservable();

  public setIdModelo(value: string | null){
    this.idModelo.next(value);
  }

  private textSelectedCarModel = signal<string | null>(null);

  public get $textSelectedCarModel(): string | null {
    return this.textSelectedCarModel();
  }

  public setTextSelectedCarModel(value: string | null) {
    this.textSelectedCarModel.set(value);
  }

  private marcaText = signal<string | null>(null);

  public get $marcaText(): string | null{
    return this.marcaText();
  }

  public setMarcaText(value: string | null){
    this.marcaText.set(value);
  }

  private autoText = signal<string | null>(null);

  public get $autoText(): string | null{
    return this.autoText();
  }

  public setAutoText(value: string | null){
    this.autoText.set(value);
  }

  private modeloText = signal<string | null>(null);

  public get $modeloText(): string | null{
    return this.modeloText();
  }

  public setModeloText(value: string | null){
    this.modeloText.set(value);
  }

  private isCompleted = new BehaviorSubject<boolean>(true);
  
  public isCompleted$ = this.isCompleted.asObservable();

  public setIsCompleted(value: boolean){
    this.isCompleted.next(value);
  }

  private textFileUploadStep = new BehaviorSubject<number>(0);

  public get textFileUploadStep$(): Observable<number> {
    return this.textFileUploadStep.asObservable();
  }

  public set textFileUploadStep$(step: number) {
    this.textFileUploadStep.next(step);
  }

  private textFileIsCompleted = new BehaviorSubject<boolean>(true);

  public textFileIsCompleted$ = this.textFileIsCompleted.asObservable();

  public setTextFileIsCompleted(value: boolean) {
    this.textFileIsCompleted.next(value);
  }

  private newTextFileSignal = signal<KDBDocument | null>(null);

  public get newTextFile(): KDBDocument | null {
    return this.newTextFileSignal();
  }

  public set newTextFile(value: KDBDocument | null){
    this.newTextFileSignal.set(value);
  }

  private totalFiles = new BehaviorSubject<number>(0);

  public totalFiles$ = this.totalFiles.asObservable();
  
  public setTotalFiles(total: number){
    this.totalFiles.next(total);
  }

  private proccessFiles = new BehaviorSubject<number>(0);

  public proccessFiles$ = this.proccessFiles.asObservable();

  public setProccessFiles(value: number){
    this.proccessFiles.next(value);
  }

}
