import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { UserInfo, userInfoObject } from "@core/interfaces";

export const UserStore = signalStore(
    { providedIn: 'root' },
    withState(userInfoObject),
    withMethods(({ redirectTypeUser, config, data,  ...store }) => ({
        setUser(user: UserInfo) {            
            if(user.data.email != '') {
                localStorage.setItem('userInfo', JSON.stringify(user));
            }
            
            patchState(store, user);
        },
        remove() {
            localStorage.removeItem('userInfo');
            patchState(store, userInfoObject);
        }
    }))
);