import { Component, Input, AfterViewInit, ElementRef, Renderer2, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-so-color-replace',
  template: `<a #labelContainer></a>`
})
export class SoColorReplaceComponent {
  private readonly translateService = inject(TranslateService);

  @Input() text: string = '';
  @Input() replacement: string = '';

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit() {
    this.updateLabelContent();
  }

  private updateLabelContent() {
    this.translateService.get('days_remaining').subscribe((res: string) => {
      const regexNumber = /%[a-zA-Z]/g; // Expresión regular para capturar el número después de %d
      const replacedText = res.replace(regexNumber, this.replacement);

      const regexHighlighted = /\*(.*?)\*/g; // Expresión regular para capturar el texto entre **
      const finalText = replacedText.replace(regexHighlighted, '<a style="color: #FF7961;font-weight: bold;">$1</a>');

      this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', finalText);
    });
  }
}
