import { Injectable, inject } from '@angular/core';
import { StorageService } from '@core/services/storages.service';
import { Request } from '@core/models/requests.model';
import { ValuationsHistory } from '@core/interfaces/catalogos.intefaces';

@Injectable({
  providedIn: 'root',
})
export class ValuationsHistoryService {
  private url = `ventas/historial/valuacion/search`;
  private readonly storageService = inject(StorageService);
  private readonly request = inject(Request);

  getValuatiosHistory(idProspecto: string) {
    const body = {
      configuracion: this.request.getConfigUser(),
      idProspecto: idProspecto,
    };
    const options = {
      headers: this.request.headers,
    };
    return this.request.Post<ValuationsHistory>(`${this.url}`, body, options);
  }
}
