<div class="d-flex gap-4 justify-content-center" *ngIf="filters">
  <div class="d-flex gap-2" *ngFor="let data of dataSets, let i = index;">
    <div class="dots" [style.background-color]="data.borderColor"></div>
    <label style="font: normal normal medium 16px/19px Montserrat;">
      {{data.label}}
    </label>
    <app-so-checkbox [size]="20" (checkboxChange)="onCheckboxChange($event, i)" [checked]="!data.hidden"></app-so-checkbox>
  </div>
</div>

<canvas class="mychart" id="line_curve-{{id}}"></canvas>
