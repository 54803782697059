<div class="container-alert">
    <div class="title-alert" >
        <so-icon [svg]="'mdi-alert-outline'"></so-icon>
        <span>{{ 'duplicity_of_prospectus_warning' | translated}}  </span>
    </div>
    <div class="mt-4">
        <p class="text-body text-center">
            {{ ('the_prospect' | translated) }} <span> {{ nameProspect }} </span> {{('already_exists_in_database' | translated )  }}
        </p>
    </div>

    <div class="d-flex justify-content-between">
        <div style="position: relative; top: 8px;">
            <app-button
            svg="mdi-close-circle-outline-danger"
            iconAlignment="right"
            type="button"
            [text]="'cancel' | translated"
            [injection]="{icon: 'margin-top: 0px important;'}"
            [scale]=".7"
            [isDangerStyle]="true"
            (click)="closeDialog()"
        />
        </div>

        <app-button
            svg="mdi-account-switch-outline-secondary"
            iconAlignment="right"
            type="button"
            class="mx-3"
            [text]="'unify_prospects' | translated"
            [scale]=".7"
            [injection]="{ label: 'pt-2' }"
            (click)="redirectTo()"
        />
    </div>    
</div>