import { Component, Input, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart, registerables } from 'node_modules/chart.js';
Chart.register(...registerables);

@Component({
  selector: 'app-so-chart-line',
  templateUrl: './so-chart-line.component.html',
  styleUrls: ['./so-chart-line.component.scss']
})
export class SoChartLineComponent {
  private readonly translateService = inject(TranslateService);
  @Input() id!: string;
  @Input() labels: any[] = [];
  @Input() data: any[] = [];

  private salesYear = '';

  ngAfterViewInit() {
    this.translateService
      .get('sales_year_formatted')
      .subscribe((res: string) => {
        this.salesYear = res.replace('%s', '2023');
        this.chartLine();
      });
  }

  chartLine(){
    new Chart('line-' + this.id, {
      type: 'line',
      data: {
        labels: this.labels,
        datasets: [{
          label: "Objetivos",
          borderColor: "#C5C5C5",
          pointBackgroundColor: "#D7F068",
          pointBorderColor: "#042A7F",
          pointBorderWidth: 2,
          data: this.data,
          fill: false,
          borderWidth: 3,
          pointRadius: 6
        }]
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: this.salesYear,
            font: {
              size: 14
            }
          },
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            grid: {
              display: true,
              drawOnChartArea: true
            },
            ticks: {
              color: '#042A7F',
              font: {
                size: 14, // Cambia el tamaño de fuente en el eje X
                weight: 600,
                family: 'Montserrat'
              }
            },
            border: {
              display: false
            }
          },
          y: {
            display: false,
            suggestedMin: 0,
            suggestedMax: 100,
            ticks: {
              color: '#A3BD36',
              stepSize: 10,
              callback: function(value, index, values) {
                if (value === 0 || value === 100) {
                  return value + ' % ';
                }
                return '';
              },
              font: {
                size: 13, // Cambia el tamaño de fuente en el eje Y
                weight: 'bolder',
                family: 'Montserrat'
              }
            },
            grid: {
              display: true,
              drawOnChartArea: true
            },
            border: {
              display: false
            }
          }
        }
      }
    });
  }



}
