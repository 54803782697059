@if (requestStatus === PromiseStatus.Pending) {
<so-spinner />
}
@else if (requestStatus === PromiseStatus.Rejected) {
<span class="so-text-2 d-block text-center">{{ errorMessage }}</span>
}
@else if (responseData.response.length === 0) {
<span class="so-text-2 d-block text-center">{{ 'no_data' | translated }}</span>
}
@else {
<div class="content-registro">
  <div class="registro" *ngFor="let data of responseData.response">
    <ng-content select=".header">
    </ng-content>
    <div class="sub-header">
      <div class="fecha-estado" [ngClass]="data.realizado === 'true'?'success':'not'">
        <span class="fecha">{{data.fechaDemo}}</span>
        <app-so-badge [color]="data.realizado? 'success-light':'danger-light'"
          [text]="data.realizado === 'true' ? translate('made'):translate('not_completed')"></app-so-badge>
      </div>
    </div>
    <div class="info-auto seccion">
      <b>{{'car'|translate}}</b>
      <div class="content-image-desc">
        <div class="image-component circle"></div>
        <p>{{data.auto}}</p>
      </div>
    </div>
    <div class="numero-vin seccion">
      <b>{{'vin_abbreviation'|translate}}</b>
      <p>{{data.vin}}</p>
    </div>
    <div class="nombre-ejecutor seccion">
      <b>{{'executive'|translate}}</b>
      <div class="content-image-desc">
        <div class="image-component circle"
          [style]="'background-image:'+'url(https://seekop10-static-files-dev.s3.us-west-1.amazonaws.com/01/4520/foto/perfil/fotoPerfil.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240621T171943Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86399&X-Amz-Credential=AKIAZD57ORPLP2VRBAN6%2F20240621%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Signature=e94e179a0150a82d0e12278b1f1a52e35c18ca30a0a3b7f6da48302a8797d170)'">
        </div>
        <p>{{data.ejecutivo}}</p>
      </div>
    </div>
    <div class="observaciones seccion">
      <b>{{'observations'|translate}}</b>
      <p class="observaciones-desc">{{data.observaciones}}</p>
    </div>
    <ng-content select=".footer">
    </ng-content>
  </div>
</div>
}
