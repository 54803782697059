import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
  booleanAttribute,
  numberAttribute,
} from '@angular/core';

@Component({
  selector: 'app-so-select',
  templateUrl: './so-select.component.html',
  styleUrls: ['./so-select.component.scss'],
})
export class SoSelectComponent implements OnInit, OnChanges {
  @Output() event = new EventEmitter<any>();
  @Output() clickVip = new EventEmitter<any>();
  selectedValue: any;
  @Input({ transform: numberAttribute }) selectedIndex: number = 0;
  @Input() label!: string;
  @Input() idSelect?: string;
  @Input() data?: string[] | object[];
  @Input() name: string = '';
  @Input() options: any[] | undefined;
  @Input() labelPosition?: 'izquierda' | 'derecha' | 'arriba';
  @Input() widthLabel?: string = '';
  @Input() width?: string = '';
  @Input() maxWidth?: string = '';
  @Input() position: string = '';
  @Input() paddingClasses: string = 'p-1';
  @Input() fontWeight: string = '500';
  @Input() value: string = '';
  @Input() placeholder?: string = '';
  @Input() selectStyles?: any;
  @Input() show?: boolean = true;
  @Input() changeArrowSelect: boolean = false;
  @Input() typeInfoStructure: boolean = false;
  @Input() arregloJson?: { key: any; value: any }[] = [];
  @Input() formGroup?: any;
  @Input() showVip?: boolean = false;
  @Input() isVip?: boolean = true;
  @Input() vip?: string = 'grayscale(1)';
  @Input() required?: boolean = false;
  @Input() loading: boolean = false;
  @Input({ transform: booleanAttribute }) requiredMark: boolean = false;

  activeVip($event: any) {
    this.isVip = !this.isVip;
    this.vip = this.validateVip();
    this.clickVip.emit({
      value: this.selectedValue,
      EventTarget: event,
      target: event?.target,
      vip: this.isVip,
    });
    $event.target.style.filter = this.vip;
  }

  validateVip() {
    return this.isVip ? 'grayscale(0)' : 'grayscale(1)';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.typeInfoStructure) this.loadData();
  }
  ngOnInit(): void {
    if (this.labelPosition === 'izquierda') {
      this.position = 'd-flex';
    }
    if (this.labelPosition === 'derecha') {
      this.position = 'd-flex flex-row-reverse';
    }

    if (!this.typeInfoStructure) this.loadData();
  }

  onSelect(event: any) {
    this.event.emit({
      value: event.target.value,
      event: event,
      EventTarget: event,
      vip: this.isVip,
    });

    if (event.target.value !== '') {
      event.target.closest('div.select__form').classList.add('valid');
      event.target.closest('div.select__form').classList.remove('invalid');
    } else {
      event.target.closest('div.select__form').classList.remove('valid');
      event.target.closest('div.select__form').classList.add('invalid');
    }

    if (event.target.value !== '') {
      event.target.classList.add('valid');
    } else {
      event.target.classList.add('invalid');
    }
  }

  getValuesKeys(arregloJson: string | object) {
    const key = Object.values(arregloJson)[0];
    const value = Object.values(arregloJson)[1];
    return { key, value };
  }

  loadData() {
    this.arregloJson = [];
    if (this.data) {
      this.data?.forEach((element) => {
        const jsonElement = this.getValuesKeys(element);
        this.arregloJson?.push(jsonElement);
      });
      this.arregloJson?.unshift({ key: '', value: 'selected' });
      if (this.arregloJson && this.arregloJson.length > 0) {
        this.selectedValue = this.arregloJson[this.selectedIndex].key;
      }
    }
  }
}
