<div class="icon__button d-flex gap-{{gap}} w-fit {{reverse}} pointer align-items-center {{disabled}}"
  [style]="'filter:'+filter+''" [ngClass]="circle ? 'icon-outline-white' : ''">
  <p class="{{color}}"
    [style]="'font-size:'+textSize+'; font-weight:'+fontWeight+'!important; color:'+textColor+'!important; '"
    [ngStyle]="styles" style="margin: 0;">{{text}}</p>
  <div *ngIf="icon.includes('img') else matIcon ">
    <button [type]="type">
      <img class="self-center" [style]="'width:'+widthIcon+'; height:'+heightIcon+''" [src]="icon" alt="{{text}}">
    </button>
  </div>
  <ng-template #matIcon>
    <mat-icon class="text-[{{sizeIcon}}] {{matIconColor}} " [style]="'font-size:'+fontSize+''"
      [style]="'width:'+widthIcon+'!important; height:'+heightIcon+'!important'">{{icon}}</mat-icon>
  </ng-template>
</div>
