import { Injectable } from '@angular/core';
import { Request } from '@core/models/requests.model';

@Injectable({
  providedIn: 'root'
})
export class LeadCaptureGeneralService {

  private url = 'ventas/catalogos/valuacion/search'

  constructor( 
    private request: Request
  ) { }

  getValuators(body: any){
    const options = { headers: this.request.headers }
    return this.request.Post(this.url, body, options);
  }

  transformValues(data: any[], keyProp: string, valueProp: string): { key: any; value: any }[] {
    return data.map(item => ({ key: item[keyProp], value: item[valueProp] }));
  }

}
