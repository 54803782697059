import { Component, OnChanges, Input } from '@angular/core';

@Component({
  selector: 'app-so-badge',
  templateUrl: './so-badge.component.html',
  styleUrls: ['./so-badge.component.scss']
})
export class SoBadgeComponent implements OnChanges{
  @Input() text: string | number = '';
  @Input() icon: string = '';
  @Input() svg: string = '';
  @Input() fontSize: string = '';
  @Input() fontWeight: 'bold' | 'normal' = 'normal';
  @Input() color: string = '';
  @Input() class: string = '';
  @Input() iconAlignment: 'left' | 'right' = 'left';
  @Input() isCircle: boolean = false;
  @Input() badgeSize: string = "3em";
  @Input() outline: boolean = false;
  @Input() smallCircle: boolean = false;
  @Input() iconsize: number = 0.6;

  public styleBadge: string = '';

  ngOnChanges(): void {
    const colorMap: { [key: string]: string } = {
      'success': 'bg-success',
      'second': 'bg-second',
      'second-white': 'bg-second-white',
      'primary': 'so-bg-primary',
      'danger': 'bg-danger',
      'warning': 'bg-warning',
      'black': 'so-bg-black-white',
      'success-light': 'bg-success-light',
      'primary-light': 'bg-primary-light',
      'danger-light': 'bg-danger-light',
      'warning-light': 'bg-warning-light',
      'black-light': 'so-bg-black-white',
      'grey-light': 'so-bg-gray',
      'success-blue': 'bg-success-blue',
      'grey': 'bg-grey',
      'default': 'bg-light'
    };
  
    this.styleBadge = colorMap[this.color] || 'bg-black' + (this.outline ? '-outline' : '');
  }
}
