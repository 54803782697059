<div [id]="name"  [ngClass]=" (formGroup.get(name)?.invalid && formGroup.get(name)?.touched ? 'invalid':'' )"
  class="{{paddingClasses}} {{position}} select__form" *ngIf="show && formGroup" [formGroup]="formGroup"
  [style]="maxWidth && ' max-width: ' + maxWidth">
  <div *ngIf="label">
    <label class="fw-medium {{paddingClasses}}"
      [style]="'color: #272D3B; font-weight: ' + fontWeight + ' !important; width: ' + widthLabel + '!important;'">{{label}}<app-so-required-mark
        [show]="requiredMark" /></label>
  </div>

  <app-icon-button *ngIf="showVip" firstIcon="true" size="16px" widthIcon="31px" heightIcon="31px"
    icon="./assets/img/mdi-vip.svg" (click)="activeVip($event)" [ngStyle]="{'filter':'{{vip}}'}">
  </app-icon-button>

  <select [value]="value" [(ngModel)]="selectedValue" [formControlName]="name" required="{{required}}"
    class="form-select form-select-sm custom-select {{ changeArrowSelect ? 'simple_arrow_bottom' : '' }}"
    id="{{ name }}" name="{{ name }}" (change)="onSelect($event)" [ngStyle]="selectStyles"
    [style]="'color: var(--so-neuter-thick);font-family: Montserrat;width: 30px; height: 28px; padding: 3px;border-radius: 10px;background:#fff; width: ' + width + '!important;padding-left: 15px;'">

    <option value="" disabled selected *ngIf="placeholder">{{placeholder}}</option>
    <option *ngFor="let v of arregloJson" value="{{v.key}}">{{ v.value | translate }}</option>
  </select>
</div>

<div [id]="name" class="{{paddingClasses}} {{position}} select__form" *ngIf="show && !formGroup"
  [style]="maxWidth && ' max-width: ' + maxWidth">
  <div *ngIf="label">
    <label class="fw-medium {{paddingClasses}}"
      [style]="'color: #272D3B; font-weight: ' + fontWeight + ' !important; width: ' + widthLabel + '!important;'">{{label}}<app-so-required-mark
        [show]="requiredMark" /></label>
  </div>

  <div class="d-flex align-items-center">
    <app-icon-button *ngIf="showVip" [attr.data-vip]="isVip" firstIcon="true" size="16px" widthIcon="31px"
      heightIcon="31px" icon="./assets/img/mdi-vip.svg" (click)="activeVip($event)" [ngStyle]="{'filter':'{{vip}}'}">
    </app-icon-button>

    <div class="d-flex w-100">
      <select [value]="value" [(ngModel)]="selectedValue" required="{{required}}"
        class="form-select form-select-sm custom-select {{ changeArrowSelect ? 'simple_arrow_bottom' : '' }}"
        [ngClass]="{'valid': formGroup?.get(name)?.valid && formGroup?.get(name)?.touched, 'invalid': formGroup?.get(name)?.invalid && formGroup?.get(name)?.touched}"
        id="{{ name }}" name="{{ name }}" (change)="onSelect($event)" [ngStyle]="selectStyles"
        [style]="'color:var(--so-neuter-thick);font-family: Montserrat;width: 30px; height: 28px; padding: 3px;border-radius: 10px;background:var(--background); width: ' + width + '!important;padding-left: 15px;'">

        <option value="" disabled selected *ngIf="placeholder">{{placeholder}}</option>
        <option *ngFor="let v of arregloJson" value="{{v.key}}" [ngValue]="v.key">{{ v.value | translate }}</option>
      </select>
    </div>
  </div>

  <div class="spinner-overlay" *ngIf="loading">
    <img class="spinner" src="./assets/img/spinner.gif" />
  </div>
</div>
