import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Request } from '@core/models/requests.model';
import { StorageService } from '@core/services/storages.service';
import { CommunicationData } from '../interfaces/communication-data.interface';
import { SaleOpportunity } from '../interfaces/sale-opportunity.interface';
import { PrivacyLog } from '../interfaces/privacy-log';
import { SummaryFollowups } from '../interfaces/summary-followups.interface';
import { type ProspectFilesResponse } from '../interfaces/prospect-files.interface';
import { type ProspectEditDataResponse } from '../interfaces/prospect-edit-data.interface';

@Injectable({
  providedIn: 'root'
})
export class SummaryProspectService {

  private url= 'ventas';

  constructor(
    private request: Request,
    private storageService: StorageService
  ) {}

  private setBodyPayload(idProspect: string, withTimeZone = false) {
    const storageData = this.storageService.getLocalStorage('userInfo').config;
    let body: any =
    {
      configuracion: {
          basesDeDatos: {
              dbEmpresa: storageData.basesDeDatos.dbEmpresa
          },
          idioma: {
              idIdioma: storageData.idioma.idIdioma,
              idPais: storageData.idioma.idPais,
          }
      },
      idProspecto: idProspect
    };
    if (withTimeZone)
      body = { ...body, configuracion: { ...body.configuracion, husoHorario: storageData.husoHorario } }

    return body;
  }

  getChanelCommunication(idProspect: string){
    let body = this.setBodyPayload(idProspect);
    const options = {
      headers: this.request.headers,
    };
    return this.request.Post<CommunicationData>(`${this.url}/canales-comunicacion/search`, body, options)
  }

  getSaleOpportunity(idProspect: string){
    let body = this.setBodyPayload(idProspect);
    const options = {
      headers: this.request.headers,
    };
    return this.request.Post<SaleOpportunity>(`${this.url}/oportunidad-venta/search`, body, options);
  } 
  
  getPrivacyLog(idProspect: string){
    let body = this.setBodyPayload(idProspect);
    const options = {
      headers: this.request.headers,
    }
    return this.request.Post<PrivacyLog>(`${this.url}/historial/privacidad/search`, body, options);
  }

  getSummaryFollowups(idProspect: string){
    let body = this.setBodyPayload(idProspect);
    const options = {
      headers: this.request.headers,
    }
    return this.request.Post<SummaryFollowups>(`${this.url}/resumen-seguimientos/search`, body, options);
  }

  getProspectFiles(idProspect: string): Observable<ProspectFilesResponse> {
    const body = this.setBodyPayload(idProspect, true);
    const options = { headers: this.request.headers };
    return this.request.Post<ProspectFilesResponse>(`${this.url}/archivo/prospecto/search/all`, body, options);
  }

  getProspectEditData(idProspect: string): Observable<ProspectEditDataResponse> {
    const body = this.setBodyPayload(idProspect);
    const options = { headers: this.request.headers };
    return this.request.Post<ProspectEditDataResponse>(`${this.url}/catalogos/datos-prospecto-edicion/search`, body, options);
  }
}
