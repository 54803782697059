<div class="card-seekop" [style]="styleCard">
  <div class=" m-0 d-flex align-items-center justify-content-start" [ngClass]="{
      'justify-content-start' : titleAlignment == 'left',
      'justify-content-center' : titleAlignment == 'center',
      'justify-content-end' : titleAlignment == 'right',
      'justify-content-between' : titleAlignment == 'between',
    }" *ngIf="title">

    <img  [src]="iconsFolderPath + icon + '.svg'"  *ngIf="!iconImg"/>
    <mat-icon class=" icon-card " [class]=" (iconAlignment == 'left' ? ' order-0 ' : ' order-1 ')  "
      *ngIf="iconImg">{{icon}}</mat-icon>

    <h1>{{title}}</h1>
  </div>
  <div [ngClass]="{
    'mt-3': title !== '' || icon !== ''
  }" >
    <ng-content></ng-content>
  </div>

</div>
