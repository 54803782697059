@if (requestStatus === PromiseStatus.Pending) {
<so-spinner />
}
@else if (requestStatus === PromiseStatus.Rejected) {
<span class="so-text-2 d-block text-center">{{ errorMessage }}</span>
}
@else {
<div>
  <so-input-reactive-form 
    [label]="'key'|translate"
    [value]="data.response.clave"
    [isDisabled]="true"
  ></so-input-reactive-form>
  <so-input-reactive-form 
    [label]="'distributor'|translate"
    [value]="data.response.distribuidor"
    [isDisabled]="true"
  ></so-input-reactive-form>
  <so-input-reactive-form 
    [label]="'executive'|translate"
    [value]="data.response.ejecutivo"
    [isDisabled]="true"
  ></so-input-reactive-form>
  <so-input-reactive-form 
    [label]="'status'|translate"
    [value]="data.response.status"
    [isDisabled]="true"
  ></so-input-reactive-form>
  <so-input-reactive-form 
    [label]="'type_of_first_contact'|translate"
    [value]="data.response.tipoPrimerContactoNombre"
    [isDisabled]="true"
  ></so-input-reactive-form>
  <so-input-reactive-form 
    [label]="'source_of_information'|translate"
    [value]="data.response.fuente"
    [isDisabled]="true"
  ></so-input-reactive-form>
  <so-input-reactive-form 
    [label]="'subcampaign'|translate"
    [value]="data.response.subcampana"
    [isDisabled]="true"
  ></so-input-reactive-form>

  <div class="ms-1 d-flex justify-content-between align-items-center">
    <div class="mt-1">
      <h6 class="subTitle" style="font-size: 14px;">{{'privacy' | translate}}</h6>
      <div class="d-flex justify-content-start">
        <app-so-radio 
          [text]="'yes' | translate" 
          [checked]="data.lead"
          [isDisabled]="true"
        ></app-so-radio>
        <app-so-radio 
          [text]="'no' | translate"
          [checked]="!data.lead"
          [isDisabled]="true"
        ></app-so-radio>
      </div>
    </div>
    <so-input-reactive-form 
      [label]="'temperature'|translate"
      [value]="data.response.temperatura"
      [isDisabled]="true"
    ></so-input-reactive-form>
  </div>

  <so-input-date-reactive-form 
    [label]="'first_contact'|translate"
    [isDisabled]="true"
    [initDate]="data.response.primerContacto"
  ></so-input-date-reactive-form>
  <so-input-date-reactive-form 
    [label]="'register'|translate"
    [isDisabled]="true"
    [initDate]="data.response.registro"
  ></so-input-date-reactive-form>
</div>
}
