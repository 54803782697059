import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-so-icon-with-badge',
  templateUrl: './so-icon-with-badge.component.html',
  styleUrls: ['./so-icon-with-badge.component.scss']
})
export class SoIconWithBadgeComponent {
  @Input() icon: string = '';
  @Input() svg: string = '';
  @Input() iconBootstrap: string = '';
  @Input() text: string = '';
  @Input() sizeIcon: number = 1;
}
