<div class="container__main">
    <button
        class="{{ styleButton }} {{ styleText }} {{injection.button}}"
        [disabled]="disabled"
        (click)="onClick()"
        [type]="type"
        [style]="buttonStyle"
        (mouseenter)="showTooltip = true"
        (mouseleave)="showTooltip = false"
        [attr.aria-label]="tooltip"
        [attr.aria-describedby]="tooltip ? 'tooltip' : null">
        <div class="container--button {{injection.content}}" [style]="'width: ' + width + '!important;' + containerStyle">
            <div [style]="'margin-top: 8px; ' + injection.icon" [class]="
                (iconAlignment == 'left' ? ' ' : 'order-last ')
                + styleIcon" *ngIf="icon || svg">
                <so-icon [icon]="icon" [svg]="svg" [size]="scale" [rotation]="iconRotation" [color]="colorIcon" [applyChange]="applyChanges"  />
            </div>

            <ng-content></ng-content>
            @if (text != '') {
                <p
                    [class]="(iconAlignment == 'left' ? 'left-p ' : 'right-p ') + injection.label"
                    [style]="textStyle"
                    [ngClass]="{'p-custom-color': textStyleIsActive, 'cancel-color': isDangerStyle }">{{text}} <span *ngIf="extra !== null" class="extra" [matTooltip]="extraTooltip">{{ extra }}</span>
                </p>
            }
        </div>

    </button>
    @if (showTooltip && tooltip) {
        <div class="custom-tooltip" id="tooltip" [style]="customStyleTooltip">{{ tooltip }}</div>
    }
</div>
