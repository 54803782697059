import { Component, OnInit, inject } from '@angular/core';
import { Response } from '@modules/lead-management/submodules/directory/interfaces/communication-data.interface';
import { SharedStateService } from '@modules/lead-management/submodules/directory/services/shared-state.service';
import { SummaryProspectService } from '@modules/lead-management/submodules/directory/services/summary-prospect.service';
import { PromiseStatus } from '@shared/enums';

@Component({
  selector: 'app-accordion-communication-channels',
  templateUrl: './accordion-communication-channels.component.html',
  styleUrls: ['./accordion-communication-channels.component.scss']
})
export class AccordionCommunicationChannelsComponent implements OnInit{

  private sharedStateServices = inject(SharedStateService);
  private summaryProspectService = inject(SummaryProspectService);
  public communicationData?: Response;

  PromiseStatus = PromiseStatus;
  requestStatus = PromiseStatus.Pending;
  errorMessage: string = '';

  imgVip: string = 'mdi-vip.svg';
  // changeimgVip() {
  //   if (this.imgVip === 'mdi-vip.svg') {
  //     this.imgVip = 'mdi-vip-disabled.svg';
  //   } else {
  //     this.imgVip = 'mdi-vip.svg';
  //   }
  // }

  setImgVip(isVip: boolean): string {
    if(isVip){
      return this.imgVip = 'mdi-vip.svg'
    }
    return this.imgVip = 'mdi-vip-disabled.svg';
  }

  ngOnInit(): void {
    this.loadChanelCommunication();
  }

  loadChanelCommunication() {
    let idProspect = this.sharedStateServices.idProspect();
    this.summaryProspectService
    .getChanelCommunication(idProspect)
    .subscribe({
      next: (data) => {
        const response = data.response;
        this.communicationData = response;
        this.requestStatus = PromiseStatus.Fulfilled;
      },
      error: ({ error }) => {
        this.errorMessage = error.message;
        this.requestStatus = PromiseStatus.Rejected;
      },
    });
  }
}
