import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-so-slider-check',
  standalone: true,
  imports: [],
  templateUrl: './so-slider-check.component.html',
  styleUrl: './so-slider-check.component.scss'
})
export class SoSliderCheckComponent {
  @Input() public checked = false;
  @Output() change = new EventEmitter<boolean>();

  public changeCheckbox(event: Event) {
    this.checked = (event.target as HTMLInputElement).checked;
    this.change.emit(this.checked);
  }
}
