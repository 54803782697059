import { ElementRef, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormStepsService {

  private form!: FormGroup;

  constructor() { }

  private isStep2 = new BehaviorSubject<boolean>(false);
  isStep2$ = this.isStep2.asObservable();

  setStep2(value: boolean){
    this.isStep2.next(value);
  }

  private isDetailCapture = new BehaviorSubject<boolean>(false);
  isDetailCapture$ = this.isDetailCapture.asObservable();

  setIsDetailCapture(value: boolean){
    this.isDetailCapture.next(value)
  }

  private idTipoDePrimerContacto = new BehaviorSubject<string>('0000000000');
  idTipoDePrimerContacto$ = this.idTipoDePrimerContacto.asObservable();

  setIdTipoDePrimerContacto(value: string){
    this.idTipoDePrimerContacto.next(value)
  }

  private idTipoDeCierre = new BehaviorSubject<string>('0000000000');
  idTipoDeCierre$ = this.idTipoDeCierre.asObservable();

  setIdTipoDeCierre(value: string){
    this.idTipoDeCierre.next(value)
  }

  private idFuente = new BehaviorSubject<string>('0000000000');
  idFuente$ = this.idFuente.asObservable();

  setIdFuente(value: string){
    this.idFuente.next(value)
  }

  private idCampana = new BehaviorSubject<string>('0000000000');
  idCampana$ = this.idCampana.asObservable();

  setIdCampana(value: string){
    this.idCampana.next(value)
  }

  setForm(form: FormGroup){
    this.form = form;
  }

  getForm(): FormGroup{
    return this.form;
  }

  resetForm(){
    if(this.form){
      this.form.reset();
      this.form.markAsUntouched();
      this.form.markAsDirty();
      this.form.updateValueAndValidity();
    }
  }

  validForm(form: FormGroup): boolean{
    return form.valid;
  }

  private resetSelect = new BehaviorSubject<any>('');
  resetSelec$ = this.resetSelect.asObservable();

  setResetSelect(value:any){
    this.resetSelect.next(value);
  }

}
