import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { isAuthenticatedGuard } from '@core/guards/is-authenticated.guard';
import { isSessionGuard } from '@core/guards/is-session.guard';
import { isAuth } from '@core/guards/is-auth.guard';
import { SharedLayoutComponent } from '@shared/layout/shared-layout/shared-layout.component';

const routes: Routes = [
  {
    path: 'mdm',
    loadChildren: () =>
      import('@modules/mdm/mdm.module').then((m) => m.MdmModule),
  },
  {
    path: 'cotizaciones',
    loadChildren: () =>
      import('@modules/cotizaciones/cotizaciones-routing.module').then((m) => m.CotizacionesRoutingModule),
  },
  {
    path: 'configuration-ia',
    loadChildren: () =>
      import('@modules/configuration-ia/configuration-ia.module').then((m) => m.ConfigurationIaModule),
  },
  {
    path: 'auth',
    canActivateChild: [isAuth],
    loadChildren: () =>
      import('@modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'daily-analysis-2-0',
    loadChildren: () => 
      import('@modules/bdc-analysis-2-0/bdc-analysis-2-0.module').then(m => m.BDCAnalysisModule),
  },
  {
    path: '',
    canActivateChild: [isSessionGuard],
    component: SharedLayoutComponent,
    children: [
      {
        path: 'reception',
        canActivateChild: [isAuthenticatedGuard],
        loadChildren: () =>
          import('@modules/reception/reception.module').then(
            (m) => m.ReceptionModule
          ),
      },
      {
        path: 'marketing',
        canActivateChild: [isAuthenticatedGuard],
        loadChildren: () =>
          import('@modules/marketing/marketing-.module').then(
            (m) => m.MarketingModule
          ),
      },
      {
        path: 'settings',
        canActivateChild: [isAuthenticatedGuard],
        loadChildren: () =>
          import('@modules/settings/settings.module').then(
            (m) => m.SettingsModule
          ),
      },
      {
        path: 'notifications',
        canActivateChild: [isAuthenticatedGuard],
        loadChildren: () =>
          import('@modules/notifications/notifications.module').then(
            (m) => m.NotificationsModule
          ),
      },
      {
        path: 'multicanal',
        canActivateChild: [isAuthenticatedGuard],
        loadChildren: () =>
          import('@modules/multicanal/multicanal.module').then(
            (m) => m.MulticanalModule
          ),
      },
      {
        path: 'lead-management',
        loadChildren: () =>
          import('@modules/lead-management/lead-management.module').then(
            (m) => m.LeadManagementModule
          ),
      },
      { path: '', pathMatch: 'full', redirectTo: 'lead-management/home' },
      { path: '**', redirectTo: 'auth/login' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
