import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileSizePipe } from './pipes/file-size.pipe';
import { UtcToLocalPipe } from './pipes/utc-to-local.pipe';
import { TranslatedPipe } from './pipes/translated.pipe';


@NgModule({
  declarations: [
    FileSizePipe,UtcToLocalPipe,TranslatedPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FileSizePipe,UtcToLocalPipe,TranslatedPipe
  ]
})
export class CoreModule { }
