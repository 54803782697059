import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SoImgCropperComponent } from '../so-img-cropper/so-img-cropper.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'so-drop-simple-file',
  templateUrl: './so-drop-simple-file.component.html',
  styleUrl: './so-drop-simple-file.component.scss'
})
export class SoDropSimpleFileComponent {
  
  @Input() imageHeight: string = "60px";
  @Input() imageWidth: string = "60px";
  @Input() inputHeight: string = "60px";
  @Input() inputWidth: string = "60px";
  @Input() imageBorderRadius: string = '50%';
  @Input() label: string = '';
  @Input() maxSizeFile: number | null = null;
  @Input() isOnlyInput: boolean = true;
  @Input({ required: true }) idInptut: string = '';
  @Input({ required: true }) idChangeInput: string = '';
  @Input() openCropper: boolean = false;
  @Input() type: 'background' | 'avatar' | 'normal' = 'background'
  
  @Output() isSizeValid = new EventEmitter<boolean>();
  @Output() fileSelected = new EventEmitter<File>();
  @Output() removeFile = new EventEmitter<boolean>();

  @Input() imageSrc?: string | ArrayBuffer | null = null;

  constructor(
    private dialog: MatDialog
  ) { }

  public onImageSelected(event: Event){
    const input = event.target as HTMLInputElement;
    if(input.files && input.files[0]){
      const file = input.files[0];

      if(this.maxSizeFile){
        const maxSizeInMB = this.maxSizeFile;
        const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

        if(file.size > maxSizeInBytes ){
          this.isSizeValid.emit(false);
          input.value = '';
        }else{
          this.isSizeValid.emit(true);
          this.fileSelected.emit(file);
          if(this.openCropper){
            this.openCropperModal(event);
          }else{
            const reader = new FileReader();
            reader.onload = () => {
              this.imageSrc = reader.result;
            };
            reader.readAsDataURL(file);
          } 
        }
      }else{
        if(this.openCropper){
          this.openCropperModal(event);
        }else{
          this.fileSelected.emit(file);
          const reader = new FileReader();
          reader.onload = () => {
            this.imageSrc = reader.result;
          };
          reader.readAsDataURL(file);
        } 
      }
    }
  }

  public resetImageInput(){
    this.imageSrc = null;
    const inputElement = document.getElementById(this.idInptut) as HTMLInputElement;
    if(inputElement){
      inputElement.value = '';
      this.removeFile.emit(true);
    }
  }

  public triggerFileInput(): void {
    const changeImageInput = document.getElementById(this.idChangeInput) as HTMLInputElement;
    if (changeImageInput) {
      changeImageInput.click(); // Activa el input para seleccionar una nueva imagen
    }
  }
  public openCropperModal(event: Event): void {    
    const dialogRef = this.dialog.open(SoImgCropperComponent,{
      width: '600px',
      height: '700px',
      data: {
        event: event,
        type: this.type,
      },
    }).afterClosed().subscribe((file: File) => {
      if(file){
        this.fileSelected.emit(file);
        const reader = new FileReader();
        reader.onload = () => {
          this.imageSrc = reader.result;
        };
        reader.readAsDataURL(file);
      }
    });
  }

}
