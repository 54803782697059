import { Component, OnInit } from '@angular/core';
import { CheckboxService } from '../../../services/observables/checkbox.service';

@Component({
  selector: 'app-so-floating-icon',
  templateUrl: './so-floating-icon.component.html',
  styleUrls: ['./so-floating-icon.component.scss']
})
export class SofloatingIconComponent {
  checkboxValue: boolean = false;
  showChat: boolean = false;

  constructor(private checkboxService: CheckboxService) {
    this.checkboxService.checkboxValue$.subscribe(value => this.checkboxValue = value);
    this.checkboxService.chatboxValue$.subscribe(value => this.showChat = value);
  }

  onCheckboxToggle() {
    this.checkboxService.updateCheckboxValue(!this.checkboxValue);
  }

  showChatFunction() {
    this.checkboxService.updateCloseChat(true);
  }
}
