@if (requestStatus === PromiseStatus.Pending) {
  <so-spinner />
}
@else if (requestStatus === PromiseStatus.Rejected) {
  <span class="so-text-2 d-block text-center">{{ errorMessage }}</span>
}
@else if (!privacyLogs || privacyLogs.length === 0) {
  <span class="so-text-2 d-block text-center">{{ 'no_data' | translated }}</span>
}
@else {
  <div class="privacy-logs-container">
    @for (item of privacyLogs; track $index) {
      <div style="height: 157px;">
        <h5 class="title-according"> {{ item.fecha }} </h5>
        <so-input-reactive-form 
          [label]="'status' | translate" 
          [value]="item.status" 
          [isDisabled]="true">
        </so-input-reactive-form>
        
        <div class="ms-1">
          <h6 class="subTitle" style=" font-size: 14px;">{{'privacy' | translate}}</h6>
          <div class="d-flex justify-content-start">
            <app-so-radio [text]="'yes' | translate" ratioId="filter_all_multiagenda" class="me-1" [checked]="item.privacidad" [isDisabled]="true"
              nameGroup="usado"></app-so-radio>
            <app-so-radio [text]="'no' | translate" ratioId="floor" nameGroup="usado" [checked]="!item.privacidad" [isDisabled]="true"></app-so-radio>
          </div>
        </div>
      </div>
    }
  </div>
}
