
<lead-capture-steps></lead-capture-steps>

@if(!isDetailCapture){
  <simple-capture [hidden]="isStep2" [isAdmin]="isAdmin" ></simple-capture>
}@else {
  <detail-capture [hidden]="isStep2" [isAdmin]="isAdmin" ></detail-capture>
}
<form [formGroup]="formLeadCapture" novalidate>

  <!-- Nuevos -->
<div class="mt-3" [hidden]="!isStep2" style="padding: 10px 20px;">
  <div *ngIf="divNuevos">
    <div class="row  gap-2 mt-3">
      <div class="col d-flex justify-content-start">
        <img src="./assets/img/mdi-car.svg" alt="" style="width: 27px; height: 24px; margin-left: 5px;">
        <p style="font: normal normal bold 20px/29px Montserrat;letter-spacing: 0px;
       color: #272D3B;margin-top: 0px;margin-left: 5px;">{{'new'|translate}}</p>
      </div>
      <div class="col d-flex justify-content-end">
        <app-so-checkbox [size]="20" (click)="toggleValuationNews($event)"></app-so-checkbox>
        <label style="font: normal normal normal 16px/20px Montserrat;
        margin-left: 5px;
        color: #272D3B;">{{'valuation' | translate}}</label>
      </div>
    </div>
    <div *ngFor="let row of nuevosRows; let i = index" data-type="nuevos">
      <div class="row">
        <div class="col" *ngIf="isMultimarca">
          <app-so-select label="{{'Brand'|translate}} *" [name]="'idMarca' + (i > 0 ? '__' + (i+1) : '')"
            [data]="marcas" name="idMarca" (event)="onSelect($event)" [required]="true" [attr.data-lote]="i"></app-so-select>
        </div>
        <div class="col">
          <app-so-select label="{{'car_of_interest'|translate}} *" [name]="'idAuto' + (i > 0 ? '__' + (i+1) : '')"
            [data]="autos" (event)="onSelect($event)" [required]="true" [attr.data-lote]="i"></app-so-select>
        </div>
        <div class="col">
          <app-so-select label="{{'car_model'|translate}} *" [data]="anios"
            [name]="'modelo' + (i > 0 ? '__' + (i+1) : '')" (event)="onSelect($event)"
            [required]="true" [attr.data-lote]="i" ></app-so-select>
        </div>
        <div class="col">
          <app-so-select label="{{'car_version'|translate}} *" [data]="versiones"
            [name]="'idProducto' + (i > 0 ? '__' + (i+1) : '')" (event)="onSelect($event)"
            [required]="true" [attr.data-lote]="i"></app-so-select>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <app-so-select-custom-reactive
            [selectLabel]="('car_color' | translate) +' *'"
            [items]="colores"
            [isSelectColor]="true"
            [name]="'idColor' + (i > 0 ? '__' + (i+1) : '')"
            (event)="onSelect($event)"
            (itemSelected)="selectColorChange($event)"
            [attr.data-lote]="i"
          >
        </app-so-select-custom-reactive>

        </div>
        <div class="col">
        </div>
        <div class="col">
        </div>
      </div>
      <div class="row" [ngStyle]="nuevosRows.length > 1  ? { 'border-bottom': '1px solid #C5C5C580'} : {}"
        style="padding-bottom: 15px;">
        <div class="d-flex justify-content-end" style="margin-left: 9px;">
          <button (click)="removeNuevos(i)" *ngIf="nuevosRows.length > 1">
            <img src="./assets/img/mdi-minus-circle-outline-danger.svg" alt="" style="width: 25px; height: 25px;">
          </button>
          <button (click)="addNuevos()" *ngIf="(row == 1 && nuevosRows.length == 1) || (row >= nuevosRows.length)">
            <mat-icon [ngClass]="'so-secondary'" [ngStyle]="{'font-weight':'500','font-size':'30px','width':'35px',
              'height':'35px','margin-top': '12px','margin-right': '4px'}">add_circle_outline</mat-icon>
          </button>
          <div *ngIf="!((row == 1 && nuevosRows.length == 1) || (row >= nuevosRows.length))" style="width: 35px;">
          </div>
        </div>
      </div>
    </div>

    @if(valuationNews) {
    <div formGroupName="valuacionNuevos">
      <div class="row">
        <div class="col">
          <label style="text-align: left;
          font: normal normal bold 15px/20px Montserrat;
          color: #002A7F;margin-left: 5px;">{{'vehicle_details'|translate}}</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <so-select-reactive-form
            [items]="appraisers"
            [selectLabel]="'appraiser'|translate"
            formControlName="idValuador"
          ></so-select-reactive-form>
        </div>
        <div class="col">
          <so-select-reactive-form
            [items]="autos"
            [selectLabel]="('car_to_evaluate'|translate) + '*'"
            (selectionChange)="onSelectEvaluateNew($event)"
            formControlName="idAuto"
          >
          </so-select-reactive-form>
        </div>
        <div class="col">
          <so-select-reactive-form
            [items]="dataYearList().new"
            [selectLabel]="('year_of_car_to_evaluate'|translate) +' *'"
            (selectionChange)="onSelectEvaluateNewYear($event)"
            formControlName="modelo"
          ></so-select-reactive-form>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <so-select-reactive-form
            [items]="dataVersionList().new"
            [selectLabel]="('car_version_to_evaluate'|translate)  +' *'"
            (selectionChange)="onSelectEvaluateNewColor($event)"
            formControlName="idProducto"
          >
          </so-select-reactive-form>
        </div>
        <div class="col">
          <app-so-select-custom-reactive
            [selectLabel]="('car_color_to_evaluate'|translate)+ ' *'"
            [items]="dataColorList().new"
            [isSelectColor]="true"
            formControlName="idColor"
            >
          </app-so-select-custom-reactive>
        </div>
        <div class="col">
          <so-input-reactive-form [label]="'car_plate_to_valuate'|translate" formControlName="placas" ></so-input-reactive-form>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <so-input-reactive-form [label]="'vin_abbreviation' | translate" formControlName="vin"></so-input-reactive-form>
        </div>
        <div class="col">
        </div>
        <div class="col">
        </div>
      </div>
    </div>
    }
  </div>
  <!-- Seminuevos -->
  <div id="optionsTypeBussiness">
    <div *ngIf="divSemiNuevos">
      <div class="row  gap-2 mt-1">
        <div class="col d-flex justify-content-start">
          <img src="./assets/img/mdi-car-electric.svg" style="width: 30px;margin-left: 5px;height: 30px;
        margin-top: -6px;">
          <p style="font: normal normal bold 20px/29px Montserrat;letter-spacing: 0px;
       color: #272D3B;margin-top: 0px;margin-left: 5px;">{{'pre_owned'|translate}}</p>
        </div>
        <div class="col d-flex justify-content-end">
          <app-so-checkbox [size]="20" (click)="toggleValuationSemi($event)"></app-so-checkbox>
          <label style="font: normal normal normal 16px/20px Montserrat;
        margin-left: 5px;
        color: #272D3B;">{{'valuation' | translate}}</label>
        </div>
      </div>
      <div *ngFor="let row of SemiNuevosRows; let i = index" data-type="seminuevos">
        {{row}}
        <div class="row">
          <div class="col">
            <app-so-select label="{{'car_model'|translate}} *" (event)="onSelect($event)" [name]="'idModeloSemi' + (i > 0 ? '__' + (i+1) : '')"
              [data]="aniosSemi" [attr.data-lote]="i"></app-so-select>
          </div>
          <div class="col">
            <app-so-select label="{{'interest_car_brand'|translate}} *" (event)="onSelect($event)" [name]="'idMarcaSemi' + (i > 0 ? '__' + (i+1) : '')"
              [data]="marcasSemi" [attr.data-lote]="i"></app-so-select>
          </div>
          <div class="col">
            <app-so-select label="{{'car_of_interest'|translate}} *" [name]="'idAutoSemi' + (i > 0 ? '__' + (i+1) : '')"  (event)="onSelect($event)"
              [data]="autosSemi" [attr.data-lote]="i"></app-so-select>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <app-so-select label="{{'car_version'|translate}} *" [name]="'idProductoSemi' + (i > 0 ? '__' + (i+1) : '')" (event)="onSelect($event)"
              [data]="productosSemi" [attr.data-lote]="i"></app-so-select>
          </div>
          <div class="col">
              <app-so-select-custom-reactive
              [selectLabel]="('car_color' | translate) +' *'"
              [items]="coloresSemi"
              [isSelectColor]="true"
              [name]="'idColorSemi' + (i > 0 ? '__' + (i+1) : '')"
              (event)="onSelect($event)"
              (itemSelected)="selectColorChangeSemi($event)"
              [attr.data-lote]="i"
            >
          </app-so-select-custom-reactive>
          </div>
          <div class="col">
            <div style="margin: 30px 0px 0px 8px;">
              <button class="d-flex mt-1" (click)="openAddCarToInventory()">
                <label
                  style="color: #A3BD36; font: normal normal 600 13px/14px Montserrat; margin-top: 3px; margin-right: 3px;">
                  <span style="font: normal normal normal 13px/14px Montserrat;">{{'load_car_from'|translate}}</span>
                  <span>&nbsp;{{('inventory'|translate).toLowerCase()}}</span>
                </label>
                <img src="./assets/img/Grupo-4620-secondary.svg" alt=""
                  style="width: 38px; height: 38px;margin-top: -11px;">
              </button>
            </div>
          </div>
        </div>
        <div class="row" [ngStyle]="SemiNuevosRows.length > 1  ? {'border-bottom': '1px solid #C5C5C580'} : {}"
          style="padding-bottom: 15px;">
          <div class="d-flex justify-content-end" style="margin-left: 9px;">
            <button (click)="removeSemiNuevos(i)" *ngIf="SemiNuevosRows.length > 1">
              <img src="./assets/img/mdi-minus-circle-outline-danger.svg" alt="" style="width: 25px; height: 25px;">
            </button>
            <button (click)="addSemiNuevos()"
              *ngIf="(row == 1 && SemiNuevosRows.length == 1) || (row >= SemiNuevosRows.length)">
              <mat-icon [ngClass]="'so-secondary'" [ngStyle]="{'font-weight':'500','font-size':'30px','width':'35px',
              'height':'35px','margin-top': '12px','margin-right': '4px'}">add_circle_outline</mat-icon>
            </button>
            <div *ngIf="!((row == 1 && SemiNuevosRows.length == 1) || (row >= SemiNuevosRows.length))"
              style="width: 35px;"></div>
          </div>
        </div>
      </div>

      @if(valuationSemi) {
      <div formGroupName="valuacionSemi">
        <div class="row">
          <div class="col">
            <label style="text-align: left;
            font: normal normal bold 15px/20px Montserrat;
            color: #002A7F;margin-left: 5px;">{{'vehicle_details'|translate}}</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <so-select-reactive-form
              [items]="appraisers"
              [selectLabel]="'appraiser'|translate"
              formControlName="idValuador"
            ></so-select-reactive-form>
          </div>
          <div class="col">
            <so-select-reactive-form
              [items]="autos"
              [selectLabel]="('car_to_evaluate'|translate) + '*'"
              (selectionChange)="onSelectEvaluatePreOwned($event)"
              formControlName="idAuto"
            >
            </so-select-reactive-form>
          </div>
          <div class="col">
            <so-select-reactive-form
              [items]="dataYearList().preOwned"
              [selectLabel]="('year_of_car_to_evaluate'|translate) +' *'"
              (selectionChange)="onSelectEvaluatePreOwnedYear($event)"
              formControlName="modelo"
            ></so-select-reactive-form>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <so-select-reactive-form
              [items]="dataVersionList().preOwned"
              [selectLabel]="('car_version_to_evaluate'|translate)  +' *'"
              (selectionChange)="onSelectEvaluatePreOwnedColor($event)"
              formControlName="idProducto"
            >
            </so-select-reactive-form>
          </div>
          <div class="col">
            <app-so-select-custom-reactive
              [selectLabel]="('car_color_to_evaluate'|translate)+ ' *'"
              [items]="dataColorList().preOwned"
              [isSelectColor]="true"
              formControlName="idColor"
              >
            </app-so-select-custom-reactive>
          </div>
          <div class="col">
            <so-input-reactive-form [label]="'car_plate_to_valuate'|translate" formControlName="placas" ></so-input-reactive-form>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <so-input-reactive-form [label]="'vin_abbreviation' | translate" formControlName="vin"></so-input-reactive-form>
          </div>
          <div class="col">
          </div>
          <div class="col">
          </div>
        </div>
      </div>
      }
    </div>
    <!-- End Seminuevos -->

    <!-- Flotilla -->
    <div *ngIf="divFlotilla">
      <div class="row  gap-2 mt-1">
        <div class="col d-flex justify-content-start">
          <img src="./assets/img/mdi-car-multiple.svg" style="width: 32px;margin-left: 5px;height: 29px;
        margin-top: -6px;">
          <p style="font: normal normal bold 20px/29px Montserrat;letter-spacing: 0px;
       color: #272D3B;margin-top: 0px;margin-left: 5px;">{{('existing_fleet'|translate)}}</p>
        </div>
        <div class="col d-flex justify-content-end">
          <app-so-checkbox [size]="20" (click)="toggleValuationFlotilla($event)"></app-so-checkbox>
          <label style="font: normal normal normal 16px/20px Montserrat;
        margin-left: 5px;
        color: #272D3B;">{{'valuation' | translate}}</label>
        </div>
      </div>
      <div *ngFor="let row of flotillaRows; let i = index" data-type="flotilla">
        <div class="row">
          <div class="col">
            <app-so-select label="{{'interest_car_brand'|translate}} *" (event)="onSelect($event)" [name]="'idMarcaFlotilla' + (i > 0 ? '__' + (i+1) : '')"
              [data]="marcasFlotillasData" [attr.data-lote]="i"></app-so-select>
          </div>
          <div class="col">
            <app-so-select label="{{'car_of_interest'|translate}} *" (event)="onSelect($event)"   [name]="'idAutoFlotilla' + (i > 0 ? '__' + (i+1) : '')"
              [data]="autosFlotillasData" [attr.data-lote]="i"></app-so-select>
          </div>
          <div class="col">
            <app-so-select label="{{'year_of_the_car'|translate}} *" (event)="onSelect($event)" [name]="'modeloFlotilla' + (i > 0 ? '__' + (i+1) : '')"
              [data]="aniosFlotillasData" [attr.data-lote]="i"></app-so-select>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <app-so-select label="{{'car_version'|translate}} *" (event)="onSelect($event)"  [name]="'idProductoFlotilla' + (i > 0 ? '__' + (i+1) : '')"
              [data]="versionesFlotillasData" [attr.data-lote]="i"></app-so-select>
          </div>
          <div class="col">
              <app-so-select-custom-reactive
              [selectLabel]="('car_color' | translate) +' *'"
              [items]="coloresFlotillasData"
              [isSelectColor]="true"
              [name]="'idColorFlotilla' + (i > 0 ? '__' + (i+1) : '')"
              (event)="onSelect($event)"
              (itemSelected)="selectColorChangeFlotillas($event)"
              [attr.data-lote]="i"
            >
          </app-so-select-custom-reactive>
          </div>
          <div class="col">
            <app-so-select label="{{'quantity'|translate}} *" (event)="onSelect($event)" [name]="'cantidadFlotilla' + (i > 0 ? '__' + (i+1) : '')"
              [data]="quantity" [attr.data-lote]="i"></app-so-select>
          </div>
        </div>
        <div class="row" [ngStyle]="flotillaRows.length > 1  ? {'border-bottom': '1px solid #C5C5C580'} : {}"
          style="padding-bottom: 15px;">
          <div class="d-flex justify-content-end" style="margin-left: 9px;">
            <button (click)="removeFlotilla(i)" *ngIf="flotillaRows.length > 1">
              <img src="./assets/img/mdi-minus-circle-outline-danger.svg" alt="" style="width: 25px; height: 25px;">
            </button>
            <button (click)="addFlotilla()"
              *ngIf="(row == 1 && flotillaRows.length == 1) || (row >= flotillaRows.length)">
              <mat-icon [ngClass]="'so-secondary'" [ngStyle]="{'font-weight':'500','font-size':'30px','width':'35px',
              'height':'35px','margin-top': '12px','margin-right': '4px'}">add_circle_outline</mat-icon>
            </button>
            <div *ngIf="!((row == 1 && flotillaRows.length == 1) || (row >= flotillaRows.length))" style="width: 35px;">
            </div>
          </div>
        </div>
      </div>

      @if(valuationFlotilla) {
        <div formGroupName="valuacionFlotilla">
          <div class="row">
            <div class="col">
              <label style="text-align: left;
              font: normal normal bold 15px/20px Montserrat;
              color: #002A7F;margin-left: 5px;">{{'vehicle_details'|translate}}</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <so-select-reactive-form
                [items]="appraisers"
                [selectLabel]="'appraiser'|translate"
                formControlName="idValuador"
              ></so-select-reactive-form>
            </div>
            <div class="col">
              <so-select-reactive-form
                [items]="autos"
                [selectLabel]="('car_to_evaluate'|translate) + '*'"
                (selectionChange)="onSelectEvaluateNew($event)"
                formControlName="idAuto"
              >
              </so-select-reactive-form>
            </div>
            <div class="col">
              <so-select-reactive-form
                [items]="dataYearList().new"
                [selectLabel]="('year_of_car_to_evaluate'|translate) +' *'"
                (selectionChange)="onSelectEvaluateNewYear($event)"
                formControlName="modelo"
              ></so-select-reactive-form>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <so-select-reactive-form
                [items]="dataVersionList().new"
                [selectLabel]="('car_version_to_evaluate'|translate)  +' *'"
                (selectionChange)="onSelectEvaluateNewColor($event)"
                formControlName="idProducto"
              >
              </so-select-reactive-form>
            </div>
            <div class="col">
              <app-so-select-custom-reactive
                [selectLabel]="('car_color_to_evaluate'|translate)+ ' *'"
                [items]="dataColorList().new"
                [isSelectColor]="true"
                formControlName="idColor"
                name="idColor"
                >
              </app-so-select-custom-reactive>
            </div>
            <div class="col">
              <so-input-reactive-form [label]="'car_plate_to_valuate'|translate" formControlName="placas" ></so-input-reactive-form>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <so-input-reactive-form [label]="'vin_abbreviation' | translate" formControlName="vin"></so-input-reactive-form>
            </div>
            <div class="col">
            </div>
            <div class="col">
            </div>
          </div>
        </div>
      }
    </div>
    <!-- End Flotilla -->

    <!-- Seguros -->
    <div *ngIf="divSeguro">
      <div class="row  gap-2 mt-1">
        <div class="col d-flex justify-content-start">
          <img src="./assets/img/car-wireless.svg" style="width: 32px;margin-left: 5px;height: 30px;
        margin-top: -6px;">
          <p style="font: normal normal bold 20px/29px Montserrat;letter-spacing: 0px;
       color: #272D3B;margin-top: 0px;margin-left: 5px;">{{'insurances'|translate}}</p>
        </div>
      </div>
      <div *ngFor="let row of seguroRows; let i = index">
        <div class="row">
          <div class="col">
            <app-so-select label="{{'car_to_insure'|translate}} *" [options]="optiones"></app-so-select>
          </div>
          <div class="col">
            <app-so-select label="{{'car_to_insure_year'|translate}} *" [options]="optiones"></app-so-select>
          </div>
          <div class="col">
            <app-so-select label="{{'car_to_insure_version'|translate}} *" name="select"
              [options]="optiones"></app-so-select>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <app-so-select label="{{'insurance_company'|translate}} *" [options]="optiones"></app-so-select>
          </div>
          <div class="col">
            <app-so-select label="{{'insurance_type'|translate}} *" name="select" [options]="optiones"></app-so-select>
          </div>
          <div class="col">
            <app-so-select label="{{'coverage_type'|translate}} *" name="select" [options]="optiones"></app-so-select>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <app-so-input-form label="{{'term'|translate}}" type="date" />
          </div>
          <div class="col">
          </div>
          <div class="col">
          </div>
        </div>
        <div class="row" [ngStyle]="seguroRows.length > 1  ? {'border-bottom': '1px solid #C5C5C580'} : {}"
          style="padding-bottom: 15px;">
          <div class="d-flex justify-content-end" style="margin-left: 9px;">
            <button (click)="removeSeguro(i)" *ngIf="seguroRows.length > 1">
              <img src="./assets/img/mdi-minus-circle-outline-danger.svg" alt="" style="width: 25px; height: 25px;">
            </button>
            <button (click)="addSeguro()" *ngIf="(row == 1 && seguroRows.length == 1) || (row >= seguroRows.length)">
              <mat-icon [ngClass]="'so-secondary'" [ngStyle]="{'font-weight':'500','font-size':'30px','width':'35px',
              'height':'35px','margin-top': '12px','margin-right': '4px'}">add_circle_outline</mat-icon>
            </button>
            <div *ngIf="!((row == 1 && seguroRows.length == 1) || (row >= seguroRows.length))" style="width: 35px;">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Seguros -->


    <!-- Financiero -->
    <div *ngIf="divFinanciero">
      <div class="row  gap-2 mt-1">
        <div class="col d-flex justify-content-start">
          <img src="./assets/img/mdi-credit-card-refresh-outline.svg" style="width: 30px;margin-left: 5px;height: 30px;
        margin-top: -6px;">
          <p style="font: normal normal bold 20px/29px Montserrat;letter-spacing: 0px;
       color: #272D3B;margin-top: -5px;margin-left: 5px;">{{'financial'|translate}}</p>
        </div>
      </div>
      <div *ngFor="let row of FinancieroRows; let i = index">
        <div class="row">
          <div class="col">
            <app-so-select label="{{'car_to_finance'|translate}} *" [options]="optiones"></app-so-select>
          </div>
          <div class="col">
            <app-so-select label="{{'car_to_finance_year'|translate}} *" [options]="optiones"></app-so-select>
          </div>
          <div class="col">
            <app-so-select label="{{'car_to_finance_version'|translate}} *" name="select"
              [options]="optiones"></app-so-select>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <app-so-select label="{{'product_type'|translate}} *" [options]="optiones"></app-so-select>
          </div>
          <div class="col">
            <app-so-select label="{{'hitch'|translate}} *" name="select" [options]="optiones"></app-so-select>
          </div>
          <div class="col">
            <app-so-select label="{{'annuity'|translate}} *" name="select" [options]="optiones"></app-so-select>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <app-so-select label="{{'commission_type'|translate}} *" name="select" [options]="optiones"></app-so-select>
          </div>
          <div class="col">
            <app-so-select label="{{'administrative_expenses'|translate}} *" name="select"
              [options]="optiones"></app-so-select>
          </div>
          <div class="col">
            <app-so-select label="{{'insurance_type'|translate}} *" name="select" [options]="optiones"></app-so-select>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <app-so-select label="{{'rates'|translate}} *" name="select" [options]="optiones"></app-so-select>
          </div>
          <div class="col">
          </div>
          <div class="col">
          </div>
        </div>
        <div class="row" [ngStyle]="FinancieroRows.length > 1  ? {'border-bottom': '1px solid #C5C5C580'} : {}"
          style="padding-bottom: 15px;">
          <div class="d-flex justify-content-end" style="margin-left: 9px;">
            <button (click)="removeFinanciero(i)" *ngIf="FinancieroRows.length > 1">
              <img src="./assets/img/mdi-minus-circle-outline-danger.svg" alt="" style="width: 25px; height: 25px;">
            </button>
            <button (click)="addFinanciero()"
              *ngIf="(row == 1 && FinancieroRows.length == 1) || (row >= FinancieroRows.length)">
              <mat-icon [ngClass]="'so-secondary'" [ngStyle]="{'font-weight':'500','font-size':'30px','width':'35px',
              'height':'35px','margin-top': '12px','margin-right': '4px'}">add_circle_outline</mat-icon>
            </button>
            <div *ngIf="!((row == 1 && FinancieroRows.length == 1) || (row >= FinancieroRows.length))"
              style="width: 35px;"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Financiero -->

    <!-- PostVenta -->

    <div *ngIf="divPostVenta">
      <div class="row  gap-2 mt-1">
        <div class="col d-flex justify-content-start">
          <img src="./assets/img/Trazado 4806.svg" style="width: 30px;margin-left: 5px;height: 30px;
        margin-top: -6px;">
          <p style="font: normal normal bold 20px/29px Montserrat;letter-spacing: 0px;
       color: #272D3B;margin-top: -5px;margin-left: 5px;">{{'after_sale'|translate}}</p>
        </div>
      </div>
      <div *ngFor="let row of posVentaRows; let i = index">
        <div class="row">
          <div class="col">
            <app-so-select label="{{'interest_car_brand'|translate}} *" [options]="optiones"></app-so-select>
          </div>
          <div class="col">
            <app-so-select label="{{'interest_car'|translate}} *" [options]="optiones"></app-so-select>
          </div>
          <div class="col">
            <app-so-select label="{{'interest_car_year'|translate}} *" name="select"
              [options]="optiones"></app-so-select>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <app-so-select label="{{'interest_car_version'|translate}} *" [options]="optiones"></app-so-select>
          </div>
          <div class="col">
            <app-so-select label="{{'service_type'|translate}} *" name="select" [options]="optiones"></app-so-select>
          </div>
          <div class="col">
            <app-so-select label="{{'mileage'|translate}} *" name="select" [options]="optiones"></app-so-select>
          </div>
        </div>
        <div class="row" [ngStyle]="posVentaRows.length > 1  ? {'border-bottom': '1px solid #C5C5C580'} : {}"
          style="padding-bottom: 15px;">
          <div class="d-flex justify-content-end" style="margin-left: 9px;">
            <button (click)="removePosVenta(i)" *ngIf="posVentaRows.length > 1">
              <img src="./assets/img/mdi-minus-circle-outline-danger.svg" alt="" style="width: 25px; height: 25px;">
            </button>
            <button (click)="addPosVenta()"
              *ngIf="(row == 1 && posVentaRows.length == 1) || (row >= posVentaRows.length)">
              <mat-icon [ngClass]="'so-secondary'" [ngStyle]="{'font-weight':'500','font-size':'30px','width':'35px',
              'height':'35px','margin-top': '12px','margin-right': '4px'}">add_circle_outline</mat-icon>
            </button>
            <div *ngIf="!((row == 1 && posVentaRows.length == 1) || (row >= posVentaRows.length))" style="width: 35px;">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End PostVenta -->

  </div>
  <div class="col d-flex justify-content-between boxFooter">
    <div class="d-flex align-items-center" id="buttonShwNuevos"
      [style.visibility]="tiposDeNegocio[0].idTipoDeNegocio === 'TN00000001' ? 'visible' : 'hidden'">
      <button class="d-flex mt-1" (click)="showNuevos()" *ngIf="!divNuevos">
        <label
          style="color: #A3BD36; font: normal normal 600 13px/14px Montserrat; margin-top: 3px; margin-right: 3px;">
          <span style="font: normal normal normal 13px/14px Montserrat;">{{'add'|translate}}</span>
          <span>&nbsp;{{('new'|translate).toLowerCase()}}</span>
        </label>
        <mat-icon
          [ngStyle]="{'font-weight':'500','font-size':'21px','margin-top': 'px','margin-right': '0px', 'color':'#A3BD36'}">
          add_circle_outline</mat-icon>
      </button>
      <button class="d-flex mt-1" (click)="showNuevos()" *ngIf="divNuevos">
        <label
          style="color: #F44336; font: normal normal 600 13px/14px Montserrat; margin-top: 3px; margin-right: 3px;">
          <span style="font: normal normal normal 13px/14px Montserrat;">{{'erase'|translate}}</span>
          <span>&nbsp;{{('new'|translate).toLowerCase()}}</span>
        </label>
        <mat-icon
          [ngStyle]="{'font-weight':'500','font-size':'21px','margin-top': 'px','margin-right': '0px', 'color':'#F44336'}">
          highlight_off</mat-icon>
      </button>
    </div>
    <div class="d-flex align-items-center" id="buttonShwSeminuevos"
      [style.visibility]="tiposDeNegocio.length >= 2 && tiposDeNegocio[1].idTipoDeNegocio === 'TN00000002' ? 'visible' : 'hidden'">
      <button class="d-flex mt-1" (click)="showdivSemiNuevos()" *ngIf="!divSemiNuevos">
        <label
          style="color: #A3BD36; font: normal normal 600 13px/14px Montserrat; margin-top: 3px; margin-right: 3px;">
          <span style="font: normal normal normal 13px/14px Montserrat;">{{'add'|translate}}</span>
          <span>&nbsp;{{('pre_owned'|translate).toLowerCase()}}</span>
        </label>
        <mat-icon
          [ngStyle]="{'font-weight':'500','font-size':'21px','margin-top': 'px','margin-right': '0px', 'color':'#A3BD36'}">
          add_circle_outline</mat-icon>
      </button>
      <button class="d-flex mt-1" (click)="showdivSemiNuevos()" *ngIf="divSemiNuevos">
        <label
          style="color: #F44336; font: normal normal 600 13px/14px Montserrat; margin-top: 3px; margin-right: 3px;">
          <span style="font: normal normal normal 13px/14px Montserrat;">{{'erase'|translate}}</span>
          <span>&nbsp;{{('pre_owned'|translate).toLowerCase()}}</span>
        </label>
        <mat-icon
          [ngStyle]="{'font-weight':'500','font-size':'21px','margin-top': 'px','margin-right': '0px', 'color':'#F44336'}">
          highlight_off</mat-icon>
      </button>
    </div>
    <div class="d-flex align-items-center" id="buttonShwFlotilla"
      [style.visibility]="tiposDeNegocio.length >= 3 && tiposDeNegocio[2].idTipoDeNegocio === 'TN00000003' ? 'visible' : 'hidden'">
      <button class="d-flex mt-1" (click)="showdivFlotilla()" *ngIf="!divFlotilla">
        <label
          style="color: #A3BD36; font: normal normal 600 13px/14px Montserrat; margin-top: 3px; margin-right: 3px;">
          <span style="font: normal normal normal 13px/14px Montserrat;">{{'add'|translate}}</span>
          <span>&nbsp;{{('existing_fleet'|translate).toLowerCase()}}</span>
        </label>
        <mat-icon
          [ngStyle]="{'font-weight':'500','font-size':'21px','margin-top': 'px','margin-right': '0px', 'color':'#A3BD36'}">
          add_circle_outline</mat-icon>
      </button>
      <button class="d-flex mt-1" (click)="showdivFlotilla()" *ngIf="divFlotilla">
        <label
          style="color: #F44336; font: normal normal 600 13px/14px Montserrat; margin-top: 3px; margin-right: 3px;">
          <span style="font: normal normal normal 13px/14px Montserrat;">{{'erase'|translate}}</span>
          <span>&nbsp;{{('existing_fleet'|translate).toLowerCase()}}</span>
        </label>
        <mat-icon
          [ngStyle]="{'font-weight':'500','font-size':'21px','margin-top': 'px','margin-right': '0px', 'color':'#F44336'}">
          highlight_off</mat-icon>
      </button>
    </div>
    <div class="d-flex align-items-center" id="buttonShwSeguros"
      [style.visibility]="tiposDeNegocio.length >= 4 && tiposDeNegocio[3].idTipoDeNegocio === 'TN00000004' ? 'visible' : 'hidden'">
      <button class="d-flex mt-1" (click)="showdivSeguro()" *ngIf="!divSeguro">
        <label
          style="color: #A3BD36; font: normal normal 600 13px/14px Montserrat; margin-top: 3px; margin-right: 3px;">
          <span style="font: normal normal normal 13px/14px Montserrat;">{{'add'|translate}}</span>
          <span>&nbsp;{{('insurances'|translate).toLowerCase()}}</span>
        </label>
        <mat-icon
          [ngStyle]="{'font-weight':'500','font-size':'21px','margin-top': 'px','margin-right': '0px', 'color':'#A3BD36'}">
          add_circle_outline</mat-icon>
      </button>
      <button class="d-flex mt-1" (click)="showdivSeguro()" *ngIf="divSeguro">
        <label
          style="color: #F44336; font: normal normal 600 13px/14px Montserrat; margin-top: 3px; margin-right: 3px;">
          <span style="font: normal normal normal 13px/14px Montserrat;">{{'erase'|translate}}</span>
          <span>&nbsp;{{('insurances'|translate).toLowerCase()}}</span>
        </label>
        <mat-icon
          [ngStyle]="{'font-weight':'500','font-size':'21px','margin-top': 'px','margin-right': '0px', 'color':'#F44336'}">
          highlight_off</mat-icon>
      </button>
    </div>
    <div class="d-flex align-items-center" id="buttonShwFinancieros"
      [style.visibility]="tiposDeNegocio.length >= 5 && tiposDeNegocio[4].idTipoDeNegocio === 'TN00000005' ? 'visible' : 'hidden'">
      <button class="d-flex mt-1" (click)="showdivFinanciero()" *ngIf="!divFinanciero">
        <label
          style="color: #A3BD36; font: normal normal 600 13px/14px Montserrat; margin-top: 3px; margin-right: 3px;">
          <span style="font: normal normal normal 13px/14px Montserrat;">{{'add'|translate}}</span>
          <span>&nbsp;{{('financial'|translate).toLowerCase()}}</span>
        </label>
        <mat-icon
          [ngStyle]="{'font-weight':'500','font-size':'21px','margin-top': 'px','margin-right': '0px', 'color':'#A3BD36'}">
          add_circle_outline</mat-icon>
      </button>
      <button class="d-flex mt-1" (click)="showdivFinanciero()" *ngIf="divFinanciero">
        <label
          style="color: #F44336; font: normal normal 600 13px/14px Montserrat; margin-top: 3px; margin-right: 3px;">
          <span style="font: normal normal normal 13px/14px Montserrat;">{{'erase'|translate}}</span>
          <span>&nbsp;{{('financial'|translate).toLowerCase()}}</span>
        </label>
        <mat-icon
          [ngStyle]="{'font-weight':'500','font-size':'21px','margin-top': 'px','margin-right': '0px', 'color':'#F44336'}">
          highlight_off</mat-icon>
      </button>
    </div>
    <div class="d-flex align-items-center" id="buttonShwPostventa"
      [style.visibility]="tiposDeNegocio.length >= 6 && tiposDeNegocio[5].idTipoDeNegocio === 'TN00000006' ? 'visible' : 'hidden'">
      <button class="d-flex mt-1" (click)="showdivPostVenta()" *ngIf="!divPostVenta">
        <label
          style="color: #A3BD36; font: normal normal 600 13px/14px Montserrat; margin-top: 3px; margin-right: 3px;">
          <span style="font: normal normal normal 13px/14px Montserrat;">{{'add'|translate}}</span>
          <span>&nbsp;{{('after_sale'|translate).toLowerCase()}}</span>
        </label>
        <mat-icon
          [ngStyle]="{'font-weight':'500','font-size':'21px','margin-top': 'px','margin-right': '0px', 'color':'#A3BD36'}">
          add_circle_outline</mat-icon>
      </button>
      <button class="d-flex mt-1" (click)="showdivPostVenta()" *ngIf="divPostVenta">
        <label
          style="color: #F44336; font: normal normal 600 13px/14px Montserrat; margin-top: 3px; margin-right: 3px;">
          <span style="font: normal normal normal 13px/14px Montserrat;">{{'erase'|translate}}</span>
          <span>&nbsp;{{('after_sale'|translate).toLowerCase()}}</span>
        </label>
        <mat-icon
          [ngStyle]="{'font-weight':'500','font-size':'21px','margin-top': 'px','margin-right': '0px', 'color':'#F44336'}">
          highlight_off</mat-icon>
      </button>
    </div>
  </div>
</div>
<!-- End Nuevos -->

</form>


<div class="row mt-2" style="padding: 0 20px 0 20px;">
  <div class="col d-flex justify-content-start">
    <img class="float-start" src="./assets/img/mdi-alert-outline.svg" style="width: 26px; height:30px">
    <label style="font: italic normal normal 14px/20px Montserrat; color: #272D3B; margin: 6px 0 0px 8px;">{{
      'required_fields' | translate }}</label>
  </div>
  <div *ngIf="!isStep2" class="col d-flex justify-content-end gap-4">
    <app-button svg="mdi-check-circle-outline" iconAlignment="right" [scale]=".8" text="{{ 'next' | translate }}"
      [injection]="{ label: 'pt-2' }" (click)="fnisStep2($event)" />
  </div>
  <div *ngIf="isStep2" class="col d-flex justify-content-end gap-4">
    <app-button svg="mdi-check-circle-outline" iconAlignment="right" [scale]=".8" text="{{ 'create_lead' | translate }}"
      [injection]="{ label: 'pt-2' }" (click)="fnisStep2($event)" />
  </div>
</div>
