import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
@Component({
  selector: 'app-so-checkbox',
  templateUrl: './so-checkbox.component.html',
  styleUrls: ['./so-checkbox.component.scss'],
})
export class SoCheckboxComponent implements OnInit {
  @Output() checkboxChange  = new EventEmitter();
  @Input() checked: boolean = false;
  @Input() checkId?: string;
  @Input() nameGroup?: string;
  @Input() text?: string;
  @Input() gap?: string;
  @Input() fontSize?: string;
  @Input() textAlign?: string;
  @Input() direction?: 'flex-row-reverse' | 'flex-column';
  @Input() textColor?: string = '#272D3B';
  @Input() fontWeight?:
    | 'bold'
    | 'bolder'
    | 'normal'
    | 'lighter'
    | '500!important';

  @Input() size: number = 26;
  @Input() disabled: boolean = false;
  @Input() truncateText: boolean = false;


  onCheckboxChange(event: any) {
    this.checkboxChange.emit({status:this.validarStatus(event),element:event.target});
  }

  ngOnInit(): void {
    this.validAlign();
  }

  validAlign() {
    if (this.textAlign === 'right') {
      this.textAlign = 'flex-row-reverse';
    }
  }

  validarStatus($event: any) {
    return this.checked = !this.checked;
  }
}
