<div class="btn-group btn-group-sm" role="group">
  @for (item of data; track data; let i = $index;let esUltimo = $last) {
    <input type="radio" class="btn-check" name="btnradio_{{id}}" [id]="'btnradio_' + id + '_' + removeSpaces(item.title)" (click)="filterClicked.emit(item)"
        autocomplete="off" [attr.checked]="item.selected ? true: null ">
    <label class="btn btnRadio " 
      [ngClass]="{'btn-inicio': i == 0 ,'btn-fin': esUltimo}" 
      [for]="'btnradio_' + id + '_' + removeSpaces(item.title)"
    >
      {{item.title|translate}}
    </label>
  }
</div>