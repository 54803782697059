<div class="text-center">
  <div class="d-flex  justify-content-{center} align-items-center">
    <div class='square  {{color}}' *ngIf="title"></div>
    <h6 class="labels">{{ title }} </h6>
  </div>
  <div  class="chart-container" style=" max-height:200px; max-width:200px">
    <canvas id="doughnut-{{id}}"></canvas>
  </div>
  <div class=" " style="margin-top: 2.5rem;">
    <h2 class="footer">{{footer}}</h2>
  </div>
</div>
