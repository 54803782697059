import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input() width: string = '100%';
  @Input() height: string = '100%';
  @Input() customStyle: string = "";
  @Input() ngStyle: {} = {};
  @Input() isTypeButton: boolean = false;
  @Input() cardShadow: boolean = true;
  @Input() cardBorder: boolean = true;
  @Input() cardBg: boolean = true;
}
