<div>
  <app-so-modal modalId="modal-sale-order" [verticalPosition]="'center'" size="modal-xl">
    <div class="modal__header" [style]="{'display':'flex','width':'100%','margin-top':'30px'}">
    </div>
    <div class="modal__body" [style]="{'padding':'15px'}">
      <div class="d-flex justify-content-center gap-2">
        <so-icon svg="mdi-shopping-cart-primary copy" [size]="0.7" />
        <p class="fw-bold ml-2" style="font-size: 19px; margin-top: 6px;">{{'sale_order'| translate}}</p>
      </div>
      <div class="mt-3">
        <div class="row gap-2 mt-3">
          <div class="col d-flex justify-content-start">
            <img src="./assets/img/mdi-clipboard-account-outline.svg" alt=""
              style="width: 27px; height: 27px;margin-left: 4px;">
            <p class="fw-bold" style="font-size: 20px; padding-left: 8px;">{{'prospect_data'|translate}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <app-so-input-form label="{{'key'|translate}}"></app-so-input-form>
          </div>
          <div class="col">
            <app-so-input-form label="{{'full_name'|translate}}"></app-so-input-form>
          </div>
          <div class="col">
            <app-so-input-form type="date" label="{{'registration_date'|translate}}"></app-so-input-form>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <app-so-input-form type="tel" [showVip]="true" [showTrash]="false"
              label="{{'phones'|translate}}"></app-so-input-form>
          </div>
          <div class="col">
            <app-so-input-form label="{{'main_email'|translate}}" type="email" placeholderCorreo=""
              placeholderDominio=""></app-so-input-form>
          </div>
          <div class="col">
            <app-so-input-form label="{{'check_in_time'|translate}}" type="time"></app-so-input-form>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <app-so-select label="{{'car'|translate}}" [options]="options"></app-so-select>
          </div>
          <div class="col">
            <app-so-select label="{{'font'|translate}}" [options]="options"></app-so-select>
          </div>
          <div class="col">
            <app-so-select label="{{'possibility_of_closure'|translate}}" [options]="options"></app-so-select>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <div class="row gap-2 mt-3">
          <div class="col d-flex justify-content-start align-items-center">
            <img src="./assets/img/mdi-ticket-percent-outline.svg" alt=""
              style="width: 27px; height: 27px;margin-left: 4px;">
            <p class="fw-bold" style="font-size: 20px; padding-left: 8px;margin-bottom: 0;">{{'tickets'|translate}}</p>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto mb-5" style="margin-left: 5px;">
        <table class=" text-sm text-left text-gray-500 dark:text-gray-400 text-left" [id]="id">
          <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
            <tr class="h-10" style="height: 25px;">
              <th class="fw-bold" style="color:#000" scope="col" *ngFor="let header of headers">
                <div class="d-flex justify-content-start align-items-center" style="font-size: 12px;">
                  <p style="margin: 0;">
                    {{header}}
                  </p>
                  <ng-container *ngIf="header.length > 0">
                    <mat-icon class="flechas">unfold_more</mat-icon>
                  </ng-container>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="" *ngFor="let row of rows" style="border-top-width: 1px; border-color: rgb(229, 231, 235);">
              <td class="">
                <p style="color: #002A7F; margin: 0; font-size: 12px;">{{row.orderkey}}</p>
              </td>
              <td class="" style="height: 2.5rem;font-size: 14px;">
                {{row.name}}
              </td>
              <td>
                <img src="./assets/img/mdi-cellphone.svg" style="width: 25px; height: 24px;">
                {{row.phone}}
              </td>
              <td class="" style="height: 2.5rem;">
                <img src="./assets/img/mdi-email-arrow-right.svg" style="width: 25px; height: 25px;">
                {{row.email}}
              </td>
              <td>
                <img *ngIf="row.vip !=='' " src="{{row.img}}" alt="" style="height: 35px;">
                <label style="font-weight: bold; color: #272D3B;padding-left: 5px;">{{row.auto}}</label>
              </td>
              <td class="" style="height: 2.5rem;font-size: 14px;">
                <app-so-badge color="primary" fontWeight="bold" text="{{row.product}}"></app-so-badge>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row mt-6">
        <div class="col d-flex justify-content-end gap-3">
          <app-button icon="add_circle_outline" iconAlignment="right" [scale]="1.2" text="{{ 'add_order' | translate }}"
            [injection]="{ label: 'pt-2' }" data-bs-toggle="modal" />
          <app-button svg="pencil-outline" iconAlignment="right" [scale]="2" text="{{ 'edit_order' | translate }}"
            [injection]="{ label: 'pt-2' }" data-bs-toggle="modal" />
          <app-button svg="microsoft-excel" iconAlignment="right" [scale]="2" text="{{ 'export_order' | translate }}"
            [injection]="{ label: 'pt-2' }" data-bs-toggle="modal" />
          <app-button icon="search" iconAlignment="right" [scale]="1.2" text="{{ 'consult' | translate }}"
            [injection]="{ label: 'pt-2' }" data-bs-toggle="modal" />
          <app-button svg="mdi-check-circle-outline" iconAlignment="right" [scale]=".9"
            text="{{ 'assign_sale' | translate }}" [injection]="{ label: 'pt-2' }" data-bs-toggle="modal" />
        </div>
      </div>
    </div>
  </app-so-modal>
</div>
