import { Component, EventEmitter, Input, Output, booleanAttribute, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'so-input-reactive-form',
  templateUrl: './so-input-reactive-form.component.html',
  styleUrl: './so-input-reactive-form.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SoInputReactiveFormComponent),
      multi: true
    }
  ]
})
export class SoInputReactiveFormComponent implements ControlValueAccessor {
  @Output() valueChanged = new EventEmitter<string>();
  @Input() placeholder?: string = '';
  @Input() label?: string = '';
  @Input() labelStyle: string = '';
  @Input() maxlength: number = 50;
  @Input() minlength?: number = 0;
  @Input() textColor: string = '';
  @Input() customStyle: {} = {};
  @Input() customBaseStyle: string = '';
  @Input() requiredField?: any;
  @Input() isValid?: boolean = true;
  @Input() statusFieldText?: string = '';
  @Input() icon?: string = '';
  @Input() isDisabled: boolean = false;
  @Input() inputId?: string;
  @Input() autocomplete: string = '';
  @Input() bigger: boolean = false;
  @Input() display: 'vertical' | 'horizontal' = 'vertical';
  @Input() fullWidth: boolean = false;
  @Input() typeInput? :
              | 'text'
              | 'name'
              | 'email'
              | 'number'
              | 'color'
              | 'password'
              | 'search'
              | 'url'
              | 'postalCode'
              | 'delimiter'
              | 'tel' = 'text';

  @Input() value?: string = '';
  @Input({ transform: booleanAttribute }) requiredMark: boolean = false;
  @Input() isUpperCase: boolean = false;
  @Input() isLowerCase: boolean = false;
  @Input() onlyAlphanumeric: boolean = false;
  @Input() maxNumber?: number; 
  @Input() minNumber?: number; 
  @Input() formControl: FormControl | null = null;

  showPassword: boolean = false;
  isPassword: boolean = false;

  onChange: any = () => {};
  onTouch: any = () => {};

  constructor() {}
  
  get touched(): boolean {
    return this.formControl?.touched ?? false;
  }

  get valid(): boolean {
    return this.formControl?.valid ?? true;
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  updateValue(value: string): void {
    if (this.typeInput === 'tel' && value !== null && value !==  undefined)
      value = value.trim();
    this.value = this.convertCase(value);
    this.onChange(this.value);
    this.onTouch();
    this.valueChanged.emit(this.value);
  }

  getValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  onPaste(event: ClipboardEvent) {
    if (this.typeInput === 'tel') {
      const pastedText = event.clipboardData?.getData('Text').trim() ?? '';
      if (!(/^\d+$/.test(pastedText)))
        event.preventDefault();
    }
  }

  onKeyPress(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;

    if (this.typeInput === 'tel' && !this.isNumberKey(event)) {
      event.preventDefault();
    }

    if(this.typeInput === 'number' && input.value.length >= this.maxlength){
      event.preventDefault();
    }

    if (this.onlyAlphanumeric) {
      const regex = /^[a-zA-Z0-9\s]$/;
    
      if (!regex.test(event.key) && event.key !== 'Backspace' && event.key !== 'Tab') {
        event.preventDefault();
      }
    }
  }

  changeTypeInput(){
    this.showPassword = !this.showPassword;
    this.isPassword = true;
    if(this.showPassword){
      this.typeInput = 'text'
    }else{
      this.typeInput = 'password'
    }
  }
  
  isNumberKey(event: KeyboardEvent): boolean {
    const charCode = event.key;
    const currentValue = (event.target as HTMLInputElement).value;
    const totalNumbers = currentValue.replace(/[^0-9]/g, '').length;
  
    if (totalNumbers >= 15 && charCode !== 'Backspace') {
      return false; // Evitar entrada si se alcanza el máximo de 15 números
    }
  
    return (charCode >= '0' && charCode <= '9') || charCode === 'Backspace';
  }

  private convertCase(value: string){
    if(this.isUpperCase){
      console.log(value.toUpperCase());
      
      return value.toUpperCase();
    }

    if(this.isLowerCase){
      return value.toLowerCase();
    }
    return value;
  }
}
