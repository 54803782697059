import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-so-card-bg-multi',
  templateUrl: './so-card-bg-multi.component.html',
  styleUrls: ['./so-card-bg-multi.component.scss']
})
export class SoCardBgMultiComponent {

  @Input() bgColor: 'WHATS' | 'FB' | 'SMS' | 'MAIL' | 'CALL' | 'INTERCHAT' | 'BLACK' | any = '';
  @Input() isVip = true;
  @Input() active = false;
  @Input() hover = true;
  @Input() img = 'avatar-random-0.png';
  @Input() title = 'prospect';
  @Input() valIcon = '';
  @Input() textMessage = '';
  @Input() iconSize = 1.5;

  @Input() typeCard: 'General' | 'Message' | 'OnlyIcon' | 'Tab' = 'General';

  @Input() icons: { name: string, value: string, type: string }[] = [];

  urlImg: string = './assets/img/'


  getImgIcon(icon: any) {
    var ic = ''
    switch (icon) {
      case 'SMS':
        ic = 'mdi-sms-multi.svg';
        break;
      case 'WHATS':
        ic = 'mdi-whatsapp-multi.svg';
        break;
      case 'MAIL':
        ic = 'mdi-email-multiple.svg';
        break;
      case 'CALL':
        ic = 'mdi-phone-in-talk.svg';
        break;
      case 'INTERCHAT':
        ic = 'logo.svg';
        break;
      case 'ADMIN':
        ic = 'shield-account-variant-outline.svg';
        break;
      default:
        ic = 'mdi-facebook-messenger.svg'
        break;
    }

    return this.urlImg + ic;
  }

  @Output() iconClick = new EventEmitter<any>();

  onClickIcon(type: string) {
    this.iconClick.emit(type);
  }

}
