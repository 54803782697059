<div class="container-lateral-menu">
  <section class="logotipo">
    <img src="assets\img\op.png" width="54" />
    <div style="height: 6px;"></div>
    <p> V.10 </p>
  </section>

  <section class="section-icons">
  @for(item of itemsMenu.items; let i = $index; track i) {
   @if (item.type === 'toggle') {
    <div class="item--toggle" [ngClass]="{ 'hoverable': item.hoverable }">
      <div class="item--toggle-icon" (click)="showToggleMenu(i)" style="text-align: center">
        @if (item.icon == 'file-chart') {
        <mat-icon>
          <svg
            style="vertical-align: baseline;"
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            viewBox="0 0 24 24"
          >
            <path
              id="file-chart"
              d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M7,20H9V14H7V20M11,20H13V12H11V20M15,20H17V16H15V20Z"
              transform="translate(-2 -3)"
            />
          </svg>
        </mat-icon>
      }
      @else {
       @if (item.svg !== 'file-chart-outline.svg') {
        <img *ngIf="item.svg" src="assets\img\{{ item.svg || '' }}" class="img-icon" />
       }
       @else {
        <div class="svg-icon">
          <div class="normal">
            <app-so-svg-icon src="assets/img/{{ item.svg || '' }}" [size]="1.5" color="--so-sidebar-items" [applyChange]="true" />
          </div>
          <div class="hovered">
            <app-so-svg-icon src="assets/img/{{ item.svg || '' }}" [size]="1.5" color="--so-hover-sidebar" [applyChange]="true" />
          </div>
        </div>
       }
      }
      </div>

      <div class="container-internal-items-toggle {{ item.show ? 'toggle-show' : 'toggle-hidden' }}"
        [style]="item?.config?.colors?.background != '' ? 'background: ' + item?.config?.colors?.background + ';' : ''"
      >
        <div class="container-item-list"
          *ngFor="let itemLink of item.contentLinks"
          [style]="item?.config?.colors?.icon != '' ? 'color: ' + item?.config?.colors?.icon + ';' : ''"
        >
          <div class="item-toggle" routerLink="{{ itemLink.url }}" [queryParams]="itemLink.params">
            <div style="margin: auto color">
             @if (itemLink.icon) {
              <mat-icon>{{ itemLink.icon }}</mat-icon>
             }
             @else {
              <app-so-svg-icon src="assets/img/{{ itemLink.svg || '' }}" [size]="1.2" color="--so-primary" [applyChange]="true" />
             }  
            </div>
            <p [style]="item?.config?.colors?.label != '' ? 'color: ' + item?.config?.colors?.label + ';' : ''">
              {{ itemLink.label || '' | translated }}
            </p>
          </div>
        </div>
      </div>
    </div>
   }
   @else {
    <div class="item--lateral" (click)="closeAllToggle()">
      <so-link route="{{ item.url }}">
        <mat-icon *ngIf="item.icon == 'home'">
          <svg
            style="vertical-align: baseline"
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            viewBox="0 0 40 35"
          >
            <path
              id="mdi-home-outline"
              d="M22,8.538,32,17.8V33.882H28V21.529H16V33.882H12V17.8L22,8.538ZM22,3,2,21.529H8V38H20V25.647h4V38H36V21.529h6Z"
              transform="translate(-2 -3)"
            />
          </svg>
        </mat-icon>

        <mat-icon *ngIf="item.icon == 'calendar-text-outline'">
          <svg
            style="vertical-align: baseline"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.1 0-2 .9-2 2v14a2 2 0 0 0 2 2h14c1.11 0 2-.89 2-2V5a2 2 0 0 0-2-2m0 16H5V9h14v10M5 7V5h14v2H5m2 4h10v2H7v-2m0 4h7v2H7v-2Z"
            />
          </svg>
        </mat-icon>

        <mat-icon
          *ngIf="
            item.icon &&
            item.icon != 'home' &&
            item.icon != 'calendar-text-outline'
          "
          >{{ item.icon }}</mat-icon
        >
        <img
          *ngIf="item.svg"
          class="img-icon"
          src="assets\img\{{ item.svg || '' }}"
        />
      </so-link>
    </div>
   }
  }
  </section>

  <section class="section-buttons" (click)="closeAllToggle()">
    <div
      class="section-buttons__container"
      *ngFor="let item of itemsMenu.actionsItems"
      (click)="handleEvent(item.event)"
    >
      <button
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#modal-simple"
      >
        <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
        <img
          *ngIf="item.svg"
          class="img-icon"
          src="assets\img\{{ item.svg || '' }}"
        />
      </button>
    </div>
  </section>
</div>

<app-so-modal modalId="modal-simple" [size]="size" verticalPosition="center"[preventClose]="true"
(close)="onClose($event)"
>
  <div
    class="modal__header"
    [style]="{ display: 'flex', width: '100%', 'margin-top': '30px' }"
  ></div>
  <div class="modal__body" [style]="{ padding: '0px' }">
    <app-simple-lead-capture [businessType]="businessType()"></app-simple-lead-capture>
  </div>
</app-so-modal>
<app-modal-sure-confirm/>
<app-modal-confirm-create/>
