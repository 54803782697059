import { Component, EventEmitter, Input, Output, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';

@Component({
  selector: 'so-input-email-reactive-form',
  templateUrl: './so-input-email-reactive-form.component.html',
  styleUrl: './so-input-email-reactive-form.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SoInputEmailReactiveFormComponent),
      multi: true
    }
  ]
})
export class SoInputEmailReactiveFormComponent implements ControlValueAccessor{

    @Input() placeholder?: string = '';
    @Input() label?: string = '';
    @Input() maxlength?: number = 50;
    @Input() minlength?: number = 0;
    @Input() textColor: string = '';
    @Input() requiredField?: any;
    @Input() email?: string;
    @Input() disabled: boolean = false;
    @Input() customStyle: {} = {};

    @Output() combinedValueChange = new EventEmitter<string>();

    suggestions: string[] = [
      'gmail.com',
      'yahoo.com',
      'outlook.com',
      'hotmail.com',
    ];
    filteredSuggestions: string[] = [];
    elementEvent?: any;

    // Propiedades para almacenar los valores de los inputs
    firstPartValue: string = '';
    secondPartValue: string = '';

    onChange: any = () => {};
    onTouch: any = () => {};

    constructor() { }

    ngOnChanges(changes: SimpleChanges) {
      if ('email' in changes) {
        const newValue = changes['email'].currentValue;
        this.updateEmailParts(newValue);
      }
    }

    // Funciones del ControlValueAccessor
    writeValue(value: any) {
      // Aquí puedes manejar el valor que se envía al componente si es necesario
      if (value !== undefined) {
        this.email = value ;
        this.updateEmailParts(this.email);
      }
    }

    registerOnChange(fn: any) {
      this.onChange = fn;
    }

    registerOnTouched(fn: any) {
      this.onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
      this.disabled = isDisabled;
    }

    private updateEmailParts(email: string | undefined) {
      const parts = (email || '').split('@');
      this.firstPartValue = parts[0];
      this.secondPartValue = parts[1] || '';
    }

    // Funciones para manejar el cambio en los inputs
    updateFirstPartValue(value: string) {
      this.firstPartValue = value;
      this.onChange(this.firstPartValue + '@' + this.secondPartValue);
      this.onTouch();
      this.emitCombinedValue();
    }

    updateSecondPartValue(value: string) {
      this.secondPartValue = value;
      this.onChange(this.firstPartValue + '@' + this.secondPartValue);
      this.onTouch();
      this.filteredSuggestions = this.suggestions.filter((suggestion) =>
        suggestion.includes(this.secondPartValue)
      );
      this.emitCombinedValue();
    }

    private emitCombinedValue() {
      const combinedValue = this.firstPartValue + '@' + this.secondPartValue;
      this.combinedValueChange.emit(combinedValue);
    }

    selectSuggestion(suggestion: string){
      this.secondPartValue = suggestion;
      this.filteredSuggestions = [];
    }

}
