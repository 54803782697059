import { Component } from '@angular/core';

@Component({
  selector: 'app-so-new-follow-up',
  templateUrl: './so-new-follow-up.component.html',
  styleUrls: ['./so-new-follow-up.component.scss']
})
export class SoNewFollowUpComponent {

}
