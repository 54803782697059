import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

interface CurrentStatusInterface {
    idEstado: string,
    nombre: string,
    color: string,
    activo: boolean,
}

const currentStatusObject: CurrentStatusInterface = {
    idEstado: '',
    nombre: '',
    color: '',
    activo: false,
}

export const CurrentStatus = signalStore(
    { providedIn: 'root' },
    withState(currentStatusObject),
    withMethods(({ ...store }) => ({
        setCurrentStatus(status: CurrentStatusInterface) {
            patchState(store, status);
        },
    }))
);