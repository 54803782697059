<div class="menu-pendientes" >
    <div class="d-flex gap-1 title-pending">
        <div class="icon-pending">
          <so-icon svg="account-clock-primary" [size]=".8" />
        </div>
        <p class="so-title mt-3">{{"filter_pending_multiagenda" | translate }}</p>
      </div>
      <div style="width: 90%; margin-bottom: -10px;">
        <app-so-select label="{{'type_activities'|translate}}" [data]="actividades" [required]="true"></app-so-select>
      </div>
      <div style="width: 90%; margin-top: 20px; margin-bottom: -5px;">
        <div class="mr-3">
          <div class="d-flex justify-content-between px-3">
            <app-so-badge ngClass="{{ '' }}" text="104" [isCircle]="true" color="black" fontSize="14px"></app-so-badge>
            <app-so-badge ngClass="{{ 'opacity-25' }}" text="90" [isCircle]="true" color="success"
              fontSize="20px"></app-so-badge>
            <app-so-badge ngClass="{{ 'opacity-25' }}" text="04" [isCircle]="true" color="danger"
              fontSize="14px"></app-so-badge>
            <app-so-badge ngClass="{{ 'opacity-25' }}" text="10" [isCircle]="true" color="success"
              fontSize="14px"></app-so-badge>
            <app-so-badge ngClass="{{ 'opacity-25' }}" text="90" [isCircle]="true" color="warning"
              fontSize="14px"></app-so-badge>
            <app-so-badge ngClass="{{ 'opacity-25' }}" text="90" [isCircle]="true" color="black"
              fontSize="14px"></app-so-badge>
            <label style="margin-right: -10px;">{{'date_reschedule'| translate}}</label>
            <mat-icon class=" filter-Fecha ">unfold_more</mat-icon>
          </div>
        </div>
      </div>

      <div style="width: 88%; margin-top: 15px;">
        @for ( card of cardsData; track card) {
        <div style="margin-bottom: 8px;">
          <app-so-card-bg [sizeCard]="'small'" [title]="card.title" [date]="card.date" [brColor]="card.brColor"
            [badgeText]="card.badgeText" [text]="card.text"></app-so-card-bg>
        </div>
        }
      </div>

      <div class="div-con-pico"></div>
</div>