
<div class="p-1 input__form">
    @if( label !== ''){
        <label class="pt-1 pb-1 fw-500" [style.color]="textColor"> {{ label }} </label>
    }
    <div class="h-fit d-flex input__form-email">
        <input class="border border-secondary-subtle start w-50 input-start"
            [(ngModel)]="firstPartValue"
            (ngModelChange)="updateFirstPartValue($event)"
            [maxLength]="maxlength"
            [minLength]="minlength"
            [disabled]="disabled"
            [ngClass]="disabled ? 'disabled' : ''"
            [ngStyle]="customStyle"
        />
        <span class="border border-secondary-subtle central-span">
            {{ '@' }}
        </span>
        <input class="border border-secondary-subtle start w-50 input-end"
            [(ngModel)]="secondPartValue"
            (ngModelChange)="updateSecondPartValue($event)"
            [maxLength]="maxlength"
            [minLength]="minlength"
            autocomplete="off"
            [disabled]="disabled"
            [ngClass]="disabled ? 'disabled' : ''"
            [ngStyle]="customStyle"
            />
        @if (filteredSuggestions.length > 0) {
            <ul>
                @for (suggestion of filteredSuggestions; track $index) {
                    <li (click)="selectSuggestion(suggestion)">
                        {{ suggestion }}
                    </li>
                }
            </ul>
        }
    </div>
</div>