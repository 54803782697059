import { Injectable } from '@angular/core';
import { Colores } from '@core/interfaces/catalogos.intefaces';
import { Request } from '@core/models/requests.model';

@Injectable({
  providedIn: 'root'
})
export class ColoresFlotillasService {
  private url = `ventas/catalogos/flotillas/color/search`;

  constructor(
    private request: Request
  ) { }

  getAllColoresFlotillas(body: any) {
    const options = { headers: this.request.headers };
    return this.request.Post<Colores>(this.url, body, options);
  }
}
