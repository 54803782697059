<div class="d-flex flex-column text-center business_line">
  <label class="labelMultiNegociol">{{'business_line' | translate}}</label>
  <div class="dropdown mt-2">
    <button class="btn btn-secondary dropdown-toggle btnDropdown btn-sm d-flex align-items-center" type="button"
      data-bs-toggle="dropdown" aria-expanded="false">
      <so-icon svg="{{tiposDeNegocio_selected_Icon}}" [size]="0.65" color="--color-icon-inverse"/>
      <a style="padding-left: 5px;"> {{tiposDeNegocio_selected_Name | translate}}</a>
      <span class="additional-icon" style="margin-left: auto;">
        <img class="iconRight" src="/assets/img/chevron-right-sucess.svg" style="width: 28px;">
        <img class="iconDown" src="/assets/img/chevron-down-sucess.svg" style="width: 28px;">
      </span>
    </button>
    @if(tiposDeNegocio.length > 1){
    <ul class="dropdown-menu">
      @for (tipo of tiposDeNegocio; track tipo.idTipoDeNegocio) {
      <li>
        <ng-container *ngIf="tipo.idTipoDeNegocio !== tiposDeNegocio_selected">
          <a class="dropdown-item" (click)="cambiarSeleccion(tipo.idTipoDeNegocio)">
            <so-icon svg="{{getIcon(tipo.idTipoDeNegocio)}}" [size]="0.65" color="--color-icon-inverse" />
            {{getName(tipo.idTipoDeNegocio) | translate}}
          </a>
        </ng-container>
      </li>
      }
    </ul>
    }
  </div>
</div>
