import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalServiceService {

  constructor() { }

  public cerrarModal(modalId:string) {
    const modal = document.getElementById(modalId);
    const modalBackdrop = document.querySelector('.modal-backdrop');
    const body = document.querySelector('body');

  
    if (modal) {
      body?.classList.remove('modal-open');
      body!.removeAttribute('style');
      body!.removeAttribute('class');
      modal.classList.remove('show');

      modal.style.display = 'none';
      if (modalBackdrop) {
        modalBackdrop.remove();      
      }
    }
  }
}
