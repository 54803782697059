
import { Component } from '@angular/core';
import { FormStepsService } from '@shared/services/observables/form-steps.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'lead-capture-steps',
  templateUrl: './lead-capture-steps.component.html',
  styleUrl: './lead-capture-steps.component.scss'
})
export class LeadCaptureStepsComponent {

  public isStep2: boolean = false;
  public isDetailCapture: boolean = false;

  private subscription$?: Subscription;
  
  constructor(
    private formStepsServices: FormStepsService  
  ){
    this.getStep();
  }

  ngOnDestroy(): void {
    this.subscription$?.unsubscribe();
  }

  previousStep() {
    this.formStepsServices.setStep2(false);
  }

  changeDetailCapture() {
    this.isDetailCapture = !this.isDetailCapture;
    this.formStepsServices.setIsDetailCapture(this.isDetailCapture);
  } 

  getStep(){
     this.subscription$ = this.formStepsServices.isStep2$.subscribe(
      res => {
        this.isStep2 = res;
      }
    )
  }


}
