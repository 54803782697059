<div 
    [style.width]="width" 
    [style.height]="height" 
    [ngClass]="{
        'no-shadow': !cardShadow,
        'no-background': !cardBg,
        'no-border': !cardBorder,
        'type-button': isTypeButton,
    }"
    [style]="customStyle" 
    [ngStyle]="ngStyle" >
    <ng-content></ng-content>
</div>
