<app-so-modal modalId="modalEditarProspecto" size="modal-xl">

  <!-- Ícono y título del modal -->
  <div class="modal__header" [style]="{'display':'flex','width':'100%','margin':'30px 42px 0 0'}">
    <div class="center-element" style="width: fit-content;">
      <app-so-title-icon
        [iconSize]=".85"
        title="{{'edit_prospect' | translated | titlecase}}"
        fontSize="medium"
        gap="13px"
        logo="./assets/img/mdi-pencil-outline.svg"></app-so-title-icon>
    </div>
  </div>

  <!-- Cuerpo del modal -->
  <div class="modal__body d-flex flex-column" [style]="{'padding': '30px', 'display': 'flex', 'box-sizing': 'border-box'}">

    <!-- Cabezera datos del prospecto -->
    <div class="mt-3 d-flex flex-column align-items-center gap-3 flex-lg-row justify-content-between">
      <div class="d-flex flex-column align-items-center gap-2 flex-lg-row">
        <app-so-title-icon
        title="{{'main_information' | translated}}"
        [iconSize]=".8"
        gap="10px"
        logo="./assets/img/mdi-clipboard-account-outline.svg"
        fontSize="small"
        ></app-so-title-icon>
        <p
          style="margin: 0 0 0 20px; font: var(--so-text-3);">
        {{ 'key' | translated | uppercase}}: {{ editData.idProspectos }}</p>
      </div>

      <div class="d-flex">
        <app-icon-button
          [style]="{'font-weight':'bold'}"
          [text]="editData.nombre + ' ' + editData.apellidoPaterno + ' ' + editData.apellidoMaterno"
          icon="./assets/img/avatar-user.png"
          class="me-lg-2 mb-2"
          firstIcon="true"
          size="var(--so-text-4)"
          textColor="var(--azul-seekop)"
          widthIcon="35px"
          heightIcon="35px"></app-icon-button>
      </div>
    </div>

    <!-- Cuerpo datos del prospecto -->
    <div class="mt-2 d-flex flex-wrap flex-column flex-lg-row gap-4 gap-lg-3" style="flex-basis: 100%;">

      <!-- Primer columna de datos del prospecto -->
      <div class="flex-lg-grow-1 d-flex flex-column gap-3 align-items-center align-items-lg-stretch">
        <app-so-input-form type="text" [value]="editData.nombre" label="{{ 'name' | translated }}" maxWidth="{{ inputsMaxWidth }}px" requiredMark="true" paddingClasses="p-0"></app-so-input-form>
        <app-so-input-form type="text" [value]="editData.cp" label="{{ 'postal_code' | translated }}" maxWidth="{{ inputsMaxWidth }}px" requiredMark="true" paddingClasses="p-0"></app-so-input-form>
        <div class="d-flex flex-column gap-2 p-[4px]">
          <p class="title" style="font-size: var(--so-text-2); margin: 0;">Género del prospecto<app-so-required-mark show="true" /></p>
          <div class="d-flex gap-2">
            <app-so-radio text="{{ 'male' | translated }}" ratioId="ratio_m" nameGroup="genero" [style]="{'flex-direction': 'reverse'}"></app-so-radio>
            <app-so-radio text="{{ 'female' | translated }}" ratioId="ratio_f" nameGroup="genero"></app-so-radio>
          </div>
        </div>
        <div class="d-flex flex-column gap-2 p-[4px]">
          <p class="title" style="font-size: var(--so-text-2); margin: 0;">{{ 'private_data' | translated }}</p>
          <div class="d-flex gap-2">
            <app-so-radio text="{{ 'yes' | translated }}" [checked]="editData.datosPrivados" ratioId="ratio_m" nameGroup="datos_privados" [style]="{'flex-direction': 'reverse'}"></app-so-radio>
            <app-so-radio text="{{ 'no' | translated }}" [checked]="!editData.datosPrivados" ratioId="ratio_f" nameGroup="datos_privados"></app-so-radio>
          </div>
        </div>
      </div>
      
      <!-- Segunda columna de datos del prospecto -->
      <div class="flex-lg-grow-1 d-flex flex-column gap-3 align-items-center align-items-lg-stretch">
        <app-so-input-form type="text" [value]="editData.segundoNombre" label="{{ 'second_name' | translated | titlecase }}" maxWidth="{{ inputsMaxWidth }}px" paddingClasses="p-0"></app-so-input-form>
        <app-so-select label="{{ 'source_of_information' | translated }}" [data]="fuentes" selectedIndex="1" maxWidth="{{ inputsMaxWidth }}px" requiredMark="true" paddingClasses="p-0" fontWeight="400"></app-so-select>
        <app-so-input-form type="text" [value]="editData.empresa" label="{{ 'company' | translated }}" maxWidth="{{ inputsMaxWidth }}px" paddingClasses="p-0"></app-so-input-form>
        <app-so-select label="{{ 'marital_status' | translated }}" [data]="estadosCiviles" selectedIndex="1" maxWidth="{{ inputsMaxWidth }}px" requiredMark="true" paddingClasses="p-0" fontWeight="400"></app-so-select>
      </div>

      <!-- Tercera columna de datos del prospecto -->
      <div class="flex-lg-grow-1 d-flex flex-column gap-3 align-items-center align-items-lg-stretch">
        <app-so-input-form type="text" [value]="editData.apellidoPaterno" label="{{ 'last_name' | translated | titlecase }}" maxWidth="{{ inputsMaxWidth }}px" requiredMark="true" paddingClasses="p-0"></app-so-input-form>
        <app-so-select label="{{ 'subcampaign' | translated }}s" [data]="subcampanas" selectedIndex="1" maxWidth="{{ inputsMaxWidth }}px" requiredMark="true" paddingClasses="p-0" fontWeight="400"></app-so-select>
        <app-so-input-form type="text" [value]="editData.rfc" label="{{ 'rfc_abbreviation' | translated }}" maxWidth="{{ inputsMaxWidth }}px" paddingClasses="p-0"></app-so-input-form>
        <app-so-select label="{{ 'sector' | translated }}" [data]="sectores" selectedIndex="1" maxWidth="{{ inputsMaxWidth }}px" paddingClasses="p-0" fontWeight="400"></app-so-select>
      </div>

      <!-- Cuarta columna de datos del prospecto -->
      <div class="flex-lg-grow-1 d-flex flex-column gap-3 align-items-center align-items-lg-stretch">
        <app-so-input-form type="text" [value]="editData.apellidoMaterno" label="{{ 'mothers_last_name' | translated | titlecase }}" maxWidth="{{ inputsMaxWidth }}px" paddingClasses="p-0"></app-so-input-form>
        <app-so-select label="Rango de edad" maxWidth="{{ inputsMaxWidth }}px" [data]="rangosEdadedes" paddingClasses="p-0" fontWeight="400"></app-so-select>
        <app-so-input-form type="text" [value]="editData.curp" label="{{ 'curp_abbreviation' | translated }}" maxWidth="{{ inputsMaxWidth }}px" paddingClasses="p-0"></app-so-input-form>
        <app-so-input-form type="date" [value]="editData.cumpleanios" label="{{ 'date_of_birth' | translated }}" maxWidth="{{ inputsMaxWidth }}px" paddingClasses="p-0" fontWeight="400"></app-so-input-form>
      </div>

      <!-- Textarea de observaciones -->
      <div class="mt-lg-3">
        <app-so-text-area
          [text]="editData.observacion"
          [style]="'width: ' + (inputsMaxWidth * 2 + 28) + 'px; max-width: 100%;'"
          class="text-center text-lg-start d-lg-inline-block"
          label="{{ 'observations' | translated }}"
          rows="3"
          paddingClasses="px-0 py-1"></app-so-text-area>
      </div>

    </div>

  </div>
  <div class="modal__footer center-element">
    <div class="w-100" style="height: 40px;">
      <app-icon-button class="cl-green-seekop" size="14px" icon="check_circle_outline" text="{{ 'save' | translated }}"
        sizeIcon="11px" [styles]="{'color':'#505665'}"></app-icon-button>
    </div>
  </div>
</app-so-modal>
