import { Component, effect, inject } from '@angular/core';
import { getState } from '@ngrx/signals';
import { showFilters } from '@shared/components/cellular/so-advance-filters/stores/advance-filters.store'
import { catalogs } from '@core/interfaces/filter-catalogs.interface';
import { FilterCatalogs } from '../../../../../core/stores/filter-catalogs.store';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@core/api/translate.service';
import { TiposDeFechasService } from '@core/services/catalogos/advanceFiltersCatalogs/advance-filters-catalogs.service';
import { RechazosService } from '@core/services/catalogos/advanceFiltersCatalogs/advance-filters-rejections.service';

@Component({
  selector: 'app-modal-filter-advance',
  templateUrl: './modal-filter-advance.component.html',
  styleUrls: ['./modal-filter-advance.component.scss'],
})
export class ModalFilterAdvanceComponent {
  private readonly translateService = inject(TranslateService);
  styleAccordion = '';
  public title: string | null = '';
  public channelIcon: string | null = '';
  public showTitle: boolean = false;

  estadosRechazo: any = [
    {id: '0', value: this.translateService.instant('definitive')},
    {id: '1', value: this.translateService.instant('temporary')},
  ];

  public catalogs: catalogs = {
    tiposDeFechas: [{}],
    tiposDeOrden: [{}],
    tiposDeMostrar: [{}],
    tiposDePersona: [{}],
    motivosRechazos: [{}],
    tiposDeRechazos: [{}],
    estadosDeRechazo: [{}],
  };
  public dateChecks = [
    {id: "radio_1", text: "floor"},
    {id: "radio_2", text: "reception"},
    {id: "radio_3", text: "no_reception"},
    {id: "radio_4", text: "contacted_by_CC"},
    {id: "radio_5", text: "rejected_prospects"},
    {id: "radio_6", text: "unfollowed_leads"},
    {id: "radio_7", text: "tracked_leads"},
    {id: "radio_8", text: "customers"},
    {id: "radio_9", text: "duplicate_prospects"},
    {id: "radio_10", text: "important_prospects"},
    {id: "radio_11", text: "prospects_with_survey_applied"},
    {id: "radio_12", text: "prospects_without_applied_survey"},
    {id: "radio_13", text: "leads"},
    {id: "radio_14", text: "street"},
  ];
  public activeFilterAdvance: boolean = false;
  private readonly showFilters = inject(showFilters);
  private readonly filterCatalogs = inject(FilterCatalogs);

  constructor(
    private tiposDeFechasService: TiposDeFechasService,
    private rechazosService: RechazosService,
    private formBuilder: FormBuilder,
  ){
    effect(() => {
      const state = getState(this.showFilters);
      this.channelIcon = state.icon,
      this.showTitle = state.showTitle
    });
    this.formAdvanceFilters = this.formBuilder.group({
      formDates: this.formBuilder.group({
        startDate: [''],
        endDate: [''],
        idTipoFecha: [''],
        idTipoOrden: [''],
        idTipoMostrar: [''],
        radio_1: false,
        radio_2: false,
        radio_3: false,
        radio_4: false,
        radio_5: false,
        radio_6: false,
        radio_7: false,
        radio_8: false,
        radio_9: false,
        radio_10: false,
        radio_11: false,
        radio_12: false,
        radio_13: false,
        radio_14: false,
      }),
      formBilling: this.formBuilder.group({
        rfc: [''],
        legalName: [''],
        idTipoPersona: [''],
      })
    })
  }
  ngAfterViewInit() {
    this.loadCatalogs();
  }  

  clearAdvancedFilters() {
    const labelBg = document.querySelector('.label-bg') as HTMLElement;
    const labelCheck = document.querySelector('.label-check') as HTMLElement;
    labelBg.classList.remove('checked');
    labelCheck.classList.remove('checked');
    this.activeFilterAdvance = false;
  }
  loadCatalogs(){
    let tiposDeFechasPromise = new Promise<[{}]>((resolve,reject) =>{
      this.tiposDeFechasService?.getAllTiposDeFechas().subscribe((rta: any) => {
          if (typeof rta.response === 'object') resolve(rta.response);
        },(error) => reject(error)
      );
    });
    let tiposDeOrden = new Promise<[{}]>((resolve,reject) =>{
      this.tiposDeFechasService?.getTiposDeOrden().subscribe((rta: any) => {
          if (typeof rta.response === 'object') resolve(rta.response);
        },(error) => reject(error)
      );
    });
    let tiposDeMostrar = new Promise<[{}]>((resolve,reject) =>{
      this.tiposDeFechasService?.getTiposDeMostrar().subscribe((rta: any) => {
          if (typeof rta.response === 'object') resolve(rta.response);
        },(error) => reject(error)
      );
    });
    let tiposDePersona = new Promise<[{}]>((resolve,reject) =>{
      this.tiposDeFechasService?.getTiposDePersona().subscribe((rta: any) => {
          if (typeof rta.response === 'object') {
            console.log(rta.response.tiposdepersona);
            
            resolve(rta.response.tiposdepersona);

          }
        },(error) => reject(error)
      );
    });
    const motivosRechazosPromise = new Promise<[{}]>((resolve, reject) => {
      this.rechazosService.getAllMotivosRechazos().subscribe({
        next: (rta: any) => {
          if (typeof rta.response === 'object')
            resolve(rta.response.MotivosRechazos);
        },
        error: (error: any) => { reject(error); }
      });
    });
    const tiposDeRechazosPromise = new Promise<[{}]>((resolve, reject) => {
      this.rechazosService.getAllTiposDeRechazos().subscribe({
        next: (rta: any) => {
          if (typeof rta.response === 'object')
            resolve(rta.response.TiposDeRechazos);
        },
        error: (error: any) => { reject(error); }
      });
    });
    Promise.all([
      tiposDeFechasPromise,
      tiposDeOrden,
      tiposDeMostrar,
      tiposDePersona,
      motivosRechazosPromise,
      tiposDeRechazosPromise,
    ]).then((values) => {
        this.catalogs.tiposDeFechas = values[0];
        this.catalogs.tiposDeOrden = values[1];
        this.catalogs.tiposDeMostrar = values[2];
        this.catalogs.tiposDePersona = values[3];
        this.catalogs.motivosRechazos = values[4];
        this.catalogs.tiposDeRechazos = values[5];
        this.catalogs.estadosDeRechazo = this.estadosRechazo;
        this.filterCatalogs.loadCatalogsData(this.catalogs);
    });
  }
  public formAdvanceFilters!: FormGroup;
  loadFormData() {
      
    console.log(this.formAdvanceFilters.value);
    
  }
}
