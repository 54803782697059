import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {
  transform(size: number | string, startInKB = false): string {
    if (typeof size === 'string') {
      size = parseFloat(size); // Convierte el string a número si es un string válido.
    }

    if (isNaN(size) || size < 0) {
      return 'Tamaño inválido';
    }

    if (size === 0) {
      return '0 Bytes';
    }

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (startInKB) sizes.splice(0, 1);
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return Math.round(size / Math.pow(1024, i)) + ' ' + sizes[i];
  }
}
