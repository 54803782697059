import { Component } from '@angular/core';

@Component({
  selector: 'app-body-loading',
  templateUrl: './body-loading.component.html',
  styleUrls: ['./body-loading.component.scss']
})
export class BodyLoadingComponent {

}
