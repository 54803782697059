@if (visible) {
  <div class="burbble-warning" [@fadeInOut] [ngStyle]="{'width': width}">
    <div class="float-end">
      <mat-icon class="icon-close order-last" (click)="onClick()">{{icon}}</mat-icon>
    </div>
    @for (parrafo of parrafos; track $index) {
      <p class="burbble-warning-p">{{ parrafo }}</p>
    }
    <p class="burbble-warning-p" style="line-height: 30px;">{{ content }}</p>
  </div>
}
