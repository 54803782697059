import { Component, ElementRef, EventEmitter, Input, OnInit, Output, booleanAttribute, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'so-input-date-reactive-form',
  templateUrl: './so-input-date-reactive-form.component.html',
  styleUrl: './so-input-date-reactive-form.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SoInputDateReactiveFormComponent),
      multi: true
    }
  ]
})
export class SoInputDateReactiveFormComponent implements ControlValueAccessor, OnInit{

  @Input() label: string = '';
  @Input() textColor: string = '';
  @Input() position: 'top'|'left' = 'top';
  @Input() initDate: string = '';
  @Input() isDisabled: boolean = false;
  @Input() minDate: string | null = null; //Formato YYYY-MM-DD 
  @Output() valueChange = new EventEmitter<string>();
  @Input() formControl: FormControl | null = null;
  @Input({ transform: booleanAttribute }) requiredMark: boolean = false;

  value: string | null = null;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    if(this.initDate){
      this.value = this.initDate;
    }
  }

  writeValue(value: any): void {
    this.value = value;
    console.log(this.initDate);
    
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    const inputElement = this.elementRef.nativeElement.querySelector('input');
    if (inputElement) {
      inputElement.disabled = isDisabled;
    }
  }

  onInputChange(event: Event): void {
    const newValue = (event.target as HTMLInputElement).value;
    const formattedDate = new Date(newValue).toUTCString();
    this.value = newValue;
    this.onChange(newValue);
    this.onTouched();
    this.valueChange.emit(formattedDate);
  }

  directionLabel( value: string): string{
    if( value === 'left'){
      return 'd-flex align-items-center';
    }
    return '';
  }

  get touched(): boolean {
    return this.formControl?.touched ?? false;
  }

  get valid(): boolean {
    return this.formControl?.valid ?? true;
  }
}
