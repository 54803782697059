@if (requestStatus === PromiseStatus.Pending) {
<so-spinner />
}
@else if (requestStatus === PromiseStatus.Rejected) {
<p class="so-text-2 mb-0 text-center">{{ errorMessage }}</p>
}
@else if (!summaryFollowups || summaryFollowups.length === 0) {
<span class="so-text-2 d-block text-center">{{ 'no_data' | translated }}</span>
}
@else {
<div style="max-height: 150px; overflow-y: auto;">
  @for (item of summaryFollowups; track $index) {
    <app-so-card-list 
      [date]="item.primerContacto" 
      [title]="item.actividad" 
      [prospect]="item.ejecutivo"
      [isSuccess]="item.exitosa"
      (buttonClick)="goToFollowUp($event, item)"
    />
  }
</div>
}
