
<form [formGroup]="form">
    @if(businessType === 'TN00000002'){
        <div formArrayName="carInterestPreOwned">
             @for (interestPreOwned of carInterestPreOwned.controls; track $index) {
                <div [formGroupName]="$index">
                    <so-car-interest-pre-owned
                        [form]="asFomrGroup(interestPreOwned)"
                        directionItems="row"
                        yearsControl="anios"
                        carBrandControl="marca"
                        carInterestControl="auto"
                        carVersionsControl="versiones"
                        colorsControl="colores"
                    ></so-car-interest-pre-owned>
                </div>
                @if (showAddButton) {
                    <div 
                        class="row"
                        style="padding-bottom: 15px;"
                        [ngStyle]="carInterestPreOwned.length > 1  ? { 'border-bottom': '1px solid #C5C5C580'} : {}"
                        >
                        <div class="d-flex justify-content-end" style="margin-left: 9px;">
                            @if(carInterestPreOwned.length > 1){
                                <button  (click)="removeCarInterestPreOwned($index)">
                                    <img src="./assets/img/mdi-minus-circle-outline-danger.svg" alt="" style="width: 25px; height: 25px;">
                                </button>
                            }
                            @if (($index + 1 == 1 && carInterestPreOwned.length == 1) || ($index + 1 >= carInterestPreOwned.length)) {
                                <button (click)="addCarInterestPreOwned()" >
                                    <mat-icon 
                                        [ngClass]="'so-secondary'" 
                                        [ngStyle]="{'font-weight':'500','font-size':'30px','width':'35px',
                                        'height':'35px','margin-top': '12px','margin-right': '4px'}">
                                        add_circle_outline
                                    </mat-icon>
                                </button>
                            }
                            @if(!(($index + 1 == 1 && carInterestPreOwned.length == 1) || ($index + 1 >= carInterestPreOwned.length))){
                                <div style="width: 35px;">
                                </div>
                            }
                        </div>
                    </div>
                }
             }
        </div> 
    }@else if (businessType === 'TN00000003') {
        <div formArrayName="carInterestFleet">
            @for ( interestFleet of carInterestFleet.controls; track $index) {
                <div [formGroupName]="$index">
                    <so-car-interest-fleet
                        [form]="asFomrGroup(interestFleet)"
                        directionItems="row"
                        interestBrandControl="marca"
                        carInterestControl="auto"
                        yearsControl="anios"
                        versionsControl="versiones"
                        colorsControl="colores"
                        quantityControl="cantidad"
                    ></so-car-interest-fleet>
                </div>
                @if (showAddButton) {
                    <div 
                        class="row"
                        style="padding-bottom: 15px;"
                        [ngStyle]="carInterestFleet.length > 1  ? { 'border-bottom': '1px solid #C5C5C580'} : {}"
                        >
                        <div class="d-flex justify-content-end" style="margin-left: 9px;">
                            @if(carInterestFleet.length > 1){
                                <button  (click)="removeCarInterestFleet($index)">
                                    <img src="./assets/img/mdi-minus-circle-outline-danger.svg" alt="" style="width: 25px; height: 25px;">
                                </button>
                            }
                            @if (($index + 1 == 1 && carInterestFleet.length == 1) || ($index + 1 >= carInterestFleet.length)) {
                                <button (click)="addCarInteresFleet()" >
                                    <mat-icon 
                                        [ngClass]="'so-secondary'" 
                                        [ngStyle]="{'font-weight':'500','font-size':'30px','width':'35px',
                                        'height':'35px','margin-top': '12px','margin-right': '4px'}">
                                        add_circle_outline
                                    </mat-icon>
                                </button>
                            }
                            @if(!(($index + 1 == 1 && carInterestFleet.length == 1) || ($index + 1 >= carInterestFleet.length))){
                                <div style="width: 35px;">
                                </div>
                            }
                        </div>
                    </div>
                }
            }
        </div>
    }@else if(businessType === 'TN00000001'){
        <div formArrayName="carInterestNew">
            @for ( interestNew of carInterestNew.controls; track $index) {
                <div [formGroupName]="$index" >
                    <so-car-interest-new
                        [form]="asFomrGroup(interestNew)"
                        brandControl="marca"
                        carsControl="auto"
                        yearsControl="anio"
                        versionsControl="versiones"
                        colorsControl="colores"
                        directionItems="row"
                    ></so-car-interest-new>
                </div>
                @if (showAddButton) {
                    <div 
                        class="row"
                        style="padding-bottom: 15px;"
                        [ngStyle]="carInterestNew.length > 1  ? { 'border-bottom': '1px solid #C5C5C580'} : {}"
                        >
                        <div class="d-flex justify-content-end" style="margin-left: 9px;">
                            @if(carInterestNew.length > 1){
                                <button  (click)="removeCarInterestNew($index)">
                                    <img src="./assets/img/mdi-minus-circle-outline-danger.svg" alt="" style="width: 25px; height: 25px;">
                                </button>
                            }
                            @if (($index + 1 == 1 && carInterestNew.length == 1) || ($index + 1 >= carInterestNew.length)) {
                                <button (click)="addCarInterestNew()" >
                                    <mat-icon 
                                        [ngClass]="'so-secondary'" 
                                        [ngStyle]="{'font-weight':'500','font-size':'30px','width':'35px',
                                        'height':'35px','margin-top': '12px','margin-right': '4px'}">
                                        add_circle_outline
                                    </mat-icon>
                                </button>
                            }
                            @if(!(($index + 1 == 1 && carInterestNew.length == 1) || ($index + 1 >= carInterestNew.length))){
                                <div style="width: 35px;">
                                </div>
                            }
                        </div>
                    </div>
                }
            }
        </div>
    }
</form>