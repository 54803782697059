import { Component, OnInit, ChangeDetectorRef, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UsuariosService } from '@core/services/usuarios/usuarios.service';

@Component({
  selector: 'app-so-executive-select',
  templateUrl: './so-executive-select.component.html',
  styleUrl: './so-executive-select.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SoExecutiveSelectComponent),
      multi: true
    }
  ]
})
export class SoExecutiveSelectComponent implements OnInit, ControlValueAccessor {
  @Input() required: boolean = false;
  @Input() label: string = '';
  @Input() showLoadingIcon: boolean = true;
  users?: any;

  onChange: any = () => {};
  onTouch: any = () => {};

  constructor(
    private UsuariosService: UsuariosService,
    private cdRef: ChangeDetectorRef
  ) {
    this.loadUserFn();
  }

  ngOnInit(): void {
   
  }

  loadUserFn() {
    this.UsuariosService?.getAllUsers().subscribe((rta: any) => {
      if (typeof rta.response === 'object') {
        this.showLoadingIcon = false;
        const values = rta.response['usuarios'] as any[];
        this.users = values.map((usuario: any) => ({
          idUsuario: usuario.idUsuario,
          nombreCompleto: usuario.empleado.nombre + ' ' + usuario.empleado.paterno + ' ' + usuario.empleado.materno
        }));
      }
      this.cdRef.detectChanges();
    });
  }

  writeValue(value: any): void {
    this.users = value;
    this.loadUserFn();
  }

  registerOnChange(fn: any): void {
    this.loadUserFn();
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.loadUserFn();
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {

  }

}
