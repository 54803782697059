import { Injectable } from '@angular/core';
import { ConfigInterface } from '@core/interfaces';
import { Anios } from '@core/interfaces/catalogos.intefaces';
import { Request } from '@core/models/requests.model';

@Injectable({
  providedIn: 'root',
})
export class AniosService {
  private url = `ventas/catalogos/nuevos/anio/search`;

  constructor(private request: Request) {}

  getAnios(body?: any, brandId?: string, carId?: string) {
    const config: ConfigInterface = this.request.getConfigUser();
    const requestBody = body ? body : { idMarca: brandId, idAuto: carId, configuracion: { basesDeDatos: config.basesDeDatos, idioma: config.idioma } };
    const options = { headers: this.request.headers };
    return this.request.Post<Anios>(this.url, requestBody, options);
  }

  formatValues(data: any[], keyProp: string, valueProp: string): { key: any; value: any }[] {
    return data.map(item => ({ key: item[keyProp], value: item[valueProp] }));
  }
}
