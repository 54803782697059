import { Injectable } from '@angular/core';
import { Employee } from '@core/interfaces/catalogos.intefaces';
import { Request } from '@core/models/requests.model';
import { StorageService } from '@core/services/storages.service';

@Injectable({
  providedIn: 'root'
})
export class YourInformationService {
  private url = `global/informacion/usuario/search`;
  private urlUpdate = `global/informacion/usuario/update`;

  constructor(
    private request: Request,
    private storageService: StorageService
  ) { }

  getYourInformation() {
    const storageData = this.storageService.getLocalStorage('userInfo').data;
    const body = {
      idEmpleado: storageData.empleado.idEmpleado,
      configuracion: this.request.getConfigUser(),
    };

    const options = {
      headers: this.request.headers,
    };

    return this.request.Post<Employee>(`${this.url}`, body, options);
  }

  registerEmployee(body: any) {
    const options = { headers: this.request.headers };
    return this.request.Put<Employee>(this.urlUpdate, body, options);
  }


}
