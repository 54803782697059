<div class="d-flex">
  <div class="container-button">
    <div class="action-button square-button" (click)="actionMenu()" [ngClass]="showFilter ? 'right' : 'left'">
      <div class="triangulo" [ngClass]="showFilter ? 'right' : 'left'"></div>
      <div class="content-img">
        <img src="./assets/img/mdi-filter-alt-white.svg" alt="">
      </div>
      <div class="content-check">
        <label for="" class="label-bg" [ngClass]="showFilter ? 'checked' : ''">
          <label for="" class="label-check" [ngClass]="showFilter ? 'checked' : ''" style="cursor: pointer;"></label>
        </label>
      </div>
    </div>
  </div>
</div>      
  