import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalSessionService } from '@core/services/global-session.service';
import { PushNotificationService } from '@modules/auth/services/push-notification/push-notification.service';

@Component({
  selector: 'so-close-session',
  templateUrl: './close-session.component.html',
  styleUrls: ['./close-session.component.scss']
})
export class SoCloseSessionComponent {
  private readonly session = inject(GlobalSessionService);
  private readonly router = inject(Router);

  // constructor(private pushNotificationService: PushNotificationService) { }
  
  async signOut(): Promise<void> {
    
    // await this.pushNotificationService.deleteTokenFromFirebase().then((mensaje) => {
    //   console.log('mensaje', mensaje);
    //   this.pushNotificationService.deleteTokenFromDB().subscribe((res) => {
    //     console.log('Se eliminó el token con Éxito', res);
    //   });
    // }).catch((error) => {
    //   console.log('error', error);
    // });
    this.session.resetAuthentication();
    this.router.navigateByUrl('/auth/login');
    // this.router.navigateByUrl('/configuration-ia/main');
  }
}
