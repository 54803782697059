import { Component } from '@angular/core';
import { NavbarService } from '@shared/services/observables/navbar.service';

@Component({
  selector: 'app-navbar-multicanal',
  templateUrl: './navbar-multicanal.component.html',
  styleUrls: ['./navbar-multicanal.component.scss']
})
export class NavbarMulticanalComponent {

  constructor(private navbarService: NavbarService) {

  }
  multicanal = false;
  toggleBoolean( type: string) {
    this.multicanal = !this.multicanal;
    this.navbarService.setBooleanValue(type);
  }

}
