<div class="file-drop-zone" 
  (dragover)="!disabled && onDragOver($event)" 
  (dragleave)="!disabled && onDragLeave($event)" 
  (drop)="!disabled && onDrop($event)"
  [class.dragging]="isDragging && !disabled" 
  [class.disabled]="disabled" 
  (click)="!disabled && fileInput.click()"
  >
  <input 
    type="file" 
    multiple 
    hidden
    #fileInput
    [disabled]="disabled"
    [id]="idInput"
    (change)="onFileSelected($event)"
    accept=".txt, .xls, .xlsx, .csv, .doc, .docx, .pdf, image/*, video/*"
  >

  @if(files.length > 0){
    <div class="row row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2" >
      @for (file of files; track $index) {
        <div class="col mt-1 d-flex justify-content-start align-items-center"
          onclick="event.stopPropagation()" 
          (click)="removeFile($index)">
          <app-button type="button" svg="mdi-minus-circle-outline-danger" [scale]=".7" class="me-2"
            [injection]="{icon: 'margin-top: 0px !important', button: '' }" [applyStyles]="false"></app-button>
          <span class="truncate-text" [title]="file.name">
            {{ file.name }}
          </span>
          <div class="px-1">
            <mat-icon
              [style.color]="getColorForFile(file)"                            
            > 
              {{ getIconForFile(file) }} 
            </mat-icon>
          </div>
        </div>
      }
    </div>
  }
</div>

<div class="d-flex align-items-center" [ngClass]="files.length > 0 ? 'justify-content-end' : 'justify-content-between'">
  @if(showMessage){
    @if (files.length == 0) {
      <p class="message">{{'grag_drop_message_first_part' | translated : 'Arrastra y suelta tus archivos aquí'}} {{'or' | translated : 'o'}} 
        <strong class="so-text">{{'grag_drop_message_second_part' | translated : 'haz clic aquí para seleccionar'}}</strong>
      </p>
    }
  }

  @if(showButtonSave){
    @if(files.length > 0 && !validateByModal){
      <div class="save-button">
        <app-button svg="mdi-check-circle-outline" iconAlignment="right" text="{{'accept' | translated : 'Aceptar'}}"
          [injection]="{ label: 'pt-2' }" (click)="saveFiles()">
        </app-button>
      </div>
    }
  }
</div>
