@if (requestStatus === PromiseStatus.Pending) {
<so-spinner />
}
@else if (requestStatus === PromiseStatus.Rejected) {
<span class="so-text-2 d-block text-center">{{ errorMessage }}</span>
}
@else {
<div>
  <h5 class="title-according">{{'contact_hours'| translate}}</h5>
  <h6 class="subtitle-according"> {{ communicationData?.horarioContactoInicio + " hrs" }} a {{ communicationData?.horarioContactoFin + " hrs" }}</h6>

  @if (communicationData?.telefonos?.length) {
    <h6 class="mb-1 mt-3" style="font-weight: 500!important; color: #272D3B; margin: 0; font-size: 14px;">
      {{'phones'|translate}}</h6>
  }
  
  <div class="d-flex align-items-end ">
    @for (item of communicationData?.telefonos; track $index) {
      <img [src]="'./assets/img/'+setImgVip(item.principal)" style="padding-bottom: 6px; cursor: pointer;">
      <div style="position: relative; left: 10px;">
        <so-input-reactive-form typeInput="tel" [isDisabled]="true" [value]="item.telefono"></so-input-reactive-form>
      </div>
    }
  </div>
  @if (communicationData?.correos?.length) {
    <div class="d-flex align-items-end ">
      <h6 class="mb-1 mt-3" style="font-weight: 500!important; color: #272D3B; margin: 0; font-size: 14px;">
          {{'emails'|translate}}</h6>
    </div>
  }

  <div style="position: relative; right: 4px;">
    @for (item of communicationData?.correos; track $index) {
        <so-input-email-reactive-form [disabled]="true" [email]="item.email"></so-input-email-reactive-form>
    }
  </div>
</div>
}
