<section>
  <div class="container--directory__inputs">
    <div [formGroup]="form">
      <app-so-input-form placeholder=""  label="{{'prospect_name'|translate}}" name="nombreCompleto" formControlName="nombreCompleto" ngDefaultControl/>
    </div>
    <div [formGroup]="form">
      <app-so-input-form placeholder="" label="{{'phone'|translate}}" name="telefono" formControlName="telefono" ngDefaultControl/>
    </div>
    <div [formGroup]="form">
      <app-so-input-form placeholder="" label="{{'email'|translate}}" name="correo" formControlName="correo" ngDefaultControl/>
    </div>
    <div [formGroup]="form">
      <so-input-date-reactive-form placeholder=""  label="{{'start_date'|translate}}" type="date" name="fechaInicio" formControlName="fechaInicio" ngDefaultControl/>
    </div>
    <div [formGroup]="form">
      <so-input-date-reactive-form placeholder="" label="{{'end_date'|translate}}" type="date" name="fechaFin" formControlName="fechaFin" ngDefaultControl/>
    </div>
    <div [formGroup]="form">
      <so-select-reactive-form [items]="typeOfDates" selectLabel="{{'date_type'|translate}}" name="idTipoFecha" formControlName="idTipoFecha" ngDefaultControl></so-select-reactive-form>
    </div>
  </div>
  <div class="result-search">
    <app-so-svg-icon src="assets/img/magnify.svg" (click)="advancedFilters()" color="--color-icon" [size]="1.2"></app-so-svg-icon>
    @if (activeQuickSearch) {
      <app-so-svg-icon src="assets/img/eraser.svg" (click)="clearAdvancedFilters()" color="--color-icon" [size]="1.2"></app-so-svg-icon>
      <div class="result-message">
        <app-so-text-state text="mostrando resultados de la busqueda rapida" color="primary" fontWeigth="bold"></app-so-text-state>
      </div>
    }
    
  </div>
  
</section>