<app-so-modal modalId="modalAsignarVenta" size="modal-xl">


  <!-- Modal header -->
  <div class="modal__header" [style]="{'display':'flex','width':'100%','margin-top':'30px'}">
    <div class="w-fit center-element">
      <app-icon-button class="so-secondary" firstIcon="true" size="18px"
        icon="./assets/img/mdi-hand-coin-outline.svg" text="{{'assign_sale'|translate}}" sizeIcon="11px"
        [styles]="{'color':'#505665','font-weight':'bold','padding':'10px'}"></app-icon-button>
    </div>
  </div>
  <!-- End Modal header -->


  <!-- Modal body -->
  <div class="modal__body row" [style]="{'padding':'30px','display':'flex','box-sizing':'border-box'}">


    <!-- Datos de facturacion -->
    <div class="d-flex justify-content-between " style="margin:10px;">

      <!-- Title -->
      <app-so-title-icon logo="/assets/img/mdi-file-sign.svg" title="{{'bill_data'|translate}}"></app-so-title-icon>
      <!-- End Title -->

      <div class="d-flex" style="gap:10px">
        <app-icon-button class="so-secondary" firstIcon="true" size="10px" icon="content_copy"
          text="{{'copy_private_address_full'|translate}}" sizeIcon="10px" [styles]="{'color':'#505665'}"></app-icon-button>
        <app-icon-button class="so-secondary" firstIcon="true" size="10px" icon="content_copy"
          text="{{'copy_office_address_full'|translate}}" sizeIcon="10px" [styles]="{'color':'#505665'}"></app-icon-button>
      </div>
    </div>

    <div class="form_billing_data d-flex" style="flex-wrap: wrap; gap: 10px;">

      <app-so-select style="width: 32%;" label="{{'person_type'|translate}}*"></app-so-select>
      <app-so-input-form style="width: 32%;" type="text" label="{{'rfc_abbreviation'|translate}} *"></app-so-input-form>
      <app-so-input-form style="width: 32%;" type="text" label="{{'curp_abbreviation'|translate}} *"></app-so-input-form>
      <app-so-input-form style="width: 32%;" type="text" label="{{'name_to_bill'|translate}} *"></app-so-input-form>
      <app-so-input-form style="width: 32%;" type="text" label="{{'street_text'|translate}} *"></app-so-input-form>

      <div class="d-flex" style="width: 32%;">
        <div style="width: 50%;">
          <app-so-input-form class="" type="text" label="{{'postal_code'|translate}} *"></app-so-input-form>
        </div>
        <div class="d-flex " style="width: 50%;">
          <app-so-input-form style="width: 50%;" type="text" label="{{'outdoor_number'|translate}} *"></app-so-input-form>
          <app-so-input-form style="width: 50%;" type="text" label="{{'indoor_number'|translate}} *"></app-so-input-form>
        </div>
      </div>

      <app-so-select style="width: 32%;" label="{{'state'|translate}}*"></app-so-select>
      <app-so-select style="width: 32%;" label="{{'city_or_delegation'|translate}} *"></app-so-select>
      <app-so-input-form style="width: 32%;" type="text" label="{{'neighborhood'|translate}}"></app-so-input-form>

    </div>

    <!-- End Datos de facturacion -->


    <!-- Datos de la venta -->

    <div class="d-flex justify-content-between " style="margin:10px;">

      <!-- Title -->
      <app-so-title-icon logo="/assets/img/mdi-file-percent-outline.svg"
        title="{{'sale_data'|translate}}"></app-so-title-icon>
      <!-- End Title -->
    </div>

    <div class="form_data_of_sale d-flex " style="flex-wrap: wrap; gap: 10px;">

      <app-so-input-form style="width: 32%;" type="text" label="{{'vin_abbreviation'|translate}} *"></app-so-input-form>
      <app-so-input-form style="width: 32%;" type="text" label="{{'order'|translate}} *"></app-so-input-form>
      <app-so-input-form style="width: 32%;" type="text" label="{{'invoice'|translate}} *"></app-so-input-form>
      <app-so-input-form style="width: 32%;" type="date" label="{{'invoice_date'|translate}}"></app-so-input-form>
      <app-so-select style="width: 32%;" label="{{'warranty_extension'|translate}}"></app-so-select>

      <div style="width: 32%;" style="width: 32%;">
        <p class="title" style="font-size: 14px;">{{'define_delivery_date'|translate}}*</p>
        <div class="d-flex justify-content-between  mt-1" style="width: 77%; gap: 10px;">
          <app-so-radio text="{{'yes'|translate}}" ratioId="ratio_no" nameGroup="datos_privados"></app-so-radio>
          <app-so-radio text="{{'no'|translate}}" ratioId="ratio_no" nameGroup="datos_privados"></app-so-radio>
          <app-so-radio text="{{'yes'|translate}}" ratioId="ratio_no" nameGroup="datos_privados"></app-so-radio>
        </div>
      </div>

      <app-so-select style="width: 32%;" label="{{'interest_car'|translate}} *"></app-so-select>
      <app-so-select style="width: 32%;" label="{{'car_model'|translate}} *"></app-so-select>
      <app-so-select style="width: 32%;" label="{{'car_version'|translate}} *"></app-so-select>

      <div class="d-flex" style="width: 32%; gap: 10px;">
        <app-so-select class="w-50" label="{{'car_color'|translate}}"></app-so-select>
        <app-so-select class="w-50" label="{{'car_interior_color'|translate}}"></app-so-select>
      </div>

      <div class="d-flex " style="width: 32%; gap: 10x;">
        <app-so-input-form class="w-50" type="text" label="{{'stock_number'|translate}} *"></app-so-input-form>
        <app-so-input-form class="w-50" type="text" label="{{'engine_number'|translate}} *"></app-so-input-form>
      </div>

      <app-so-input-form style="width: 32%;" type="text"
        label="{{'import_certificate'|translate}}*"></app-so-input-form>

      <app-so-select style="width: 32%;" label="{{'sale_type'|translate}}*"></app-so-select>
      <app-so-select style="width: 32%;" label="{{'payment_method'|translate}}*"></app-so-select>
      <app-so-input-form style="width: 32%;" type="text" label="{{'sale_price'|translate}}"></app-so-input-form>

      <div class="w-100 " style="margin-bottom: 5px;">
        <div class="d-flex">
          <div class="col-sm-4">
            <p class="title" style="font-size: 14px;">{{'is_an_used_car'|translate}}*</p>
            <div class="d-flex justify-content-between  mt-1" style="width: 77%; gap:10px">
              <app-so-radio text="{{'no'|translate}}" ratioId="ratio_no_usage" nameGroup="usado"></app-so-radio>
              <app-so-radio text="{{'yes'|translate}}" ratioId="ratio_no_usage" nameGroup="usado"></app-so-radio>
              <app-so-radio text="{{'yes'|translate}}" ratioId="ratio_no_usage_2" nameGroup="usado"></app-so-radio>
            </div>
          </div>

          <div class="col-sm-4">
            <p class="title" style="font-size: 14px;">{{'sale_with_auto_insurance'|translate}}*</p>
            <div class="d-flex justify-content-between  mt-1" style="width: 77%; gap:10px">
              <app-so-radio text="{{'no'|translate}}" ratioId="ratio_no_usage" nameGroup="usado"></app-so-radio>
              <app-so-radio text="{{'yes'|translate}}" ratioId="ratio_no_usage" nameGroup="usado"></app-so-radio>
              <app-so-radio text="{{'yes'|translate}}" ratioId="ratio_no_usage_2" nameGroup="usado"></app-so-radio>
            </div>
          </div>

          <div class="col-sm-4 ">
            <p class="title" style="font-size: 14px;">{{'car_replace_one_of_our_brand'|translate}}*</p>
            <div class="d-flex justify-content-between  mt-1" style="width: 77%; gap:10px">
              <app-so-radio text="{{'no'|translate}}" ratioId="ratio_no_usage" nameGroup="usado"></app-so-radio>
              <app-so-radio text="{{'yes'|translate}}" ratioId="ratio_no_usage" nameGroup="usado"></app-so-radio>
              <app-so-radio text="{{'yes'|translate}}" ratioId="ratio_no_usage_2" nameGroup="usado"></app-so-radio>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- End Datos de la venta -->



    <!-- Datos del vehiculo -->
    <div class="d-flex justify-content-between" style="margin:13px 0; ">

      <!-- Title -->
      <app-so-title-icon logo="/assets/img/mdi-car-key.svg"
        title="{{'who_drive_the_car'|translate}}"></app-so-title-icon>
      <!-- End Title -->

    </div>

    <div class="form-who_drive_the_car d-flex">
      <app-so-input-form class="col-sm-4" type="text" label="{{'name'|translate}}"></app-so-input-form>
      <app-so-input-form class="col-sm-4" type="text" label="{{'last_name'|translate}}"></app-so-input-form>
      <app-so-input-form class="col-sm-4" type="text" label="{{'mothers_last_name'|translate}}"></app-so-input-form>
    </div>

    <app-so-text-area label="observations"></app-so-text-area>

    <!-- End Datos del vehiculo -->


  </div>
  <!-- End Modal body -->

  <!-- Modal Footer -->

  <div class="modal__footer w-100">

    <div class="d-flex justify-content-between">
      <app-icon-button class="so-warning" firstIcon="true" size="12px" icon="./assets/img/mdi-alert-outline.svg"
        text="{{'required_fields'|translate}}" sizeIcon="10px"
        [styles]="{'color':'#505665','font-weight':'500'}"></app-icon-button>

      <div class="d-flex" style="gap: 10px;">
        <app-icon-button class="so-secondary" size="12px" icon="directions_car" text="{{'see_stock'|translate}}"
          sizeIcon="26px" [styles]="{'color':'#505665'}"></app-icon-button>
        <app-icon-button class="so-secondary" size="12px" icon="check_circle_outline" text="{{'save'|translate}}"
          sizeIcon="26px" [styles]="{'color':'#505665'}"></app-icon-button>
      </div>
    </div>
  </div>

  <!-- Modal Footer -->







</app-so-modal>
