import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TiposDePersona } from '@core/interfaces/catalogos.intefaces';
import { Request } from '@core/models/requests.model';

@Injectable({
  providedIn: 'root',
})
export class TiposDePersonaService {
  public token = localStorage.getItem('Token');
  private url = `global/catalogos/tipodepersona/all`;
  constructor(private http: HttpClient, private request: Request) {}

  getAllTiposDePersona() {
    const options = { headers: this.request.headers };
    return this.request.Get<TiposDePersona>( this.url,options);
  }
}
