// Closure para divir un nombre completo
export const splitNameFn = (name1Attribute: string, name2Attribute?: string, lastName1Attribute?: string, lastName2Attribute?: string) => (
  (name: string) => {
    const parts: string[] = name.split(' ').reduce<string[]>((acc, part) => {
      if (part.trim() !== '')
        acc.push(part)
      return acc;
    }, []);
  
    const splittedName: any = {};

    if (parts.length > 0)
      splittedName[name1Attribute] = parts[0];

    if (parts.length === 2 || parts.length === 3)
      if (lastName1Attribute)
        splittedName[lastName1Attribute] = parts[1];

    if (parts.length === 3) {
      if (lastName2Attribute) 
        splittedName[lastName2Attribute] = parts[2];
    }
    else if (parts.length === 4) {
      if (name2Attribute)
        splittedName[name2Attribute] = parts[1];
      if (lastName1Attribute)
        splittedName[lastName1Attribute] = parts[2];
      if (lastName2Attribute)
        splittedName[lastName2Attribute] = parts[3];
    }

    return splittedName;
  }
);
