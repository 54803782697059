import { Component } from '@angular/core';

@Component({
  selector: 'app-navbar-marketing',
  templateUrl: './navbar-marketing.component.html',
  styleUrls: ['./navbar-marketing.component.scss']
})
export class NavbarMarketingComponent {

  toggleBoolean() {
  }

 /*  icons = [
    {
      icon: 'mdi-server-network-success'
    },
    {
      icon: 'mdi-newspaper-variant-multiple-outline-success'
    },
    {
      icon: 'mdi-microsoft-excel-success'
    },
    {
      icon: 'database-search-outline-success'
    },
  ] */

}
