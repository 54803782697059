import { Component, Input } from '@angular/core';

@Component({
  selector: 'so-link',
  templateUrl: './so-link.component.html',
  styleUrls: ['./so-link.component.scss']
})
export class SoLinkComponent {
  @Input() route: string = '/home';
}
