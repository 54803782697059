import { Injectable } from '@angular/core';
import { ClosureType } from '@core/interfaces/catalogos.intefaces';
import { Request } from '@core/models/requests.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClosureTypesService {


  public token = localStorage.getItem('Token');
  private url = `ventas/catalogos/segmentacion/cierre/search`;

  constructor(
    private http: HttpClient,
    private request: Request
  ) { }

  getAllClosureTypes(body:any) {
    const options = { headers: this.request.headers };
    return this.request.Post<ClosureType>(this.url,body, options);
  }

  formatValues(data: any[], keyProp: string, valueProp: string): { key: any; value: any }[] {
    return data.map(item => ({ key: item[keyProp], value: item[valueProp] }));
  }
}
