import { Component, EventEmitter, inject, Output, Input, effect } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DynamicComponentService, AlertType } from '@shared/components/atomics/body-loading/dynamic-component-service.service';
import { take } from 'rxjs/operators';
import { EmployeeUpdate } from '@core/interfaces/catalogos.intefaces';
import { YourInformationService } from '@modules/settings/services/yourInformation/your-information.service';
import { StorageService } from '@core/services/storages.service';
import { TranslateService } from '@core/api/translate.service';
import { Request } from '@core/models/requests.model';
import { GlobalSessionService } from '@core/services/global-session.service';
import { UserStore } from '@core/stores/session.store';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-so-form-config',
  templateUrl: './so-form-config.component.html',
  styleUrls: ['./so-form-config.component.scss']
})
export class SoFormConfigComponent {
  private readonly yourInformationService = inject(YourInformationService);
  private readonly translateService = inject(TranslateService);
  public readonly globalSession = inject(GlobalSessionService);
  private readonly userStore = inject(UserStore);

  public miFormularioC!: FormGroup;
  public validForm?: boolean = false;
  private userInfo = this.globalSession.userInfo();

  constructor(
    private dynamicComponentService: DynamicComponentService,
    private formBuilder: FormBuilder,
    private StorageService: StorageService,
    private Request: Request,
    private dialog: MatDialog,
  ) {
    this.miFormularioC = this.formBuilder.group({
      nombreC: [''],
      segundoC: [''],
      paternoC: [''],
      maternoC: [''],
      telC: ['', [Validators.pattern('[0-9]{10}'), Validators.maxLength(10), Validators.minLength(10)]],
      nacimientoC: this.getBirthday(),
    });
  }
  private employee: EmployeeUpdate = {
    configuracion: this.Request.getConfigUser(),
    idEmpleado: this.userInfo.data.empleado.idEmpleado,
    nombre: '',
    segundo: '',
    paterno: '',
    materno: '',
    foto: '',
    genero: this.userInfo.data.empleado.genero,
    celular: this.userInfo.data.empleado.celular,
    email: this.userInfo.data.empleado.email,
    idGrupoDeTrabajo: this.userInfo.data.empleado.idGrupoDeTrabajo,
    idEmpresa: this.userInfo.data.empleado.empresa.idEmpresa,
    activo: this.userInfo.data.empleado.activo,
    idModificado: this.userInfo.data.empleado.idEmpleado,
    nacimiento: '',
    archivo: {
      nombre:'',
      contenido:''
    },
    perfiles:
      [{
        idPerfil: this.userInfo.data.perfiles[0].idPerfil,
        titulo: this.userInfo.data.perfiles[0].titulo
      }],
    idioma: {
      idIdioma: this.userInfo.data.empleado.empresa.idioma.idIdioma,
      idPais: this.userInfo.data.empleado.empresa.pais.idPais
    },
  }

  onChange(data: any) { }
  fnSave(event: any) {
    if(this.miFormularioC.pristine){
      return;
    };
    if (this.validFieldsRequired() > 0) {
        this.dynamicComponentService.showAlert({
        title: 'Error',
        message: this.translateService.instant('missing_required_fields'),
        htmlContent: '',
        alertType: AlertType.error,
        autohide: true,
        animate: true,
      });
    } else {
      this.validForm = this.isFormValid();
      this.sendData();
    }
  }

  sendData() {
    this.miFormularioC.dirty;
    this.dynamicComponentService.showBodyLoading();
    if (this.validForm) {
      this.builtData();
      this.yourInformationService
        .registerEmployee(JSON.stringify(this.employee, null, 2))
        .pipe(take(1))
        .subscribe(
          (success: any) => {
            this.dialog.openDialogs[0].close(this.employee);
            this.dynamicComponentService.destroyBodyLoading();
            this.dynamicComponentService.showAlert({
              title: this.translateService.instant('message')+':',
              message: success.message,
              htmlContent: '',
              alertType: AlertType.success,
              autohide: true,
              animate: true,
            });
            this.updateStorageData();
          },
          (rta: any) => {
            this.dynamicComponentService.destroyBodyLoading();
            this.dynamicComponentService.showAlert({
              title: 'Error',
              message: rta.error.message,
              htmlContent: '',
              alertType: AlertType.error,
              autohide: true,
              animate: true,
            });
          }
        );
    } else {
      this.dynamicComponentService.destroyBodyLoading();
      const alertOptions: any = {
        title: 'Error',
        message: this.translateService.instant('missing_required_fields'),
        alertType: AlertType.error,
        autohide: false,
        animate: true,
      };

      this.dynamicComponentService.showAlert(alertOptions);
    }
  }
  updateStorageData(){
    const form = this.miFormularioC.value;
    this.globalSession.userInfo().data.empleado.nombre = form['nombreC'] != '' ? form['nombreC'] : this.userInfo.data.empleado.nombre;
    this.globalSession.userInfo().data.empleado.segundo = form['segundoC'] != '' ? form['segundoC'] : this.userInfo.data.empleado.segundo;
    this.globalSession.userInfo().data.empleado.paterno = form['paternoC'] != '' ? form['paternoC'] : this.userInfo.data.empleado.paterno;
    this.globalSession.userInfo().data.empleado.materno = form['maternoC'] != '' ? form['maternoC'] : this.userInfo.data.empleado.materno;
    this.globalSession.userInfo().data.empleado.celular = form['telC'] != '' ? form['telC'] : this.userInfo.data.empleado.celular;
    if(this.miFormularioC.value.nacimientoC !== '' && this.miFormularioC.value.nacimientoC != this.getBirthday()){
      this.StorageService.setLocalStorage('birthday', this.employee.nacimiento);
    }else{
      this.employee.nacimiento = this.StorageService.getLocalStorage('birthday') ?? '';
    }
    this.userStore.setUser(this.globalSession.userInfo());
  }

  validFieldsRequired() {
    let count = 0;
    document.querySelectorAll('#modal-configuracion app-so-input-form').forEach((e) => {
      const input = e.querySelector('input');
      const div = e.querySelector('div');
      if (input?.value === '') {
        if (input.required) {
          div?.classList.add('invalid');
          count++;
        } else {
          div?.classList.add('valid');
        }
      }
    });
    document.querySelectorAll('#modal-configuracion app-so-select').forEach((e) => {
      const select = e.querySelector('select');
      const div = e.querySelector('div');
      const selectedIndex = select?.selectedIndex;
      const value = select?.options[!selectedIndex ? 0 : selectedIndex].value;
      if (selectedIndex === 0) {
        if (select?.required) {
          div?.classList.add('invalid');
          count++;
        } else {
          div?.classList.add('valid');
        }
      }
    });
    return count;
  }

  isFormValid(): boolean {
    return this.miFormularioC.valid;
  }

  builtData() {
    if (this.isFormValid()) {
      this.employee.nombre = this.miFormularioC.value.nombreC !== '' ? this.miFormularioC.value.nombreC : '';
      this.employee.segundo = this.miFormularioC.value.segundoC !== '' ? this.miFormularioC.value.segundoC : '';
      this.employee.paterno = this.miFormularioC.value.paternoC !== '' ? this.miFormularioC.value.paternoC : '';
      this.employee.materno = this.miFormularioC.value.maternoC !== '' ? this.miFormularioC.value.maternoC : '';
      this.employee.celular = this.miFormularioC.value.telC !== '' ? this.miFormularioC.value.telC : '';
      if(this.miFormularioC.value.nacimientoC !== '' && this.miFormularioC.value.nacimientoC != this.getBirthday()){
        this.employee.nacimiento =  this.miFormularioC.value.nacimientoC + ' 00:00:00';
      }else{
        this.employee.nacimiento = this.StorageService.getLocalStorage('birthday') ?? '';
      }
    } else {
      console.error('El formulario no es válido o no está definido.');
    }
  }
  getBirthday(){
    return this.StorageService.getLocalStorage('birthday');
  }
}
