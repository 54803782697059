<div>
  <app-so-modal modalId="modal-reject-prospect" [verticalPosition]="'center'">
    <div class="modal__header">
    </div>
    <div class="modal__body" [style]="{'padding':'10px'}">
      <div class="d-flex justify-content-center gap-2">
        <so-icon svg="mdi-minus-circle-outline" [size]="0.8" />
        <p class="fw-bold ml-2" style="font-size: 19px;">{{'reject_prospect'| translate}}</p>
      </div>
      <section>
        <div class="gap-2" style="margin-left: 10px;margin-top: 20px;">
          <div class="col-12">
            <p class="title">
              {{'contact_to_prospect'|translate}}</p>
          </div>
          <div class="d-flex col-12 gap-2 mt-1">
            <app-so-radio text="" ratioId="ratio_yes" [style]="{'flex-direction':'reverse'}"
              nameGroup="datos_privados"></app-so-radio> <label class="labelRadio">Si pude</label>
            <app-so-radio text="" ratioId="ratio_no" [style]="{'margin-left':'85px'}"
              nameGroup="datos_privados"></app-so-radio> <label class="labelRadio">Si pude</label>
          </div>
        </div>
        <div style="margin-top: 5px;">
          <app-so-select label="{{'reason_for_rejection'|translate}}" [options]="options"></app-so-select>
        </div>
        <div style="margin-top: 5px;">
          <app-so-text-area label="{{'observations'|translate}}"></app-so-text-area>
        </div>
      </section>
      <div class="col d-flex justify-content-center align-items-center">
        <img class="float-start" src="./assets/img/mdi-alert-outline.svg" style="width: 26px; height:30px">
        <label>{{ 'required_fields' | translate }}</label>
      </div>
      <div class="text-center">
        <app-button svg="mdi-check-circle-outline" iconAlignment="right" [scale]=".8" text="{{ 'reject' | translate }}"
        [injection]="{ label: 'pt-2' }"  data-bs-toggle="modal"/>
      </div>
    </div>
  </app-so-modal>
</div>
